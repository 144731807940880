import { group as radashGroup, sum as radashSum, unique as radashUnique } from 'radash';

import { ChartService } from '../../../../services/chart.service';
import { ColorService } from '../../../../services/color.service';
import template from './pbo-data.component.html';

const KeyEnum = {
    LOGICAL_TYPE: 'logicalType',
    NB_POSITIONS: 'nbPositions',
    POSITION_FUNCTION: 'positionFunction',
};

class PboDataComponent {
    barChartData = { datasets: [], labels: [] };
    chartSize = 180;
    columns = [];
    doughnutChartData = { bigLegend: {}, datasets: [], labels: [] };
    data = [];
    parentRef = {};

    constructor($filter) {
        this._translate = $filter('translate');
    }

    $onInit() {
        this.doughnutChartData = this.getDoughnutChartData(this.data);
        this.barChartData = this.getBarChartData(this.data);
        this.columns = this.getColumns();
    }

    getBarChartData(data) {
        const labels = radashUnique(data.map((currentData) => currentData[KeyEnum.POSITION_FUNCTION]));

        const dataGroupedByLogicalType = radashGroup(data, (currentData) => currentData[KeyEnum.LOGICAL_TYPE]);

        return {
            datasets: Object.entries(dataGroupedByLogicalType).map(([key, values]) => {
                const tableChartData = labels.map((label) => {
                    const findValue = values.find((value) => value[KeyEnum.POSITION_FUNCTION] === label);

                    return findValue?.[KeyEnum.NB_POSITIONS] || 0;
                });

                return ChartService.getTableChartDataset(
                    tableChartData,
                    key,
                    ColorService.getCableLogicalTypeColor(key),
                );
            }),
            labels: labels,
        };
    }

    getColumns() {
        return [
            {
                key: KeyEnum.LOGICAL_TYPE,
                translation: this._translate('shared.logicalType'),
            },
            {
                key: KeyEnum.POSITION_FUNCTION,
                translation: this._translate('shared.positionFunction'),
            },
            {
                key: KeyEnum.NB_POSITIONS,
                translation: this._translate('shared.nbPositions'),
            },
        ];
    }

    getDoughnutChartData(data) {
        const positionCount = radashSum(data, (currentData) => currentData[KeyEnum.NB_POSITIONS] || 0);
        const dataGroupedByFunction = radashGroup(data, (currentData) => currentData[KeyEnum.POSITION_FUNCTION]);

        return {
            bigLegend: {
                figure: positionCount,
                name: this._translate('shared.location', { COUNT: positionCount }),
            },
            datasets: [
                {
                    backgroundColor: Object.keys(dataGroupedByFunction).map((key) =>
                        ColorService.getPositionFunctionTypeColor(key),
                    ),
                    data: Object.values(dataGroupedByFunction).map((currentData) =>
                        radashSum(currentData, (value) => value[KeyEnum.NB_POSITIONS] || 0),
                    ),
                },
            ],
            labels: Object.keys(dataGroupedByFunction),
        };
    }
}

angular.module('dotic').component('pboDataIndicator', {
    bindings: {
        data: '<',
        parentRef: '<',
    },
    controller: PboDataComponent,
    templateUrl: template,
});
