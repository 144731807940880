import { sum as radashSum } from 'radash';

import { ColorService } from '../../../../services/color.service';
import template from './local-number-by-implantation.component.html';

const KeyEnum = {
    AD_RACC: 'adRacc',
    IMPLANTATION: 'implantation',
    HOUSING_COUNT: 'housingCount',
    PERCENT: 'percent',
};

class LocalNumberByImplantationComponent {
    chartData = { bigLegend: {}, datasets: [], labels: [] };
    chartSize = 180;
    columns = [];
    data = [];
    parentRef = {};
    tableTitle = '';

    constructor($filter) {
        this._translate = $filter('translate');
    }

    $onInit() {
        this.chartData = this.getChartData(this.data);
        this.columns = this.getColumns();
        this.tableTitle = this._translate('shared.detailLocalCount');
    }

    getChartData(data) {
        const housingCount = radashSum(data, (currentData) => currentData[KeyEnum.HOUSING_COUNT] || 0);
        const colors = data.map((currentData) =>
            ColorService.getImplantationTypeColor(currentData[KeyEnum.IMPLANTATION]),
        );

        return {
            bigLegend: {
                figure: housingCount,
                name: this._translate('shared.local', { COUNT: housingCount }),
            },
            datasets: [
                {
                    backgroundColor: colors,
                    data: data.map((currentData) => currentData[KeyEnum.HOUSING_COUNT]),
                },
            ],
            labels: data.map((currentData) => currentData[KeyEnum.IMPLANTATION]),
        };
    }

    getColumns() {
        return [
            {
                key: KeyEnum.AD_RACC,
                translation: `${this._translate('shared.connectionTypeId')} (ad_racc)`,
            },
            {
                key: KeyEnum.IMPLANTATION,
                translation: this._translate('shared.implantationType'),
            },
            {
                key: KeyEnum.HOUSING_COUNT,
                translation: this._translate('shared.housingCount'),
            },
            {
                key: KeyEnum.PERCENT,
                translation: this._translate('shared.housingRatioByConnectionType'),
            },
        ];
    }
}

angular.module('dotic').component('localNumberByImplantationIndicator', {
    bindings: {
        data: '<',
        parentRef: '<',
    },
    controller: LocalNumberByImplantationComponent,
    templateUrl: template,
});
