import { pick as radashPick } from 'radash';

import {
    CompaniesApi,
    DataModelsApi,
    DepositMinimalPreservationDays,
    FMEServersApi,
} from '../../../../sdk/connect-control-api-v1/src';
import { GeoserverConnectionStateEnum, SubscriptionOptionEnum } from '../../../models/company.model';
import defaultLogo from '../../../../assets/images/pdf/Logo_ConnectControl_Black_1_line.svg';
import { formTypeEnum } from '../../../models/form.model';
import { SynopticColorStandardEnum } from '../../../../sdk/connect-control-api-v1/src';
import template from './company-form.component.html';

class CompanyFormComponent {
    base64OrUrlSelectedLogo = null;
    calculationPlugs = {};
    companyForm = {};
    companyFormValue = { subscription: SubscriptionOptionEnum.BASIC, subCompanies: [] };
    companySubscriptionOptions = [];
    dataModels = [];
    defaultLogo = defaultLogo;
    fmeServers = [];
    geoserverConnexionState = GeoserverConnectionStateEnum.INFO;
    isCreationForm = false;
    isSubCompanyOptionToggled = false;
    loading = true;
    newPage = [];
    passwordInput = {
        secretAccessKey: 'secretAccessKey',
        geoserverPassword: 'geoserverPassword',
    };
    /** @type {passwordInput | ''} */
    passwordVisibility = '';
    // Case undefined means that no logo modification is asked.
    selectedLogo = undefined;
    subCompanies = [];
    submitText = '';
    synopticColorStandardEnumOptions = [];
    title = '';

    constructor($apiClientService, $fileService, $filter, $scope, $state, $stateParams, $timeout, $toasterService) {
        this._$fileService = $fileService;
        this._$scope = $scope;
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._translate = $filter('translate');

        this._companiesApi = new CompaniesApi($apiClientService.client);
        this._dataModelsApi = new DataModelsApi($apiClientService.client);
        this._fmeServersApi = new FMEServersApi($apiClientService.client);
        this.DepositMinimalPreservationDays = new DepositMinimalPreservationDays();
    }

    async $onInit() {
        this.title = this._translate('shared.loading');
        this.subCompanies = await this.getAllSubCompanies();
        this.fmeServers = await this.getFMEServers();
        this.companySubscriptionOptions = [
            {
                value: SubscriptionOptionEnum.BASIC,
                label: this._translate('shared.basic'),
            },
            {
                value: SubscriptionOptionEnum.ADVANCED,
                label: this._translate('shared.advanced'),
            },
        ];
        const synopticColorStandardEnum = new SynopticColorStandardEnum();
        this.synopticColorStandardEnumOptions = [
            {
                value: synopticColorStandardEnum.EUROPE,
                label: 'Europe',
            },
            {
                value: synopticColorStandardEnum.ORANGE,
                label: 'Orange',
            },
        ];

        await this.setPage(this._$stateParams.formType);

        // Timeout necessary as AngularJs doesn't trigger digest cycle from async / await method or function.
        this._$timeout(() => (this.loading = false));

        this._$scope.$emit('keepPreviousNavigation', {
            newPage: this.newPage,
            defaultPrevious: {
                title: this._translate('shared.company'),
                href: this._$state.href('app.companyList'),
                key: 'companies',
            },
            allowedPreviousKeys: ['companies'],
        });
    }

    async checkGeoserverConnection() {
        if (!this.companyFormValue.geoserver?.url) {
            this._$toasterService.error({
                body: this._translate('companyForm.checkGeoserverConnection.error'),
            });

            return;
        }

        try {
            await this._companiesApi.checkGeoserverConnection({
                ...radashPick(this.companyFormValue.geoserver, ['password', 'url', 'userName']),
                company: this.companyFormValue.id,
            });
            this.geoserverConnexionState = GeoserverConnectionStateEnum.SUCCESS;
            this._$toasterService.success({
                body: this._translate('companyForm.checkGeoserverConnection.success'),
            });
        } catch {
            this.geoserverConnexionState = GeoserverConnectionStateEnum.ALERT;
            this._$toasterService.error({
                body: this._translate('companyForm.checkGeoserverConnection.alert'),
            });
        }
    }

    async deleteCompanyLogo() {
        try {
            await this._companiesApi.deleteCompanyLogo(this.companyFormValue.id);
            angular.element('#companyLogo_file').val(null);
            this.companyFormValue.companyLogo = null;
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    async fetchCompany(companyId) {
        try {
            const companyFormValue = (await this._companiesApi.getDataToEditWithHttpInfo(companyId)).response.body;
            this.base64OrUrlSelectedLogo = companyFormValue.companyLogo?.locationDist;
            this.isSubCompanyOptionToggled = !!companyFormValue.subCompanies.length;

            this.dataModels = await this.initDataModels();
            this.calculationPlugs = this.initCalculationPlugs(companyFormValue, this.dataModels);

            return { ...companyFormValue, geoserver: companyFormValue.geoserver ?? {} };
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    async getAllSubCompanies() {
        try {
            return (await this._companiesApi.getAllSubCompaniesWithHttpInfo()).response.body;
        } catch (error) {
            this._$toasterService.error(error);

            return [];
        }
    }

    async getFMEServers() {
        try {
            const servers = (await this._fmeServersApi.listWithHttpInfo()).response.body;

            return servers.map((server) => {
                return { label: server.name, value: server._id };
            });
        } catch (error) {
            this._$toasterService.error(error);

            return [];
        }
    }

    getSubCompanyIds(company) {
        if (!this.isSubCompanyOptionToggled || company.settings?.feature?.isSubCompany) {
            return [];
        }

        return company.subCompanies.map((subCompany) => subCompany._id);
    }

    async initCompany() {
        try {
            const company = (await this._companiesApi.getNewWithHttpInfo()).response.body;

            this.dataModels = await this.initDataModels();
            this.calculationPlugs = this.initCalculationPlugs(company, this.dataModels);

            return company;
        } catch (error) {
            if (!error.data?.message) {
                return;
            }

            this._$toasterService.error(error.data.message);
        }
    }

    initCalculationPlugs(company, dataModels) {
        return dataModels.reduce((acc, dataModel) => {
            const calculationPlug = company.settings.calculationPlugs.find(
                (calculation) => calculation.dataModel === dataModel._id,
            );

            return {
                ...acc,
                [dataModel._id]: {
                    attributes: calculationPlug?.attributes ?? dataModel.settings.calculationPlugsDefault,
                    dataModel: dataModel._id,
                },
            };
        }, {});
    }

    async initDataModels() {
        try {
            const dataModels = (await this._dataModelsApi.listNamesWithHttpInfo({})).response.body;

            return dataModels.map((dataModel) => {
                return {
                    ...dataModel,
                    options: dataModel.settings.calculationPlugsChoice.map((calculation) => {
                        return {
                            key: calculation,
                            value: calculation,
                        };
                    }),
                };
            });
        } catch (error) {
            this._$toasterService.error(error);

            return [];
        }
    }

    isSubCompanyActive(id) {
        return this.companyFormValue.subCompanies.some((subCompany) => subCompany.id === id);
    }

    async onSubmit() {
        const dataToSubmit = {
            ...this.companyFormValue,
            ...(this.companyFormValue.settings?.calculationPlugs && {
                settings: {
                    ...this.companyFormValue.settings,
                    calculationPlugs: Object.values(this.calculationPlugs),
                },
            }),
            subCompanies: this.getSubCompanyIds(this.companyFormValue),
        };

        try {
            switch (this._$stateParams.formType) {
                case formTypeEnum.EDITION:
                    await this._companiesApi.update(this._$stateParams.companyId, dataToSubmit);
                    break;
                case formTypeEnum.CREATION:
                case formTypeEnum.DUPLICATION:
                default:
                    await this._companiesApi.create(dataToSubmit);
            }

            // Escape case undefined, as it means that no logo modification is asked.
            if (this._$stateParams.formType === formTypeEnum.EDITION && this.selectedLogo === null) {
                await this.deleteCompanyLogo();
            }

            if (this.selectedLogo) {
                await this.updateCompanyLogo(this.companyFormValue, this.selectedLogo);
            }

            this._$toasterService.success({
                body: this._translate('companyForm.editCompanySuccessMessage'),
            });
            this._$state.go('app.companyList');
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    async setPage(formType) {
        switch (formType) {
            case formTypeEnum.DUPLICATION:
                this.isCreationForm = false;
                this.companyFormValue = await this.fetchCompany(this._$stateParams.companyId);
                this.title = this._translate('companyForm.duplicateCompany');
                this.submitText = this._translate('shared.create');
                this.newPage = [
                    {
                        key: 'duplicateCompany',
                        title: this.title,
                        href: this._$state.href('app.companyDuplicate'),
                    },
                ];

                break;
            case formTypeEnum.EDITION:
                this.isCreationForm = false;
                this.companyFormValue = await this.fetchCompany(this._$stateParams.companyId);
                this.title = this._translate('companyForm.editCompany');
                this.submitText = this._translate('shared.edit');
                this.newPage = [
                    {
                        key: 'editCompany',
                        title: this.title,
                        href: this._$state.href('app.companyEdit'),
                    },
                ];

                break;
            case formTypeEnum.CREATION:
            default:
                this.isCreationForm = true;
                this.companyFormValue = await this.initCompany();
                this.title = this._translate('companyForm.createCompany');
                this.submitText = this._translate('shared.create');
                this.newPage = [
                    {
                        key: 'createCompany',
                        title: this.title,
                        href: this._$state.href('app.companyCreate'),
                    },
                ];
        }
    }

    setGeoserverPassword(newPassword) {
        if (!newPassword) {
            delete this.companyFormValue.geoserver.password;

            return;
        }

        this.companyFormValue.geoserver.password = newPassword;
    }

    setGEDFibrePassword(newPassword) {
        if (!newPassword) {
            delete this.companyFormValue.orangeSettings.GEDFibrePassword;

            return;
        }

        this.companyFormValue.orangeSettings.GEDFibrePassword = newPassword;
    }

    setS3SecretAccessKey(newKey) {
        if (!newKey) {
            delete this.companyFormValue.s3.secretAccessKey;

            return;
        }

        this.companyFormValue.s3 = {
            ...this.companyFormValue.s3,
            secretAccessKey: newKey,
        };
    }

    setPostProcessingManagment() {
        this.companyFormValue.settings.feature.hasPostProcessManagerEnabled =
            !this.companyFormValue.settings.feature.hasPostProcessEnabled;
    }

    async setSelectedLogo(file) {
        this.selectedLogo = file;
        this.base64OrUrlSelectedLogo = await this._$fileService.convertToBase64(file);
    }

    setSubCompany(subCompanyToSet) {
        const subCompanies = this.companyFormValue.subCompanies;

        if (subCompanies.every((subCompany) => subCompany.id !== subCompanyToSet.id)) {
            this.companyFormValue.subCompanies = [...subCompanies, subCompanyToSet];

            return;
        }

        this.companyFormValue.subCompanies = subCompanies.filter((subCompany) => subCompany.id !== subCompanyToSet.id);
    }

    toggleAddSubCompany() {
        this.isSubCompanyOptionToggled = !this.isSubCompanyOptionToggled;
    }

    /**
     * @param {passwordInput} inputName
     */
    togglePasswordVisibility(inputName) {
        this.passwordVisibility = this.passwordVisibility === inputName ? '' : inputName;
    }

    async updateCompanyLogo(company, file) {
        if (!file) {
            return;
        }

        try {
            const data = (await this._companiesApi.postCompanyLogoWithHttpInfo(company._id, { file: file })).response
                .body;
            this.companyFormValue.companyLogo = data.company.companyLogo;
        } catch (error) {
            this._$toasterService.error(error);
        }
    }
}

angular.module('dotic').component('companyFormPage', {
    controller: CompanyFormComponent,
    templateUrl: template,
});
