import { fork as radashFork } from 'radash';

import { DepositsApi, ReportsApi } from '../../../../sdk/connect-control-api-v1/src';
import template from './anct-verification-compare.component.html';

class ANCTVerificationCompareComponent {
    ANCTVerificationA = {
        controls: [],
        deposit: null,
        formattedControls: [],
        indicatorsDataStructures: null,
    };
    ANCTVerificationB = {
        controls: [],
        deposit: null,
        formattedControls: [],
        indicatorsDataStructures: null,
    };
    company = {};
    controlComparisons = [];
    controlComparisonDetailToDisplay = '';
    controlDiffCount = 0;
    controlPointsAnomalyImprovementCount = 0;
    controlPointsAnomalyRegressionCount = 0;
    controlSameCount = 0;
    indicatorToDisplay = 'controls';
    isIndicatorsVisible = false;
    isAnomaliesVisible = false;
    loading = true;

    constructor(
        $apiClientService,
        $dataModelService,
        $excelService,
        $filter,
        $filterService,
        $location,
        $modalService,
        $reportService,
        $scope,
        $segmentsService,
        $state,
        $timeout,
        $toasterService,
        indicatorChartService,
        LoaderService,
        reportProvider,
        userMetricsProvider,
    ) {
        this._$dataModelService = $dataModelService;
        this._$excelService = $excelService;
        this._$filterService = $filterService;
        this._humanizeSeverity = $filter('humanizeSeverity');
        this._translate = $filter('translate');
        this._$location = $location;
        this._$modalService = $modalService;
        this._$reportService = $reportService;
        this._$scope = $scope;
        this._$segmentsService = $segmentsService;
        this._$state = $state;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._LoaderService = LoaderService;
        this._depositApi = new DepositsApi($apiClientService.client);
        this._indicatorChartService = indicatorChartService;
        this._reportApi = new ReportsApi($apiClientService.client);
        this._reportProvider = reportProvider;
        this._userMetricsProvider = userMetricsProvider;
    }

    async initANCTVerification(depositId) {
        const deposit = (await this._depositApi.getByIdWithHttpInfo(depositId)).response.body;
        const report = (await this._reportApi.getDetailsWithHttpInfo(deposit.report._id, { isANCT: true })).response
            .body;
        const reportStatistic = (await this._reportApi.getReportStatisticsWithHttpInfo(report._id)).response.body;
        const indicatorsDataStructures = this._indicatorChartService.generateANCTFullDataStructure(
            reportStatistic.indicators.ANCT,
        );
        // Necessary for control synthesis indicator
        const formattedControls = this._$reportService.getControls(
            report.controls,
            report.deposit.gridControl,
        ).controls;

        return {
            controls: report.controls,
            deposit: deposit,
            formattedControls: formattedControls,
            indicatorsDataStructures: indicatorsDataStructures,
        };
    }

    async $onInit() {
        this.loading = true;

        try {
            this.ANCTVerificationA = await this.initANCTVerification(this._$state.params.depositAId);
            this.ANCTVerificationB = await this.initANCTVerification(this._$state.params.depositBId);

            this._userMetricsProvider.compareReports(
                this.ANCTVerificationA.deposit.report._id,
                this.ANCTVerificationB.deposit.report._id,
            );

            this.controlComparisons = this._$reportService.getComparisonReports(
                this.ANCTVerificationA,
                this.ANCTVerificationB,
            );

            const [sameControls, diffControls] = radashFork(
                this.controlComparisons,
                (controlComparison) =>
                    controlComparison.faults1 === controlComparison.faults2 &&
                    controlComparison.enabled1 === controlComparison.enabled2,
            );
            this.controlDiffCount = diffControls.length;
            this.controlSameCount = sameControls.length;
            this.controlPointsAnomalyRegressionCount = this.controlComparisons.filter(
                (controlComparison) =>
                    controlComparison.invalid_count1 < controlComparison.invalid_count2 &&
                    controlComparison.enabled1 === controlComparison.enabled2,
            ).length;
            this.controlPointsAnomalyImprovementCount = this.controlComparisons.filter(
                (controlComparison) =>
                    controlComparison.invalid_count1 > controlComparison.invalid_count2 &&
                    controlComparison.enabled1 === controlComparison.enabled2,
            ).length;
        } catch (error) {
            this._$toasterService.error(error);
        }

        this._$scope.$emit('keepPreviousNavigation', {
            newPage: [
                {
                    key: 'anct-comparison',
                    title: this._translate('shared.comparison'),
                    href: this._$location.path(),
                },
            ],
            defaultPrevious: {
                title: this._translate('shared.ANCTVerifiedDeposit'),
                href: this._$state.href('app.anctVerificationsList'),
                key: 'anctVerifications',
            },
            allowedPreviousKeys: ['anctVerification', 'anctVerifications'],
        });

        this._$timeout(() => {
            this.loading = false;
        });
    }

    findControlPoint(data, item) {
        return (
            data.find((control) => control.controlPoint === item.controlPoint && control.severity === item.severity) ??
            {}
        );
    }

    async openControlErrors(item) {
        if (item.invalid_count1 <= 0 && item.invalid_count2 <= 0) {
            return;
        }

        const depositAId = this._$state.params.depositAId;
        const depositBId = this._$state.params.depositBId;

        const versionA = this.ANCTVerificationA.deposit.version;
        const versionB = this.ANCTVerificationB.deposit.version;

        const controlA = this.findControlPoint(this.ANCTVerificationA.deposit.report.controls, item) || {};
        const controlB = this.findControlPoint(this.ANCTVerificationB.deposit.report.controls, item) || {};

        if (controlA.invalid_count <= 0 && controlB.invalid_count <= 0) {
            return;
        }

        const hasControlPointInError = !Object.entries(controlA).length || !Object.entries(controlB).length;
        if (!hasControlPointInError) {
            try {
                const controlErrors = await Promise.all([
                    this._reportProvider.getVersionControlJson(depositAId, controlA.controlPoint, controlA.severity),
                    this._reportProvider.getVersionControlJson(depositBId, controlB.controlPoint, controlB.severity),
                ]);
                const control = angular.isDefined(controlA)
                    ? { ...controlA, versionA, versionB }
                    : { ...controlB, versionA, versionB };

                return this._$modalService.triggerControlErrorModal(control, controlErrors, undefined, false);
            } catch (error) {
                this._$toasterService.error(error);
            }
        }

        this.reportId =
            angular.isDefined(controlA) && controlA.faults >= 0
                ? this.ANCTVerificationA.deposit.report.id
                : this.ANCTVerificationB.deposit.report.id;
        const control = angular.isDefined(controlA) && controlA.faults >= 0 ? controlA : controlB;

        return this._$modalService.triggerControlErrorModal(
            control,
            this._$reportService.getErrors(this.reportId, control.controlPoint, control.severity),
        );
    }

    toggleAnomaliesVisibility() {
        this.isAnomaliesVisible = !this.isAnomaliesVisible;
    }

    toggleControlComparisonDetail(controlPoint) {
        this.controlComparisonDetailToDisplay =
            controlPoint !== this.controlComparisonDetailToDisplay ? controlPoint : null;
    }

    toggleIndicatorsVisibility() {
        this.isIndicatorsVisible = !this.isIndicatorsVisible;
    }
}

angular.module('dotic').component('anctVerificationsCompare', {
    controller: ANCTVerificationCompareComponent,
    templateUrl: template,
});
