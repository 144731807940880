/**
 * ConnectControl API Documentation (v1)
 * The official ConnectControl Open API documentation (v1)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class OrderEnum.
* @enum {}
* @readonly
*/
export default class OrderEnum {
    
        /**
         * value: "asc"
         * @const
         */
        "ASC" = "asc";

    
        /**
         * value: "desc"
         * @const
         */
        "DESC" = "desc";

    
        /**
         * value: ""
         * @const
         */
        "NONE" = "";

    

    /**
    * Returns a <code>OrderEnum</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/OrderEnum} The enum <code>OrderEnum</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

