/**
 * ConnectControl API Documentation (v1)
 * The official ConnectControl Open API documentation (v1)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The CreateFttxHeritageInventoryDto model module.
 * @module model/CreateFttxHeritageInventoryDto
 * @version 1.0.0
 */
class CreateFttxHeritageInventoryDto {
    /**
     * Constructs a new <code>CreateFttxHeritageInventoryDto</code>.
     * @alias module:model/CreateFttxHeritageInventoryDto
     * @param deposit {String} 
     * @param project {String} 
     */
    constructor(deposit, project) { 
        
        CreateFttxHeritageInventoryDto.initialize(this, deposit, project);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, deposit, project) { 
        obj['deposit'] = deposit;
        obj['project'] = project;
    }

    /**
     * Constructs a <code>CreateFttxHeritageInventoryDto</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CreateFttxHeritageInventoryDto} obj Optional instance to populate.
     * @return {module:model/CreateFttxHeritageInventoryDto} The populated <code>CreateFttxHeritageInventoryDto</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CreateFttxHeritageInventoryDto();

            if (data.hasOwnProperty('deposit')) {
                obj['deposit'] = ApiClient.convertToType(data['deposit'], 'String');
            }
            if (data.hasOwnProperty('project')) {
                obj['project'] = ApiClient.convertToType(data['project'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>CreateFttxHeritageInventoryDto</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CreateFttxHeritageInventoryDto</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of CreateFttxHeritageInventoryDto.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['deposit'] && !(typeof data['deposit'] === 'string' || data['deposit'] instanceof String)) {
            throw new Error("Expected the field `deposit` to be a primitive type in the JSON string but got " + data['deposit']);
        }
        // ensure the json data is a string
        if (data['project'] && !(typeof data['project'] === 'string' || data['project'] instanceof String)) {
            throw new Error("Expected the field `project` to be a primitive type in the JSON string but got " + data['project']);
        }

        return true;
    }


}

CreateFttxHeritageInventoryDto.RequiredProperties = ["deposit", "project"];

/**
 * @member {String} deposit
 */
CreateFttxHeritageInventoryDto.prototype['deposit'] = undefined;

/**
 * @member {String} project
 */
CreateFttxHeritageInventoryDto.prototype['project'] = undefined;






export default CreateFttxHeritageInventoryDto;

