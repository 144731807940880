import template from './controlGridAdminList.html';

class ControlGridAdminListController {
    constructor(
        $auth,
        $authorizationService,
        $deleteService,
        $filter,
        $gridControlService,
        $gridService,
        $location,
        $modalService,
        $scope,
        $state,
        $tableService,
        $timeout,
        $toasterService,
        companyProvider,
        gridControlProvider,
        userMetricsProvider,
    ) {
        this._$deleteService = $deleteService;
        this._$gridControlService = $gridControlService;
        this._$gridService = $gridService;
        this._$state = $state;
        this._$tableService = $tableService;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._$scope = $scope;
        this._companyProvider = companyProvider;
        this._gridControlProvider = gridControlProvider;
        this._translate = $filter('translate');
        this._$modalService = $modalService;

        this.page = $state.params.p || null;
        this.pageSize = 10;
        this.sort = { column: 'updatedAt', order: -1 };
        this.sort = { column: 'updateParent', order: -1 };
        this.totalCount = 0;

        this.isAllowed = $authorizationService.isAllowed;
        this.companyId = $auth.getPayload().company;

        this.gridList = [];
        this.gridTemplateList = [];
        this.isTemplateVisible = false;
        this.isWorkingGridsVisible = true;

        this.tableSelect = this._$tableService.select();
        this.tableDetail = this._$tableService.detail();

        this.filter = {
            search: $state.params.search || '',
        };

        $scope.$watchGroup(['$ctrl.filter.search'], (next, prev) => {
            if (next !== prev) {
                this.onFiltersChange();
            }
        });

        $scope.$emit('updateNavigation', {
            newPage: [
                {
                    key: 'controlGrids',
                    title: this._translate('shared.controlGrid'),
                    href: $location.path(),
                },
            ],
        });

        $scope.$on('$locationChangeSuccess', () => {
            this.filter.search = $state.params.search || '';
            $scope.$emit('updateNavigationUrl');
        });

        // Watch selection
        $scope.$on('selectRows', (e, data) => {
            this.tableSelect.select(data.rows, true);
        });

        userMetricsProvider.listControlGrids();
    }

    async $onInit() {
        await this.getLists();
    }

    async getLists() {
        this.loading = true;

        try {
            await Promise.all([this.search(this.page, this.pageSize, this.sort), this.getAdminTemplates()]);
            this._$scope.$apply();
        } catch (error) {
            this._$toasterService.error(error);
        }

        // Timeout necessary as AngularJs doesn't trigger digest cycle from async / await method or function
        this._$timeout(() => (this.loading = false));
    }

    async getAdminTemplates() {
        this.gridTemplateList = await this._$gridService.getTemplates(this.filter, this.sort, false);
    }

    async search(page, perPage, sort) {
        const getPagination = await this._$gridService.search(
            page,
            perPage,
            sort,
            { ...this.filter, template: false },
            false,
        );

        if (!getPagination) {
            return;
        }

        this.totalCount = getPagination?.totalCount ?? 0;
        this.gridList = getPagination?.data ?? [];
    }

    async onFiltersChange() {
        if (this.tableSelect) {
            this.tableSelect.empty();
        }

        const search = this.filter.search.toLocaleLowerCase();
        this._$state.go('.', { search });
        await this.getLists();
    }

    listDataModels(item) {
        if (!item.dataModel || item.dataModel.length === 0) {
            return '-';
        }

        return item.dataModel.map((dataModel) => dataModel.name).join(', ');
    }

    async openDataModelsModal(dataModels) {
        await this._$modalService.triggerListModal(
            dataModels.map((dataModel) => ({ name: dataModel.name })),
            this._translate('shared.dataModel'),
        );
    }

    duplicateControlGrid(controlGridId) {
        this._$gridControlService.duplicate(this.companyId, controlGridId).then((controlGridCreated) => {
            this._$state.go('app.controlGridView', {
                gridControlId: controlGridCreated.id,
            });
        });
    }

    selectVisibleRows() {
        this._$scope.$broadcast('getVisibleRows');
    }

    selectedGridHasDependencies() {
        const selected = this.gridList.filter(this.tableSelect.isActive);

        return selected?.some((grid) => grid.countDeposits > 0);
    }

    removeTooltip() {
        if (!this.tableSelect.hasSelected()) {
            return '';
        } else if (this.selectedGridHasDependencies()) {
            return this._translate('shared.attachedGrid');
        } else {
            return this._translate('shared.deleteSelection');
        }
    }

    async removeControlGrids() {
        const selectedGrids = this.gridList.filter(this.tableSelect.isActive);
        const selectedFullName = selectedGrids && selectedGrids[0] ? selectedGrids[0].fullname : '';

        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.controlGrid', {
                COUNT: selectedGrids.length,
                NAME: selectedFullName,
            }).toLowerCase(),
        );
        if (!isAccepted) {
            return;
        }

        this.deleting = true;

        const selectedGridIds = selectedGrids.map((selectedGrid) => selectedGrid.id);
        await this._$deleteService.deleteArrayOfIds(
            selectedGridIds,
            (gridId) => this._gridControlProvider.delete(gridId),
            'removeModal.successGrid',
            'removeModal.failureGrid',
        );

        await this.getLists();
        this.tableSelect.empty();
        this.deleting = false;
    }

    toggleTemplates() {
        this.isTemplateVisible = !this.isTemplateVisible;
    }

    toggleGrids() {
        this.isWorkingGridsVisible = !this.isWorkingGridsVisible;
    }

    compareGrid(controlGrid) {
        this._$state.go('app.comparisonGridPage', {
            controlGrid: controlGrid.id,
        });
    }
}

angular.module('dotic').component('controlGridAdminListPage', {
    controller: ControlGridAdminListController,
    templateUrl: template,
});
