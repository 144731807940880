import { unique as radashUnique } from 'radash';

import { ColorService, GeneralIconColorEnum } from '../../color.service';
import { HelpersService } from '../../helpers.service';
import { IndicatorService } from '../indicator.service';

const KeyEnum = {
    MANAGER: 'manager',
    NATURE: 'nature',
    OWNER: 'owner',
    PHYSICAL_TYPE: 'physicalType',
    TECHNICAL_POINT_COUNT: 'technicalPointCount',
};

// Linked to technical-point-by-characteristic indicator
class IndicatorR00827Service {
    static ANCTCorrespondenceNumber = 27;

    constructor($filter) {
        this._translate = $filter('translate');
    }

    generateDataStructure(data) {
        const managerOwnerSqueezedData = HelpersService.squeezeObjects(
            data,
            [KeyEnum.MANAGER, KeyEnum.OWNER],
            KeyEnum.TECHNICAL_POINT_COUNT,
        );
        const naturePhysicalTypeSqueezedData = HelpersService.squeezeObjects(
            data,
            [KeyEnum.NATURE, KeyEnum.PHYSICAL_TYPE],
            KeyEnum.TECHNICAL_POINT_COUNT,
        );

        const managers = radashUnique(data.map((currentData) => currentData[KeyEnum.MANAGER]));
        const natures = radashUnique(data.map((currentData) => currentData[KeyEnum.NATURE]));

        const managerColors = ColorService.getDistinctRandomColors(managers.length, ColorService.colorsPalette);
        const natureColors = ColorService.getDistinctRandomColors(natures.length, ColorService.colorsPalette);

        const managerFormattedData = IndicatorService.getTableChartFormattedData(
            managerOwnerSqueezedData,
            KeyEnum.MANAGER,
            KeyEnum.OWNER,
            KeyEnum.TECHNICAL_POINT_COUNT,
        );
        const natureFormattedData = IndicatorService.getTableChartFormattedData(
            naturePhysicalTypeSqueezedData,
            KeyEnum.NATURE,
            KeyEnum.PHYSICAL_TYPE,
            KeyEnum.TECHNICAL_POINT_COUNT,
        );

        const managerTableChartData = IndicatorService.getTableChartData(
            managerFormattedData,
            (manager) => managerColors[managers.indexOf(manager)],
        );
        const natureTableChartData = IndicatorService.getTableChartData(
            natureFormattedData,
            (nature) => natureColors[natures.indexOf(nature)],
        );

        return {
            ANCTCorrespondenceNumber: IndicatorR00827Service.ANCTCorrespondenceNumber,
            data: data,
            iconOptions: this.getIconOptions(data),
            tableChartDataArray: [
                {
                    ...managerTableChartData,
                    options: {
                        xLabel: this._translate('shared.technicalPoint'),
                        yLabel: this._translate('shared.owner'),
                    },
                },
                {
                    ...natureTableChartData,
                    options: {
                        xLabel: this._translate('shared.technicalPoint'),
                        yLabel: this._translate('shared.physicalType'),
                    },
                },
            ],
            tableData: {
                columns: this.getColumns(),
                title: this._translate('shared.detailTechnicalPointCount'),
            },
        };
    }

    getColumns() {
        return [
            {
                key: KeyEnum.TECHNICAL_POINT_COUNT,
                translation: this._translate('shared.technicalPointCount'),
            },
            {
                key: KeyEnum.MANAGER,
                translation: this._translate('shared.manager'),
            },
            {
                key: KeyEnum.OWNER,
                translation: this._translate('shared.owner'),
            },
            {
                key: KeyEnum.NATURE,
                translation: this._translate('shared.nature'),
            },
            {
                key: KeyEnum.PHYSICAL_TYPE,
                translation: this._translate('shared.physicalType'),
            },
        ];
    }

    getIconOptions(data) {
        const clusteredEntities = data.reduce(
            (acc, currentData) => {
                acc[KeyEnum.MANAGER].push(currentData[KeyEnum.MANAGER]);
                acc[KeyEnum.NATURE].push(currentData[KeyEnum.NATURE]);
                acc[KeyEnum.OWNER].push(currentData[KeyEnum.OWNER]);
                acc[KeyEnum.PHYSICAL_TYPE].push(currentData[KeyEnum.PHYSICAL_TYPE]);

                return acc;
            },
            {
                [KeyEnum.MANAGER]: [],
                [KeyEnum.NATURE]: [],
                [KeyEnum.OWNER]: [],
                [KeyEnum.PHYSICAL_TYPE]: [],
            },
        );

        return [
            {
                color: GeneralIconColorEnum.MANAGER,
                icon: 'architecture-alt',
                label: this._translate('shared.manager'),
                value: radashUnique(clusteredEntities[KeyEnum.MANAGER]).length,
            },
            {
                color: GeneralIconColorEnum.OWNER,
                icon: 'architecture-alt',
                label: this._translate('shared.owner'),
                value: radashUnique(clusteredEntities[KeyEnum.OWNER]).length,
            },
            {
                color: GeneralIconColorEnum.NATURE,
                icon: 'cement-mix',
                label: this._translate('shared.nature'),
                value: radashUnique(clusteredEntities[KeyEnum.NATURE]).length,
            },
            {
                color: GeneralIconColorEnum.PHYSICAL_TYPE,
                icon: 'cement-mix',
                label: this._translate('shared.physicalType'),
                value: radashUnique(clusteredEntities[KeyEnum.PHYSICAL_TYPE]).length,
            },
        ];
    }
}

angular.module('dotic').factory('indicatorR00827Service', IndicatorR00827Service);
