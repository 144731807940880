import template from './statistics.html';

class StatisticsPage {
    page = 0;
    pageSize = 10;
    sort = { column: 'createdAt', order: -1 };
    totalCount = 0;

    constructor(
        $auth,
        $authorizationService,
        $deleteService,
        $filter,
        $location,
        $modalService,
        $phaseService,
        $scope,
        $segmentsService,
        $state,
        $tableService,
        $timeout,
        $toasterService,
        $uibModal,
        controlConfigurationProvider,
        deliveryZoneProvider,
        projectProvider,
        statisticsProvider,
        userMetricsProvider,
        companyProvider,
    ) {
        this._$deleteService = $deleteService;
        this._$toasterService = $toasterService;
        this._$segmentsService = $segmentsService;
        this._$timeout = $timeout;
        this._$scope = $scope;
        this._$state = $state;
        this._projectProvider = projectProvider;
        this._deliveryZoneProvider = deliveryZoneProvider;
        this._controlConfigurationProvider = controlConfigurationProvider;
        this._$uibModal = $uibModal;
        this._$tableService = $tableService;
        this._statisticsProvider = statisticsProvider;
        this._translate = $filter('translate');
        this._userMetricsProvider = userMetricsProvider;
        this._$modalService = $modalService;
        this._companyProvider = companyProvider;

        this.companyId = $auth.getPayload().company;
        // Items
        this.projects = [];
        this.deliveryZones = [];
        this.controlConfiguration = [];

        // Filters options
        this.projectList = [];
        this.projectIdList = [];
        this.deliveryZoneList = [];
        this.phaseList = $phaseService.getAll().map((value) => ({ key: value, value }));
        this.segmentsList = this._$segmentsService.getAll().map((value) => ({ key: value, value }));
        this.subCompaniesList = [];
        this.controlConfigurationList = [];

        // init generate filters
        this.generateFilter = {
            from: null,
            to: null,
            projectIds: [],
            projects: [],
            deliveryZones: [],
            phases: [],
            networkSegments: [],
            subCompanies: [],
            zipNames: [],
            controlConfigurations: [],
            users: [],
        };

        this.isAllowed = $authorizationService.isAllowed;
        this.loading = true;
        this.generationLoading = false;
        this.generationStarting = false;
        this.table = [];
        this.statistics = [];
        this.checkStatusTimeout = null;
        this.aggregatorId = null;

        $scope.$emit('updateNavigation', {
            newPage: [
                {
                    key: 'statistics',
                    title: this._translate('shared.controlStatistic'),
                    href: $location.path(),
                },
            ],
        });
        $scope.$on('selectRows', (e, data) => {
            this.tableSelect.select(data.rows, true);
        });
    }

    async $onInit() {
        await this.initData();
    }

    async initData() {
        this.loading = true;

        this.tableDetail = this._$tableService.detail();

        try {
            await this._userMetricsProvider.listControlStatistics();
            this.statistics = await this.getStatisticsHistory();

            await Promise.all([
                this.getProjectIds(),
                this.getProjects(),
                this.getFileNames(),
                this.getControlConfigurations(),
                this.getUsers(),
            ]);

            this.tableSelect = this._$tableService.select();
        } catch (error) {
            this._$toasterService.error(error);
        }

        // Timeout necessary as AngularJs doesn't trigger digest cycle from async / await method or function
        this._$timeout(() => (this.loading = false));
    }

    selectAll() {
        this._$scope.$broadcast('getVisibleRows');
    }

    async removeStatistics() {
        const selectedStatistics = this.statistics.filter(this.tableSelect.isActive);

        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.statistic', {
                COUNT: selectedStatistics.length,
                NAME: selectedStatistics[0].statisticName,
            }).toLowerCase(),
        );
        if (!isAccepted) {
            return;
        }

        const selectedStatisticIds = selectedStatistics.map((selectedStatistic) => selectedStatistic._id);
        await this._$deleteService.deleteArrayOfIds(
            selectedStatisticIds,
            (statisticId) => this._statisticsProvider.remove(statisticId),
            'removeModal.successStatistic',
        );

        await this.initData();
    }

    cleaningInput() {
        this.generateFilter = {
            to: null,
            from: null,
            phases: [],
            networkSegments: [],
            fillGrid: '',
            projects: [],
            zipNames: [],
            projectIds: [],
            subCompanies: [],
            deliveryZones: [],
            controlConfigurations: [],
            users: [],
        };
    }

    getSelectionType() {
        const size = this.tableSelect.selectionSize();
        switch (size) {
            case this.statistics.length:
                return 'all';
            case 0:
                return 'none';
            case 10:
                return 'visible';
            default:
                return 'few';
        }
    }

    generateStatistics() {
        const modal = this._$uibModal.open({
            component: 'statisticsFilterModal',
            size: 'xl',
            backdrop: 'static',
            resolve: {
                projectList: () => this.projectList,
                projectIdList: () => this.projectIdList,
                deliveryZones: () => this.deliveryZones,
                deliveryZoneList: () => this.deliveryZoneList,
                phaseList: () => this.phaseList,
                segmentsList: () => this.segmentsList,
                subCompaniesList: () => this.subCompaniesList,
                controlConfigurations: () => this.controlConfiguration,
                controlConfigurationList: () => this.controlConfigurationList,
                zipNameList: () => this.zipNameList,
                generateFilter: () => ({ ...this.generateFilter }),
                userList: () => this.userList,
            },
        });

        modal.result.then(
            async (data) => {
                if (!data?.params) {
                    return;
                }
                // Keep selected filters in memory
                this.generateFilter = data.filters;

                try {
                    this._userMetricsProvider.generateControlStatistics();
                    this._$timeout(() => {
                        this.generationLoading = true;
                    });

                    const aggregator = await this._statisticsProvider.generate(data.params);

                    this.generationStarting = true;
                    this.aggregatorId = aggregator.id;
                    if (aggregator.status === 'PENDING') {
                        this._$toasterService.info({
                            title: this._translate('shared.generating'),
                            body: this._translate('shared.depositsCanTakeTime'),
                        });

                        return this.checkStatus();
                    }

                    if (aggregator?.exists) {
                        this._$toasterService.info({
                            title: this._translate('statisticsPage.statisticsExisting'),
                            body: 'aggregator-exists-toaster',
                            bodyOutputType: 'directive',
                            directiveData: { aggregator },
                        });
                    }

                    this.statistics = await this.getStatisticsHistory();
                } catch (error) {
                    this._$toasterService.error(error);
                } finally {
                    this.generationStarting = false;
                    this.generationLoading = false;
                }
            },
            () => {},
        );
    }

    checkStatus() {
        this._statisticsProvider.status(this.aggregatorId).then(async ({ status }) => {
            if (status === 'PENDING') {
                this.generationLoading = true;
                this.generationStarting = true;
                this.cancelStatusTimeout();
                this.checkStatusTimeout = this._$timeout(() => this.checkStatus(), 8000);
            } else if (status === 'SUCCESS') {
                this.cancelStatusTimeout();
                this._$toasterService.success(this._translate('shared.generationSucceeded'));
                this.aggregatorId = null;
                this.generationStarting = false;
                this.generationLoading = false;
                this.cleaningInput();
                await this.initData();
            } else if (status === 'FAIL' || status === 'DEPRECATED') {
                this.cancelStatusTimeout();
                this._$toasterService.error(this._translate('shared.generationFailed'));
                this.aggregatorId = null;
                this.generationStarting = false;
                this.generationLoading = false;
                await this.initData();
            }
        });
    }

    cancelStatusTimeout() {
        if (this.checkStatusTimeout) {
            this._$timeout.cancel(this.checkStatusTimeout);
        }
    }

    /**
     * Get stats objects
     * @return {promise}
     */
    async getStatisticsHistory() {
        const results = await this._statisticsProvider.list({
            limit: this.pageSize,
            page: this.page - 1,
            order: this.sort.order,
            orderBy: this.sort.column,
        });
        this.totalCount = results.totalCount;

        return results.data.map((res) => {
            res.networkSegments = this._$segmentsService.setNetworkSegments(res.networkSegments);

            return res;
        });
    }

    /**
     * Get project ids from delivery zones
     * @return {promise}
     */
    getProjectIds() {
        return this._deliveryZoneProvider.list().then((deliveryZones) => {
            this.deliveryZones = deliveryZones;
            this.projectIdList = deliveryZones.reduce((acc, { projectId }) => {
                if (projectId && !acc.find((a) => a.key === projectId)) {
                    acc.push({ key: projectId, value: projectId });
                }

                return acc;
            }, []);
        });
    }

    /**
     * Get project
     * @return {promise}
     */
    async getProjects() {
        try {
            this.projects = await this._projectProvider.getAll();
            this.projectList = this.projects.map(({ id, name }) => ({
                key: id,
                value: name,
            }));
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    /**
     * Get project
     * @return {promise}
     */
    getFileNames() {
        return this._statisticsProvider
            .listFileNames()
            .then((fileNames) => {
                this.zipNameList = fileNames.map((name) => ({
                    key: name,
                    value: name,
                }));
            })
            .catch(this._$toasterService.error);
    }

    /**
     * Get ControlConfigurations
     * @return {promise}
     */
    getControlConfigurations() {
        return this._controlConfigurationProvider
            .getAll()
            .then((controlConfiguration) => {
                this._$timeout(() => {
                    this.controlConfiguration = controlConfiguration;
                });
            })
            .catch(this._$toasterService.error);
    }

    /**
     * Get users
     * @return {promise}
     */
    async getUsers() {
        try {
            const users = await this._companyProvider.getEmployeeDetailsForCompany(this.companyId);
            this.userList = users.map((user) => ({
                key: user.id,
                value: user.fullname,
            }));
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    async onPaginationChange(page, pageSize, sort) {
        this.page = page;
        this.pageSize = pageSize;
        this.sort = sort;

        this.loading = true;
        this.statistics = await this.getStatisticsHistory();

        this._$timeout(() => (this.loading = false));
    }
    compareWith(statistic) {
        this._$state.go('app.comparisonPage', {
            controlStat: statistic._id,
        });
    }

    showDetails(statistics) {
        this.tableDetail.toggle(statistics);
    }

    /**
     * Check if the file already exist
     * if not run the generate method
     * @param type
     * @param statisticId
     */
    downloadStatistic(statisticId, type = 'aggregated') {
        this._statisticsProvider.status(statisticId).then(({ aggregatedReportFileStatus, errorsLogFileStatus }) => {
            const status = type === 'aggregated' ? aggregatedReportFileStatus : errorsLogFileStatus;
            if (status === 'SUCCESS') {
                this.downloadFile(statisticId, type);
            } else {
                this.generateFile(statisticId, type);
            }
        });
    }

    generateFile(statisticId, type = 'aggregated') {
        this._$toasterService.info(this._translate('shared.generating'));
        this._statisticsProvider
            .generateFile(statisticId, type)
            .then(() => this.checkReportGenerated(statisticId, type))
            .catch(this._$toasterService.error);
    }

    downloadFile(statisticId, type = 'aggregated') {
        this._$toasterService.info(this._translate('shared.downloadingFile'));

        try {
            this._userMetricsProvider.downloadControlStatistics(statisticId);
            this._statisticsProvider.download(statisticId, type);
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    checkReportGenerated(statisticId, type = 'aggregated') {
        this._statisticsProvider.status(statisticId).then(({ aggregatedReportFileStatus, errorsLogFileStatus }) => {
            const stat = this.statistics.find((stat) => stat.id === statisticId);

            stat.waitingReport = aggregatedReportFileStatus === 'LOADING' || errorsLogFileStatus === 'LOADING';
            if (stat.waitingReport) {
                // Check while files are not ready
                this._$timeout(() => this.checkReportGenerated(statisticId, type), 10000);
            } else {
                this._$timeout(() => {
                    stat.waitingReport = false;
                    this._$toasterService.success(this._translate('statisticsPage.aggregatorFileGenerated'));
                    this.downloadFile(statisticId, type);
                });
            }
        });
    }
}

angular
    .module('dotic')
    .component('statisticsPage', {
        controller: StatisticsPage,
        templateUrl: template,
    })
    .directive('aggregatorExistsToaster', () => {
        return {
            link: function (scope) {
                const { aggregator } = scope.directiveData;
                scope.id = aggregator._id;
            },
            template:
                '<a data-ui-sref="app.statisticDetail({ statisticId: id })"><i class="icofont icofont-eye"></i> Voir la statistique</a>',
        };
    });
