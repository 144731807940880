import { FeatureEnum } from '../../guards/has-feature.guard';

angular.module('dotic').config(($stateProvider) => {
    $stateProvider
        .state('app.fttxDataEvolution', {
            url: '/fttxDataEvolution',
            component: 'fttxDataEvolution',
            data: {
                authenticationRequired: true,
                roleRequired: ['admin'],
            },
            resolve: {
                canActivate: [
                    'hasFeatureGuard',
                    (hasFeatureGuard) => {
                        return hasFeatureGuard(FeatureEnum.FTTX_DATA_EVOLUTION);
                    },
                ],
            },
        })
        .state('app.fttxDataEvolutionView', {
            url: '/fttxDataEvolution/{inventoryId:[A-Za-z0-9]+}',
            component: 'fttxDataEvolutionView',
            data: {
                authenticationRequired: true,
                roleRequired: ['admin'],
            },
            resolve: {
                canActivate: [
                    'hasFeatureGuard',
                    (hasFeatureGuard) => {
                        return hasFeatureGuard(FeatureEnum.FTTX_DATA_EVOLUTION);
                    },
                ],
            },
        });
});
