angular.module('dotic').config(($stateProvider) => {
    $stateProvider.state('app.interventionGrid', {
        url: '/interventionGrid',
        data: {
            authenticationRequired: true,
            roleRequired: ['admin'],
        },
    });
    $stateProvider.state('app.interventionGridsList', {
        url: '/superAdmin/interventionGrids',
        component: 'interventionGridListPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['superAdmin'],
        },
    });
    $stateProvider.state('app.interventionGridCreate', {
        url: '/superAdmin/interventionGrids/create',
        component: 'interventionGridFormPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['superAdmin'],
        },
    });
    $stateProvider.state('app.interventionGridEdit', {
        url: '/superAdmin/interventionGrids/{gridId:[a-f0-9]+}/edit',
        component: 'interventionGridFormPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['superAdmin'],
        },
    });
    $stateProvider.state('app.interventionGridView', {
        url: '/interventionGrids/{gridId:[a-f0-9]+}/view',
        component: 'interventionGridViewPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['superAdmin', 'admin'],
        },
    });
});
