angular.module('dotic').factory('controlConfigurationProvider', controlConfigurationProvider);

function controlConfigurationProvider(ApiProvider, URI_KEYS) {
    return {
        get: getControlConfiguration,
        getAll: getAllControlConfiguration,
        getFillGridLinks,
        getControlGridLinks,
    };

    /**
     * GET /controlConfigurations/:controlConfigurationId
     *
     * @param {string} controlConfigurationId
     *
     * @returns {*} Promise
     */
    function getControlConfiguration(controlConfigurationId) {
        return ApiProvider.one(URI_KEYS.CONTROL_CONFIGURATION, controlConfigurationId).get();
    }

    /**
     * GET /controlConfigurations/:controlConfigurationId/fill-grid-links
     *
     * @param {string} controlConfigurationId
     *
     * @returns {*} Promise
     */
    function getFillGridLinks(controlConfigurationId) {
        return ApiProvider.one(URI_KEYS.CONTROL_CONFIGURATION, controlConfigurationId).one('fill-grid-links').get();
    }

    /**
     * GET /controlConfigurations/:controlConfigurationId/control-grid-links
     *
     * @param {string} controlConfigurationId
     *
     * @returns {*} Promise
     */
    function getControlGridLinks(controlConfigurationId) {
        return ApiProvider.one(URI_KEYS.CONTROL_CONFIGURATION, controlConfigurationId).one('control-grid-links').get();
    }

    /**
     * GET /controlConfigurations
     * @param {string?} queryParams.dataModel
     *
     * @returns {*} Promise
     */
    function getAllControlConfiguration(queryParams = {}) {
        return ApiProvider.one(URI_KEYS.CONTROL_CONFIGURATION).customGET(null, queryParams);
    }
}
