export const defaultPagination = {
    data: [],
    page: 1,
    count: 0,
};

export const ControlResultTypeEnum = {
    DIFF: 'diff',
    ERROR: 'error',
    FAIL: 'fail',
    IMPROVEMENT: 'improvement',
    REGRESSION: 'regression',
    SAME: 'same',
    SUCCESS: 'success',
    UNUSED: 'unused',
};
