export const AddressStateTypeEnum = {
    CI: 'CI',
    DE: 'DE',
    EC: 'EC',
    RC: 'RC',
    RD: 'RD',
    SI: 'SI',
};

export const BoxTypeEnum = {
    BD: 'BD',
    BPE: 'BPE',
    PBO: 'PBO',
    PTO: 'PTO',
};

export const CableLogicalTypeEnum = {
    BM: 'BM',
    CD: 'CD',
    CO: 'CO',
    CT: 'CT',
    CX: 'CX',
    DI: 'DI',
    LH: 'LH',
    NC: 'NC',
    RA: 'RA',
    TD: 'TD',
    TR: 'TR',
};

export const DistanceRangeEnum = {
    FROM_0_TO_50: '0 - 50',
    FROM_50_TO_100: '50 - 100',
    FROM_100_TO_150: '100 - 150',
    FROM_150_TO_200: '150 - 200',
    FROM_200_TO_300: '200 - 300',
    FROM_300_TO_500: '300 - 500',
    MORE_THAN_500: 'Plus de 500',
};

export const GraceKeyEnum = {
    DESIGN: 'rf_design (t_reference)',
};

export const ImplantationTypeEnum = {
    AIR: 'AERIEN',
    AIR_TELECOM: 'AERIEN TELECOM',
    FRONT: 'FACADE',
    NULL: 'NULL',
    PIPE: 'CONDUITE',
    SPECIFIC: 'SPECIFIQUE',
    TELECOM: 'TELECOM',
};

export const SeverityEnum = {
    BLOCKING: 'blocking',
    MAJOR: 'major',
    MINOR: 'minor',
};

export const TechnicalPointNatureTypeEnum = {
    BUILDING: 'IMMEUBLE',
    CHAMBER: 'CHAMBRE',
    FRONT_ANCHOR: 'ANCRAGE FACADE',
    OTHER: 'AUTRE',
    SUPPORT: 'APPUI',
};

export const FieldEnum = {
    AD_IMNEUF: 'ad_imneuf',
    AD_ISOLE: 'ad_isole',
    AD_PRIO: 'ad_prio',
};

export const LogicalTypeEnum = {
    CLIENT: 'CLIENT',
    NETWORK: 'RESEAU',
};

export const PositionFunctionTypeEnum = {
    AT: 'AT',
    CO: 'CO',
    EP: 'EP',
    MA: 'MA',
    PA: 'PA',
    PI: 'PI',
};

export const PrecisionClassEnum = {
    A: 'A',
    AP: 'AP',
    B: 'B',
    C: 'C',
    EMPTY: 'vide',
};

export const SiteLogicalTypeEnum = {
    FTTH: 'FTTH',
    NRO: 'NRO',
    SRO: 'SRO',
};

export const SliceTypeEnum = {
    MIXED: 'Mixte',
    OTHER: 'Autre',
    PEHD: 'PEHD',
    PVC: 'PVC',
};
