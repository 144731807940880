import template from './arch-indicator.component.html';

class ArchIndicatorComponent {
    indicatorDataStructure = {
        ANCTCorrespondenceNumber: null,
        falseTitle: '',
        trueTitle: '',
        value: 0,
    };
    isParentLoading = false;
    title = '';
}

angular.module('dotic').component('archIndicator', {
    bindings: {
        indicatorDataStructure: '<',
        isParentLoading: '<?',
        title: '@',
    },
    controller: ArchIndicatorComponent,
    templateUrl: template,
});
