import template from './user-metrics-details.html';

class UserMetricsDetailsPage {
    loading = true;
    history = null;
    stats = null;

    constructor($state, $stateParams, $scope, $filter, $location, $toasterService, userProvider, userMetricsProvider) {
        this._$location = $location;
        this._$scope = $scope;
        this._$stateParams = $stateParams;
        this._$state = $state;
        this._translate = $filter('translate');
        this._userProvider = userProvider;
        this._userMetricsProvider = userMetricsProvider;
        this._$toasterService = $toasterService;
    }

    async $onInit() {
        await this.loadStats();
    }

    async loadStats() {
        this.loading = true;
        try {
            this.stats = await this._userProvider.stats(this._$stateParams.userId);
            this.setScopeNavigation(this.stats.user);
            this.history = await this._userMetricsProvider.getMetricsByUser(this._$stateParams.userId);
        } catch {
            this._$toasterService.error('toaster.error');
        }
        this.loading = false;
    }

    setScopeNavigation(user) {
        this._$scope.$emit('keepPreviousNavigation', {
            newPage: [
                {
                    key: 'userMetricsDetails',
                    title: user.fullname,
                    href: this._$location.path(),
                },
            ],
            defaultPrevious: {
                title: this._translate('shared.userMetrics'),
                href: this._$state.href('app.userMetrics'),
                key: 'userMetrics',
            },
            allowedPreviousKeys: ['userMetrics'],
        });
    }
}

angular.module('dotic').component('userMetricsDetailsPage', {
    templateUrl: template,
    controller: UserMetricsDetailsPage,
});
