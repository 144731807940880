import { SynopticApi } from '../../../../sdk/connect-control-api-v1/src';
import template from './plans-export-zip.html';

class PlansExportZipComponent {
    isDownloading = false;
    zipId = '';

    constructor($apiClientService, $fileService, $filter, $location, $scope, $stateParams, $toasterService) {
        this._$fileService = $fileService;
        this._$toasterService = $toasterService;
        this._translate = $filter('translate');
        this._synopticApi = new SynopticApi($apiClientService.client);

        this.zipId = $stateParams.zipId;

        $scope.$emit('updateNavigation', {
            newPage: [
                {
                    key: 'synoptic',
                    title: this._translate('shared.bayBoxPlan'),
                    href: $location.path(),
                },
            ],
        });
    }

    async downloadZip() {
        this.isDownloading = true;

        try {
            const response = (await this._synopticApi.downloadPlansExportWithHttpInfo(this.zipId)).response;
            this._$fileService.downloadFileFromResponse(response);
        } catch {
            this._$toasterService.error(this._translate('toaster.downloadError'));
        }

        this.isDownloading = false;
    }
}

angular.module('dotic').component('plansExportZipComponent', {
    controller: PlansExportZipComponent,
    templateUrl: template,
});
