export const defaultControlFilterOptions = [
    {
        allowed: true,
        name: 'projectIds',
        value: 'projectId',
        hasSearch: true,
        placeholder: 'filter.all.projectIds',
        translateParamsLabel: { COUNT: 1 },
        translateKeyLabel: 'shared.businessCode',
    },
    {
        allowed: true,
        name: 'controlConfigurations',
        value: 'controlConfiguration',
        hasSearch: true,
        placeholder: 'filter.all.controlConfigurations',
        translateKeyLabel: 'shared.controlConfiguration',
    },
    {
        allowed: true,
        name: 'date',
        value: 'date',
        placeholder: 'filter.all.date',
        type: 'date',
        saveValue: true,
        translateKeyLabel: 'shared.date',
    },
    {
        allowed: true,
        name: 'idLivrables',
        value: 'idLivrable',
        placeholder: 'filter.all.deliverableIds',
        hasSearch: true,
        translateKeyLabel: 'shared.deliverableID',
    },
    {
        allowed: true,
        name: 'phases',
        value: 'phase',
        placeholder: 'filter.all.phases',
        translateKeyLabel: 'shared.steps',
        saveValue: true,
    },
    {
        allowed: true,
        name: 'dataModels',
        value: 'dataModel',
        hasSearch: true,
        placeholder: 'filter.all.dataModels',
        translateKeyLabel: 'shared.dataModel',
        translateParamsLabel: { COUNT: 1 },
    },
    {
        allowed: true,
        name: 'projects',
        value: 'project',
        hasSearch: true,
        placeholder: 'filter.all.projects',
        translateKeyLabel: 'shared.project',
    },
    {
        allowed: true,
        name: 'subCompanies',
        value: 'subCompany',
        hasSearch: true,
        placeholder: 'filter.all.subCompanies',
        translateKeyLabel: 'shared.subcontractor',
    },
    {
        allowed: true,
        name: 'segmentsList',
        selected: 'segment',
        placeholder: 'filter.all.segments',
        saveValue: true,
        translateKeyLabel: 'shared.networkSegment',
        type: 'multi-select',
    },
    {
        allowed: true,
        name: 'status',
        value: 'status',
        placeholder: 'filter.all.status',
        saveValue: true,
        translateKeyLabel: 'shared.status',
    },
    {
        allowed: true,
        name: 'createdBy',
        value: 'createdBy',
        hasSearch: true,
        placeholder: 'filter.all.createdBy',
        translateKeyLabel: 'shared.createdBy',
    },
    {
        allowed: true,
        name: 'versions',
        value: 'version',
        placeholder: 'filter.all.versions',
        saveValue: true,
        translateKeyLabel: 'shared.versions',
    },
    {
        allowed: true,
        name: 'deliveryZones',
        value: 'deliveryZone',
        hasSearch: true,
        placeholder: 'filter.all.deliveryZones',
        translateKeyLabel: 'shared.deliveryZone',
        translateParamsLabel: { COUNT: 1 },
    },
];
