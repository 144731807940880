import { ColorService } from '../../color.service';
import { IndicatorService } from '../indicator.service';

const KeyEnum = {
    DISTANCE_CLASS: 'distanceClass',
    DISTANCE_KM: 'distanceKm',
    NODE_NRO_CODE: 'nodeNroCode',
    NRA_CODE: 'nraCode',
    NRA_INSEE_CODE: 'nraInseeCode',
    NRA_OPERATOR: 'nraOperator',
    NRO_CODE: 'nroCode',
    REFERENCE_NRO: 'referenceNRO',
};

// Linked to distance-between-nro-nra indicator.
class IndicatorR00830Service {
    static ANCTCorrespondenceNumber = 30;

    constructor($filter) {
        this._translate = $filter('translate');
    }

    generateDataStructure(data) {
        const formattedData = IndicatorService.getFormattedKilometerData(data, [KeyEnum.DISTANCE_KM]);

        return {
            ANCTCorrespondenceNumber: IndicatorR00830Service.ANCTCorrespondenceNumber,
            data: formattedData,
            doughnutChartData: this.getDoughnutChartData(data),
            tableData: {
                columns: this.getColumns(),
                title: this._translate('shared.detailDistanceBetweenNroNra'),
            },
        };
    }

    getColumns() {
        return [
            { key: KeyEnum.NODE_NRO_CODE, translation: this._translate('shared.nodeNroCode') },
            { key: KeyEnum.REFERENCE_NRO, translation: this._translate('shared.referenceNro') },
            { key: KeyEnum.NRO_CODE, translation: this._translate('shared.nroCode') },
            { key: KeyEnum.NRA_CODE, translation: this._translate('shared.nraCode') },
            { key: KeyEnum.NRA_INSEE_CODE, translation: this._translate('shared.nraInseeCode') },
            { key: KeyEnum.NRA_OPERATOR, translation: this._translate('shared.nraOperator') },
            { key: KeyEnum.DISTANCE_KM, translation: `${this._translate('shared.distance', { COUNT: 1 })} (Km)` },
        ];
    }

    getDoughnutChartData(data) {
        const chartData = data.reduce(
            (acc, range) => {
                if (range[KeyEnum.DISTANCE_KM] <= 0.1) {
                    acc.zeroToOneHundred += 1;
                } else if (range[KeyEnum.DISTANCE_KM] <= 0.5) {
                    acc.oneHundredToFiveHundred += 1;
                } else if (range[KeyEnum.DISTANCE_KM] <= 1) {
                    acc.fiveHundredToThousand += 1;
                } else {
                    acc.overOneThousand += 1;
                }

                return acc;
            },
            {
                zeroToOneHundred: 0,
                oneHundredToFiveHundred: 0,
                fiveHundredToThousand: 0,
                overOneThousand: 0,
            },
        );

        return {
            bigLegend: {
                figure: data.length,
                name: 'NRO',
            },
            datasets: [
                {
                    data: [
                        chartData.zeroToOneHundred,
                        chartData.oneHundredToFiveHundred,
                        chartData.fiveHundredToThousand,
                        chartData.overOneThousand,
                    ],
                    backgroundColor: ColorService.genericDistanceColorOrdered,
                },
            ],
            labels: [
                `${this._translate('shared.lessThan', { VALUE: 0.1 })} Km`,
                `${this._translate('shared.betweenTwoValues', { SECOND_VALUE: 0.5, FIRST_VALUE: 0.1 })} Km`,
                `${this._translate('shared.betweenTwoValues', { SECOND_VALUE: 1, FIRST_VALUE: 0.5 })} Km`,
                `${this._translate('shared.greaterThan', { VALUE: 1 })} Km`,
            ],
        };
    }
}

angular.module('dotic').factory('indicatorR00830Service', IndicatorR00830Service);
