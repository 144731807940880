import dashboardSuperAdminTemplate from './superAdmin/dashboard.html';

angular.module('dotic').config(($stateProvider) => {
    $stateProvider
        .state('app.dashboard', {
            url: '/dashboard',
            data: {
                authenticationRequired: true,
                roleRequired: ['admin', 'extendedUser', 'superAdmin', 'user'],
            },
            onEnter: ($auth, $state) => {
                const userRole = $auth.getPayload().userRole;
                if (userRole === 'superAdmin') {
                    return $state.go('app.dashboardSuperAdmin');
                }
            },
        })
        .state('app.dashboardSuperAdmin', {
            url: '/superAdmin/dashboard?phase&company&user&createdAtStart&createdAtEnd&groupByLastVersion',
            params: {
                phase: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                company: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                user: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                createdAtStart: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                createdAtEnd: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                groupByLastVersion: {
                    type: 'bool',
                    value: false,
                    squash: true,
                    dynamic: true,
                },
            },
            templateUrl: dashboardSuperAdminTemplate,
            controller: 'DashboardSuperAdminController as $ctrl',
            data: {
                authenticationRequired: true,
                roleRequired: ['superAdmin'],
            },
        });
});
