import { pick as radashPick } from 'radash';

import { defaultControlFilterOptions } from '../../../models/filter.model';
import { DepositsApi } from '../../../../sdk/connect-control-api-v1/src';
import { DepositStatusEnum } from '../../../models/deposit.model';
import { HelpersService } from '../../../services/helpers.service';

class ANCTVerificationsListComponent {
    depositList = [];
    filter = {};
    filterOptions = [...defaultControlFilterOptions];
    filterValues = {
        controlConfigurations: [],
        createdBy: [],
        dataModels: [],
        deliveryZones: [],
        idLivrable: [],
        phases: [],
        projectIds: [],
        projects: [],
        segmentsList: [],
        servers: [],
        status: [],
    };
    loading = true;
    paginationParameters = { page: 1, perPage: 20, sort: { column: 'createdAt', order: -1 } };
    tableDetail = null;
    tableSelect = null;
    totalCount = 0;
    user = null;

    constructor(
        $apiClientService,
        $dataModelService,
        $filter,
        $filterService,
        $location,
        $scope,
        $segmentsService,
        $state,
        $stateParams,
        $tableService,
        $timeout,
        $toasterService,
        $userService,
        depositProvider,
    ) {
        this._$dataModelService = $dataModelService;
        this._$filterService = $filterService;
        this._$location = $location;
        this._$scope = $scope;
        this._$segmentsService = $segmentsService;
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._$tableService = $tableService;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._$userService = $userService;
        this._depositApi = new DepositsApi($apiClientService.client);
        this._depositProvider = depositProvider;
        this._translate = $filter('translate');

        // Watch filter change
        $scope.$watchGroup(
            [
                '$ctrl.createdAtEnd',
                '$ctrl.createdAtStart',
                '$ctrl.filter.controlConfiguration',
                '$ctrl.filter.createdBy',
                '$ctrl.filter.dataModel',
                '$ctrl.filter.date',
                '$ctrl.filter.deliveryZone',
                '$ctrl.filter.idLivrable',
                '$ctrl.filter.phase',
                '$ctrl.filter.project',
                '$ctrl.filter.projectId',
                '$ctrl.filter.search',
                '$ctrl.filter.segment',
                '$ctrl.filter.server',
                '$ctrl.filter.status',
                '$ctrl.filter.version',
            ],
            async (newValue, oldValue) => {
                if (newValue !== oldValue) {
                    await this.updateFilters();
                }
            },
        );

        // Watch query params change
        $scope.$on('$locationChangeSuccess', () => {
            $scope.$emit('updateNavigationUrl');
        });

        $scope.$on('selectRows', (e, data) => {
            if (this.user.role === 'user') {
                this.tableSelect.select(
                    data.rows.filter((row) => row.createdBy === this.user.id),
                    true,
                );

                return;
            }

            this.tableSelect.select(data.rows);
        });
    }

    async $onInit() {
        this.tableDetail = this._$tableService.detail();
        this.tableSelect = this._$tableService.select();

        this.filterValues.segmentsList = this._$segmentsService.getAll().map((segment) => ({
            key: segment,
            value: segment,
        }));

        this.user = this._$userService.getConnectedUser();

        this.setFilter(this._$stateParams);

        this.paginationParameters.page = this._$stateParams.p || 1;

        this._$scope.$emit('updateNavigation', {
            newPage: [
                {
                    key: 'anctVerifications',
                    title: this._translate('shared.ANCTVerifiedDeposit'),
                    href: this._$location.path(),
                },
            ],
        });

        try {
            const filterValues = await this.getAvailableFilter();
            if (filterValues) {
                this.filterValues = filterValues;
            }
            await this.search(
                this.paginationParameters.page,
                this.paginationParameters.perPage,
                this.paginationParameters.sort,
            );
        } catch (error) {
            this._$toasterService.error(error);
        }

        this._$timeout(() => {
            this.loading = false;
        });
    }

    canViewControl(deposit) {
        const isControlJob = !!HelpersService.getControlJob(deposit);

        return isControlJob && deposit.status === DepositStatusEnum.DONE && deposit.report?.id;
    }

    async getAvailableFilter() {
        try {
            return (await this._depositApi.listFiltersWithHttpInfo({ filters: this.filter })).response.body;
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    getDefaultFilter(params = {}) {
        const createdAtEnd = params?.date?.createdAtEnd || this._$stateParams.createdAtEnd;
        const createdAtStart = params?.date?.createdAtStart || this._$stateParams.createdAtStart;

        const date =
            createdAtEnd || createdAtStart
                ? {
                      ...(createdAtEnd && { endDate: moment(createdAtEnd) }),
                      ...(createdAtStart && { startDate: moment(createdAtStart) }),
                  }
                : '';

        return {
            controlConfiguration: params.controlConfiguration || '',
            createdBy: params.createdBy || '',
            dataModel: params.dataModel || '',
            date: date,
            deliveryZone: params.deliveryZone || '',
            idLivrable: params.idLivrable || '',
            phase: params.phase || '',
            project: params.project || '',
            projectId: params.projectId || '',
            search: params.search || '',
            segment: this._$filterService.getFilterValue(params.segment),
            server: params.server || '',
            status: params.status || '',
            version: params.version || '',
        };
    }

    preparedResultDeposit(dirtyDeposits) {
        return dirtyDeposits.map((deposit) => {
            const totalControlPoint =
                deposit.report.errorsBySeverity.blocking +
                deposit.report.errorsBySeverity.error +
                deposit.report.errorsBySeverity.major +
                deposit.report.errorsBySeverity.minor +
                deposit.report.errorsBySeverity.success;

            const messageHousing = this._$dataModelService.isDataGraceTHDV3(deposit)
                ? 'ad_nblres, ad_nblpro, ad_nblent, ad_nblpub, ad_nblobj, ad_nblope'
                : 'ad_nblhab, ad_nblpro';

            if (!deposit.report?.newPlug || !deposit.dataModel) {
                return {
                    ...deposit,
                    networkSegments: this._$segmentsService.setNetworkSegments(deposit.networkSegments) || [],
                };
            }

            return {
                ...deposit,
                networkSegments: this._$segmentsService.setNetworkSegments(deposit.networkSegments) || [],
                report: {
                    ...deposit.report,
                    newPlug: {
                        ...deposit.report.newPlug,
                        messageHousing: messageHousing,
                    },

                    errorsBySeverity: {
                        ...deposit.report.errorsBySeverity,
                        totalControlPoint: totalControlPoint,
                    },
                },
            };
        });
    }

    removeFilter(filterName, initialValue) {
        this.filter[filterName] = initialValue;
        this._$state.go('.', this.filter);
    }

    removeSearch() {
        this.filter.search = '';
        this._$state.go('.', this.filter);
    }

    async search(page, perPage, sort) {
        this.loading = true;

        this.paginationParameters = { page: page, perPage: perPage, sort: sort };

        try {
            const listControl = await this._depositProvider.listControl({
                ...this.paginationParameters,
                filters: { ...this.filter, isANCT: true },
            });

            const depositData = this.preparedResultDeposit(listControl.results);

            this.depositList = depositData.map((deposit) => {
                return {
                    ...deposit,
                    networkSegments: this._$segmentsService.sortSegments(deposit.networkSegments || []),
                };
            });

            this.totalCount = listControl.count;
        } catch (error) {
            this._$toasterService.error(error);
        }

        this.loading = false;
    }

    setFilter(params, isIncludingSearch = true) {
        this.filter = {
            ...this.getDefaultFilter({ ...params, ...(isIncludingSearch && { search: this.filter.search }) }),
        };

        const dateFilter = this.filterOptions.find((item) => item.name === 'date');

        this._$scope.$broadcast('forceFilterRender', {
            filter: dateFilter,
            saveChanges: false,
            openDropdown: false,
        });

        this.updateStateParams();
    }

    showDetails(deposit) {
        this.tableDetail.toggle(deposit);
    }

    async updateFilters() {
        this.loading = true;
        this.updateStateParams();

        const filterValues = await this.getAvailableFilter();
        if (filterValues) {
            this.filterValues = filterValues;
        }

        this.tableSelect.empty();
        await this.search(1, this.paginationParameters.perPage, this.paginationParameters.sort);
    }

    updateStateParams() {
        this._$state.go('.', {
            ...radashPick(this.filter, [
                'company',
                'controlConfiguration',
                'createdAtEnd',
                'createdAtStart',
                'createdBy',
                'dataModel',
                'deliveryZone',
                'idLivrable',
                'phase',
                'project',
                'projectId',
                'search',
                'server',
                'status',
                'version',
            ]),
            segment: this.filter.segment.join(','),
        });
    }
}

angular.module('dotic').controller('anctVerificationsListController', ANCTVerificationsListComponent);
