import template from './deposit-comparison.component.html';

class DepositComparisonComponent {
    depositA = null;
    depositB = null;
    isParentLoading = false;

    constructor($authorizationService) {
        this.isAllowed = $authorizationService.isAllowed;
    }
}

angular.module('dotic').component('depositComparisonComponent', {
    bindings: {
        depositA: '<',
        depositB: '<',
        isParentLoading: '<?',
    },
    controller: DepositComparisonComponent,
    templateUrl: template,
});
