import * as radash from 'radash';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import template from './doughnutchart.html';

Chart.register(...registerables);

function parseChartDataForLegend(chart) {
    if (!chart) {
        return [];
    }

    const data = [];
    const dataSet = chart.data.datasets[0];

    if (!dataSet) {
        return [];
    }

    const total = radash.sum(dataSet.data);

    for (let j = 0; j < dataSet.data.length; j++) {
        data.push({
            color: dataSet.backgroundColor[j],
            percentage: Math.round((100 * 100 * dataSet.data[j]) / total) / 100,
            value: dataSet.data[j],
            label: chart.data.labels[j],
        });
    }

    return data;
}

class CcDoughnutChartController {
    constructor($scope, $element, $timeout) {
        this._$timeout = $timeout;
        this._$element = $element;
        this._$scope = $scope;

        this.interactivity = true;
        this.notResponsive = false;

        if (angular.isUndefined(this.absolute)) {
            this.absolute = false;
        }

        this.options = {
            indexAxis: this.indexAxis ?? 'x',
            responsive: true,
            maintainAspectRatio: false,
            cutout: '75%',
            plugins: {
                datalabels: {
                    display: false,
                },
                legend: { display: false },
            },
            onClick: ($event, activePoints) => this.chartClickEvent($event, activePoints),
            onHover: ($event, element) => this.chartHover($event, element),
        };

        this.chartId = `id${Math.random().toString(16).replace('.', '')}`;
    }

    chartHover($event, element) {
        $event.native.target.style.cursor = element[0] ? 'pointer' : 'default';
    }

    chartClickEvent($event, activePoints) {
        if (!this.interactivity || !activePoints.length) {
            return;
        }

        const index = activePoints[0].index;

        this.onClick({
            customClickEvent: {
                index: index,
                isDoughnut: true,
                label: this.chart.data.labels[index],
                typeDeposit: this.chart.data.datasets[0].spec,
                value: this.chart.data.datasets[0].data[index],
            },
        });
    }

    $onChanges() {
        let size = 219;
        if (angular.isDefined(this.size)) {
            size = parseInt(this.size, 10);
        }

        if (this.notResponsive) {
            this.options.responsive = false;
        }

        this.options.tooltips = {
            opacity: 1,
            position: 'nearest',
            enabled: !this.tooltips,
        };

        this._$timeout(() => {
            const chartElement = this._$element.find(`#${this.chartId}`);
            // Avoid unexpected resizing
            const container = this._$element.find('.cc-chart-container')[0];
            container.style.minHeight = `${size}px`;

            if (chartElement.length > 0) {
                const doughnutChart = chartElement.get(0).getContext('2d');

                if (this.chart) {
                    this.chart.destroy();
                }

                this.chart = new Chart(doughnutChart, {
                    type: 'doughnut',
                    data: this.data,
                    options: this.options,
                    plugins: [ChartDataLabels],
                });

                this.legends = parseChartDataForLegend(this.chart);
            }
        });
    }
}

angular.module('dotic').component('ccDoughnutChart', {
    templateUrl: template,
    controller: CcDoughnutChartController,
    controllerAs: '$ctrl',
    bindings: {
        absolute: '@',
        indexAxis: '@',
        data: '<',
        evolutionLegend: '@',
        interactivity: '<',
        labels: '@',
        multiDoughnut: '@',
        notResponsive: '<?',
        onClick: '&',
        size: '@',
        smallLegend: '@',
        tooltips: '@',
    },
});
