import defaultAvatar from '../../../assets/images/default/avatar.svg';

class UserListController {
    constructor($filter, $filterService, $modalService, $scope, $state, $timeout, $toasterService, userProvider) {
        this._translate = $filter('translate');
        this._$filterService = $filterService;
        this._$state = $state;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._userProvider = userProvider;
        this._$modalService = $modalService;

        this.avatar = defaultAvatar;
        this.loading = true;
        this.userList = [];
        this.filteredUserList = [];
        this.filters = {
            role: $state.params.role || '',
            user: $state.params.user || '',
        };
        this.roles = [];

        $scope.$watchGroup(['$ctrl.userList', '$ctrl.filters.role', '$ctrl.filters.user'], () => this.filterUsers());

        $scope.$emit('updateNavigation', {
            newPage: [
                {
                    title: 'Liste des utilisateurs',
                    href: $state.href('app.userList'),
                },
            ],
        });
    }

    async $onInit() {
        await this.initData();
    }

    async initData() {
        this.loading = true;

        try {
            this.userList = await this._userProvider.getAllWithCompanies();
            this.setRolesFromUsers();
        } catch (error) {
            this._$toasterService.error(error);
        }

        // Timeout necessary as AngularJs doesn't trigger digest cycle from async / await method or function
        this._$timeout(() => (this.loading = false));
    }

    async createUser() {
        const isSubmitted = await this._$modalService.triggerSuperAdminFormModal();
        if (!isSubmitted) {
            return;
        }

        await this.initData();
    }

    filterUsers() {
        const { role, user } = this.filters;
        this._$state.go('.', { role, user });

        const searchString = user.toLowerCase();

        this.filteredUserList = this.userList.filter(this._$filterService.userRole(role)).filter((item) => {
            const matchUser = this._$filterService.userSearch(searchString)(item);
            const matchCompany = this.filterSearchCompanyByName(searchString, item);

            return matchCompany || matchUser;
        });
    }

    filterSearchCompanyByName(search, user) {
        const companyNames = user.companies.map((c) => c.name.toLocaleLowerCase()).join(' ');

        return companyNames.indexOf(search) > -1;
    }

    async removeUser(user) {
        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.user', {
                NAME: user.fullname,
            }).toLowerCase(),
        );
        if (!isAccepted) {
            return;
        }

        try {
            await this._userProvider.remove(user.id);
            this._$toasterService.info({
                body: this._translate('removeModal.successUser'),
            });
        } catch (error) {
            this._$toasterService.error(error);
        }

        await this.initData();
    }

    setRolesFromUsers() {
        this.roles = this._$filterService.formatForFilter(
            this.userList,
            (user) => user.role,
            (user) => user.role,
            'humanizeRole',
        );
    }

    showCompanies(user) {
        return user.companies?.map((company) => company.name).join(', ');
    }
}

angular.module('dotic').controller('UserListController', UserListController);
