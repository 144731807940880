import { sum as radashSum } from 'radash';

import { ColorService, DoughnutChartColorEnum } from '../../../services/color.service';
import template from './deployment-advancement-level-indicator.html';

const KeyEnum = {
    ADDRESS_STATUS_ID: 'addressStatusId',
    ADDRESS_STATUS_NAME: 'addressStatusName',
    ADVANCEMENT: 'advancement',
};

class DeploymentAdvancementLevelIndicatorComponent {
    chartSize = 180;
    columns = [];
    data = [];
    doughnutChartData = { bigLegend: {}, datasets: [], labels: [] };
    isParentLoading = false;
    KeyEnum = KeyEnum;
    parentRef = {};

    constructor($filter) {
        this._translate = $filter('translate');
    }

    $onInit() {
        this.doughnutChartData = this.getDoughnutChartData(this.data);
        this.columns = this.getColumns();
    }

    getColumns() {
        return [
            {
                key: KeyEnum.ADDRESS_STATUS_ID,
                translation: 'ID',
            },
            {
                key: KeyEnum.ADDRESS_STATUS_NAME,
                translation: this._translate('shared.type'),
            },
            {
                isPercentage: true,
                key: KeyEnum.ADVANCEMENT,
                translation: this._translate('shared.progress'),
            },
        ];
    }

    getDoughnutChartData(data) {
        const colors = data.map((currentData) =>
            ColorService.getAddressStateColor(currentData[KeyEnum.ADDRESS_STATUS_ID]),
        );

        return {
            bigLegend: {
                figure: '100 %',
                name: this._translate('shared.progress'),
            },
            datasets: [
                {
                    backgroundColor: [...colors, DoughnutChartColorEnum.NEUTRAL],
                    data: [
                        ...data.map((currentData) => currentData[KeyEnum.ADVANCEMENT]),
                        100 - radashSum(data, (currentData) => currentData[KeyEnum.ADVANCEMENT]),
                    ],
                },
            ],
            labels: [
                ...data.map((currentData) => currentData[KeyEnum.ADDRESS_STATUS_NAME]),
                this._translate('shared.theoreticalReserveRate'),
            ],
        };
    }
}

angular.module('dotic').component('deploymentAdvancementLevelIndicator', {
    controller: DeploymentAdvancementLevelIndicatorComponent,
    templateUrl: template,
    bindings: {
        data: '<',
        isParentLoading: '<',
        parentRef: '<',
    },
});
