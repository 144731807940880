import { fork as radashFork } from 'radash';

import { DepositsApi, ReportsApi } from '../../../../sdk/connect-control-api-v1/src';
import { ControlResultTypeEnum } from '../../../models/common.model';
import template from './anct-verification-compare.component.html';

class ANCTVerificationCompareComponent {
    ANCTVerificationA = {
        controls: [],
        deposit: null,
        formattedControls: [],
        indicatorsDataStructures: null,
    };
    ANCTVerificationB = {
        controls: [],
        deposit: null,
        formattedControls: [],
        indicatorsDataStructures: null,
    };
    company = {};
    controlComparisons = [];
    controlComparisonDetailToDisplay = '';
    controlDiffCount = 0;
    controlPointsAnomalyImprovementCount = 0;
    controlPointsAnomalyRegressionCount = 0;
    controlSameCount = 0;
    filteredControls = [];
    filterOptions = [
        {
            allowed: true,
            name: 'results',
            value: 'result',
            placeholder: 'filter.all.result',
            translateParamsLabel: { COUNT: 1 },
            translateKeyLabel: 'shared.result',
        },
    ];
    filters = {
        result: '',
    };
    filterValues = {
        results: [],
    };
    indicatorToDisplay = 'controls';
    isIndicatorsVisible = false;
    isAnomaliesVisible = false;
    loading = true;

    constructor(
        $apiClientService,
        $dataModelService,
        $excelService,
        $filter,
        $filterService,
        $location,
        $modalService,
        $reportService,
        $scope,
        $segmentsService,
        $state,
        $timeout,
        $toasterService,
        indicatorChartService,
        LoaderService,
        reportProvider,
        userMetricsProvider,
    ) {
        this._$dataModelService = $dataModelService;
        this._$excelService = $excelService;
        this._$filterService = $filterService;
        this._humanizeSeverity = $filter('humanizeSeverity');
        this._translate = $filter('translate');
        this._$location = $location;
        this._$modalService = $modalService;
        this._$reportService = $reportService;
        this._$scope = $scope;
        this._$segmentsService = $segmentsService;
        this._$state = $state;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._LoaderService = LoaderService;
        this._depositApi = new DepositsApi($apiClientService.client);
        this._indicatorChartService = indicatorChartService;
        this._reportApi = new ReportsApi($apiClientService.client);
        this._reportProvider = reportProvider;
        this._userMetricsProvider = userMetricsProvider;

        // Set filters from urls
        this.setFilters($state.params);
        $scope.$on('$locationChangeSuccess', () => {
            this.setFilters($state.params);
        });
        // Watch scope changes
        $scope.$watchGroup(['$ctrl.filters.result', '$ctrl.controlComparisons'], () => this.filterControls());
    }

    async initANCTVerification(depositId) {
        const deposit = (await this._depositApi.getByIdWithHttpInfo(depositId)).response.body;
        const report = (await this._reportApi.getDetailsWithHttpInfo(deposit.report._id, { isANCT: true })).response
            .body;
        const reportStatistic = (await this._reportApi.getReportStatisticsWithHttpInfo(report._id)).response.body;
        const indicatorsDataStructures = this._indicatorChartService.generateANCTFullDataStructure(
            reportStatistic.indicators.ANCT,
        );
        // Necessary for control synthesis indicator
        const formattedControls = this._$reportService.getControls(
            report.controls,
            report.deposit.gridControl,
        ).controls;

        return {
            controls: report.controls,
            deposit: deposit,
            formattedControls: formattedControls,
            indicatorsDataStructures: indicatorsDataStructures,
        };
    }

    async $onInit() {
        this.loading = true;
        this.filterValues = this.getFilterValues();

        try {
            this.ANCTVerificationA = await this.initANCTVerification(this._$state.params.depositAId);
            this.ANCTVerificationB = await this.initANCTVerification(this._$state.params.depositBId);

            this._userMetricsProvider.compareReports(
                this.ANCTVerificationA.deposit.report._id,
                this.ANCTVerificationB.deposit.report._id,
            );

            this.controlComparisons = this._$reportService.getComparisonReports(
                this.ANCTVerificationA,
                this.ANCTVerificationB,
            );

            const [sameControls, diffControls] = radashFork(
                this.controlComparisons,
                (controlComparison) =>
                    controlComparison.invalid_count1 === controlComparison.invalid_count2 &&
                    controlComparison.enabled1 === controlComparison.enabled2,
            );
            this.controlDiffCount = diffControls.length;
            this.controlSameCount = sameControls.length;
            this.controlPointsAnomalyRegressionCount = this.controlComparisons.filter(
                (controlComparison) =>
                    controlComparison.invalid_count1 < controlComparison.invalid_count2 &&
                    controlComparison.enabled1 === controlComparison.enabled2,
            ).length;
            this.controlPointsAnomalyImprovementCount = this.controlComparisons.filter(
                (controlComparison) =>
                    controlComparison.invalid_count1 > controlComparison.invalid_count2 &&
                    controlComparison.enabled1 === controlComparison.enabled2,
            ).length;
        } catch (error) {
            this._$toasterService.error(error);
        }

        this._$scope.$emit('keepPreviousNavigation', {
            newPage: [
                {
                    key: 'anct-comparison',
                    title: this._translate('shared.comparison'),
                    href: this._$location.path(),
                },
            ],
            defaultPrevious: {
                title: this._translate('shared.ANCTVerifiedDeposit'),
                href: this._$state.href('app.anctVerificationsList'),
                key: 'anctVerifications',
            },
            allowedPreviousKeys: ['anctVerification', 'anctVerifications', 'compare'],
        });

        this._$timeout(() => {
            this.loading = false;
        });
    }

    filterControls() {
        this._$state.go('.', {
            ...this.filters,
        });

        this.filteredControls = this.controlComparisons.filter(this.resultFilter(this.filters.result));
    }

    findControlPoint(data, item) {
        return (
            data.find((control) => control.controlPoint === item.controlPoint && control.severity === item.severity) ??
            {}
        );
    }

    getFilterValues() {
        return {
            results: [
                { key: ControlResultTypeEnum.DIFF, value: this._translate('shared.difference') },
                { key: ControlResultTypeEnum.SAME, value: this._translate('shared.same') },
                { key: ControlResultTypeEnum.UNUSED, value: this._translate('shared.nonExistent') },
                { key: ControlResultTypeEnum.ERROR, value: this._translate('shared.inoperative') },
                { key: ControlResultTypeEnum.FAIL, value: this._translate('shared.anomaly') },
                { key: ControlResultTypeEnum.SUCCESS, value: this._translate('shared.succeeded') },
                {
                    key: ControlResultTypeEnum.IMPROVEMENT,
                    value: this._translate('statisticComparisonEvolution.filterImprovement'),
                },
                {
                    key: ControlResultTypeEnum.REGRESSION,
                    value: this._translate('statisticComparisonEvolution.filterRegression'),
                },
            ],
        };
    }

    async openControlErrors(item) {
        if (item.invalid_count1 <= 0 && item.invalid_count2 <= 0) {
            return;
        }

        const depositAId = this._$state.params.depositAId;
        const depositBId = this._$state.params.depositBId;

        const versionA = this.ANCTVerificationA.deposit.version;
        const versionB = this.ANCTVerificationB.deposit.version;

        const controlA = this.findControlPoint(this.ANCTVerificationA.deposit.report.controls, item) || {};
        const controlB = this.findControlPoint(this.ANCTVerificationB.deposit.report.controls, item) || {};

        if (controlA.invalid_count <= 0 && controlB.invalid_count <= 0) {
            return;
        }

        const hasControlPointInError = !Object.entries(controlA).length || !Object.entries(controlB).length;
        if (!hasControlPointInError) {
            try {
                const controlErrors = await Promise.all([
                    this._reportProvider.getVersionControlJson(depositAId, controlA.controlPoint, controlA.severity),
                    this._reportProvider.getVersionControlJson(depositBId, controlB.controlPoint, controlB.severity),
                ]);
                const control = angular.isDefined(controlA)
                    ? { ...controlA, versionA, versionB }
                    : { ...controlB, versionA, versionB };

                return this._$modalService.triggerControlErrorModal(control, controlErrors, undefined, false);
            } catch (error) {
                this._$toasterService.error(error);
            }
        }

        this.reportId =
            angular.isDefined(controlA) && controlA.faults >= 0
                ? this.ANCTVerificationA.deposit.report.id
                : this.ANCTVerificationB.deposit.report.id;
        const control = angular.isDefined(controlA) && controlA.faults >= 0 ? controlA : controlB;

        return this._$modalService.triggerControlErrorModal(
            control,
            this._$reportService.getErrors(this.reportId, control.controlPoint, control.severity),
        );
    }

    resultFilter(key) {
        return (control) => {
            switch (key) {
                case ControlResultTypeEnum.SUCCESS:
                    return !control.invalid_count1 || !control.invalid_count2;
                case ControlResultTypeEnum.ERROR: {
                    const code = [-1, -2];

                    return code.indexOf(control.invalid_count1) !== -1 || code.indexOf(control.invalid_count2) !== -1;
                }
                case ControlResultTypeEnum.UNUSED:
                    return control.invalid_count1 === -3 || control.invalid_count2 === -3;
                case ControlResultTypeEnum.FAIL:
                    return control.invalid_count1 > 0 || control.invalid_count2 > 0;
                case ControlResultTypeEnum.DIFF:
                    return control.invalid_count1 !== control.invalid_count2 || control.enabled1 !== control.enabled2;
                case ControlResultTypeEnum.SAME:
                    return control.invalid_count1 === control.invalid_count2 && control.enabled1 === control.enabled2;
                case ControlResultTypeEnum.IMPROVEMENT:
                    return control.invalid_count1 > control.invalid_count2 && control.enabled1 === control.enabled2;
                case ControlResultTypeEnum.REGRESSION:
                    return control.invalid_count1 < control.invalid_count2 && control.enabled1 === control.enabled2;
                default:
                    return true;
            }
        };
    }

    setFilters(params = {}) {
        this.filters = {
            result: params.result || '',
        };
    }

    showSameOrDiff(value) {
        this.isAnomaliesVisible = true;
        this.filters.result = value;
        const resultFilter = this.filterOptions.find((item) => item.name === 'results');
        this._$scope.$broadcast('forceFilterRender', {
            filter: resultFilter,
            saveChanges: true,
            openDropdown: false,
        });

        // $location.hash('id') with $anchorScroll don't work here...
        this._$timeout(() => {
            const element = angular.element('#anomalies');
            angular.element('html').animate({ scrollTop: element.offset().top }, 'slow');
        }, 0);
    }

    toggleAnomaliesVisibility() {
        this.isAnomaliesVisible = !this.isAnomaliesVisible;
    }

    toggleControlComparisonDetail(controlPoint) {
        this.controlComparisonDetailToDisplay =
            controlPoint !== this.controlComparisonDetailToDisplay ? controlPoint : null;
    }

    toggleIndicatorsVisibility() {
        this.isIndicatorsVisible = !this.isIndicatorsVisible;
    }
}

angular.module('dotic').component('anctVerificationsCompare', {
    controller: ANCTVerificationCompareComponent,
    templateUrl: template,
});
