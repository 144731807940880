/**
 * ConnectControl API Documentation (v1)
 * The official ConnectControl Open API documentation (v1)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class IndicatorNameEnum.
* @enum {}
* @readonly
*/
export default class IndicatorNameEnum {
    
        /**
         * value: "abandonedObject"
         * @const
         */
        "ABANDONED_OBJECT" = "abandonedObject";

    
        /**
         * value: "advancementLevelByPTOAndCapacityMax"
         * @const
         */
        "ADVANCEMENT_LEVEL_BY_PTO_AND_CAPACITY_MAX" = "advancementLevelByPTOAndCapacityMax";

    
        /**
         * value: "advancementLevelByStateAndCapacityMax"
         * @const
         */
        "ADVANCEMENT_LEVEL_BY_STATE_AND_CAPACITY_MAX" = "advancementLevelByStateAndCapacityMax";

    
        /**
         * value: "averageBoxSaturationRate"
         * @const
         */
        "AVERAGE_BOX_SATURATION_RATE" = "averageBoxSaturationRate";

    
        /**
         * value: "averageCableSaturationRate"
         * @const
         */
        "AVERAGE_CABLE_SATURATION_RATE" = "averageCableSaturationRate";

    
        /**
         * value: "averageBoxTheoreticalReserveRatio"
         * @const
         */
        "AVERAGE_BOX_THEORETICAL_RESERVE_RATIO" = "averageBoxTheoreticalReserveRatio";

    
        /**
         * value: "averageCableTheoreticalReserveRatio"
         * @const
         */
        "AVERAGE_CABLE_THEORETICAL_RESERVE_RATIO" = "averageCableTheoreticalReserveRatio";

    
        /**
         * value: "avgpbo"
         * @const
         */
        "AVGPBO" = "avgpbo";

    
        /**
         * value: "beingDeployedAddresses"
         * @const
         */
        "BEING_DEPLOYED_ADDRESSES" = "beingDeployedAddresses";

    
        /**
         * value: "boxStatus"
         * @const
         */
        "BOX_STATUS" = "boxStatus";

    
        /**
         * value: "bpe"
         * @const
         */
        "BPE" = "bpe";

    
        /**
         * value: "cableBti"
         * @const
         */
        "CABLE_BTI" = "cableBti";

    
        /**
         * value: "cableControl"
         * @const
         */
        "CABLE_CONTROL" = "cableControl";

    
        /**
         * value: "cableLengthByCapacityAndLogicalType"
         * @const
         */
        "CABLE_LENGTH_BY_CAPACITY_AND_LOGICAL_TYPE" = "cableLengthByCapacityAndLogicalType";

    
        /**
         * value: "cableLengthByImplantation"
         * @const
         */
        "CABLE_LENGTH_BY_IMPLANTATION" = "cableLengthByImplantation";

    
        /**
         * value: "cableLengthByModuloAndLogicalType"
         * @const
         */
        "CABLE_LENGTH_BY_MODULO_AND_LOGICAL_TYPE" = "cableLengthByModuloAndLogicalType";

    
        /**
         * value: "cableLengthByOrganizationAndDesign"
         * @const
         */
        "CABLE_LENGTH_BY_ORGANIZATION_AND_DESIGN" = "cableLengthByOrganizationAndDesign";

    
        /**
         * value: "cableLengthByOwnerAndManager"
         * @const
         */
        "CABLE_LENGTH_BY_OWNER_AND_MANAGER" = "cableLengthByOwnerAndManager";

    
        /**
         * value: "cableLengthBySliceType"
         * @const
         */
        "CABLE_LENGTH_BY_SLICE_TYPE" = "cableLengthBySliceType";

    
        /**
         * value: "cableLengthByType"
         * @const
         */
        "CABLE_LENGTH_BY_TYPE" = "cableLengthByType";

    
        /**
         * value: "cableLengthByTypeAndProvider"
         * @const
         */
        "CABLE_LENGTH_BY_TYPE_AND_PROVIDER" = "cableLengthByTypeAndProvider";

    
        /**
         * value: "cables"
         * @const
         */
        "CABLES" = "cables";

    
        /**
         * value: "codeHexacle"
         * @const
         */
        "CODE_HEXACLE" = "codeHexacle";

    
        /**
         * value: "collectReusedLength"
         * @const
         */
        "COLLECT_REUSED_LENGTH" = "collectReusedLength";

    
        /**
         * value: "completionRateByAttribute"
         * @const
         */
        "COMPLETION_RATE_BY_ATTRIBUTE" = "completionRateByAttribute";

    
        /**
         * value: "connectivity"
         * @const
         */
        "CONNECTIVITY" = "connectivity";

    
        /**
         * value: "deployedAddresses"
         * @const
         */
        "DEPLOYED_ADDRESSES" = "deployedAddresses";

    
        /**
         * value: "fieldOccurrence"
         * @const
         */
        "FIELD_OCCURRENCE" = "fieldOccurrence";

    
        /**
         * value: "housing"
         * @const
         */
        "HOUSING" = "housing";

    
        /**
         * value: "housingCountByNro"
         * @const
         */
        "HOUSING_COUNT_BY_NRO" = "housingCountByNro";

    
        /**
         * value: "housingCountByPmReference"
         * @const
         */
        "HOUSING_COUNT_BY_PM_REFERENCE" = "housingCountByPmReference";

    
        /**
         * value: "junctionBox"
         * @const
         */
        "JUNCTION_BOX" = "junctionBox";

    
        /**
         * value: "layerFile"
         * @const
         */
        "LAYER_FILE" = "layerFile";

    
        /**
         * value: "lgCable"
         * @const
         */
        "LG_CABLE" = "lgCable";

    
        /**
         * value: "linearMeterByClass"
         * @const
         */
        "LINEAR_METER_BY_CLASS" = "linearMeterByClass";

    
        /**
         * value: "linearMeterByLogicalType"
         * @const
         */
        "LINEAR_METER_BY_LOGICAL_TYPE" = "linearMeterByLogicalType";

    
        /**
         * value: "linearMeterByMethod"
         * @const
         */
        "LINEAR_METER_BY_METHOD" = "linearMeterByMethod";

    
        /**
         * value: "localNumberByDistanceRange"
         * @const
         */
        "LOCAL_NUMBER_BY_DISTANCE_RANGE" = "localNumberByDistanceRange";

    
        /**
         * value: "localNumberByDistanceRangeAndAdRaclong"
         * @const
         */
        "LOCAL_NUMBER_BY_DISTANCE_RANGE_AND_AD_RACLONG" = "localNumberByDistanceRangeAndAdRaclong";

    
        /**
         * value: "localNumberByImplantation"
         * @const
         */
        "LOCAL_NUMBER_BY_IMPLANTATION" = "localNumberByImplantation";

    
        /**
         * value: "localNumberByLogicalType"
         * @const
         */
        "LOCAL_NUMBER_BY_LOGICAL_TYPE" = "localNumberByLogicalType";

    
        /**
         * value: "localNumberBySiteLogicalType"
         * @const
         */
        "LOCAL_NUMBER_BY_SITE_LOGICAL_TYPE" = "localNumberBySiteLogicalType";

    
        /**
         * value: "nature_type_phy"
         * @const
         */
        "NATURE_PHYSICAL_TYPE" = "nature_type_phy";

    
        /**
         * value: "nbBPE"
         * @const
         */
        "NB_BPE" = "nbBPE";

    
        /**
         * value: "nbBti"
         * @const
         */
        "NB_BTI" = "nbBti";

    
        /**
         * value: "nbByNature"
         * @const
         */
        "NB_BY_NATURE" = "nbByNature";

    
        /**
         * value: "nbByPhysicalType"
         * @const
         */
        "NB_BY_PHYSICAL_TYPE" = "nbByPhysicalType";

    
        /**
         * value: "nbConnectionsByImplantationType"
         * @const
         */
        "NB_CONNECTIONS_BY_IMPLANTATION" = "nbConnectionsByImplantationType";

    
        /**
         * value: "nbOpticalChamber"
         * @const
         */
        "NB_OPTICAL_CHAMBER" = "nbOpticalChamber";

    
        /**
         * value: "nbPa"
         * @const
         */
        "NB_PA" = "nbPa";

    
        /**
         * value: "newPlug"
         * @const
         */
        "NEW_PLUG" = "newPlug";

    
        /**
         * value: "nroCountByDistanceClass"
         * @const
         */
        "NRO_COUNT_BY_DISTANCE_CLASS" = "nroCountByDistanceClass";

    
        /**
         * value: "nroHostingInOrangeNra"
         * @const
         */
        "NRO_HOSTING_IN_ORANGE_NRA" = "nroHostingInOrangeNra";

    
        /**
         * value: "distanceBetweenNroNra"
         * @const
         */
        "NRO_NRA_DISTANCE" = "distanceBetweenNroNra";

    
        /**
         * value: "distanceBetweenNroPbo"
         * @const
         */
        "NRO_PBO_DISTANCE" = "distanceBetweenNroPbo";

    
        /**
         * value: "distanceBetweenNroPto"
         * @const
         */
        "NRO_PTO_DISTANCE" = "distanceBetweenNroPto";

    
        /**
         * value: "nroTypePhy"
         * @const
         */
        "NRO_PHYSICAL_TYPE" = "nroTypePhy";

    
        /**
         * value: "distanceBetweenNroSro"
         * @const
         */
        "NRO_SRO_DISTANCE" = "distanceBetweenNroSro";

    
        /**
         * value: "opticalCableSupport"
         * @const
         */
        "OPTICAL_CABLE_SUPPORT" = "opticalCableSupport";

    
        /**
         * value: "pathway"
         * @const
         */
        "PATHWAY" = "pathway";

    
        /**
         * value: "pbo"
         * @const
         */
        "PBO" = "pbo";

    
        /**
         * value: "pboCountByStatus"
         * @const
         */
        "PBO_COUNT_BY_STATUS" = "pboCountByStatus";

    
        /**
         * value: "pboData"
         * @const
         */
        "PBO_DATA" = "pboData";

    
        /**
         * value: "pboReservePercent"
         * @const
         */
        "PBO_RESERVE_PERCENT" = "pboReservePercent";

    
        /**
         * value: "plugsStatus"
         * @const
         */
        "PLUGS_STATUS" = "plugsStatus";

    
        /**
         * value: "ptoStatus"
         * @const
         */
        "PTO_STATUS" = "ptoStatus";

    
        /**
         * value: "raFtte"
         * @const
         */
        "RA_FTTE" = "raFtte";

    
        /**
         * value: "siteNumberByLogicalType"
         * @const
         */
        "SITE_NUMBER_BY_LOGICAL_TYPE" = "siteNumberByLogicalType";

    
        /**
         * value: "sliceLinearMeterByType"
         * @const
         */
        "SLICE_LINEAR_METER_BY_TYPE" = "sliceLinearMeterByType";

    
        /**
         * value: "sro"
         * @const
         */
        "SRO" = "sro";

    
        /**
         * value: "distanceBetweenSroPbo"
         * @const
         */
        "SRO_PBO_DISTANCE" = "distanceBetweenSroPbo";

    
        /**
         * value: "sroTypePhy"
         * @const
         */
        "SRO_PHYSICAL_TYPE" = "sroTypePhy";

    
        /**
         * value: "tables"
         * @const
         */
        "TABLES" = "tables";

    
        /**
         * value: "technicalPointByCharacteristic"
         * @const
         */
        "TECHNICAL_POINT_BY_CHARACTERISTIC" = "technicalPointByCharacteristic";

    
        /**
         * value: "testableCondition"
         * @const
         */
        "TESTABLE_CONDITION" = "testableCondition";

    
        /**
         * value: "type_ebp_pto"
         * @const
         */
        "TYPE_EBP_PTO" = "type_ebp_pto";

    
        /**
         * value: "nbBoxes"
         * @const
         */
        "NB_BOXES" = "nbBoxes";

    
        /**
         * value: "nbGc"
         * @const
         */
        "NB_GC" = "nbGc";

    
        /**
         * value: "nbHouses"
         * @const
         */
        "NB_HOUSES" = "nbHouses";

    
        /**
         * value: "nbPlugs"
         * @const
         */
        "NB_PLUGS" = "nbPlugs";

    
        /**
         * value: "nroSro"
         * @const
         */
        "NRO_SRO" = "nroSro";

    
        /**
         * value: "technicalPoints"
         * @const
         */
        "TECHNICAL_POINTS" = "technicalPoints";

    

    /**
    * Returns a <code>IndicatorNameEnum</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/IndicatorNameEnum} The enum <code>IndicatorNameEnum</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

