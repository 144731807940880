import { unique as radashUnique } from 'radash';

import { ChartService } from './chart.service';

export class IndicatorService {
    static formatDecimalNumber(value, precision = 3) {
        const power = 10 ** precision;

        return Math.round(value * power) / power;
    }

    static getFormattedKilometerData(data, keys = []) {
        return data.map((currentData) => {
            const formattedValues = keys.reduce((acc, key) => {
                try {
                    return {
                        ...acc,
                        [key]: IndicatorService.formatDecimalNumber(currentData[key]),
                    };
                } catch {
                    return acc;
                }
            }, {});

            return {
                ...currentData,
                ...formattedValues,
            };
        });
    }

    // Return data with format { coloredLabel1: { label1: data1, label2: data2, ... } ... }
    static getTableChartFormattedData(data, coloredLabelKey, labelKey, dataKey) {
        return data.reduce((acc, currentData) => {
            return {
                ...acc,
                [currentData[coloredLabelKey]]: {
                    ...acc[currentData[coloredLabelKey]],
                    [currentData[labelKey]]: currentData[dataKey],
                },
            };
        }, {});
    }

    static getTableChartData(tableChartFormattedData, getColor, dataDefaultValue = 0) {
        const coloredLabels = Object.keys(tableChartFormattedData);
        const labels = radashUnique(
            coloredLabels.map((coloredLabel) => Object.keys(tableChartFormattedData[coloredLabel])).flat(),
        );

        const datasets = coloredLabels.map((coloredLabel) => {
            const dataGroupedByColoredBar = labels.map((label) => {
                return tableChartFormattedData[coloredLabel][label] || dataDefaultValue;
            });

            return ChartService.getTableChartDataset(dataGroupedByColoredBar, coloredLabel, getColor(coloredLabel));
        });

        return {
            datasets: datasets,
            labels: labels,
        };
    }

    static sortByCableCapacity(array) {
        return array.sort((a, b) => Number(a.cableCapacity) - Number(b.cableCapacity));
    }
}

angular.module('dotic').factory('$indicatorService', () => new IndicatorService());
