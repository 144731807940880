angular.module('dotic').config(($stateProvider) => {
    $stateProvider
        .state('app.projects', {
            url: '/projects',
            data: {
                authenticationRequired: true,
                roleRequired: ['admin', 'user', 'extendedUser'],
            },
        })
        .state('app.projectDetail', {
            url: '/projects/:projectId',
            component: 'projectDetailPage',
            data: {
                authenticationRequired: true,
                roleRequired: ['admin', 'user', 'extendedUser'],
            },
        })
        .state('app.projectDuplicate', {
            url: '/projects/:projectId/duplicate',
            data: {
                authenticationRequired: true,
                roleRequired: ['admin'],
            },
        })
        .state('app.projectEdit', {
            url: '/projects/:projectId/edit',
            data: {
                authenticationRequired: true,
                roleRequired: ['admin'],
            },
        });
});
