import { ChartService } from '../../chart.service';
import { DoughnutChartColorEnum } from '../../color.service';
import { HelpersService } from '../../helpers.service';

const KeyEnum = {
    INF_20_PERCENT_RESERVE_PBO_COUNT: 'inf20PercentReservePBOCount',
    SUP_20_PERCENT_RESERVE_PBO_COUNT: 'sup20PercentReservePBOCount',
};

// Linked to pbo-reserve-percent indicator
class IndicatorR00824Service {
    static ANCTCorrespondenceNumber = 24;

    constructor($filter) {
        this._translate = $filter('translate');
    }

    generateDataStructure(data) {
        const totalPBOCount =
            data[KeyEnum.INF_20_PERCENT_RESERVE_PBO_COUNT] + data[KeyEnum.SUP_20_PERCENT_RESERVE_PBO_COUNT];
        const inf20Percent = HelpersService.formatDecimalNumber(
            totalPBOCount ? (data[KeyEnum.INF_20_PERCENT_RESERVE_PBO_COUNT] / totalPBOCount) * 100 : 100,
        );

        return {
            ANCTCorrespondenceNumber: IndicatorR00824Service.ANCTCorrespondenceNumber,
            archChartData: {
                datasets: [
                    {
                        backgroundColor: [DoughnutChartColorEnum.BIG_SUCCESS, DoughnutChartColorEnum.NEUTRAL],
                        data: [100 - inf20Percent, inf20Percent],
                    },
                ],
                labels: [
                    this._translate('shared.moreThan20PercentReserve'),
                    this._translate('shared.lessThan20PercentReserve'),
                ],
            },
            chartOptions: ChartService.getArchChartDefaultOptions(),
            data: [data],
            description: {
                positivePercent: 100 - inf20Percent,
                positiveValue: data[KeyEnum.SUP_20_PERCENT_RESERVE_PBO_COUNT],
                totalValue: totalPBOCount,
                unit: 'PBO',
                unitStatus: this._translate('shared.moreThan20PercentReserve', { OPTION: 'with' }).toLowerCase(),
            },
            tableData: {
                columns: this.getColumns(),
                title: this._translate('shared.detailPboReservePercent'),
            },
        };
    }

    getColumns() {
        return [
            {
                key: KeyEnum.INF_20_PERCENT_RESERVE_PBO_COUNT,
                translation: this._translate('shared.lessThan20PercentReserve'),
            },
            {
                key: KeyEnum.SUP_20_PERCENT_RESERVE_PBO_COUNT,
                translation: this._translate('shared.moreThan20PercentReserve'),
            },
        ];
    }
}

angular.module('dotic').factory('indicatorR00824Service', IndicatorR00824Service);
