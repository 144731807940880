import template from './fmeFormModal.html';

class FmeFormModalComponent {
    constructor($auth, $toasterService, $filter) {
        this._$toasterService = $toasterService;
        this._$filter = $filter;

        const payload = $auth.getPayload();
        this.companyId = payload.company;
        this.userId = payload.userId;

        this.projectList = [];
        this.mappedProjectList = [];
        this.isEditMode = false;
    }

    $onInit() {
        this.name = this.resolve.name;
        this.description = this.resolve.description;
        this.host = this.resolve.host;
        this.token = this.resolve.token;
        this.repositoryName = this.resolve.repositoryName;
        this.workbenchName = this.resolve.workbenchName;
        this.isEditMode = this.resolve.isEditMode;
    }

    onFormSubmit() {
        this.close({
            $value: {
                description: this.description,
                host: this.host,
                name: this.name,
                repositoryName: this.repositoryName,
                token: this.token,
                workbenchName: this.workbenchName,
            },
        });
    }

    cancelPopup() {
        this.dismiss();
    }
}

angular.module('dotic').component('fmeFormModal', {
    templateUrl: template,
    controller: FmeFormModalComponent,
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
    },
});
