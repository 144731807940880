angular.module('dotic').config(($stateProvider) => {
    $stateProvider
        .state('app.synoptic', {
            url: '/synoptic/{zipId:[A-Za-z0-9]+}/download',
            redirectTo: 'app.plansExportZipDownload',
            data: {
                authenticationRequired: true,
                roleRequired: ['admin', 'user', 'extendedUser'],
            },
        })
        .state('app.plansExportZipDownload', {
            url: '/plans-export/{zipId:[A-Za-z0-9]+}/download',
            component: 'plansExportZipComponent',
            data: {
                authenticationRequired: true,
                roleRequired: ['admin', 'user', 'extendedUser'],
            },
        });
});
