import depositListControlTemplate from './control/depositListControl.html';
import depositListLoadTemplate from './load/depositListLoad.html';
import depositListTemplate from './depositList.html';
import { FMEJobEnum } from '../../models/deposit.model';

angular.module('dotic').config(($stateProvider) => {
    $stateProvider
        .state('app.depositList', {
            url: '/deposits/list?phase&status&project&company&createdBy&createdAtStart&createdAtEnd&search&p&controlConfiguration',
            params: {
                phase: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                status: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                project: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                company: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                createdBy: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                createdAtStart: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                createdAtEnd: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                search: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                p: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
            },
            templateUrl: depositListTemplate,
            controller: 'DepositListController as $ctrl',
            data: {
                authenticationRequired: true,
                roleRequired: ['admin', 'extendedUser', 'user'],
            },
        })
        .state('app.depositJob-control', {
            url: '/deposits/control?phase&segment&status&project&projectId&deliveryZone&dataModel&version&controlConfiguration&company&createdBy&createdAtStart&createdAtEnd&date&dateStart&dateend$search&p&idLivrable',
            params: {
                phase: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                segment: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                status: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                project: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                projectId: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                deliveryZone: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                dataModel: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                version: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                controlConfiguration: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                company: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                createdBy: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                date: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                createdAtStart: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                createdAtEnd: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                idLivrable: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                search: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                p: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
            },
            templateUrl: depositListControlTemplate,
            controller: 'DepositListController as $ctrl',
            data: {
                authenticationRequired: true,
                roleRequired: ['admin', 'extendedUser', 'user'],
                currentJob: FMEJobEnum.CONTROL,
            },
        })
        /**
         * Redirect to report page from a depositId
         */
        .state('app.depositJob-control-report', {
            url: '/deposits/control/{depositId:[A-Za-z0-9]+}/report',
            template: '<h5 class="m-20">Vous allez être redirigé vers votre rapport</h5>',
            controllerAs: 'vm',
            controller: function ($stateParams, $state, depositProvider) {
                depositProvider
                    .getReport($stateParams.depositId)
                    .then((res) => $state.go(`app.reportDetail`, { reportId: res.id }))
                    .catch(() => $state.go('app.depositJob-control'));
            },
            data: {
                authenticationRequired: true,
                roleRequired: ['admin', 'extendedUser', 'user'],
            },
        })
        .state('app.depositJob-load', {
            url: '/deposits/load?phase&segment&search&status&project&deliveryZone&dataModel&date&createdBy&projectId&server&createdAtStart&createdAtEnd&p',
            params: {
                phase: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                segment: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                search: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                status: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                project: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                deliveryZone: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                dataModel: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                date: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                createdAtStart: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                createdAtEnd: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                createdBy: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                projectId: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                server: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                p: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
            },
            templateUrl: depositListLoadTemplate,
            controller: 'DepositListController as $ctrl',
            data: {
                authenticationRequired: true,
                roleRequired: ['admin', 'extendedUser', 'user'],
                currentJob: FMEJobEnum.LOAD,
            },
        });
});
