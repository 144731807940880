angular.module('dotic').config(($stateProvider) => {
    $stateProvider
        .state('app.userMetrics', {
            url: '/superAdmin/usersMetrics?p',
            component: 'userMetricsPage',
            params: {
                p: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
            },
            data: {
                authenticationRequired: true,
                roleRequired: ['superAdmin'],
            },
        })
        .state('app.userMetricsDetails', {
            url: '/superAdmin/userMetrics/:userId',
            component: 'userMetricsDetailsPage',
            data: {
                authenticationRequired: true,
                roleRequired: ['superAdmin'],
            },
        });
});
