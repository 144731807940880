angular.module('dotic').factory('authenticationProvider', authenticationProvider);

function authenticationProvider(AuthProvider) {
    return {
        changeCompany,
        updateToken,
    };

    function changeCompany(companyId) {
        return AuthProvider.one('change-company').customPOST({
            companyId: companyId,
        });
    }

    function updateToken() {
        return AuthProvider.one('update').post();
    }
}
