import { ColorService } from './color.service';

class CapacityCableService {
    sumCapacityCable(data) {
        if (!data) {
            return {};
        }

        return data.reduce((acc, entry) => {
            if (!acc[entry.type]) {
                acc[entry.type] = {
                    type: entry.type,
                    count: 0,
                    title: entry.type,
                    nbCables: 0,
                    backgroundColor: ColorService.getCableLogicalTypeColor(entry.type),
                };
            }

            acc[entry.type].count += entry.count;
            acc[entry.type].nbCables += entry.nbCables || 0;

            return acc;
        }, {});
    }

    reduceType(data) {
        return Object.values(data).reduce((type, nbCable) => {
            for (const [key, value] of Object.entries(nbCable)) {
                if (!type[key]) {
                    type[key] = 0;
                }

                type[key] += value;
            }

            return type;
        }, {});
    }

    getSumNbCable(data1, data2, isComparison) {
        let sumDataOrigin = {};

        if (data1.length) {
            sumDataOrigin = this.reduceType(data1);
        }

        let result = sumDataOrigin.nbCables ?? 0;

        if (isComparison && data2.length) {
            const sumDataToCompare = this.reduceType(data2);

            result = {
                origin: sumDataOrigin.nbCables,
                toCompare: sumDataToCompare.nbCables ?? 0,
            };
        }

        return result;
    }
}

angular.module('dotic').factory('$indicatorCapacityCableService', () => new CapacityCableService());
