import angular from 'angular';

import EnvironmentSettingsService from '../../../services/environment-settings.service';
import { GridActionEnum } from '../../../models/grid.model';
import { HelpersService } from '../../../services/helpers.service';
import template from './fillGridView.html';

class FillGridViewController {
    possibleActions = '';

    constructor(
        $auth,
        $authorizationService,
        $fillGridService,
        $filter,
        $filterService,
        $gridService,
        $location,
        $modalService,
        $scope,
        $state,
        $stateParams,
        $tableService,
        $timeout,
        $toasterService,
        $uibModal,
        dataModelProvider,
        fillGridProvider,
        userMetricsProvider,
    ) {
        this._$fillGridService = $fillGridService;
        this._$filterService = $filterService;
        this._humanizeSeverity = $filter('humanizeSeverity');
        this._translate = $filter('translate');
        this._$location = $location;
        this._$scope = $scope;
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._$tableService = $tableService;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._$uibModal = $uibModal;
        this._dataModelProvider = dataModelProvider;
        this._fillGridProvider = fillGridProvider;

        this.isAllowed = $authorizationService.isAllowed;
        this.companyId = $auth.getPayload().company;
        this.isOrange = EnvironmentSettingsService.isOrange();

        this._$modalService = $modalService;
        this._$gridService = $gridService;

        this.isBottomFilter = true;
        this.filterOptions = [
            {
                allowed: true,
                name: 'testableConditionList',
                value: 'testableName',
                placeholder: 'filter.all.testableCondition',
                translateKeyLabel: 'shared.testableCondition',
                hasSearch: true,
            },
            {
                allowed: true,
                name: 'severityList',
                selected: 'severity',
                placeholder: 'filter.all.severity',
                saveValue: true,
                translateKeyLabel: 'shared.severity',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'phaseList',
                selected: 'phase',
                placeholder: 'filter.all.phases',
                translateKeyLabel: 'shared.steps',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'phaseActivatedList',
                value: 'phaseActivated',
                placeholder: 'filter.all.state',
                translateKeyLabel: 'shared.state',
                saveValue: true,
            },
            {
                allowed: true,
                name: 'relationList',
                selected: 'relation',
                placeholder: 'filter.all.relation',
                hasSearch: true,
                translateKeyLabel: 'shared.relationship',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'requiredList',
                selected: 'required',
                placeholder: 'filter.all.status',
                saveValue: true,
                translateKeyLabel: 'shared.status',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'tableList',
                selected: 'table',
                placeholder: 'filter.all.table',
                hasSearch: true,
                translateKeyLabel: 'shared.table',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'tableTypes',
                value: 'tableType',
                placeholder: 'filter.all.tableType',
                translateKeyLabel: 'shared.tableType',
                saveValue: true,
            },
        ];

        this.filterValues = {
            tableList: [],
            severityList: [
                { key: 'minor', value: this._humanizeSeverity('minor') },
                { key: 'major', value: this._humanizeSeverity('major') },
                {
                    key: 'blocking',
                    value: this._humanizeSeverity('blocking'),
                },
            ],
            phaseActivatedList: [
                {
                    key: 'activated',
                    value: this._translate('shared.activated', { GENDER: 'male' }),
                },
                {
                    key: 'disable',
                    value: this._translate('shared.disabled'),
                },
            ],
            phaseList: [],
            relationList: [],
            requiredList: [
                {
                    key: 'required',
                    value: this._translate('status.required', { COUNT: 1 }),
                },
                {
                    key: 'optional',
                    value: this._translate('status.optional', { COUNT: 1 }),
                },
                {
                    key: 'noRequired',
                    value: this._translate('status.noRequired', { COUNT: 1 }),
                },
            ],
            tableTypes: [
                {
                    key: '^t_',
                    value: this._translate('fillGrid.linkedTable'),
                },
                {
                    key: '^l_',
                    value: this._translate('fillGrid.listedTable'),
                },
            ],
            testableConditionList: [
                { key: true, value: this._translate('shared.yes') },
                { key: false, value: this._translate('shared.no') },
            ],
        };

        this.dataModels = [];
        this.fillGrid = [];
        this.fillGridId = $stateParams.gridFillId;
        this.fillGridList = [];
        this.setDefaultFilter($stateParams);

        this.filteredFillGridList = [];
        this.thematicList = [];
        this.status = {};
        this.loading = true;

        this._$scope.$watchGroup(
            [
                '$ctrl.fillGrid.isAdvanced',
                '$ctrl.fillGridList',
                '$ctrl.filter.severity',
                '$ctrl.filter.phase',
                '$ctrl.filter.phaseActivated',
                '$ctrl.filter.relation',
                '$ctrl.filter.required',
                '$ctrl.filter.search',
                '$ctrl.filter.table',
                '$ctrl.filter.tableType',
                '$ctrl.filter.testableName',
            ],
            () => this.setScopeGridFillViewWithFilters(),
        );

        userMetricsProvider.openFillGrid(this.fillGridId);
    }

    // includeSearch allows to clear all filters except search -- cf. cancelFilter function
    setDefaultFilter(params = {}, includeSearch = true) {
        if (!includeSearch && this.filter) {
            params.search = this.filter.search;
        }

        this.filter = {
            severity: this._$filterService.getFilterValue(params.severity),
            phase: this._$filterService.getFilterValue(params.phase),
            phaseActivated: params.phaseActivated || '',
            relation: this._$filterService.getFilterValue(params.relation),
            required: this._$filterService.getFilterValue(params.required),
            search: params.search || '',
            table: this._$filterService.getFilterValue(params.table),
            tableType: params.tableType || '',
            testableName: params.testableCondition || '',
        };
    }

    $onInit() {
        Promise.all([this._fillGridProvider.get(this.fillGridId), this._dataModelProvider.getList()])
            .then(([fillGrid, dataModels]) => {
                this.fillGrid = fillGrid;
                this.fillGridList = fillGrid.data;
                this.dataModels = dataModels;
                this.prepareFilters(this.fillGridList);
                this.phasesEnabledList = HelpersService.getPhasesListWithCountEnabled(this.fillGridList);
                this.phasesByStatus = this.getPhasesByStatus(this.fillGridList);
                this.phases = this.phasesEnabledList.map(({ name }) => name);
                this.tableDetail = this._$tableService.detail();
                this.possibleActions = this._$gridService.getPossibleActions(this.fillGrid, [
                    GridActionEnum.DUPLICATION,
                    GridActionEnum.EDITION,
                ]);

                this._$scope.$emit('keepPreviousNavigation', {
                    newPage: [
                        {
                            key: 'fillGrid',
                            title: `${this._translate('shared.fillGrid')} ${this.fillGrid.fullname}`,
                            href: this._$location.path(),
                        },
                    ],
                    defaultPrevious: {
                        title: this._translate('shared.fillGrid'),
                        href: this._$state.href('app.gridFillAdminList'),
                        key: 'fillGrids',
                    },
                    allowedPreviousKeys: [
                        'comparaison',
                        'comparedFillGrids',
                        'controlConfigurationDetail',
                        'controlConfigurationGridsLink',
                        'controlConfigurations',
                        'fillGrids',
                        'fillingStatistic',
                        'fillingStatisticsCompare',
                        'gridFillFromDeposit',
                        'projectDetail',
                        'report',
                    ],
                });
                this.showButtonCancelFilter = HelpersService.isFilterEmpty(this.filters);
                this._$timeout(() => {
                    this.loading = false;
                });
            })
            .catch((e) => this._$toasterService.error(e));
    }

    static filterByPhases(phaseActivated) {
        if (phaseActivated === 'activated') {
            return (control) => control.phases.some((phase) => phase.isEnabled);
        } else if (phaseActivated === 'disable') {
            return (control) => control.phases.every((phase) => !phase.isEnabled);
        }

        return () => true;
    }

    setScopeGridFillViewWithFilters() {
        const { severity, phase, phaseActivated, relation, required, search, table, tableType, testableName } =
            this.filter;

        this._$state.go('.', {
            severity: severity.join(','),
            phase: phase.join(','),
            phaseActivated,
            relation: relation.join(','),
            required: required.join(','),
            search,
            table: table.join(','),
            tableType,
            testableName,
        });

        let regexpTableType = null;
        if (tableType && tableType !== '') {
            regexpTableType = new RegExp(tableType, 'i');
        }

        this.filteredFillGridList = this.fillGridList
            .filter(FillGridViewController.filterByPhases(phaseActivated))
            .filter(this.filterSearch(search))
            .filter(this.filterPhases(phase))
            .filter(this._$filterService.genericMultiFilter(relation, 'relation'))
            .filter(this._$filterService.genericMultiFilter(table, 'tableName'))
            .filter(this.filterTableTypes(regexpTableType))
            .filter(this._$filterService.genericMultiFilter(severity, 'severity'))
            .filter(this._$filterService.genericMultiFilter(required, 'required'))
            .filter(this.filterTestableCondition(testableName));

        this.showButtonCancelFilter = HelpersService.isFilterEmpty(this.filter);
    }

    filterTestableCondition(testableName) {
        return (attr) => testableName === '' || attr.testableCondition === testableName;
    }

    filterSearch(search) {
        const string = search.toLowerCase();

        return (attr) =>
            search === '' ||
            attr.attributName.toLowerCase().indexOf(string) > -1 ||
            attr.regex.toLowerCase().indexOf(string) > -1;
    }

    filterPhases(phases) {
        return (attr) =>
            !phases.length ||
            phases.some((phase) =>
                attr.phases.find((attributePhase) => attributePhase.phaseName === phase && attributePhase.isEnabled),
            );
    }

    filterTableTypes(regexpTableType) {
        return (attr) => regexpTableType === null || regexpTableType.test(attr.relation);
    }

    async duplicateFillGrid(companyId, fillGridId) {
        const isAccepted = await this._$modalService.triggerConfirmationModal(
            'shared.confirmDuplicateGrid',
            'shared.continue',
        );
        if (!isAccepted) {
            return;
        }

        this.duplicating = true;

        try {
            const fillGridCreated = await this._$fillGridService.duplicate(companyId, fillGridId);
            this._$state.go('app.fillGridView', {
                gridFillId: fillGridCreated.id,
            });
        } catch (error) {
            if (!error.data || !angular.isArray(error.data)) {
                this._$toasterService.error(error);

                return;
            }

            this._$gridService.displayDuplicatedError(error.data, 5);
        } finally {
            this.duplicating = false;
        }
    }

    async downloadFillGrid(fillGridId) {
        return await this._$gridService.onExportGrid(fillGridId, true);
    }

    prepareFilters(fillGridList) {
        const phaseList = new Map();
        const tableList = new Map();
        const relationList = new Map();

        if (!fillGridList.length) {
            return;
        }

        fillGridList[0].phases.forEach(({ phaseName }) => {
            phaseList.set(phaseName, phaseName);
        });

        fillGridList.forEach(({ relation, tableName }) => {
            tableList.set(tableName, tableName);
            if (relation) {
                relationList.set(relation, relation);
            }
        });

        this.filterValues.phaseList = Array.from(phaseList, FillGridViewController.mapToObject);
        this.filterValues.tableList = Array.from(tableList, FillGridViewController.mapToObject);
        this.filterValues.relationList = Array.from(relationList, FillGridViewController.mapToObject);
    }

    getDataModelName(value) {
        if (value) {
            const dataModel = this.dataModels.find((dataModel) => dataModel.id === value || dataModel.id === value.id);

            if (dataModel) {
                return dataModel.name;
            }
        }

        return '-';
    }

    isRemovable() {
        return (this.isAllowed(['superAdmin']) || !this.fillGrid.isTemplate) && this.fillGrid.countDeposits === 0;
    }

    removeTooltip() {
        if (this.fillGrid.isTemplate && !this.isAllowed(['superAdmin'])) {
            return this._translate('shared.templateGrid');
        } else if (this.fillGrid.countDeposits > 0) {
            return this._translate('shared.attachedGrid', { COUNT: 1 });
        } else {
            return this._translate('shared.deleteGrid');
        }
    }

    async removeFillGrid() {
        if (!this.isRemovable()) {
            return;
        }

        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.fillGrid', {
                COUNT: 1,
                NAME: this.fillGrid.fullname,
            }).toLowerCase(),
        );
        if (!isAccepted) {
            return;
        }

        try {
            await this._fillGridProvider.delete(this.fillGrid.id);
            this._$toasterService.info(
                this._translate('removeModal.successGrid', {
                    COUNT: 1,
                }),
            );

            if (this.isAllowed(['superAdmin'])) {
                return await this._$state.go('app.gridFillList');
            }

            await this._$state.go('app.gridFillAdminList');
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    viewRelationDataModelList(fillGridDataModelRelationName) {
        const getDataModelValue = async () =>
            await this._dataModelProvider
                .getDataModelValue(this.fillGrid.dataModel.id, fillGridDataModelRelationName)
                .then((dataModelValues) => {
                    return dataModelValues;
                })
                .catch(this._$toasterService.error);

        this._$uibModal.open({
            component: 'viewModalList',
            resolve: { dataModelValues: getDataModelValue },
            size: 'lg',
        });
    }

    compareFillGrid(fillGrid) {
        this._$state.go('app.comparisonGridPage', {
            fillGrid: fillGrid.id,
        });
    }

    static mapToObject([key, value]) {
        return { key, value };
    }

    getPhasesByStatus(list) {
        const result = {};
        list.forEach((data) => {
            const { phases, required } = data;

            phases.forEach((phase) => {
                if (phase.isEnabled) {
                    this.status[required] = true;

                    if (!result[phase.phaseName]) {
                        result[phase.phaseName] = {};
                    }

                    result[phase.phaseName][required] = (result[phase.phaseName][required] || 0) + 1;
                }
            });
        });

        return result;
    }

    getStateParams() {
        const { search, tableType, table, relation, severity, phase, phaseActivated } = this.filter;

        return {
            gridFillId: this.fillGrid.id,
            search,
            tableType,
            table: table.join(','),
            relation: relation.join(','),
            severity: severity.join(','),
            phase: phase.join(','),
            phaseActivated,
        };
    }

    cancelFilters() {
        this.setDefaultFilter({}, false);
    }

    removeFilter(filterName, initialValue) {
        this.filter[filterName] = initialValue;
        this._$state.go('.', this.filter);
    }

    removeSearch() {
        this.filter.search = '';
        this._$state.go('.', this.filter);
    }
}

angular.module('dotic').component('fillGridViewPage', {
    controller: FillGridViewController,
    templateUrl: template,
});
