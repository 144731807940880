const dateFormat = 'DD/MM/YYYY';

function gridControlService($filter, $toasterService, gridControlProvider, $phaseService, $segmentsService) {
    const segmentsList = $segmentsService.getAll();

    const phaseList = $phaseService.getAll();

    return {
        duplicate: duplicate,
        excelToJson: excelToJson,
        filterByPhasesAndSegments: filterByPhasesAndSegments,
        filterSearch: filterSearch,
        getGenericControlPoints: getGenericControlPoints,
        jsonToExcel: jsonToExcel,
    };

    function filterByPhasesAndSegments(isActivated) {
        if (isActivated === 'true') {
            return (control) =>
                control.phases.some((phase) => phase.isEnabled) &&
                control.networkSegments.some((networkSegment) => networkSegment.isEnabled);
        }

        if (isActivated === 'false') {
            return (control) =>
                control.phases.every((phase) => !phase.isEnabled) &&
                control.networkSegments.every((networkSegment) => !networkSegment.isEnabled);
        }

        return () => true;
    }

    function filterSearch(search) {
        return (control) =>
            search === '' ||
            (control.description || '').toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            (control.controlPoint || '').toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            (control.idClient || '').toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            (control.shortDescription || '').toLowerCase().indexOf(search.toLowerCase()) > -1;
    }

    function getGenericControlPoints() {
        return ['001', '002', '003', '005', '006', '007', '008', '145', '486', '265'];
    }

    function getVariable(variable1Type, variable, defaultVariable) {
        const newObject = {
            variable: null,
            variables: [],
            defaultVariable: null,
        };

        let value = variable;
        if (angular.isNumber(value)) {
            value = value.toString();
        }

        if (value) {
            switch (variable1Type) {
                case 'select':
                    newObject.variables = value.split('|').map((s) => s.trim());
                    newObject.defaultVariable = defaultVariable;
                    break;
                case 'input':
                case 'templates':
                case 'NC':
                    newObject.variable = value;
                    newObject.defaultVariable = defaultVariable;
                    break;
            }
        }

        return newObject;
    }

    function excelToJson(objArray) {
        const thematics = new Set();
        const newItems = objArray.map((item, index) => {
            const newObject = {};

            ['THEMATIQUE', 'ID_CC', 'DESCRIPTION_COURTE', 'CRITICITE', 'TYPE_VARIABLE1'].forEach((field) => {
                // check if empty and not = 0
                if (!item[field] && !angular.isNumber(item[field])) {
                    throw new Error(`Le champ ${field} ne doit pas être vide. Ligne: ${index + 2}`);
                }
            });

            const CRITICITE = item.CRITICITE.trim().toString();
            const severities = ['minor', 'major', 'blocking'];
            if (severities.indexOf(CRITICITE) === -1) {
                throw new Error(`Le champ CRITICITE doit être l'un de : ${severities.join(', ')}. Ligne: ${index + 2}`);
            }

            const TYPE_VARIABLE1 = item.TYPE_VARIABLE1.trim().toString();
            const types = ['select', 'input', 'templates', 'NC'];

            if (types.indexOf(TYPE_VARIABLE1) === -1) {
                throw new Error(`Le champ TYPE_VARIABLE1 doit être l'un de : ${types.join(', ')}. Ligne: ${index + 2}`);
            }

            let TYPE_VARIABLE2 = null;
            if (item.VARIABLE2 || angular.isNumber(item.VARIABLE2)) {
                TYPE_VARIABLE2 = item.TYPE_VARIABLE2.trim().toString();
                if (types.indexOf(TYPE_VARIABLE2) === -1) {
                    throw new Error(
                        `Le champ TYPE_VARIABLE2 doit être l'un de : ${types.join(', ')}. Ligne: ${index + 2}`,
                    );
                }
            }

            let OFFER;
            if (item.OFFRE) {
                OFFER = {
                    standard: 'basic',
                    basic: 'basic',
                    advanced: 'advanced',
                }[item.OFFRE];
            }

            if (!OFFER) {
                OFFER = 'basic';
            }

            newObject.thematicName = item.THEMATIQUE.trim()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase();

            newObject.phases = [];
            newObject.controlPoint = item.ID_CC.toString().padStart(3, '0');
            newObject.severity = item.CRITICITE;
            newObject.offer = OFFER;
            newObject.shortDescription = item.DESCRIPTION_COURTE;
            newObject.description = item.DESCRIPTION ? item.DESCRIPTION : 'Pas de description';
            newObject.object = item.OBJET;
            newObject.lastModificationDate = item.LAST_MODIFICATION_DATE_converted
                ? item.LAST_MODIFICATION_DATE_converted.toDate()
                : new Date('01/01/2022');
            newObject.usedAttributes = item.ATTRIBUT ? item.ATTRIBUT.split('|').map((str) => str.trim()) : [];
            newObject.tablesSQL = item.TABLE ? item.TABLE.split('|').map((str) => str.trim()) : [];

            newObject.controlRequest = item.REQUETE_CONTROLE;
            newObject.idClient = item.ID_CLIENT;

            // Variable 1
            newObject.variable1Type = TYPE_VARIABLE1;
            newObject.variableUnit = item.UNITE_VARIABLE1;
            const { variable, defaultVariable, variables } = getVariable(
                TYPE_VARIABLE1,
                item.VARIABLE1,
                item.VARIABLE1_DEFAUT,
            );
            newObject.variable = variable;
            newObject.variables = variables;
            newObject.defaultVariable = defaultVariable;

            // Variable 2
            newObject.variable2Type = TYPE_VARIABLE2;
            newObject.variable2Unit = item.UNITE_VARIABLE2;
            const {
                variable: variable2,
                defaultVariable: defaultVariable2,
                variables: variables2,
            } = getVariable(TYPE_VARIABLE2, item.VARIABLE2, item.VARIABLE2_DEFAUT);
            newObject.variable2 = variable2;
            newObject.variables2 = variables2;
            newObject.defaultVariable2 = defaultVariable2;

            thematics.add(newObject.thematicName);

            newObject.phases = Object.getOwnPropertyNames(item)
                .filter((propertyName) => propertyName.startsWith('P_'))
                .map((propertyName) => {
                    const string = propertyName.split('_');
                    const phaseName = string[1].trim();

                    return {
                        phaseName: phaseName,
                        isEnabled: item[propertyName] === 1,
                    };
                });

            // same thing for network segments
            newObject.networkSegments = Object.getOwnPropertyNames(item)
                .filter((propertyName) => propertyName.startsWith('S_'))
                .map((propertyName) => {
                    const string = propertyName.split('_');
                    const segmentName = string[1].trim();

                    return {
                        segmentName: segmentName,
                        isEnabled: item[propertyName] === 1,
                    };
                });

            return newObject;
        });

        return { controls: newItems, thematicList: Array.from(thematics) };
    }

    function jsonToExcel(controlList, isArchive = false) {
        const getValues = (values) => (angular.isArray(values) ? values.join('|') : undefined);
        const getValue = (value) => (value === null ? undefined : value);

        return controlList.map((control) => {
            // Keep this order !
            const data = {
                THEMATIQUE: control.thematicName,
                ID_CC: control.controlPoint,
                OBJET: getValue(control.object),
                DESCRIPTION_COURTE: getValue(control.shortDescription),
                DESCRIPTION: getValue(control.description),
                LAST_MODIFICATION_DATE: getValue(control.lastModificationDate)
                    ? moment(getValue(control.lastModificationDate)).format(dateFormat)
                    : '',
                CRITICITE: control.severity,
                OFFRE: control.offer,
                REQUETE_CONTROLE: getValue(control.controlRequest),
                TABLE: getValues(control.tablesSQL),
                ATTRIBUT: getValues(control.usedAttributes || control.attributes),
            };

            data.TYPE_VARIABLE1 = getValue(control.variable1Type);
            data.VARIABLE1_DEFAUT = getValue(control.defaultVariable);
            data.VARIABLE1 =
                control.variable1Type === 'select' ? getValues(control.variables) : getValue(control.variable);
            data.UNITE_VARIABLE1 = getValue(control.variableUnit);
            data.TYPE_VARIABLE2 = getValue(control.variable2Type);
            data.VARIABLE2_DEFAUT = getValue(control.defaultVariable2);
            data.VARIABLE2 =
                control.variable2Type === 'select' ? getValues(control.variables2) : getValue(control.variable2);
            data.UNITE_VARIABLE2 = getValue(control.variable2Unit);

            // Set backward compatibility for archive before the feature

            if (isArchive && data.LAST_MODIFICATION_DATE === '') {
                if (data.TYPE_VARIABLE1 !== 'NC' && angular.isUndefined(data.VARIABLE1)) {
                    data.TYPE_VARIABLE1 = 'NC';
                    data.VARIABLE1 = 'NC';
                    data.VARIABLE1_DEFAUT = 'NC';
                    data.UNITE_VARIABLE1 = 'NC';
                } else {
                    data.VARIABLE1_DEFAUT = data.VARIABLE1;
                }

                if (data.TYPE_VARIABLE2 !== 'NC' && angular.isUndefined(data.VARIABLE2)) {
                    data.TYPE_VARIABLE2 = 'NC';
                    data.VARIABLE2 = 'NC';
                    data.VARIABLE2_DEFAUT = 'NC';
                    data.UNITE_VARIABLE2 = 'NC';
                } else {
                    data.VARIABLE2_DEFAUT = data.VARIABLE2;
                }
            }

            const phases = [];

            // In control grid archive there is only one phase selected when deposit is run
            if (isArchive) {
                phases.push({
                    isEnabled: true,
                    phaseName: control.phase,
                });
            }

            phaseList.forEach((phase) => {
                const currentPhase = isArchive
                    ? phases.find(({ phaseName }) => phaseName === phase)
                    : control.phases.find(({ phaseName }) => phaseName === phase);

                data[`P_${phase}`] = angular.isDefined(currentPhase) && currentPhase.isEnabled ? 1 : 0;
            });

            segmentsList.forEach((segment) => {
                const currentSegment = control.networkSegments.find(({ segmentName }) => segment === segmentName);
                // check if the segment exist for this control & his value of isEnabled property
                data[`S_${segment}`] = angular.isDefined(currentSegment) && currentSegment.isEnabled ? 1 : 0;
            });

            data.ID_CLIENT = getValue(control.idClient);

            return data;
        });
    }

    async function duplicate(companyId, controlGridId) {
        const controlGrid = await gridControlProvider.get(controlGridId);

        const parent = controlGrid.id;
        const parentTemplate = controlGrid.isTemplate ? parent : controlGrid.parentTemplate;

        const newControlGrid = {
            parent,
            parentTemplate,
            company: [companyId],
            data: controlGrid.data,
            dataModel: controlGrid.dataModel,
            description: controlGrid.description,
            isAdvanced: controlGrid.isAdvanced,
            isClonable: controlGrid.isClonable,
            isEditable: controlGrid.isEditable,
            isTemplate: false,
            name: controlGrid.name,
            phases:
                controlGrid.phases.length > 0 ? controlGrid.phases : ['PRE', 'DIA', 'AVP', 'PRO', 'EXE', 'REC', 'MCO'],
            conditions: controlGrid.conditions.map((condition) => ({
                conditionName: condition.conditionName,
                shortDescription: condition.shortDescription,
                description: condition.description,
                variable: condition.variable,
                enable: condition.enable,
            })),
        };

        const controlGridCreated = await gridControlProvider.create(newControlGrid);

        $toasterService.info('La grille de contrôle a bien été dupliquée');

        return controlGridCreated;
    }
}

angular.module('dotic').factory('$gridControlService', gridControlService);
