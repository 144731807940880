import { ChartService } from '../../../../services/chart.service';
import template from './connectability-indicator.component.html';

class ConnectabilityIndicatorComponent {
    isGraceV3;
    constructor($filter) {
        this._translate = $filter('translate');

        this.data = [];
        this.dataOrigin = [];
        this.dataToCompare = [];
        this.chartConnectability = null;
        this.oldIndicator = false;
        this.compare = false;

        this.connectChartOptions = ChartService.getArchChartDefaultOptions();
    }

    $onInit() {
        if (!this.data) {
            this.oldIndicator = true;

            return;
        }

        if (this.compare) {
            if (!this.data.connectOrigin && !this.data.connectToCompare) {
                this.oldIndicator = true;

                return;
            }

            this.oldIndicator = false;

            this.dataOrigin = this.data.connectOrigin;
            this.dataToCompare = this.data.connectToCompare;

            this.data = {
                nbSufRatio: this.dataOrigin.nbSufRatio - this.dataToCompare.nbSufRatio,
                nbSufConnected: this.dataOrigin.nbSufConnected - this.dataToCompare.nbSufConnected,
                nbSuf: this.dataOrigin.nbSuf - this.dataToCompare.nbSuf,
            };
        }

        this.loadConnectabilityCharts(this.data.nbSufRatio);
    }

    loadConnectabilityCharts(connectability) {
        let backGroundColor1 = '#f0bc00';

        if (connectability < 30) {
            backGroundColor1 = 'rgb(221, 48, 115)';
        }

        if (connectability > 70) {
            backGroundColor1 = '#00a889';
        }

        this.chartConnectability = {
            labels: [this._translate('shared.connectable'), this._translate('shared.noConnectable')],
            datasets: [
                {
                    label: this._translate('shared.anomaly'),
                    data: [Math.abs(connectability), 100 - Math.abs(connectability)],
                    backgroundColor: [backGroundColor1, 'rgba(230, 230, 230, 1)'],
                },
            ],
        };
    }
}

angular.module('dotic').component('connectabilityIndicator', {
    controller: ConnectabilityIndicatorComponent,
    templateUrl: template,
    bindings: {
        data: '<',
        compare: '<',
        isGraceV3: '<',
        maxHeight: '<',
    },
});
