import { pick as radashPick } from 'radash';

import { HelpersService } from '../../../services/helpers.service';
import template from './compareGrid.html';

class CompareGridPage {
    constructor(
        $filter,
        $filterService,
        $location,
        $phaseService,
        $scope,
        $segmentsService,
        $state,
        $tableService,
        $timeout,
        $toasterService,
        gridControlProvider,
        userMetricsProvider,
    ) {
        this._$filterService = $filterService;
        this._$location = $location;
        this._$phasesService = $phaseService;
        this._$scope = $scope;
        this._$segmentsService = $segmentsService;
        this._$state = $state;
        this._$tableService = $tableService;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._humanizeThematic = $filter('humanizeThematic');
        this._translate = $filter('translate');
        this._gridControlProvider = gridControlProvider;

        this.grids = [{}, {}];

        this.segmentsList = this._$segmentsService.getAll();
        this.phaseList = this._$phasesService.getAll();

        this.metaDataByGrids = [];

        this.controlGridList = [];
        this.compareList = [];
        this.filteredCompareGridList = [];

        this.sumDifference = 0;
        this.sumIdentical = 0;
        this.sumUnique = 0;

        // Filters
        this.isBottomFilter = true;
        this.filterOptions = [
            {
                allowed: true,
                name: 'idInterneList',
                selected: 'idInterne',
                placeholder: 'filter.all.internalId',
                hasSearch: true,
                translateKeyLabel: 'shared.internalId',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'diffList',
                value: 'diff',
                placeholder: 'filter.all.comparison',
                translateKeyLabel: 'shared.comparison',
            },
            {
                allowed: true,
                name: 'objectList',
                selected: 'object',
                placeholder: 'filter.all.object',
                hasSearch: true,
                translateKeyLabel: 'shared.object',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'thematicList',
                selected: 'thematic',
                placeholder: 'filter.all.thematic',
                hasSearch: true,
                translateKeyLabel: 'shared.thematic',
                type: 'multi-select',
            },
        ];

        this.filterValues = {
            idInterneList: [],
            thematicList: [],
            objectList: [],
            diffList: [
                {
                    key: 'diff',
                    value: this._translate('shared.different', { COUNT: 1 }),
                },
                {
                    key: 'unique1',
                    value: this._translate('shared.missingGrid2'),
                },
                {
                    key: 'unique2',
                    value: this._translate('shared.missingGrid1'),
                },
                {
                    key: 'equal',
                    value: this._translate('shared.same', { COUNT: 1 }),
                },
            ],
        };

        this.initFilterIntoUrl($state.params);

        $scope.$watchGroup(
            [
                '$ctrl.filter.search',
                '$ctrl.filter.idInterne',
                '$ctrl.filter.thematic',
                '$ctrl.filter.object',
                '$ctrl.filter.diff',
                '$ctrl.compareList',
            ],
            () => {
                this.setScopeGridControlCompareWithFilters();
            },
        );

        $scope.$on('$locationChangeSuccess', () => this.initFilterIntoUrl($state.params));

        userMetricsProvider.compareControlGrids(this._$state.params.grid1, this._$state.params.grid2);
        this.loading = true;
    }

    initFilterIntoUrl(params = {}, includeSearch = true) {
        if (!includeSearch && this.filter) {
            params.search = this.filter.search;
        }

        this.filter = {
            search: params.search || '',
            idInterne: this._$filterService.getFilterValue(params.idInterne),
            thematic: this._$filterService.getFilterValue(params.thematic),
            object: this._$filterService.getFilterValue(params.object),
            diff: params.diff || '',
        };
    }

    async $onInit() {
        this._$scope.$emit('keepPreviousNavigation', {
            newPage: [
                {
                    key: 'comparaison',
                    title: `Comparaison des grilles`,
                    href: this._$location.path(),
                },
            ],
            defaultPrevious: {
                title: this._translate('shared.controlGrid'),
                href: this._$state.href('app.gridControlAdminList'),
                key: 'deposits',
            },
            allowedPreviousKeys: ['controlGrid'],
        });

        this.hasDiffOpen = true;

        await Promise.all([
            this._gridControlProvider.getGridsControlToCompare(this._$state.params.grid1, this._$state.params.grid2),
        ])
            .then((grids) => {
                this.grids = grids[0];

                this.grids[0].companyName = this.grids[0].company[0].name;

                if (this.grids[1].company.length > 1) {
                    this.grids[1].companyName = this.grids[1].company.find(
                        ({ _id }) => _id === this._$state.params.company,
                    ).name;
                } else {
                    this.grids[1].companyName = this.grids[1].company[0].name;
                }

                this.metaDataByGrids = this.grids.map((grid) => {
                    return {
                        ...radashPick(grid, [
                            'id',
                            'companyName',
                            'createdAt',
                            'description',
                            'name',
                            'isAdvanced',
                            'isEditable',
                            'isTemplate',
                            'updatedAt',
                            'version',
                        ]),
                        dataModel: grid.dataModel.map((dataModel) => dataModel.name),
                        createdBy: grid.createdBy?.fullname ?? '',
                    };
                });

                // Optimisation of comparaison for control point "002"
                const findMultiControlPointsGrid1 = this.grids[0].data
                    .filter((data) => data.controlPoint === '002')
                    .map((control) => {
                        return {
                            ...control,
                            controlPoint: `${control.controlPoint}_${control.severity}`,
                        };
                    });

                const findMultiControlPointsGrid2 = this.grids[1].data
                    .filter((data) => data.controlPoint === '002')
                    .map((control) => {
                        return {
                            ...control,
                            controlPoint: `${control.controlPoint}_${control.severity}`,
                        };
                    });

                const filterMultiControlPointGrid1 = [
                    ...this.grids[0].data.filter((data) => data.controlPoint !== '002'),
                    ...findMultiControlPointsGrid1,
                ].sort((a, b) => a.controlPoint - b.controlPoint);

                const filterMultiControlPointGrid2 = [
                    ...this.grids[1].data.filter((data) => data.controlPoint !== '002'),
                    ...findMultiControlPointsGrid2,
                ].sort((a, b) => a.controlPoint - b.controlPoint);

                const gridsData = [filterMultiControlPointGrid1, filterMultiControlPointGrid2];

                const [grid1DataToCompare, grid2DataToCompare] = gridsData.map((group) => {
                    return group.map((data) => ({
                        controlPoint: data.controlPoint,
                        defaultVariable2: data.defaultVariable2,
                        defaultVariable: data.defaultVariable,
                        description: data.description,
                        idClient: data.idClient,
                        lastModificationDate: data.lastModificationDate,
                        object: data.object,
                        offer: data.offer,
                        phases: data.phases.map((phase) => {
                            return {
                                isEnabled: phase.isEnabled,
                                phaseName: phase.phaseName,
                            };
                        }),
                        networkSegments: data.networkSegments,
                        severity: data.severity,
                        shortDescription: data.shortDescription,
                        tablesSQL: data.tablesSQL.join(' , '),
                        thematicName: data.thematicName,
                        usedAttributes: data.usedAttributes.join(' , '),
                        variable1Type: data.variable1Type,
                        variable2: data.variable2,
                        variable2Type: data.variable2Type,
                        variable2Unit: data.variable2Unit,
                        variable: data.variable,
                        variableUnit: data.variableUnit,
                        variables2: data.variables2.join(' , '),
                        variables: data.variables.join(' , '),
                    }));
                });

                // key element to compare to count Admin diff
                const keyToCompare = ['defaultVariable', 'defaultVariable2', 'idClient', 'variable', 'variable2'];

                // key element to compare to count diff in Reference grid
                const KeyNotRelatedAdmin = [
                    'object',
                    'thematicName',
                    'tablesSQL',
                    'shortDescription',
                    'lastModificationDate',
                    'description',
                    'model',
                    'usedAttributes',
                    'variable1Type',
                    'variable2Type',
                    'variable2Unit',
                    'variableUnit',
                ];

                const grid1DataToCompareWithCountDiffs = grid1DataToCompare
                    .filter((elementFiltered) => {
                        elementFiltered.phases = elementFiltered.phases.filter((phase) => phase.isEnabled);

                        elementFiltered.networkSegments = elementFiltered.networkSegments
                            .filter((segment) => segment.isEnabled)
                            .map(({ segmentName, isEnabled }) => ({
                                segmentName,
                                isEnabled,
                            }));

                        return grid2DataToCompare.some((elementCompared) => {
                            elementCompared.phases = elementCompared.phases.filter((phase) => phase.isEnabled);
                            elementCompared.networkSegments = elementCompared.networkSegments
                                .filter((segment) => segment.isEnabled)
                                .map(({ segmentName, isEnabled }) => ({
                                    segmentName,
                                    isEnabled,
                                }));

                            return (
                                elementFiltered.controlPoint === elementCompared.controlPoint &&
                                !angular.equals(elementFiltered, elementCompared)
                            );
                        });
                    })
                    .map((el) => {
                        const element2 = grid2DataToCompare.find((el2) => el2.controlPoint === el.controlPoint);

                        const nbDiffNotAdmin = this.countDataElementDifferenceAdmin(KeyNotRelatedAdmin, el, element2);
                        const globalCount = this.countDataElementDifferenceAdmin(keyToCompare, el, element2);
                        const nbPhaseDiff = this.countPhaseDifferenceAdmin(el, element2);
                        const nbSegmentDiff = this.countSegmentDifferenceAdmin(el, element2);
                        const nbSeverityDiff = this.countSeverityDifferenceAdmin(el, element2);
                        const nbListeVariables = this.countDataElementDifferenceAdmin(['variables'], el, element2);
                        const nbListeVariables2 = this.countDataElementDifferenceAdmin(['variables2'], el, element2);

                        const nbDiff =
                            nbSegmentDiff +
                            nbPhaseDiff +
                            globalCount +
                            nbSeverityDiff +
                            nbListeVariables +
                            nbListeVariables2;

                        return {
                            ...el,
                            el2: element2,
                            isDiff: !!(nbDiff + nbDiffNotAdmin),
                            nbDiff,
                            nbPhaseDiff,
                            nbSegmentDiff,
                            nbSeverityDiff,
                            nbListeVariables,
                            nbListeVariables2,
                            nbDiffNotAdmin,
                        };
                    });

                const diffGrid1 = grid1DataToCompareWithCountDiffs.filter((el) => el.isDiff);

                const equals = grid1DataToCompare
                    .filter((elementFiltered) => {
                        return grid2DataToCompare.some((elementCompared) =>
                            angular.equals(
                                {
                                    ...elementFiltered,
                                    description: elementFiltered.description.replace(/\s+/g, ''),
                                },
                                {
                                    ...elementCompared,
                                    description: elementCompared.description.replace(/\s+/g, ''),
                                },
                            ),
                        );
                    })
                    .map((el) => {
                        const element2 = grid2DataToCompare.find((el2) => el2.controlPoint === el.controlPoint);

                        return { ...el, el2: element2, isEqual: true };
                    });

                const uniqueGrid1 = grid1DataToCompare
                    .filter((elementFiltered) => {
                        return !grid2DataToCompare.some(
                            (elementCompared) => elementCompared.controlPoint === elementFiltered.controlPoint,
                        );
                    })
                    .map((el) => ({ ...el, isUniqueInGrid1: true }));

                const uniqueGrid2 = grid2DataToCompare
                    .filter((elementFiltered) => {
                        return !grid1DataToCompare.some((elementCompared) => {
                            return elementFiltered.controlPoint === elementCompared.controlPoint;
                        });
                    })
                    .map((el) => {
                        const element2 = { ...el };

                        return {
                            controlPoint: el.controlPoint,
                            thematicName: el.thematicName,
                            object: el.object,
                            shortDescription: el.shortDescription,
                            el2: element2,
                            isUniqueInGrid2: true,
                        };
                    });

                const comparedControlGridList = [...diffGrid1, ...equals, ...uniqueGrid1, ...uniqueGrid2];

                this.sumDifference = diffGrid1.length + uniqueGrid1.length + uniqueGrid2.length;

                this.sumUnique = uniqueGrid1.length + uniqueGrid2.length;

                this.sumIdentical = equals.length;

                this.countEnabledPhases();
                this.countEnabledSegments();
                this.setGridEnabledConditions();
                this.prepareControlGridCompare(comparedControlGridList);

                this.showButtonCancelFilter = HelpersService.isFilterEmpty(this.filter);

                this.tableDetail = this._$tableService.detail();
            })
            .then(() => {
                this._$timeout(() => {
                    this.loading = false;
                });
            })
            .catch((e) => {
                this._$toasterService.error(e);
            });
    }

    compareStrings(selectedString, comparedString) {
        return angular.equals(selectedString?.replace(/\s+/g, ''), comparedString?.replace(/\s+/g, ''));
    }

    countDataElementDifferenceAdmin(keyOfComparison, elementDataCompared, elementDataSelected) {
        let counter = 0;
        keyOfComparison.forEach((key) => {
            if (key in elementDataCompared) {
                if (angular.isString(elementDataSelected[key]) && angular.isString(elementDataCompared[key])) {
                    if (this.compareStrings(elementDataSelected[key], elementDataSelected[key])) {
                        return;
                    }

                    counter++;
                }

                if (!angular.equals(elementDataSelected[key], elementDataCompared[key])) {
                    counter++;
                }
            }
        });

        return counter;
    }

    countPhaseDifferenceAdmin(elementDataCompared, elementDataSelected) {
        let counter = 0;
        if ('phases' in elementDataCompared) {
            if (!HelpersService.arePhasesEqual(elementDataSelected['phases'], elementDataCompared['phases'])) {
                counter++;
            }
        }

        return counter;
    }

    countSegmentDifferenceAdmin(elementDataCompared, elementDataSelected) {
        let counter = 0;
        if (elementDataCompared.networkSegments) {
            if (!angular.equals(elementDataSelected.networkSegments, elementDataCompared.networkSegments)) {
                counter++;
            }
        }

        return counter;
    }

    countSeverityDifferenceAdmin(elementDataCompared, elementDataSelected) {
        let counter = 0;
        if ('severity' in elementDataCompared) {
            if (!angular.equals(elementDataSelected['severity'], elementDataCompared['severity'])) {
                counter++;
            }
        }

        return counter;
    }

    setGridEnabledConditions() {
        this.grids.forEach((grid) => {
            grid.controlPointActivated = grid.data.filter((controlPoint) => {
                return controlPoint && HelpersService.isControlPointEnabled(controlPoint) ? controlPoint : 0;
            });
        });
    }

    getParentName(grid) {
        if (!grid || (grid.isTemplate && !grid.parent)) {
            return this._translate('shared.templateGrid');
        }

        const fullGrid = this.grids.find((initialGrid) => initialGrid.id === grid.id);
        if (fullGrid?.parent?.fullname) {
            return fullGrid.parent.fullname;
        }

        return this._translate('shared.noTemplateParent');
    }

    countEnabledPhases() {
        this.grids.forEach((grid) => {
            grid.phasesEnabledListOf = HelpersService.getPhasesListWithCountEnabled(grid.data);
        });
    }

    countEnabledSegments() {
        this.grids.forEach((grid) => {
            grid.networkSegmentEnabledList = HelpersService.getNetworkSegmentListWithCountEnabled(grid.data);
        });
    }

    isDataPhaseEnabledForGrid1(phase, item) {
        const currentPhase1 = item.phases.find((e) => e.phaseName === phase);

        return currentPhase1 ? currentPhase1.isEnabled : false;
    }

    isDataPhaseEnabledForGrid2(phase, item) {
        const currentPhase2 = item.el2.phases.find((e) => e.phaseName === phase);

        return currentPhase2 ? currentPhase2.isEnabled : false;
    }

    toggleDiffVisibility() {
        this.hasDiffOpen = !this.hasDiffOpen;
    }

    showDiff() {
        this.hasDiffOpen = true;
        this.filter.diff = 'diff';

        const resultFilter = this.filterOptions.find((item) => item.name === 'diffList');
        this._$scope.$broadcast('forceFilterRender', {
            filter: resultFilter,
            saveChanges: false,
            openDropdown: false,
        });

        // $location.hash('id') with $anchorScroll don't work here...
        this._$timeout(() => {
            const element = angular.element('#compareGridControl');
            angular.element('html').animate({ scrollTop: element.offset().top }, 'slow');
        }, 0);
    }

    showSame() {
        this.hasDiffOpen = true;
        this.filter.diff = 'equal';
        const resultFilter = this.filterOptions.find((item) => item.name === 'diffList');
        this._$scope.$broadcast('forceFilterRender', {
            filter: resultFilter,
            saveChanges: false,
            openDropdown: false,
        });

        // $location.hash('id') with $anchorScroll don't work here...
        this._$timeout(() => {
            const element = angular.element('#compareGridControl');
            angular.element('html').animate({ scrollTop: element.offset().top }, 'slow');
        }, 0);
    }

    prepareControlGridCompare(controlGrid) {
        this.compareList = controlGrid;

        const thematicList = new Map();
        const objectList = new Map();
        const idInterneList = new Map();

        this.compareList.forEach(({ thematicName, object, controlPoint }) => {
            thematicList.set(thematicName, this._humanizeThematic(thematicName));
            idInterneList.set(controlPoint, controlPoint);
            if (angular.isString(object)) {
                objectList.set(object, object);
            }
        });

        this.filterValues.thematicList = Array.from(thematicList, CompareGridPage.mapToObject);
        this.filterValues.objectList = Array.from(objectList, CompareGridPage.mapToObject);
        this.filterValues.idInterneList = Array.from(idInterneList, CompareGridPage.mapToObject);
    }

    static mapToObject([key, value]) {
        return {
            key,
            value,
        };
    }

    static filterSearch(search) {
        return (control) =>
            search === '' ||
            (control.controlPoint || '').toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            search === '' ||
            (control.shortDescription || '').toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            (control.idClient || '').toLowerCase().indexOf(search.toLowerCase()) > -1;
    }

    static filterByDiff(key) {
        if (key === 'diff') {
            return (control) => control.isDiff === true;
        } else if (key === 'unique1') {
            return (control) => control.isUniqueInGrid1 === true;
        } else if (key === 'unique2') {
            return (control) => control.isUniqueInGrid2 === true;
        } else if (key === 'equal') {
            return (control) => control.isEqual === true;
        }

        return () => true;
    }

    setScopeGridControlCompareWithFilters() {
        const { idInterne, thematic, object, diff, search } = this.filter;

        this._$state.go('.', {
            search,
            idInterne: idInterne.join(','),
            thematic: thematic.join(','),
            object: object.join(','),
            diff,
        });

        this.filteredCompareGridList = this.compareList
            .filter(CompareGridPage.filterSearch(search))
            .filter(this._$filterService.filterControlPoint(idInterne))
            .filter(this._$filterService.genericMultiFilter(object, 'object'))
            .filter(this._$filterService.genericMultiFilter(thematic, 'thematicName'))
            .filter(CompareGridPage.filterByDiff(diff));
    }

    cancelFilters() {
        this.initFilterIntoUrl({}, false);
    }

    removeFilter(filterName, initialValue) {
        this.filter[filterName] = initialValue;
        this._$state.go('.', this.filter);
    }

    removeSearch() {
        this.filter.search = '';
        this._$state.go('.', this.filter);
    }
}

angular.module('dotic').component('compareGridPage', {
    controller: CompareGridPage,
    templateUrl: template,
});
