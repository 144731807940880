import { ChartService } from '../../../services/chart.service';
import { GeneralBarChartColorEnum } from '../../../services/color.service';
import { NetworkSaturationLevelTypeEnum } from '../../../models/indicator.model';
import template from './network-saturation-level-indicator.html';

const KeyEnum = {
    CABLE_CAPACITY: 'cableCapacity',
    AVERAGE_RESERVE: 'averageReserve',
    AVERAGE_SATURATION: 'averageSaturation',
};

class NetworkSaturationLevelIndicatorComponent {
    chartData = { datasets: [], labels: [] };
    data = [];
    isParentLoading = false;
    type = NetworkSaturationLevelTypeEnum.SATURATION;
    xLabel = '';

    constructor($chartService, $filter) {
        this.barChartOptions = {
            responsive: true,
            scales: $chartService.getDefaultScaleConfiguration(false),
        };
        this._translate = $filter('translate');
    }

    $onInit() {
        if (!Object.values(NetworkSaturationLevelTypeEnum).includes(this.type)) {
            throw new Error('Incorrect type set for NetworkSaturationLevel indicator');
        }

        this.xLabel = this._translate(
            this.type === NetworkSaturationLevelTypeEnum.SATURATION
                ? 'shared.saturationRate'
                : 'shared.theoreticalReserveRate',
        );
        this.chartData = this.getChartData(this.data);
    }

    getChartData(data) {
        const sortedData = data.sort((a, b) => a[KeyEnum.CABLE_CAPACITY] - b[KeyEnum.CABLE_CAPACITY]);
        const key =
            this.type === NetworkSaturationLevelTypeEnum.SATURATION
                ? KeyEnum.AVERAGE_SATURATION
                : KeyEnum.AVERAGE_RESERVE;

        return {
            datasets: [
                ChartService.getTableChartDataset(
                    sortedData.map((value) => value[key]),
                    this.xLabel,
                    GeneralBarChartColorEnum.SATURATION_RATE,
                ),
            ],
            labels: sortedData.map((currentData) => currentData[KeyEnum.CABLE_CAPACITY] || '-'),
        };
    }
}

angular.module('dotic').component('networkSaturationLevelIndicator', {
    controller: NetworkSaturationLevelIndicatorComponent,
    templateUrl: template,
    bindings: {
        data: '<',
        isParentLoading: '<',
        type: '@?',
    },
});
