import alasql from 'alasql';
import { pick as radashPick } from 'radash';

class ExcelService {
    constructor($filter, $toasterService) {
        this._translate = $filter('translate');
        this._$toasterService = $toasterService;
    }

    async downloadXLSX(sheetOptions, tables, fileName, translateKey) {
        const sql = `SELECT * INTO XLSX("${fileName}.xlsx", ?) FROM ?`;
        try {
            await alasql.promise(sql, [sheetOptions, tables]);

            if (translateKey) {
                this._$toasterService.success(this._translate(translateKey));
            }
        } catch {
            this._$toasterService.error({ body: this._translate('toaster.downloadError') });
        }
    }

    async importXLSX(src, overrideSQL = null) {
        const sql = 'SELECT * FROM XLSX(?, {autoExt: false})';
        try {
            return await alasql.promise(overrideSQL ?? sql, [src]);
        } catch {
            this._$toasterService.error({
                body: this._translate('shared.errorUploadFile'),
            });

            return [];
        }
    }

    exportReportXLSX(
        tables,
        indicators,
        companySettings,
        fileName,
        translateKeySuccess,
        options = { hasNbRecordsPerTable: true },
    ) {
        const sheetOptions = this.getCommonSheetsName();
        const sheetTables = [...tables, ...Object.values(radashPick(indicators, ['controlPoints', 'nbControls']))];

        const hasDefaultIndicatorsEnabled = companySettings.feature.hasIndicatorsDefault;
        if (hasDefaultIndicatorsEnabled) {
            sheetOptions.push(...this.getDefaultIndicatorsSheetsName(options));
            sheetTables.push(
                ...sheetOptions
                    .filter(
                        (sheetOption) =>
                            !['anomalyReport', 'controlPoints', 'metadata', 'nbControls'].includes(sheetOption.key),
                    )
                    .map((sheetOption) => {
                        return indicators[sheetOption.key] || [];
                    }),
            );
        }

        return this.downloadXLSX(
            sheetOptions.map((opt) => {
                return { ...opt, sheetid: opt.sheetid.substring(0, 31) };
            }),
            sheetTables,
            fileName,
            translateKeySuccess,
        );
    }

    getCommonSheetsName() {
        return [
            { sheetid: this._translate('shared.metadata'), header: true, key: 'metadata' },
            { sheetid: this._translate('shared.anomalyReport'), header: true, key: 'anomalyReport' },
            {
                sheetid: this._translate('shared.controlPoint'),
                header: true,
                key: 'controlPoints',
            },
            {
                sheetid: this._translate('shared.nbControl'),
                header: true,
                key: 'nbControls',
            },
        ];
    }

    getDefaultIndicatorsSheetsName(options = { hasNbRecordsPerTable: true }) {
        return [
            ...(options.hasNbRecordsPerTable
                ? [{ sheetid: this._translate('shared.nbRecordPerTable'), header: true, key: 'nbRecordsPerTable' }]
                : []),
            { sheetid: this._translate('shared.nbLinePBO'), header: true, key: 'avgpbo' },
            {
                sheetid: this._translate('shared.connectionRate'),
                header: true,
                key: 'connectivity',
            },
            {
                sheetid: this._translate('shared.nbDwellingsConnected'),
                header: true,
                key: 'nbHousing',
            },
            {
                sheetid: this._translate('shared.nbPotentialOutlets'),
                header: true,
                key: 'nbPlugs',
            },
            {
                sheetid: this._translate('shared.buildingTypes'),
                header: true,
                key: 'housingType',
            },
            {
                sheetid: this._translate('shared.nbBox'),
                header: true,
                key: 'nbCases',
            },
            {
                sheetid: this._translate('shared.junctionBox'),
                header: true,
                key: 'junctionBox',
            },
            {
                sheetid: this._translate('shared.typeNROSRO'),
                header: true,
                key: 'nroSro',
            },
            {
                sheetid: this._translate('shared.nbBTI'),
                header: true,
                key: 'nbBti',
            },
            {
                sheetid: this._translate('shared.associatedCablesBti'),
                header: true,
                key: 'cableBti',
            },
            {
                sheetid: this._translate('shared.technicalPoints'),
                header: true,
                key: 'technicalPoint',
            },
            {
                sheetid: this._translate('shared.pathways'),
                header: true,
                key: 'pathways',
            },
            {
                sheetid: this._translate('shared.opticalCableLength'),
                header: true,
                key: 'lgCablesDiff',
            },
            {
                sheetid: this._translate('shared.opticalCable'),
                header: true,
                key: 'diffCables',
            },
            {
                sheetid: `${this._translate('shared.distance')} PBO - NRO`,
                header: true,
                key: 'distanceBetweenNroPbo',
            },
            {
                sheetid: `${this._translate('shared.distance')} SRO - NRO`,
                header: true,
                key: 'distanceBetweenNroSro',
            },
            {
                sheetid: `${this._translate('shared.distance')} PBO - SRO`,
                header: true,
                key: 'distanceBetweenSroPbo',
            },
        ];
    }
}

angular
    .module('dotic')
    .factory('$excelService', ($filter, $toasterService) => new ExcelService($filter, $toasterService));
