import { ColorService } from '../../../services/color.service';
import { IndicatorService } from '../../../services/indicator.service';
import template from './nro-data-indicator.html';

const KeyEnum = {
    DISTANCE_CLASS: 'distanceClass',
    DISTANCE_KM: 'distanceKm',
    NODE_NRO_CODE: 'nodeNroCode',
    NRA_OPERATOR: 'nraOperator',
    NRO_CODE: 'nroCode',
    REFERENCE_NRO: 'referenceNRO',
};

class NroDataIndicatorComponent {
    chartSize = 180;
    columns = [];
    data = [];
    doughnutChartData = { bigLegend: {}, datasets: [], labels: [] };
    isParentLoading = false;
    parentRef = {};

    constructor($filter) {
        this._translate = $filter('translate');
    }

    $onInit() {
        this.data = IndicatorService.getFormattedKilometerData(this.data, [KeyEnum.DISTANCE_KM]);
        this.doughnutChartData = this.getDoughnutChartData(this.data);
        this.columns = this.getColumns();
    }

    getColumns() {
        return [
            { key: KeyEnum.NODE_NRO_CODE, translation: this._translate('shared.nodeNroCode') },
            { key: KeyEnum.REFERENCE_NRO, translation: this._translate('shared.referenceNro') },
            { key: KeyEnum.NRO_CODE, translation: this._translate('shared.nroCode') },
            { key: KeyEnum.NRA_OPERATOR, translation: this._translate('shared.nraOperator') },
            { key: KeyEnum.DISTANCE_KM, translation: `${this._translate('shared.distance', { COUNT: 1 })} (Km)` },
        ];
    }

    getDoughnutChartData(data) {
        const chartData = data.reduce(
            (acc, range) => {
                if (range[KeyEnum.DISTANCE_KM] <= 0.1) {
                    acc.zeroToOneHundred += 1;
                } else if (range[KeyEnum.DISTANCE_KM] <= 0.5) {
                    acc.oneHundredToFiveHundred += 1;
                } else if (range[KeyEnum.DISTANCE_KM] <= 1) {
                    acc.fiveHundredToThousand += 1;
                } else {
                    acc.overOneThousand += 1;
                }

                return acc;
            },
            {
                zeroToOneHundred: 0,
                oneHundredToFiveHundred: 0,
                fiveHundredToThousand: 0,
                overOneThousand: 0,
            },
        );

        return {
            bigLegend: {
                figure: data.length,
                name: 'NRO',
            },
            datasets: [
                {
                    data: [
                        chartData.zeroToOneHundred,
                        chartData.oneHundredToFiveHundred,
                        chartData.fiveHundredToThousand,
                        chartData.overOneThousand,
                    ],
                    backgroundColor: ColorService.genericDistanceColorOrdered,
                },
            ],
            labels: [
                `${this._translate('shared.lessThan', { VALUE: 0.1 })} Km`,
                `${this._translate('shared.betweenTwoValues', { SECOND_VALUE: 0.5, FIRST_VALUE: 0.1 })} Km`,
                `${this._translate('shared.betweenTwoValues', { SECOND_VALUE: 1, FIRST_VALUE: 0.5 })} Km`,
                `${this._translate('shared.greaterThan', { VALUE: 1 })} Km`,
            ],
        };
    }
}

angular.module('dotic').component('nroDataIndicator', {
    controller: NroDataIndicatorComponent,
    templateUrl: template,
    bindings: {
        data: '<',
        isParentLoading: '<',
        parentRef: '<',
    },
});
