import { unique as radashUnique } from 'radash';

import { ColorService, GeneralIconColorEnum } from '../../../../services/color.service';
import { IndicatorService } from '../../../../services/indicator.service';
import template from './cable-length-by-implantation.component.html';

const KeyEnum = {
    CABLE_CAPACITY: 'cableCapacity',
    CABLE_LENGTH: 'cableLength',
    CABLE_LOGICAL_TYPE: 'cableLogicalType',
    IMPLANTATION: 'implantation',
};

class CableLengthByImplantationComponent {
    capacityChartData = { datasets: [], labels: [] };
    columns = [];
    data = [];
    formattedData = [];
    iconOptions = [];
    lengthChartData = { datasets: [], labels: [] };
    parentRef = {};
    tableTitle = '';

    constructor($filter) {
        this._translate = $filter('translate');
    }

    $onInit() {
        this.formattedData = IndicatorService.getFormattedKilometerData(this.data, [KeyEnum.CABLE_LENGTH]);
        this.iconOptions = this.getIconOptions(this.formattedData);

        const capacityFormattedData = IndicatorService.getTableChartFormattedData(
            this.formattedData,
            KeyEnum.CABLE_LOGICAL_TYPE,
            KeyEnum.IMPLANTATION,
            KeyEnum.CABLE_CAPACITY,
        );
        this.capacityChartData = IndicatorService.getTableChartData(capacityFormattedData, (logicalType) =>
            ColorService.getCableLogicalTypeColor(logicalType),
        );

        const lengthFormattedData = IndicatorService.getTableChartFormattedData(
            this.formattedData,
            KeyEnum.CABLE_LOGICAL_TYPE,
            KeyEnum.IMPLANTATION,
            KeyEnum.CABLE_LENGTH,
        );
        this.lengthChartData = IndicatorService.getTableChartData(lengthFormattedData, (logicalType) =>
            ColorService.getCableLogicalTypeColor(logicalType),
        );

        this.columns = this.getColumns();
        this.tableTitle = this._translate('reportCompare.detailLengthCable');
    }

    getColumns() {
        return [
            {
                key: KeyEnum.IMPLANTATION,
                translation: this._translate('shared.implantationType'),
            },
            {
                key: KeyEnum.CABLE_LOGICAL_TYPE,
                translation: this._translate('shared.logicalType'),
            },
            {
                key: KeyEnum.CABLE_CAPACITY,
                translation: this._translate('shared.cableCapacity'),
            },
            {
                key: KeyEnum.CABLE_LENGTH,
                translation: `${this._translate('shared.length', { COUNT: 1 })} (km)`,
            },
        ];
    }

    getIconOptions(data) {
        const implantations = radashUnique(data.map((currentData) => currentData[KeyEnum.IMPLANTATION]));
        const logicalTypes = radashUnique(data.map((currentData) => currentData[KeyEnum.CABLE_LOGICAL_TYPE]));

        return [
            {
                color: GeneralIconColorEnum.IMPLANTATION_TYPE,
                icon: 'cement-mix',
                label: this._translate('shared.implantationType'),
                value: implantations.length,
            },
            {
                color: GeneralIconColorEnum.LOGICAL_TYPE,
                icon: 'architecture-alt',
                label: this._translate('shared.logicalType'),
                value: logicalTypes.length,
            },
        ];
    }
}

angular.module('dotic').component('cableLengthByImplantationIndicator', {
    bindings: {
        data: '<',
        parentRef: '<',
    },
    controller: CableLengthByImplantationComponent,
    templateUrl: template,
});
