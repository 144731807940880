/**
 * ConnectControl API Documentation (v1)
 * The official ConnectControl Open API documentation (v1)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";

/**
* Synoptic service.
* @module api/SynopticApi
* @version 1.0.0
*/
export default class SynopticApi {

    /**
    * Constructs a new SynopticApi. 
    * @alias module:api/SynopticApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Download node excel plan
     * @param {Object.<String, Object>} body body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Blob} and HTTP response
     */
    downloadPlanExcelWithHttpInfo(body) {
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling downloadPlanExcel");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
      let returnType = 'Blob';
      return this.apiClient.callApi(
        '/api/v1/synoptic/download/node', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Download node excel plan
     * @param {Object.<String, Object>} body body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Blob}
     */
    downloadPlanExcel(body) {
      return this.downloadPlanExcelWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Download zip containing all plans
     * @param {String} zipId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Blob} and HTTP response
     */
    downloadPlansExportWithHttpInfo(zipId) {
      let postBody = null;
      // verify the required parameter 'zipId' is set
      if (zipId === undefined || zipId === null) {
        throw new Error("Missing the required parameter 'zipId' when calling downloadPlansExport");
      }

      let pathParams = {
        'zipId': zipId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = ['application/zip'];
      let returnType = 'Blob';
      return this.apiClient.callApi(
        '/api/v1/synoptic/download/{zipId}/download/zip', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Download zip containing all plans
     * @param {String} zipId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Blob}
     */
    downloadPlansExport(zipId) {
      return this.downloadPlansExportWithHttpInfo(zipId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Generate zip containing all excel format plans
     * @param {Object.<String, Object>} body body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    generatePlansExportWithHttpInfo(body) {
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling generatePlansExport");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/synoptic/deposit/{depositId}/generate/zip', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Generate zip containing all excel format plans
     * @param {Object.<String, Object>} body body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    generatePlansExport(body) {
      return this.generatePlansExportWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get synoptic url
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    getWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/synoptic', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get synoptic url
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    get() {
      return this.getWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get data from deposit
     * @param {String} depositId 
     * @param {Object} opts Optional parameters
     * @param {String} [controlId] 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    getFromDepositWithHttpInfo(depositId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'depositId' is set
      if (depositId === undefined || depositId === null) {
        throw new Error("Missing the required parameter 'depositId' when calling getFromDeposit");
      }

      let pathParams = {
        'depositId': depositId
      };
      let queryParams = {
        'controlId': opts['controlId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/synoptic/deposit/{depositId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get data from deposit
     * @param {String} depositId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.controlId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    getFromDeposit(depositId, opts) {
      return this.getFromDepositWithHttpInfo(depositId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get PTO (from deposit)
     * @param {String} depositId 
     * @param {String} params 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    getPTOWithHttpInfo(depositId, params) {
      let postBody = null;
      // verify the required parameter 'depositId' is set
      if (depositId === undefined || depositId === null) {
        throw new Error("Missing the required parameter 'depositId' when calling getPTO");
      }
      // verify the required parameter 'params' is set
      if (params === undefined || params === null) {
        throw new Error("Missing the required parameter 'params' when calling getPTO");
      }

      let pathParams = {
        'depositId': depositId
      };
      let queryParams = {
        'params': params
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/synoptic/deposit/{depositId}/PTO', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get PTO (from deposit)
     * @param {String} depositId 
     * @param {String} params 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    getPTO(depositId, params) {
      return this.getPTOWithHttpInfo(depositId, params)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get plan (from deposit)
     * @param {String} depositId 
     * @param {String} type 
     * @param {String} params 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    getPlanWithHttpInfo(depositId, type, params) {
      let postBody = null;
      // verify the required parameter 'depositId' is set
      if (depositId === undefined || depositId === null) {
        throw new Error("Missing the required parameter 'depositId' when calling getPlan");
      }
      // verify the required parameter 'type' is set
      if (type === undefined || type === null) {
        throw new Error("Missing the required parameter 'type' when calling getPlan");
      }
      // verify the required parameter 'params' is set
      if (params === undefined || params === null) {
        throw new Error("Missing the required parameter 'params' when calling getPlan");
      }

      let pathParams = {
        'depositId': depositId,
        'type': type
      };
      let queryParams = {
        'params': params
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/synoptic/deposit/{depositId}/{type}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get plan (from deposit)
     * @param {String} depositId 
     * @param {String} type 
     * @param {String} params 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    getPlan(depositId, type, params) {
      return this.getPlanWithHttpInfo(depositId, type, params)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Search data from deposit
     * @param {String} depositId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    postSearchWithHttpInfo(depositId) {
      let postBody = null;
      // verify the required parameter 'depositId' is set
      if (depositId === undefined || depositId === null) {
        throw new Error("Missing the required parameter 'depositId' when calling postSearch");
      }

      let pathParams = {
        'depositId': depositId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/synoptic/deposit/{depositId}/search', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Search data from deposit
     * @param {String} depositId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    postSearch(depositId) {
      return this.postSearchWithHttpInfo(depositId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
