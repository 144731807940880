import { group as radashGroup, max as radashMax, sum as radashSum } from 'radash';

import { FieldEnum } from '../models/grace.model';

// This field doesn't come from backend
export const FieldOccurrenceAdditionalFieldEnum = {
    NON_SPECIFIC: 'nonSpecific',
    ADDRESS_COUNT: 'addressCount',
};

export const FieldOccurrenceKeyEnum = {
    FIELD: 'field',
    OCCURRENCE_COUNT: 'occurrenceCount',
    VALUE: 'value',
};

export class IndicatorFieldOccurrenceService {
    constructor($filter) {
        this._translate = $filter('translate');
    }

    getDifferenceReformattedData(differenceFormattedData) {
        return differenceFormattedData.map((currentData) => {
            return {
                ...currentData,
                [FieldOccurrenceKeyEnum.FIELD]: this.getDisplayedLabel(
                    currentData[FieldOccurrenceKeyEnum.FIELD],
                    currentData[FieldOccurrenceKeyEnum.OCCURRENCE_COUNT],
                ),
            };
        });
    }

    getDisplayedLabel(field, count) {
        switch (field) {
            case FieldEnum.AD_IMNEUF:
                return this._translate('shared.newBuilding', { COUNT: count });
            case FieldEnum.AD_ISOLE:
                return this._translate('shared.isolatedLocals', { COUNT: count });
            case FieldEnum.AD_PRIO:
                return this._translate('shared.prioritySiteConnection', { COUNT: count });
            case FieldOccurrenceAdditionalFieldEnum.NON_SPECIFIC:
                return this._translate('shared.addressWithoutSpecificities', { COUNT: count });
            case FieldOccurrenceAdditionalFieldEnum.ADDRESS_COUNT:
                return this._translate('shared.addressCount');
            default:
                return field;
        }
    }

    static getFormattedFieldOccurrenceData(data) {
        const elementsGroupedByField = radashGroup(data, (currentData) => currentData[FieldOccurrenceKeyEnum.FIELD]);
        const occurrenceCount = IndicatorFieldOccurrenceService.getOccurrenceCount(elementsGroupedByField);

        const fieldsWithOccurrences = Object.entries(elementsGroupedByField).map(([field, elements]) => {
            const validElements = elements.filter((element) => element[FieldOccurrenceKeyEnum.VALUE]);

            return {
                [FieldOccurrenceKeyEnum.FIELD]: field,
                [FieldOccurrenceKeyEnum.OCCURRENCE_COUNT]:
                    radashSum(
                        validElements,
                        (validElement) => validElement[FieldOccurrenceKeyEnum.OCCURRENCE_COUNT] ?? 0,
                    ) ?? 0,
            };
        });

        return [
            ...fieldsWithOccurrences,
            {
                [FieldOccurrenceKeyEnum.FIELD]: FieldOccurrenceAdditionalFieldEnum.ADDRESS_COUNT,
                [FieldOccurrenceKeyEnum.OCCURRENCE_COUNT]: occurrenceCount,
            },
            {
                [FieldOccurrenceKeyEnum.FIELD]: FieldOccurrenceAdditionalFieldEnum.NON_SPECIFIC,
                [FieldOccurrenceKeyEnum.OCCURRENCE_COUNT]:
                    occurrenceCount -
                    radashSum(
                        fieldsWithOccurrences,
                        (fieldWithOccurrence) => fieldWithOccurrence[FieldOccurrenceKeyEnum.OCCURRENCE_COUNT],
                    ),
            },
        ];
    }

    static getOccurrenceCount(elementsGroupedByField) {
        return (
            radashMax(
                Object.values(elementsGroupedByField).map((currentValues) =>
                    radashSum(currentValues, (value) => value[FieldOccurrenceKeyEnum.OCCURRENCE_COUNT]),
                ),
            ) ?? 0
        );
    }
}

angular
    .module('dotic')
    .factory('$indicatorFieldOccurrenceService', ($filter) => new IndicatorFieldOccurrenceService($filter));
