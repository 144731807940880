import { pick as radashPick } from 'radash';

function reportService($filter, $segmentsService, $indicatorMaxValueService, $toasterService, reportProvider) {
    const humanizeThematic = $filter('humanizeThematic');
    const secondsToTime = $filter('secondsToTime');
    const translate = $filter('translate');
    const segmentsService = $segmentsService;
    const indicatorMaxValueService = $indicatorMaxValueService;
    const toasterService = $toasterService;

    return {
        computeChunk: computeChunk,
        getControls: getControls,
        getComparisonReports: getComparisonReports,
        getErrors: getErrors,
        sortControls: sortControls,
        percentage: percentage,
        severitiesToArray: severitiesToArray,
        format,
        preparationSynthesisForExcel: preparationSynthesisForExcel,
        makeNameStatistics: makeNameStatistics,
        preparationAnomaliesForExcel: preparationAnomaliesForExcel,
        scrollDoughnut,
        scrollBar,
    };

    function computeChunk(array = [], chunkLength) {
        const chunks = [];
        let index = 0;
        while (index < array.length) {
            const chunkEndPosition = index + chunkLength;
            chunks.push(array.slice(index, chunkEndPosition));
            index = chunkEndPosition;
        }

        return chunks;
    }

    function scrollDoughnut(element, filter) {
        switch (element.index) {
            case 0:
                filter.result = 'success';

                return;
            case 1:
                filter.result = 'fail';

                return;
            case 2:
                filter.result = 'error';

                return;
            default:
                filter.result = 'warning';

                return;
        }
    }

    function scrollBar(element, filter) {
        switch (element.datasetIndexResult) {
            case 0:
                filter.result = 'success';

                return;
            case 1:
                filter.result = 'fail';

                return;
            case 2:
                filter.result = 'error';

                return;
            default:
                filter.result = 'warning';

                return;
        }
    }

    function getControls(reportControls, controlGrid) {
        const errorStats = {
            global: {},
            thematics: {},
        };

        const enhancedControls = reportControls.reduce((acc, control) => {
            const gridControlPoint = controlGrid.find(
                (gridControl) =>
                    `${gridControl.severity}-${gridControl.controlPoint}` ===
                    `${control.severity}-${control.controlPoint}`,
            );
            if (gridControlPoint) {
                mutateErrorStats(control, errorStats);
                acc.push({
                    ...control,
                    ...radashPick(gridControlPoint, [
                        'attributes',
                        'controlRequest',
                        'idClient',
                        'object',
                        'tablesSQL',
                        'variableUnit',
                        'variable2Unit',
                    ]),
                    faults: control.error ? -2 : control.invalid_count,
                });
            } else {
                // Cas où le point de controle est présent dans le rapport mais pas dans l'image de la grille (très problématique)
                // -1 si 'error' est vide et -2 si 'erreur' est remplie.
                acc.push({
                    ...control,
                    invalid_count: -1,
                    total_count: -1,
                    valid_count: -1,
                    faults: control.error ? -2 : -1,
                });
            }

            return acc;
        }, []);

        return {
            controls: enhancedControls.filter((control) => control.enable),
            errorStats: errorStats,
        };
    }

    function getErrors(reportId, controlPoint, severity) {
        return async ({ page, limit, order, column }) => {
            try {
                return await reportProvider.getProjectControlJson(reportId, controlPoint, severity, {
                    page: page,
                    limit: limit,
                    order: order,
                    column: column,
                });
            } catch (error) {
                toasterService.error(error);

                return [];
            }
        };
    }

    function _byControlPointReducer(acc, control) {
        return {
            ...acc,
            [`${control.controlPoint}_${control.severity}`]: control,
        };
    }

    function getComparisonReports(report1, report2) {
        const controlPoints = new Set([
            ...report1.controls.map(({ controlPoint, severity }) => `${controlPoint}_${severity}`),
            ...report2.controls.map(({ controlPoint, severity }) => `${controlPoint}_${severity}`),
        ]);
        const controlPoint1ByKey = report1.controls.reduce(_byControlPointReducer, {});
        const controlPoint2ByKey = report2.controls.reduce(_byControlPointReducer, {});

        return Array.from(controlPoints)
            .map((controlPoint) => {
                const data = controlPoint1ByKey[controlPoint] || controlPoint2ByKey[controlPoint];

                const enabled1 = !!controlPoint1ByKey[controlPoint];
                const enabled2 = !!controlPoint2ByKey[controlPoint];

                return {
                    controlPoint: data.controlPoint,
                    idClient: data.idClient,
                    shortDescription: data.shortDescription,
                    thematic: data.thematic,
                    severity: data.severity,
                    status: data.enable,
                    object: data.object,
                    error1: controlPoint1ByKey[controlPoint]?.error || null,
                    error2: controlPoint2ByKey[controlPoint]?.error || null,
                    faults1: controlPoint1ByKey[controlPoint]?.faults || null,
                    faults2: controlPoint2ByKey[controlPoint]?.faults || null,
                    enabled1: enabled1,
                    enabled2: enabled2,
                    invalid_count1: enabled1 ? controlPoint1ByKey[controlPoint].invalid_count || 0 : -1,
                    invalid_count2: enabled2 ? controlPoint2ByKey[controlPoint].invalid_count || 0 : -1,
                    description: data.description,
                    total_count1: controlPoint1ByKey[controlPoint]?.total_count || 0,
                    total_count2: controlPoint2ByKey[controlPoint]?.total_count || 0,
                };
            })
            .sort((a, b) => a.error1?.localeCompare(b.error1));
    }

    function mutateErrorStats(control, errorStats) {
        if (!errorStats.thematics[control.thematic]) {
            errorStats.thematics[control.thematic] = {};
        }

        if (!errorStats.thematics[control.thematic][control.severity]) {
            errorStats.thematics[control.thematic][control.severity] = {
                success: 0,
                errors: 0,
                total: 0,
            };
        }

        if (!errorStats.global[control.severity]) {
            errorStats.global[control.severity] = {
                success: 0,
                errors: 0,
                total: 0,
            };
        }

        errorStats.thematics[control.thematic][control.severity].success += control.valid_count ?? 0;
        errorStats.thematics[control.thematic][control.severity].errors += control.invalid_count ?? 0;
        errorStats.thematics[control.thematic][control.severity].total +=
            (control.valid_count ?? 0) + (control.invalid_count ?? 0);
        errorStats.global[control.severity].success += control.valid_count ?? 0;
        errorStats.global[control.severity].errors += control.invalid_count ?? 0;
        errorStats.global[control.severity].total += (control.valid_count ?? 0) + (control.invalid_count ?? 0);
    }

    function sortControls(array) {
        const zeroControlArray = array.filter(({ total_count }) => parseInt(total_count, 10) < 1);

        const controls = array
            .filter(({ total_count }) => parseInt(total_count, 10) > 0)
            .sort((a, b) => {
                const ratioA = parseFloat(a.valid_count) / parseFloat(a.total_count);
                const ratioB = parseFloat(b.valid_count) / parseFloat(b.total_count);

                if (ratioA < ratioB) {
                    return -1;
                }

                if (ratioA > ratioB) {
                    return 1;
                }

                return 0;
            });

        return controls.concat(zeroControlArray);
    }

    function percentage(a, b) {
        if (parseInt(b, 10) === 0) {
            return '0,00';
        }

        return Number((parseFloat(a) / parseFloat(b)) * 100).toLocaleString('fr-FR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    }

    function severitiesToArray(object) {
        if (!object) {
            return;
        }

        return ['blocking', 'major', 'minor'].map((key) => {
            if (angular.isUndefined(object[key])) {
                return { severity: key, sumControls: '0', value: '0' };
            }

            return {
                severity: key,
                sumControls: object[key].total,
                value: this.percentage(object[key].errors, object[key].total),
            };
        });
    }

    function preparedChartDistanceRange(data) {
        let zeroToThreeHundred = 0;
        let threeHundredToFiveHundred = 0;
        let fiveHundredToThousand = 0;
        let thousandToThreeThousand = 0;
        let overThreeThousand = 0;

        if (!data) {
            return null;
        }

        data.forEach((range) => {
            if (range.distanceKm > 0 && range.distanceKm <= 0.3) {
                zeroToThreeHundred += 1;
            } else if (range.distanceKm > 0.3 && range.distanceKm <= 0.5) {
                threeHundredToFiveHundred += 1;
            } else if (range.distanceKm > 0.5 && range.distanceKm <= 1) {
                fiveHundredToThousand += 1;
            } else if (range.distanceKm > 1 && range.distanceKm <= 3) {
                thousandToThreeThousand += 1;
            } else if (range.distanceKm > 3) {
                overThreeThousand += 1;
            }
        });

        const labels = [
            `${translate('shared.lessThan', { VALUE: 0.3 })} Km`,
            `${translate('shared.betweenTwoValues', { FIRST_VALUE: 0.3, SECOND_VALUE: 0.5 })} Km`,
            `${translate('shared.betweenTwoValues', { FIRST_VALUE: 0.5, SECOND_VALUE: 1 })} Km`,
            `${translate('shared.betweenTwoValues', { FIRST_VALUE: 1, SECOND_VALUE: 3 })} Km`,
            `${translate('shared.greaterThan', { VALUE: 3 })} Km`,
        ];

        const datasets = [
            {
                data: [
                    zeroToThreeHundred,
                    threeHundredToFiveHundred,
                    fiveHundredToThousand,
                    thousandToThreeThousand,
                    overThreeThousand,
                ],
                backgroundColor: ['#50d0c3', '#00a2fb', '#5069a9', '#edb349', '#dd3073'],
            },
        ];

        const numberTotalDistances = data.length;

        return {
            bigLegend: {
                figure: numberTotalDistances,
                name: `${translate('shared.distance', {
                    COUNT: numberTotalDistances,
                })} `,
            },
            labels,
            datasets,
        };
    }

    function format(data) {
        const report = data.report;
        const gridControl = data.report.deposit.gridControl;
        const tables = data.indicators.tables;
        const gridFill = data.report.deposit.gridFill;

        const { controls: dataControls, errorStats } = getControls(data.report.controls, gridControl);
        report.deposit.networkSegments = segmentsService.setNetworkSegments(report.deposit.networkSegments);

        const glossary = data.controls.global.errorControls.map((control) => {
            return {
                idClient: control.idClient,
                controlPoint: control.controlPoint,
                short: control.shortDescription,
                description: control.description,
            };
        });

        const blockingErrorsChartData = {
            labels: Object.keys(data.controls.thematics).map((thematic) => humanizeThematic(thematic)),
            datasets: [
                {
                    label: translate('shared.blockingAnomaly', {
                        COUNT: data.errorBlocking,
                    }),
                    backgroundColor: '#c72c48',
                    pointBorderColor: '#fff',
                    categoryPercentage: 0.7,
                    barPercentage: 0.7,
                    maxBarThickness: 15,
                    data: Object.values(data.controls.thematics).map((data) => Math.round(data.errorBlocking)),

                    datalabels: {
                        align: 'end',
                        anchor: 'end',
                        padding: '0',
                        clamp: true,
                        formatter: function (value) {
                            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
                        },
                    },
                },
            ],
        };

        const majorErrorsChartData = {
            labels: Object.keys(data.controls.thematics).map((thematic) => humanizeThematic(thematic)),
            datasets: [
                {
                    label: translate('shared.majorAnomaly', {
                        COUNT: data.errorMajor,
                    }),
                    backgroundColor: '#ec8b89',
                    pointBorderColor: '#fff',
                    categoryPercentage: 0.7,
                    barPercentage: 0.7,
                    maxBarThickness: 15,
                    data: Object.values(data.controls.thematics).map((data) => Math.round(data.errorMajor)),
                    datalabels: {
                        align: 'end',
                        anchor: 'end',
                        padding: '0',
                        clamp: true,
                        formatter: function (value) {
                            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
                        },
                    },
                },
            ],
        };

        const minorErrorsChartData = {
            labels: Object.keys(data.controls.thematics).map((thematic) => humanizeThematic(thematic)),
            datasets: [
                {
                    label: translate('shared.minorAnomaly', {
                        COUNT: data.errorMinor,
                    }),
                    backgroundColor: '#fdd41e',
                    pointBorderColor: '#fff',
                    categoryPercentage: 0.7,
                    barPercentage: 0.7,
                    maxBarThickness: 15,
                    data: Object.values(data.controls.thematics).map((data) => Math.round(data.errorMinor)),
                    datalabels: {
                        align: 'end',
                        anchor: 'end',
                        padding: '0',
                        clamp: true,
                        formatter: function (value) {
                            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
                        },
                    },
                },
            ],
        };

        const blockingErrorsChartDataMaxValue = blockingErrorsChartData
            ? indicatorMaxValueService.findMaxValueFlatten(blockingErrorsChartData)
            : 0;
        const majorErrorsChartDataMaxValue = majorErrorsChartData
            ? indicatorMaxValueService.findMaxValueFlatten(majorErrorsChartData)
            : 0;
        const minorErrorsChartDataMaxValue = minorErrorsChartData
            ? indicatorMaxValueService.findMaxValueFlatten(minorErrorsChartData)
            : 0;

        const nbBoxes = [
            { value: data.indicators.pbo, id: 'pbo' },
            { value: data.indicators.bpe, id: 'bpe' },
            {
                value: data.indicators.type_ebp_pto,
                id: 'pto',
            },
        ];

        const pbo = [
            { value: data.indicators.pbo, id: 'pbo' },
            { value: data.indicators.bpe, id: 'bpe' },
            {
                value: data.indicators.type_ebp_pto,
                id: 'pto',
            },
        ];

        const junctionBox = data.indicators.junctionBox;

        const distanceBetweenNroPboChart = preparedChartDistanceRange(data.indicators.distanceBetweenNroPbo);
        const distanceBetweenSroPboChart = preparedChartDistanceRange(data.indicators.distanceBetweenSroPbo);
        const distanceBetweenNroSroChart = preparedChartDistanceRange(data.indicators.distanceBetweenNroSro);

        let hasSegmentTrDi = false;
        let hasSegmentDi = false;

        if (report.deposit.networkSegments.length) {
            hasSegmentTrDi =
                report.deposit.networkSegments.includes('TR') && report.deposit.networkSegments.includes('DI');
            hasSegmentDi =
                report.deposit.networkSegments.includes('DI') && !report.deposit.networkSegments.includes('TR');
        }

        return {
            report,
            gridControl,
            gridFill,
            tables,
            dataControls,
            errorStats,
            glossary,
            blockingErrorsChartData,
            minorErrorsChartData,
            majorErrorsChartData,
            blockingErrorsChartDataMaxValue,
            majorErrorsChartDataMaxValue,
            minorErrorsChartDataMaxValue,
            pbo,
            nbBoxes,
            distanceBetweenNroPboChart,
            distanceBetweenNroSroChart,
            distanceBetweenSroPboChart,
            hasSegmentTrDi,
            hasSegmentDi,
            junctionBox,
        };
    }

    /**
     * Download reports EXCEL
     */
    function mapKeyToUpperCase(obj) {
        if (!angular.isObject(obj)) {
            throw new Error("obj it isn't an object");
        }

        const objConvertedToLowerCase = {};
        for (const [key, value] of Object.entries(obj)) {
            objConvertedToLowerCase[key.toUpperCase()] = value;
        }

        return objConvertedToLowerCase;
    }

    function makeNameStatistics(statisticsDate) {
        return moment(statisticsDate).format('DD/MM/YYYY [à] HH:mm');
    }

    function preparationSynthesisForExcel(reports) {
        const datas = [],
            obj = {};
        reports.forEach((report) => {
            let { deposit } = report;
            if (!report.deposit) {
                deposit = report;
            }

            obj[translate(getKeyI18n('deposit'))] = getValueOfSynthesis(deposit, 'name');
            obj[translate('shared.file')] =
                deposit === null ? '-' : getValueOfSynthesis(deposit.zipFile, 'originalname');
            obj[translate(getKeyI18n('version'))] = getValueOfSynthesis(deposit, 'version');
            obj[translate(getKeyI18n('treatmentDuration'))] = secondsToTime(deposit.FMEJobs[0].duration);
            obj[translate(getKeyI18n('dateAndTime'))] = makeNameStatistics(deposit.createdAt);
            obj[translate(getKeyI18n('savedBy'))] = report.createdBy
                ? getValueOfSynthesis(report.createdBy, 'fullname')
                : '-';
            obj[translate(getKeyI18n('step'))] = getValueOfSynthesis(deposit, 'phase');
            obj[translate(getKeyI18n('segments'))] = getValueOfSynthesis(deposit, 'networkSegments');
            obj[translate(getKeyI18n('project'))] = getValueOfSynthesis(report.project, 'name');
            obj[translate(getKeyI18n('controlConfiguration'))] =
                deposit === null ? '-' : getValueOfSynthesis(deposit, 'controlConfigurationName');
            obj[translate(getKeyI18n('controlGrid'))] =
                deposit === null ? '-' : getValueOfSynthesis(deposit, 'controlGridName');
            obj[translate(getKeyI18n('fillGrid'))] =
                deposit === null ? '-' : getValueOfSynthesis(deposit, 'fillGridName');
            obj[translate(getKeyI18n('dataModel'))] =
                deposit === null ? '-' : getValueOfSynthesis(deposit, 'dataModelName');
            if (reports.length > 1) {
                obj[translate(getKeyI18n('numberOfPlugs'))] = getValueOfSynthesis(deposit, 'numberOfPlug');
            }

            datas.push(mapKeyToUpperCase(obj));
            datas.push({});
        });

        return datas;
    }

    function getValueOfSynthesis(statistic, key) {
        if (!statistic[key] || statistic[key] === null) {
            return '-';
        }

        return statistic[key];
    }

    // Renvoie les balises de traduction correspondant à la clé transmise
    function getKeyI18n(key) {
        switch (key) {
            /**
             * Partie synthèse
             */
            case 'dateAndTime':
                return 'deposit.depositDate';
            case 'fillGrid':
                return 'shared.fillGrid';
            case 'segments':
                return 'shared.networkSegment';
            /**
             * Partie rapport d'anomalies
             */
            case 'anomalies':
                return 'shared.anomaly';
            case 'var 1':
            case 'var 2':
                return 'shared.shortVariable';
            case 'total_count':
                return 'shared.nbControl';
            case 'recipientOperatingMode':
                return 'shared.recipientOperatingMode';
            case 'targetSIOperatingMode':
                return 'deposit.targetSI';
            case 'conditionForAction':
                return 'shared.conditionForAction';
            default:
                return `shared.${key}`;
        }
    }

    function preparationAnomaliesForExcel(anomalies, names = {}) {
        if (!names.nameOrigin || !names.nameToCompare) {
            return anomalies.map((anomaly) => {
                return mapKeyToUpperCase({
                    ...prepareAnomalies({ ...anomaly }, names),
                });
            });
        }

        const attributesDouble = ['var 1', 'var 2', 'anomalies', 'total_count'];

        const anomalies1 = anomalies[0].map((anomaly) => {
            return mapKeyToUpperCase({
                ...prepareAnomalies({ ...anomaly }, names),
            });
        });
        const anomalies2 = anomalies[1].map((anomaly) => {
            return mapKeyToUpperCase({
                ...prepareAnomalies({ ...anomaly }, names),
            });
        });

        const resultFromAnomaly1 = anomalies1.map((anomaly) => {
            const controlPointA = `${anomaly[translate(getKeyI18n('severity')).toUpperCase()]}-${
                anomaly[translate(getKeyI18n('idInterne')).toUpperCase().replaceAll(' ', '_')]
            }`;
            const anomalyToCompare = anomalies2.find((anomaly) => {
                const controlPointB = `${anomaly[translate(getKeyI18n('severity')).toUpperCase()]}-${
                    anomaly[translate(getKeyI18n('idInterne')).toUpperCase().replaceAll(' ', '_')]
                }`;

                return controlPointA === controlPointB;
            });

            attributesDouble.forEach((attribute) => {
                const key = `${translate(getKeyI18n(attribute))
                    .toUpperCase()
                    .replaceAll(' ', '_')}_${names.nameToCompare.toUpperCase().replaceAll(' ', '_')}`;
                anomaly[key] = angular.isUndefined(anomalyToCompare) ? '-' : anomalyToCompare[key];
            });

            const index = anomalies2.findIndex((item) => item === anomalyToCompare);
            if (index > -1) {
                anomalies2.splice(index, 1);
            }

            return anomaly;
        });

        const resultFromAnomaly2 = anomalies2.map((anomaly) => {
            attributesDouble.forEach((attribute) => {
                const key = `${translate(getKeyI18n(attribute)).toUpperCase().replaceAll(' ', '_')}_${names.nameOrigin
                    .toUpperCase()
                    .replaceAll(' ', '_')}`;
                anomaly[key] = '-';
            });

            return anomaly;
        });

        return [...resultFromAnomaly1, ...resultFromAnomaly2].sort(
            (a, b) =>
                a[translate(getKeyI18n('idInterne')).toUpperCase().replaceAll(' ', '_')] -
                b[translate(getKeyI18n('idInterne')).toUpperCase().replaceAll(' ', '_')],
        );
    }

    function prepareAnomalies(anomalies, names) {
        return preparedListAttributes().reduce((acc, attribute) => {
            return { ...acc, ...getValueOfAnomaly(anomalies, names, attribute) };
        }, {});
    }

    // Défini les attributs du fichier Excel (colonnes du tableau)
    function preparedListAttributes() {
        return [
            'idInterne',
            'severity',
            'thematic',
            'object',
            'control',
            'description',
            'var 1',
            'var 2',
            'anomalies',
            'total_count',
            'conditionForAction',
            'operatingMode',
            'recipientOperatingMode',
            'targetSIOperatingMode',
        ];
    }

    function getValueOfAnomaly(controlPoint, names, key) {
        const translateParams = {};
        if (key === 'var 1') {
            translateParams.NUMBER = 1;
        }

        if (key === 'var 2') {
            translateParams.NUMBER = 2;
        }

        const translateKey = translate(getKeyI18n(key), translateParams);
        const attributesDouble = ['var 1', 'var 2', 'anomalies', 'total_count'];
        if (names.nameOrigin && names.nameToCompare && attributesDouble.includes(key)) {
            return {
                [`${translateKey}_${names.nameOrigin}`.replaceAll(' ', '_')]: translateValue(key, controlPoint),
                [`${translateKey}_${names.nameToCompare}`.replaceAll(' ', '_')]: translateValue(key, controlPoint),
            };
        }

        return {
            [translateKey.replaceAll(' ', '_')]: translateValue(key, controlPoint),
        };
    }

    // Renvoie la valeur correspondant à la clé transmise
    function translateValue(key, value) {
        switch (key) {
            case 'idInterne':
                return value.controlPoint || '-';
            case 'control':
                return value.shortDescription || '-';
            case 'anomalies':
                return value.invalid_count || value.error || 0;
            case 'var 1':
                return value.variable || '-';
            case 'var 2':
                return value.variable2 || '-';
            case 'operatingMode':
                return value.possibleCause || '-';
            case 'conditionForAction':
                return value.errorCode || '-';
            default:
                return value[key] || '-';
        }
    }
}

angular
    .module('dotic')
    .factory('$reportService', [
        '$filter',
        '$segmentsService',
        '$indicatorMaxValueService',
        '$toasterService',
        'reportProvider',
        reportService,
    ]);
