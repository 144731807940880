import template from './interventionGridList.html';

class InterventionGridListPage {
    constructor(
        $authorizationService,
        $deleteService,
        $filter,
        $location,
        $scope,
        $tableService,
        $timeout,
        $toasterService,
        $uibModal,
        LoaderService,
        interventionGridProvider,
        $modalService,
    ) {
        this._$deleteService = $deleteService;
        this._$scope = $scope;
        this._interventionGridProvider = interventionGridProvider;
        this._$tableService = $tableService;
        this._translate = $filter('translate');
        this._$timeout = $timeout;
        this._LoaderService = LoaderService;
        this._$toasterService = $toasterService;
        this._$uibModal = $uibModal;
        this.isAllowed = $authorizationService.isAllowed;
        this._$modalService = $modalService;

        this.loading = true;
        this.grids = [];

        $scope.$emit('updateNavigation', {
            newPage: [
                {
                    key: 'interventionGridsList',
                    title: this._translate('shared.interventionGridList'),
                    href: $location.path(),
                },
            ],
        });

        // Watch selection
        $scope.$on('selectRows', (e, data) => {
            this.tableSelect.select(data.rows, true);
        });
    }

    async $onInit() {
        await this.initData();
    }

    async initData() {
        this.loading = true;

        try {
            this.grids = await this._interventionGridProvider.getGrids();
            this.tableSelect = this._$tableService.select();
        } catch (error) {
            this._$toasterService.error(error);
        }

        // Timeout necessary as AngularJs doesn't trigger digest cycle from async / await method or function
        this._$timeout(() => (this.loading = false));
    }

    selectVisibleRows() {
        this._$scope.$broadcast('getVisibleRows');
    }

    isAllowedToRemove(item) {
        return item && this.isAllowed(['superAdmin']);
    }

    getSelectionType() {
        const size = this.tableSelect.selectionSize();
        const removable = this.grids.filter((item) => this.isAllowedToRemove(item._id));

        if (size === removable.length) {
            return 'all';
        }

        if (size === 0) {
            return 'none';
        }

        if (size === 10) {
            return 'visible';
        }

        return 'few';
    }

    async removeInterventionGrids() {
        const selectedGrids = this.grids
            .filter(this.tableSelect.isActive)
            .filter((item) => this.isAllowedToRemove(item._id));

        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.interventionGrid', {
                COUNT: selectedGrids.length,
                NAME: selectedGrids[0].name,
            }).toLowerCase(),
        );
        if (!isAccepted) {
            return;
        }

        const selectedGridIds = this.tableSelect.getSelection();
        await this._$deleteService.deleteArrayOfIds(
            selectedGridIds,
            (gridId) => this._interventionGridProvider.deleteGrid(gridId),
            'removeModal.successGrid',
        );

        await this.initData();
        this._LoaderService.dismiss();
    }

    openCompanyModal(grid) {
        const companiesList = grid.companies;

        this._$uibModal.open({
            size: 'md',
            component: 'companiesListModal',
            resolve: {
                companies: () => companiesList,
            },
        });
    }
}

angular.module('dotic').component('interventionGridListPage', {
    templateUrl: template,
    controller: InterventionGridListPage,
});
