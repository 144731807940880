import { ColorService, GeneralBarChartColorEnum } from '../../../../services/color.service';
import { ChartService } from '../../../../services/chart.service';
import { IndicatorService } from '../../../../services/indicator.service';
import template from './cable-length-by-slice-type.component.html';

const KeyEnum = {
    CABLE_LENGTH: 'cableLength',
    SLICE_COMPOSITION: 'sliceComposition',
};

class CableLengthBySliceTypeComponent {
    chartData = { datasets: [], labels: [] };
    columns = [];
    data = [];
    formattedData = [];
    iconOptions = [];
    parentRef = {};
    tableTitle = '';

    constructor($filter) {
        this._translate = $filter('translate');
    }

    $onInit() {
        this.formattedData = IndicatorService.getFormattedKilometerData(this.data, [KeyEnum.CABLE_LENGTH]);
        this.iconOptions = this.getIconOptions(this.formattedData);

        const sliceCompositions = this.formattedData.map((currentData) => currentData[KeyEnum.SLICE_COMPOSITION]);
        const datasets = [
            ChartService.getTableChartDataset(
                this.formattedData.map((currentData) => currentData[KeyEnum.CABLE_LENGTH]),
                this._translate('shared.length', { COUNT: 1 }),
                GeneralBarChartColorEnum.LENGTH,
            ),
        ];

        this.chartData = {
            datasets: datasets,
            labels: sliceCompositions,
        };

        this.columns = this.getColumns();
        this.tableTitle = this._translate('reportCompare.detailLengthCable');
    }

    getColumns() {
        return [
            {
                key: KeyEnum.SLICE_COMPOSITION,
                translation: this._translate('shared.conductTypeBySlice'),
            },
            {
                key: KeyEnum.CABLE_LENGTH,
                translation: `${this._translate('shared.length', { COUNT: 1 })} (km)`,
            },
        ];
    }

    getIconOptions(data) {
        return data.map((currentData) => {
            return {
                color: ColorService.getSliceTypeColor(currentData[KeyEnum.SLICE_COMPOSITION]),
                icon: 'cement-mix',
                label: currentData[KeyEnum.SLICE_COMPOSITION],
                value: currentData[KeyEnum.CABLE_LENGTH],
            };
        });
    }
}

angular.module('dotic').component('cableLengthBySliceTypeIndicator', {
    bindings: {
        data: '<',
        parentRef: '<',
    },
    controller: CableLengthBySliceTypeComponent,
    templateUrl: template,
});
