import { FeatureEnum } from '../../guards/has-feature.guard';

angular.module('dotic').config(($stateProvider) => {
    $stateProvider.state('app.exportGeoPackage', {
        url: '/export/geopackage',
        component: 'exportGeoPackagePage',
        data: {
            authenticationRequired: true,
            roleRequired: ['admin'],
        },
        resolve: {
            canActivate: [
                'hasFeatureGuard',
                (hasFeatureGuard) => {
                    return hasFeatureGuard(FeatureEnum.GEO_PACKAGE_EXPORT);
                },
            ],
        },
    });
});
