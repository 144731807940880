class ComparisonService {
    constructor($modalService, $state) {
        this._$modalService = $modalService;
        this._$state = $state;
    }

    controlGridComparePage(grid1, grid2, company) {
        return this._$state.go('app.gridControlCompare', {
            grid1,
            grid2,
            company,
        });
    }

    async controlStatComparePage(statisticsOrigin, statisticsToCompare) {
        const stat1EndDate = statisticsOrigin.endDate;
        const stat2EndDate = statisticsToCompare.endDate;

        let idStatisticsOrigin;
        let idStatisticsToCompare;

        if (stat1EndDate <= stat2EndDate) {
            idStatisticsOrigin = statisticsOrigin.id;
            idStatisticsToCompare = statisticsToCompare.id;
        }

        if (stat1EndDate > stat2EndDate) {
            idStatisticsOrigin = statisticsToCompare.id;
            idStatisticsToCompare = statisticsOrigin.id;
        }

        if (angular.equals(statisticsOrigin.phases.sort(), statisticsToCompare.phases.sort())) {
            return this._$state.go('app.statisticsCompare', {
                idStatisticsOrigin,
                idStatisticsToCompare,
            });
        }

        const isAccepted = await this._$modalService.triggerConfirmationModal(
            'statisticsPage.compareDifferentPhasesTitle',
            'shared.continue',
        );
        if (!isAccepted) {
            return;
        }

        return this._$state.go('app.statisticsCompare', {
            idStatisticsOrigin,
            idStatisticsToCompare,
        });
    }

    depositComparePage(deposit1, deposit2) {
        return this._$state.go('app.reportCompare', {
            deposit1,
            deposit2,
        });
    }

    fillGridComparePage(grid1, grid2, company) {
        return this._$state.go('app.gridFillAdminCompare', {
            idGridSelected: grid1,
            idFillGridCompared: grid2,
            company,
        });
    }

    async fillingStatComparePage(fillStatOrigin, fillStatCompare) {
        const [statisticsOrigin, statisticSelected] = [fillStatOrigin, fillStatCompare];
        const [{ _id: idStatisticsOrigin }, { _id: idStatisticsSelected }] = [fillStatOrigin, fillStatCompare];

        if (angular.equals(statisticsOrigin.phases, statisticSelected.phases)) {
            return this._$state.go('app.fillingStatisticsCompare', {
                idStatisticsOrigin: idStatisticsOrigin,
                idStatisticsSelected: idStatisticsSelected,
            });
        }

        const isAccepted = await this._$modalService.triggerConfirmationModal(
            'statisticsPage.compareDifferentPhasesTitle',
            'shared.continue',
        );
        if (!isAccepted) {
            return;
        }

        return this._$state.go('app.fillingStatisticsCompare', {
            idStatisticsOrigin: idStatisticsOrigin,
            idStatisticsSelected: idStatisticsSelected,
        });
    }

    async goToComparePage(objectOrigin, objectCompare, type, company = null) {
        switch (type) {
            case 'ANCTComparison':
                await this._$state.go('app.anctVerificationsCompare', {
                    depositAId: objectOrigin,
                    depositBId: objectCompare,
                });
                break;
            case 'controlStat':
                await this.controlStatComparePage(objectOrigin, objectCompare);
                break;
            case 'fillStat':
                await this.fillingStatComparePage(objectOrigin, objectCompare);
                break;
            case 'deposit':
                await this.depositComparePage(objectOrigin, objectCompare);
                break;
            case 'controlGrid':
                await this.controlGridComparePage(objectOrigin, objectCompare, company);
                break;
            case 'fillGrid':
                await this.fillGridComparePage(objectOrigin, objectCompare, company);
                break;
        }
    }
}

angular
    .module('dotic')
    .factory('$comparisonService', ($modalService, $state) => new ComparisonService($modalService, $state));
