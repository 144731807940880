import template from './sub-companies-modal.component.html';

class SubCompaniesModalComponent {
    constructor($scope, $toasterService) {
        this._$toasterService = $toasterService;
        this._$scope = $scope;

        this.companies = [];
        this.loading = true;
    }

    $onInit() {
        const { companies } = this.resolve;

        if (angular.isArray(companies)) {
            this.setCompanies(companies);
        } else if (angular.isFunction(companies)) {
            companies().then((results) => this.setCompanies(results));
        } else {
            this.loading = false;
        }
    }

    setCompanies(companies) {
        this.loading = false;
        this.companies = companies;
    }

    closePopup() {
        this.close();
    }
}

angular.module('dotic').component('subCompaniesModal', {
    bindings: {
        resolve: '<',
        close: '&',
    },
    controller: SubCompaniesModalComponent,
    templateUrl: template,
});
