export const AddressesDeploymentStateTypeEnum = {
    DONE: 'done',
    IN_PROGRESS: 'inProgress',
};

export const BtiPositionEnum = {
    // the spaces are needed because we receive always a space in the indicator at the end of the value
    IN: 'En entrée ',
    OUT: 'En sortie ',
};

export const IndicatorsTypeEnum = {
    ADDRESSES: 'ADDRESSES',
    BOXES: 'BOXES',
    DEPLOYMENT_PROGRESS: 'DEPLOYMENT_PROGRESS',
    FIBERS_CABLES: 'FIBERS_CABLES',
    NETWORK_SATURATION_LEVEL: 'NETWORK_SATURATION_LEVEL',
    NETWORK_SITES: 'NETWORK_SITES',
    PATHWAYS: 'PATHWAYS',
    TECHNICAL_POINTS: 'TECHNICAL_POINTS',
};

export const NetworkSaturationLevelTypeEnum = {
    RESERVE: 'reserve',
    SATURATION: 'saturation',
};
