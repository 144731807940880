import { parallel as radashParallel } from 'radash';

import { DepositsApi } from '../../sdk/connect-control-api-v1/src';
import { FMEJobEnum } from '../models/deposit.model';

class DeleteService {
    constructor($apiClientService, $filter, $modalService, $toasterService, LoaderService) {
        this._$toasterService = $toasterService;
        this._$modalService = $modalService;
        this._translate = $filter('translate');
        this._depositsApi = new DepositsApi($apiClientService.client);
        this._LoaderService = LoaderService;
    }

    /**
     * @param {string[]} idsToDelete
     * @param { (id: string) => Promise<void> } deleteFunction
     * @param {string} i18nSuccessKey
     * @param {string} i18nFailureKey
     * @param {number} parallelDeletionLimit
     * @return {Promise<void>}
     */
    async deleteArrayOfIds(
        idsToDelete,
        deleteFunction,
        i18nSuccessKey = 'removeModal.success',
        i18nFailureKey = 'removeModal.failure',
        parallelDeletionLimit = 10,
    ) {
        const results = await radashParallel(parallelDeletionLimit, idsToDelete, async (idToDelete) => {
            try {
                await deleteFunction(idToDelete);

                return { success: true };
            } catch (error) {
                return { success: false, error: error.message };
            }
        });

        const allSucceeded = results.every((result) => result.success);
        if (allSucceeded) {
            this._$toasterService.info(this._translate(i18nSuccessKey, { COUNT: results.length }));

            return;
        }

        this._$toasterService.error(this._translate(i18nFailureKey, { COUNT: results.length }));
    }

    async deleteDepositIds(depositIds) {
        const isANCT = (
            await Promise.all(
                depositIds.map(async (depositId) => {
                    try {
                        return (await this._depositsApi.getByIdWithHttpInfo(depositId)).response.body;
                    } catch {
                        return null;
                    }
                }),
            )
        ).some((deposit) => deposit?.isANCT && deposit.FMEJobs[0].job === FMEJobEnum.CONTROL);

        const isAccepted = await this._$modalService.triggerConfirmDepositDeletionModal(isANCT);

        if (!isAccepted) {
            return;
        }

        this._LoaderService.open(
            `<p>${this._translate('deposit.deletingFiles', {
                COUNT: depositIds.length,
            })}</p>`,
        );

        await this.deleteArrayOfIds(depositIds, (id) => this._depositsApi.remove(id), 'deposit.successDeleteDeposit');

        this._LoaderService.dismiss();
    }
}

angular
    .module('dotic')
    .factory(
        '$deleteService',
        ($apiClientService, $filter, $modalService, $toasterService, LoaderService) =>
            new DeleteService($apiClientService, $filter, $modalService, $toasterService, LoaderService),
    );
