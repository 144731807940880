/**
 * ConnectControl API Documentation (v1)
 * The official ConnectControl Open API documentation (v1)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class SegmentNameEnum.
* @enum {}
* @readonly
*/
export default class SegmentNameEnum {
    
        /**
         * value: "DI"
         * @const
         */
        "DI" = "DI";

    
        /**
         * value: "RA"
         * @const
         */
        "RA" = "RA";

    
        /**
         * value: "TR"
         * @const
         */
        "TR" = "TR";

    

    /**
    * Returns a <code>SegmentNameEnum</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/SegmentNameEnum} The enum <code>SegmentNameEnum</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

