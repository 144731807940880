import userManualSynopticInterfaceComparisonTemplate from './synoptic-interface-comparison.html';
import userManualSynopticSynoBayBoxSynoBayBoxShowBayTemplate from './synoBayBox/synoBayBoxShowBay.html';
import userManualSynopticSynoBayBoxSynoBayBoxShowBoxTemplate from './synoBayBox/synoBayBoxShowBox.html';
import userManualSynopticSynoBayBoxSynoBayBoxShoxCassetteTemplate from './synoBayBox/synoBayBoxShoxCassette.html';
import userManualSynopticSynoBayBoxSynoBayBoxTemplate from './synoBayBox/synoBayBox.html';
import userManualSynopticSynoDetailSynoDetailFonctionalityTemplate from './synoDetail/synoDetailFonctionality.html';
import userManualSynopticSynoDetailSynoDetailShowElementTemplate from './synoDetail/synoDetailShowElement.html';
import userManualSynopticSynoDetailSynoDetailTemplate from './synoDetail/synoDetail.html';
import userManualSynopticSynoLinkedSynoLinkedMviewerTemplate from './synoLinked/synoLinkedMviewer.html';
import userManualSynopticSynoLinkedSynoLinkedReportTemplate from './synoLinked/synoLinkedReport.html';
import userManualSynopticSynoLinkedSynoLinkedTemplate from './synoLinked/synoLinked.html';
import userManualSynopticSynopticSeeSynopticSeeOpenTemplate from './synopticSee/synopticSeeOpen.html';
import userManualSynopticSynopticSeeSynopticSeeTemplate from './synopticSee/synopticSee.html';
import userManualSynopticSynopticTemplate from './synoptic.html';
import userManualSynopticSynopticUseSynopticUseConsistencyTemplate from './synopticUse/synopticUseConsistency.html';
import userManualSynopticSynopticUseSynopticUseInfoSectionTemplate from './synopticUse/synopticUseInfoSection.html';
import userManualSynopticSynopticUseSynopticUseInfoTemplate from './synopticUse/synopticUseInfo.html';
import userManualSynopticSynopticUseSynopticUseLegendeSectionTemplate from './synopticUse/synopticUseLegendeSection.html';
import userManualSynopticSynopticUseSynopticUseSearchTemplate from './synopticUse/synopticUseSearch.html';
import userManualSynopticSynopticUseSynopticUseTemplate from './synopticUse/synopticUse.html';

angular.module('dotic').config(($stateProvider) => {
    $stateProvider
        .state('app.help.synoptic', {
            url: '/synoptic',
            templateUrl: userManualSynopticSynopticTemplate,
            data: {
                title: 'Mode synoptique optique des câbles',
                navigation: [{ title: 'Mode synoptique', sref: 'app.help.synoptic' }],
                previous: 'app.help.cartoUseLinkSyno',
                next: 'app.help.synopticSee',
            },
        })
        .state('app.help.synopticSee', {
            url: '/synoptic/visualiser',
            templateUrl: userManualSynopticSynopticSeeSynopticSeeTemplate,
            data: {
                title: 'Visualiser mode synoptique',
                navigation: [
                    { title: 'Mode synoptique', sref: 'app.help.synoptic' },
                    { title: 'Visualiser', sref: 'app.help.synopticSee' },
                ],
                previous: 'app.help.synoptic',
                next: 'app.help.synopticSeeOpen',
            },
        })
        .state('app.help.synopticSeeOpen', {
            url: '/synoptic/ouvrir',
            templateUrl: userManualSynopticSynopticSeeSynopticSeeOpenTemplate,
            data: {
                title: 'Ouvrir le mode synoptique',
                navigation: [
                    { title: 'Mode synoptique', sref: 'app.help.synoptic' },
                    { title: 'Visualiser', sref: 'app.help.synopticSee' },
                ],
                previous: 'app.help.synopticSee',
                next: 'app.help.synopticUse',
            },
        })
        .state('app.help.synopticUse', {
            url: '/synoptic/utiliser',
            templateUrl: userManualSynopticSynopticUseSynopticUseTemplate,
            data: {
                title: 'Utiliser mode synoptique',
                navigation: [
                    { title: 'Mode synoptique', sref: 'app.help.synoptic' },
                    { title: 'Utiliser', sref: 'app.help.synopticUse' },
                ],
                previous: 'app.help.synopticSeeOpen',
                next: 'app.help.synopticUseInfo',
            },
        })
        .state('app.help.synopticUseInfo', {
            url: '/synoptic/informations',
            templateUrl: userManualSynopticSynopticUseSynopticUseInfoTemplate,
            data: {
                title: 'Informations générales',
                navigation: [
                    { title: 'Mode synoptique', sref: 'app.help.synoptic' },
                    { title: 'Utiliser', sref: 'app.help.synopticUse' },
                ],
                previous: 'app.help.synopticUse',
                next: 'app.help.synopticUseInfoSection',
            },
        })
        .state('app.help.synopticUseInfoSection', {
            url: '/synoptic/section-informations',
            templateUrl: userManualSynopticSynopticUseSynopticUseInfoSectionTemplate,
            data: {
                title: 'Section information',
                navigation: [
                    { title: 'Mode synoptique', sref: 'app.help.synoptic' },
                    { title: 'Utiliser', sref: 'app.help.synopticUse' },
                ],
                previous: 'app.help.synopticUseInfo',
                next: 'app.help.synopticUseConsistency',
            },
        })

        .state('app.help.synopticUseConsistency', {
            url: '/synoptic/section-coherence',
            templateUrl: userManualSynopticSynopticUseSynopticUseConsistencyTemplate,
            data: {
                title: 'Section cohérence des données',
                navigation: [
                    { title: 'Mode synoptique', sref: 'app.help.synoptic' },
                    { title: 'Utiliser', sref: 'app.help.synopticUse' },
                ],
                previous: 'app.help.synopticUseInfoSection',
                next: 'app.help.synopticUseLegendeSection',
            },
        })

        .state('app.help.synopticUseLegendeSection', {
            url: '/synoptic/section-legende',
            templateUrl: userManualSynopticSynopticUseSynopticUseLegendeSectionTemplate,
            data: {
                title: 'Section legende',
                navigation: [
                    { title: 'Mode synoptique', sref: 'app.help.synoptic' },
                    { title: 'Utiliser', sref: 'app.help.synopticUse' },
                ],
                previous: 'app.help.synopticUseConsistency',
                next: 'app.help.synopticUseSearch',
            },
        })
        .state('app.help.synopticUseSearch', {
            url: '/synoptic/section-recherche',
            templateUrl: userManualSynopticSynopticUseSynopticUseSearchTemplate,
            data: {
                title: 'Section recherche',
                navigation: [
                    { title: 'Mode synoptique', sref: 'app.help.synoptic' },
                    { title: 'Utiliser', sref: 'app.help.synopticUse' },
                ],
                previous: 'app.help.synopticUseLegendeSection',
                next: 'app.help.synoDetail',
            },
        })

        .state('app.help.synoDetail', {
            url: '/synoptic/detail',
            templateUrl: userManualSynopticSynoDetailSynoDetailTemplate,
            data: {
                title: 'Détail mode synoptique',
                navigation: [
                    { title: 'Mode synoptique', sref: 'app.help.synoptic' },
                    { title: 'Détail', sref: 'app.help.synoDetail' },
                ],
                previous: 'app.help.synopticUseSearch',
                next: 'app.help.synoDetailShowElement',
            },
        })
        .state('app.help.synoDetailShowElement', {
            url: '/synoptic/elements-syno',
            templateUrl: userManualSynopticSynoDetailSynoDetailShowElementTemplate,
            data: {
                title: 'Éléménts du synoptique',
                navigation: [
                    { title: 'Mode synoptique', sref: 'app.help.synoptic' },
                    { title: 'Détail', sref: 'app.help.synoDetail' },
                ],
                previous: 'app.help.synoDetail',
                next: 'app.help.synoDetailFonctionality',
            },
        })
        .state('app.help.synoDetailFonctionality', {
            url: '/synoptic/fonctionnement',
            templateUrl: userManualSynopticSynoDetailSynoDetailFonctionalityTemplate,
            data: {
                title: 'Fonctionnement du synoptique',
                navigation: [
                    { title: 'Mode synoptique', sref: 'app.help.synoptic' },
                    { title: 'Détail', sref: 'app.help.synoDetail' },
                ],
                previous: 'app.help.synoDetailShowElement',
                next: 'app.help.synoBayBox',
            },
        })
        .state('app.help.synoBayBox', {
            url: '/synoptic/plan-baie-boite',
            templateUrl: userManualSynopticSynoBayBoxSynoBayBoxTemplate,
            data: {
                title: 'Plan de baie / boite',
                navigation: [
                    { title: 'Mode synoptique', sref: 'app.help.synoptic' },
                    { title: 'Plan Baie/boite', sref: 'app.help.synoBayBox' },
                ],
                previous: 'app.help.synoDetailFonctionality',
                next: 'app.help.synoBayBoxShowBay',
            },
        })
        .state('app.help.synoBayBoxShowBay', {
            url: '/synoptic/plan de baie',
            templateUrl: userManualSynopticSynoBayBoxSynoBayBoxShowBayTemplate,
            data: {
                title: 'Plan de baie',
                navigation: [
                    { title: 'Mode synoptique', sref: 'app.help.synoptic' },
                    { title: 'Plan Baie/boite', sref: 'app.help.synoBayBox' },
                ],
                previous: 'app.help.synoBayBox',
                next: 'app.help.synoBayBoxShowBox',
            },
        })
        .state('app.help.synoBayBoxShowBox', {
            url: '/synoptic/plan de boite',
            templateUrl: userManualSynopticSynoBayBoxSynoBayBoxShowBoxTemplate,
            data: {
                title: 'Plan de boite',
                navigation: [
                    { title: 'Mode synoptique', sref: 'app.help.synoptic' },
                    { title: 'Plan Baie/boite', sref: 'app.help.synoBayBox' },
                ],
                previous: 'app.help.synoBayBoxShowBay',
                next: 'app.help.synoBayBoxShoxCassette',
            },
        })
        .state('app.help.synoBayBoxShoxCassette', {
            url: '/synoptic/cassette',
            templateUrl: userManualSynopticSynoBayBoxSynoBayBoxShoxCassetteTemplate,
            data: {
                title: 'Voir une cassette',
                navigation: [
                    { title: 'Mode synoptique', sref: 'app.help.synoptic' },
                    { title: 'Plan Baie/boite', sref: 'app.help.synoBayBox' },
                ],
                previous: 'app.help.synoBayBoxShowBox',
                next: 'app.help.synoLinked',
            },
        })
        .state('app.help.synoLinked', {
            url: '/synoptic/link-synoptic',
            templateUrl: userManualSynopticSynoLinkedSynoLinkedTemplate,
            data: {
                title: 'Relier un synoptique',
                navigation: [
                    { title: 'Mode synoptique', sref: 'app.help.synoptic' },
                    { title: 'Détail', sref: 'app.help.synoLinked' },
                ],
                previous: 'app.help.synoBayBoxShoxCassette',
                next: 'app.help.synoLinkedReport',
            },
        })
        .state('app.help.synoLinkedReport', {
            url: '/synoptic/link-report',
            templateUrl: userManualSynopticSynoLinkedSynoLinkedReportTemplate,
            data: {
                title: 'Relier à un rapport',
                navigation: [
                    { title: 'Mode synoptique', sref: 'app.help.synoptic' },
                    { title: 'Détail', sref: 'app.help.synoLinked' },
                ],
                previous: 'app.help.synoLinked',
                next: 'app.help.synoLinkedMviewer',
            },
        })
        .state('app.help.synoLinkedMviewer', {
            url: '/synoptic/link-mviewer',
            templateUrl: userManualSynopticSynoLinkedSynoLinkedMviewerTemplate,
            data: {
                title: 'Relier au Mviewer',
                navigation: [
                    { title: 'Mode synoptique', sref: 'app.help.synoptic' },
                    { title: 'Détail', sref: 'app.help.synoLinked' },
                ],
                previous: 'app.help.synoLinkedReport',
                next: 'app.help.dashbord&Nav',
            },
        })
        .state('app.help.synopticInterfaceComparison', {
            url: '/synoptic/interface-comparison',
            templateUrl: userManualSynopticInterfaceComparisonTemplate,
            data: {
                navigation: [{ title: 'Mode synoptique', sref: 'app.help.synoptic' }],
                title: 'Nouvel affichage Synoptique optique',
            },
        });
});
