// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../../../assets/images/illustrations/stop.svg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<div class=\"modal-header modal-header--empty\"> <i class=\"icofont icofont-close analytics--buttonCross-cancel-popup\" ng-click=\"$ctrl.cancel()\"></i> </div> <div class=\"modal-body\"> <img alt=\"{{ 'shared.missingImage' | translate }}\" class=\"alert-modale__image-hero\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"/> <div class=\"alert-modale__title\">{{ 'shared.warning' | translate }}</div> <div class=\"alert-modale__content\"> <div ng-if=\"!$ctrl.resolve.isANCT\">{{ 'deposit.aboutToDeleteDeliverable' | translate }}.</div> <div ng-if=\"$ctrl.resolve.isANCT\"> {{ 'deposit.aboutToDeleteDeliverable' | translate }}, {{ 'shared.ANCTVerificationsAsWell' | translate | lowercase }}. <br/> </div> {{ 'shared.continue' | translate }} </div> </div> <div class=\"modal-footer\"> <cc-button class=\"analytics--button-cancel-popup\" size=\"big\" color=\"reverse-alert\" on-click=\"$ctrl.cancel()\"> {{ 'shared.cancel' | translate }} </cc-button> <cc-button class=\"analytics--button-removeDeposit-popup\" size=\"big\" on-click=\"$ctrl.confirm()\" color=\"alert\"> {{ 'shared.delete' | translate }} </cc-button> </div> ";
// Exports
var _module_exports = code;;
var path = '/app/components/modals/confirm-deposit-deletion/confirm-deposit-deletion-modal.component.html';
window.angular.module('dotic').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;