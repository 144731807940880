import { FMEJobEnum } from '../../models/deposit.model';
import template from './deposits-preview.html';

class DepositsPreviewController {
    loading = true;
    deposits = {
        results: [],
        page: 1,
        count: 0,
    };
    filters = {};
    hasSelectionMultiple = false;
    hasSelectionUnique = false;
    job = FMEJobEnum.LOAD;
    pageSize = 5;
    tableSelect = {};
    isOnlyLastVersions = false;

    constructor($scope, $tableService, $timeout, depositProvider) {
        this._depositProvider = depositProvider;
        this._$scope = $scope;
        this._$tableService = $tableService;
        this._$timeout = $timeout;

        $scope.$on('selectRows', (event, data) => {
            this.tableSelect.select(data.rows, true);
        });
    }

    async $onInit() {
        this.tableSelect = this._$tableService.select();
        this.deposits = await this.getDeposits();
    }

    async $onChanges(changes) {
        if (this.loading || !changes.filters.previousValue || !changes.filters.currentValue) {
            return;
        }

        this.deposits = await this.getDeposits();
    }

    async getDeposits(page = 1) {
        this.loading = true;
        try {
            return await this._depositProvider.listAll({
                filters: {
                    job: this.job,
                    controlConfiguration: this.filters.controlConfigurations,
                    createdAtStart: this.filters.from,
                    createdAtEnd: this.filters.to,
                    createdBy: this.filters.users,
                    dataModel: this.filters.dataModel,
                    deliveryZone: this.filters.deliveryZones,
                    fillGrid: this.filters.fillGrid,
                    hasIdLivrable: this.filters.hasIdLivrable,
                    has002Control: this.filters.has002Control,
                    isSegmentFilterStrict: this.filters.isSegmentFilterStrict,
                    phase: this.filters.phases,
                    projectId: this.filters.projectIds,
                    project: this.filters.project ? [this.filters.project] : this.filters.projects,
                    segment: this.filters.networkSegments,
                    subCompany: this.filters.subCompanies,
                    status: 'SUCCESS',
                    zipFileName: this.filters.zipNames,
                    ...(this.isOnlyLastVersions && { version: 'last' }),
                },
                page: page,
                perPage: this.pageSize,
            });
        } catch {
            return {
                results: [],
                page: 1,
                count: 0,
            };
        } finally {
            this._$timeout(() => {
                this.loading = false;
            });
        }
    }

    async onPaginationChange(page, pageSize) {
        this.pageSize = pageSize;
        this.deposits = await this.getDeposits(page);
    }

    onSelectDeposit(deposit) {
        if (this.hasSelectionUnique && !this.tableSelect.isActive(deposit.id)) {
            this.tableSelect.empty();
        }

        this.tableSelect.select(deposit);

        return this.onSelected({
            $value: this.tableSelect.getSelection(),
        });
    }

    selectAll() {
        this._$scope.$broadcast('getVisibleRows');
    }
}

angular.module('dotic').component('depositsPreview', {
    templateUrl: template,
    controller: DepositsPreviewController,
    bindings: {
        hasSelectionUnique: '<',
        hasSelectionMultiple: '<',
        isOnlyLastVersions: '<',
        job: '@',
        filters: '<',
        onSelected: '&',
    },
});
