import { group as radashGroup, sum as radashSum, unique as radashUnique } from 'radash';

import { ChartService } from '../../services/chart.service';
import { ColorService } from '../../services/color.service';
import template from './opticalCables.html';

class OpticalCablesController {
    chartData = { datasets: [], labels: [] };
    data = null;
    hasNbEdges = true;
    isOld = false;
    loaded = false;
    newNbCables = false;
    sumNbCable = 0;
    types = {};

    constructor($indicatorCapacityCableService, $timeout) {
        this._$indicatorCapacityCableService = $indicatorCapacityCableService;
        this._$timeout = $timeout;
    }

    $onInit() {
        this._$timeout(() => {
            this.isOld = true;

            if (angular.isArray(this.data) && this.data.length) {
                this.types = this._$indicatorCapacityCableService.sumCapacityCable(this.data);
                this.sumNbCable = this._$indicatorCapacityCableService.getSumNbCable(this.data, [], false);

                this.chartData = this.getChartData(this.data);

                this.newNbCables = angular.isNumber(this.sumNbCable);

                this.isOld = false;
            }

            this.loaded = true;
        });
    }

    getChartData(data) {
        const labels = radashUnique(data.map((currentData) => currentData.capacity)).sort((a, b) => b - a);

        const valuesByType = radashGroup(data, (currentData) => currentData.type);

        const datasets = Object.entries(valuesByType).map(([key, value]) => {
            const valuesByCapacity = labels.map((capacity) => {
                const foundBox = value.filter((box) => box.capacity === capacity);

                return radashSum(foundBox, (box) => box.count);
            });

            return ChartService.getTableChartDataset(valuesByCapacity, key, ColorService.getCableLogicalTypeColor(key));
        });

        return { labels: labels, datasets: datasets };
    }
}

angular.module('dotic').component('opticalCables', {
    controller: OpticalCablesController,
    templateUrl: template,
    bindings: {
        hasNbEdges: '<',
        parentRef: '<',
        data: '<',
    },
});
