import { formTypeEnum } from '../../models/form.model';

angular.module('dotic').config(($stateProvider) => {
    $stateProvider
        .state('app.companyList', {
            url: '/companies/list?offer&search&p&sortedBy',
            component: 'companyListPage',
            params: {
                offer: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                search: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                p: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                sortedBy: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
            },
            data: {
                authenticationRequired: true,
                roleRequired: ['superAdmin'],
            },
        })
        .state('app.companyCreate', {
            component: 'companyFormPage',
            data: {
                authenticationRequired: true,
                roleRequired: ['superAdmin'],
            },
            params: {
                formType: formTypeEnum.CREATION,
            },
            url: '/companies/create',
        })
        .state('app.companyEdit', {
            component: 'companyFormPage',
            data: {
                authenticationRequired: true,
                roleRequired: ['superAdmin'],
            },
            params: {
                formType: formTypeEnum.EDITION,
            },
            url: '/companies/:companyId/edit',
        })
        .state('app.companyDuplicate', {
            component: 'companyFormPage',
            data: {
                authenticationRequired: true,
                roleRequired: ['superAdmin'],
            },
            params: {
                formType: formTypeEnum.DUPLICATION,
            },
            url: '/companies/:companyId/duplicate',
        })
        .state('app.companyDetail', {
            url: '/companies/:companyId/details?role&relation&search',
            component: 'companyDetailPage',
            params: {
                role: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                relation: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                search: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
            },
            data: {
                authenticationRequired: true,
                roleRequired: ['superAdmin'],
            },
        });
});
