import { ChartService } from '../../chart.service';
import { DoughnutChartColorEnum } from '../../color.service';
import { HelpersService } from '../../helpers.service';

const KeyEnum = {
    COLLECT_LENGTH: 'collectLength',
    COLLECT_REUSED_LENGTH: 'collectReusedLength',
};

// Linked to collect-reused-length indicator
class IndicatorR00831Service {
    static ANCTCorrespondenceNumber = 31;

    constructor($filter) {
        this._translate = $filter('translate');
    }

    generateDataStructure(data) {
        const reusedPercent = HelpersService.formatDecimalNumber(
            data[KeyEnum.COLLECT_LENGTH]
                ? (data[KeyEnum.COLLECT_REUSED_LENGTH] / data[KeyEnum.COLLECT_LENGTH]) * 100
                : 0,
        );

        return {
            ANCTCorrespondenceNumber: IndicatorR00831Service.ANCTCorrespondenceNumber,
            archChartData: {
                datasets: [
                    {
                        backgroundColor: [DoughnutChartColorEnum.NEUTRAL, DoughnutChartColorEnum.BIG_SUCCESS],
                        data: [100 - reusedPercent, reusedPercent],
                        label: this._translate('shared.length'),
                    },
                ],
                labels: [this._translate('shared.reused'), this._translate('shared.reused', { IS_NEGATIVE: 1 })],
            },
            chartOptions: ChartService.getArchChartDefaultOptions(),
            data: [data],
            description: {
                positivePercent: reusedPercent,
                positiveValue: data[KeyEnum.COLLECT_REUSED_LENGTH],
                totalValue: data[KeyEnum.COLLECT_LENGTH],
                unit: this._translate('shared.meter').toUpperCase(),
                unitStatus: this._translate('shared.toBeReused').toLowerCase(),
            },
            tableData: {
                columns: this.getColumns(),
                title: this._translate('shared.detailCollectLengthUsage'),
            },
        };
    }

    getColumns() {
        return [
            {
                key: KeyEnum.COLLECT_REUSED_LENGTH,
                translation: this._translate('shared.reused'),
            },
            {
                key: KeyEnum.COLLECT_LENGTH,
                translation: this._translate('shared.collectLength'),
            },
        ];
    }
}

angular.module('dotic').factory('indicatorR00831Service', IndicatorR00831Service);
