import { unique as radashUnique } from 'radash';

import { ColorService, GeneralIconColorEnum } from '../../color.service';
import { HelpersService } from '../../helpers.service';
import { IndicatorService } from '../indicator.service';

const KeyEnum = {
    CABLE_CAPACITY: 'cableCapacity',
    CABLE_LENGTH: 'cableLength',
    CABLE_LOGICAL_TYPE: 'cableLogicalType',
    IMPLANTATION: 'implantation',
};

// Linked to cable-length-by-implantation indicator.
class IndicatorR00141Service {
    static ANCTCorrespondenceNumber = 28;

    constructor($filter) {
        this._translate = $filter('translate');
    }

    generateDataStructure(data) {
        const formattedData = IndicatorService.getFormattedKilometerData(data, [KeyEnum.CABLE_LENGTH]);

        const capacityFormattedData = IndicatorService.getTableChartMaxFormattedData(
            data,
            KeyEnum.CABLE_LOGICAL_TYPE,
            KeyEnum.IMPLANTATION,
            KeyEnum.CABLE_CAPACITY,
        );

        const lengthFormattedData = IndicatorService.getTableChartSumFormattedData(
            data,
            KeyEnum.CABLE_LOGICAL_TYPE,
            KeyEnum.IMPLANTATION,
            KeyEnum.CABLE_LENGTH,
        );

        const lengthKilometerFormattedData = Object.entries(lengthFormattedData).reduce(
            (acc, [cableLogicalType, lengthByImplantation]) => {
                return {
                    ...acc,
                    [cableLogicalType]: Object.entries(lengthByImplantation).reduce((acc2, [implantation, length]) => {
                        return {
                            ...acc2,
                            [implantation]: HelpersService.formatDecimalNumber(length),
                        };
                    }, {}),
                };
            },
            {},
        );

        return {
            ANCTCorrespondenceNumber: IndicatorR00141Service.ANCTCorrespondenceNumber,
            capacityTableChartData: IndicatorService.getTableChartData(capacityFormattedData, (logicalType) =>
                ColorService.getCableLogicalTypeColor(logicalType),
            ),
            data: formattedData,
            iconOptions: this.getIconOptions(formattedData),
            lengthTableChartData: IndicatorService.getTableChartData(lengthKilometerFormattedData, (logicalType) =>
                ColorService.getCableLogicalTypeColor(logicalType),
            ),
            tableData: {
                columns: this.getColumns(),
                title: this._translate('reportCompare.detailLengthCable'),
            },
        };
    }

    getColumns() {
        return [
            {
                key: KeyEnum.IMPLANTATION,
                translation: this._translate('shared.implantationType'),
            },
            {
                key: KeyEnum.CABLE_LOGICAL_TYPE,
                translation: this._translate('shared.logicalType'),
            },
            {
                key: KeyEnum.CABLE_CAPACITY,
                translation: this._translate('shared.cableCapacity'),
            },
            {
                key: KeyEnum.CABLE_LENGTH,
                translation: `${this._translate('shared.length', { COUNT: 1 })} (km)`,
            },
        ];
    }

    getIconOptions(data) {
        const implantations = radashUnique(data.map((currentData) => currentData[KeyEnum.IMPLANTATION]));
        const logicalTypes = radashUnique(data.map((currentData) => currentData[KeyEnum.CABLE_LOGICAL_TYPE]));

        return [
            {
                color: GeneralIconColorEnum.IMPLANTATION_TYPE,
                icon: 'cement-mix',
                label: this._translate('shared.implantationType'),
                value: implantations.length,
            },
            {
                color: GeneralIconColorEnum.LOGICAL_TYPE,
                icon: 'architecture-alt',
                label: this._translate('shared.logicalType'),
                value: logicalTypes.length,
            },
        ];
    }
}

angular.module('dotic').factory('indicatorR00141Service', IndicatorR00141Service);
