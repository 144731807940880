function AuthConfig($authProvider) {
    $authProvider.withCredentials = false;
    $authProvider.baseUrl = APP_SETTINGS.baseAuthUrl;
    $authProvider.signupUrl = '';
    $authProvider.loginUrl = 'security/login';

    if (APP_SETTINGS.OAUTH2?.tara?.clientId && APP_SETTINGS.OAUTH2?.tara?.authorizationEndpoint) {
        $authProvider.oauth2({
            name: 'tara',
            url: '/tara',
            clientId: APP_SETTINGS.OAUTH2.tara.clientId,
            redirectUri: `${window.location.origin}/login/oauth2`, // eslint-disable-line
            authorizationEndpoint: APP_SETTINGS.OAUTH2.tara.authorizationEndpoint,
            popupOptions: { width: 450, height: 380 },
        });
    }
}

angular.module('dotic').config(AuthConfig);
