/**
 * ConnectControl API Documentation (v1)
 * The official ConnectControl Open API documentation (v1)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import PhaseNameEnum from './PhaseNameEnum';
import SegmentNameEnum from './SegmentNameEnum';

/**
 * The FttxHeritageInventoryDeposit model module.
 * @module model/FttxHeritageInventoryDeposit
 * @version 1.0.0
 */
class FttxHeritageInventoryDeposit {
    /**
     * Constructs a new <code>FttxHeritageInventoryDeposit</code>.
     * @alias module:model/FttxHeritageInventoryDeposit
     * @param createdAt {String} 
     * @param deliveryZone {String} 
     * @param id {String} 
     * @param indicators {Object} 
     * @param name {String} 
     * @param phase {module:model/PhaseNameEnum} 
     * @param version {Number} 
     * @param zipName {String} 
     */
    constructor(createdAt, deliveryZone, id, indicators, name, phase, version, zipName) { 
        
        FttxHeritageInventoryDeposit.initialize(this, createdAt, deliveryZone, id, indicators, name, phase, version, zipName);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, createdAt, deliveryZone, id, indicators, name, phase, version, zipName) { 
        obj['createdAt'] = createdAt;
        obj['deliveryZone'] = deliveryZone;
        obj['id'] = id;
        obj['indicators'] = indicators;
        obj['name'] = name;
        obj['phase'] = phase;
        obj['version'] = version;
        obj['zipName'] = zipName;
    }

    /**
     * Constructs a <code>FttxHeritageInventoryDeposit</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/FttxHeritageInventoryDeposit} obj Optional instance to populate.
     * @return {module:model/FttxHeritageInventoryDeposit} The populated <code>FttxHeritageInventoryDeposit</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new FttxHeritageInventoryDeposit();

            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'String');
            }
            if (data.hasOwnProperty('deliveryZone')) {
                obj['deliveryZone'] = ApiClient.convertToType(data['deliveryZone'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('idLivrable')) {
                obj['idLivrable'] = ApiClient.convertToType(data['idLivrable'], 'String');
            }
            if (data.hasOwnProperty('indicators')) {
                obj['indicators'] = ApiClient.convertToType(data['indicators'], Object);
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('phase')) {
                obj['phase'] = PhaseNameEnum.constructFromObject(data['phase']);
            }
            if (data.hasOwnProperty('networkSegments')) {
                obj['networkSegments'] = ApiClient.convertToType(data['networkSegments'], [SegmentNameEnum]);
            }
            if (data.hasOwnProperty('version')) {
                obj['version'] = ApiClient.convertToType(data['version'], 'Number');
            }
            if (data.hasOwnProperty('zipName')) {
                obj['zipName'] = ApiClient.convertToType(data['zipName'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>FttxHeritageInventoryDeposit</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>FttxHeritageInventoryDeposit</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of FttxHeritageInventoryDeposit.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['createdAt'] && !(typeof data['createdAt'] === 'string' || data['createdAt'] instanceof String)) {
            throw new Error("Expected the field `createdAt` to be a primitive type in the JSON string but got " + data['createdAt']);
        }
        // ensure the json data is a string
        if (data['deliveryZone'] && !(typeof data['deliveryZone'] === 'string' || data['deliveryZone'] instanceof String)) {
            throw new Error("Expected the field `deliveryZone` to be a primitive type in the JSON string but got " + data['deliveryZone']);
        }
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // ensure the json data is a string
        if (data['idLivrable'] && !(typeof data['idLivrable'] === 'string' || data['idLivrable'] instanceof String)) {
            throw new Error("Expected the field `idLivrable` to be a primitive type in the JSON string but got " + data['idLivrable']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is an array
        if (!Array.isArray(data['networkSegments'])) {
            throw new Error("Expected the field `networkSegments` to be an array in the JSON data but got " + data['networkSegments']);
        }
        // ensure the json data is a string
        if (data['zipName'] && !(typeof data['zipName'] === 'string' || data['zipName'] instanceof String)) {
            throw new Error("Expected the field `zipName` to be a primitive type in the JSON string but got " + data['zipName']);
        }

        return true;
    }


}

FttxHeritageInventoryDeposit.RequiredProperties = ["createdAt", "deliveryZone", "id", "indicators", "name", "phase", "version", "zipName"];

/**
 * @member {String} createdAt
 */
FttxHeritageInventoryDeposit.prototype['createdAt'] = undefined;

/**
 * @member {String} deliveryZone
 */
FttxHeritageInventoryDeposit.prototype['deliveryZone'] = undefined;

/**
 * @member {String} id
 */
FttxHeritageInventoryDeposit.prototype['id'] = undefined;

/**
 * @member {String} idLivrable
 */
FttxHeritageInventoryDeposit.prototype['idLivrable'] = undefined;

/**
 * @member {Object} indicators
 */
FttxHeritageInventoryDeposit.prototype['indicators'] = undefined;

/**
 * @member {String} name
 */
FttxHeritageInventoryDeposit.prototype['name'] = undefined;

/**
 * @member {module:model/PhaseNameEnum} phase
 */
FttxHeritageInventoryDeposit.prototype['phase'] = undefined;

/**
 * @member {Array.<module:model/SegmentNameEnum>} networkSegments
 */
FttxHeritageInventoryDeposit.prototype['networkSegments'] = undefined;

/**
 * @member {Number} version
 */
FttxHeritageInventoryDeposit.prototype['version'] = undefined;

/**
 * @member {String} zipName
 */
FttxHeritageInventoryDeposit.prototype['zipName'] = undefined;






export default FttxHeritageInventoryDeposit;

