import { DoughnutChartColorEnum } from '../../../../services/color.service';
import template from './code-hexacle.component.html';

const KeyEnum = {
    ADDRESS_COUNT: 'addressCount',
    NULL_HEXACLE_COUNT: 'nullHexacleCount',
    VALID_HEXACLE_COUNT: 'validHexacleCount',
    VALID_SUP10CHAR_HEXACLE_COUNT: 'validSup10charHexacleCount',
    ZIP_CODE: 'zipCode',
};

class CodeHexacleComponent {
    chartData = { bigLegend: {}, datasets: [], labels: [] };
    chartSize = 180;
    columns = [];
    data = [];
    parentRef = {};
    tableTitle = '';

    constructor($filter) {
        this._translate = $filter('translate');
    }

    $onInit() {
        this.chartData = this.getChartData(this.data);
        this.columns = this.getColumns();
        this.tableTitle = this._translate('shared.detailHexacleCode');
    }

    getChartData(data) {
        const colors = [
            DoughnutChartColorEnum.SUCCESS,
            DoughnutChartColorEnum.BIG_SUCCESS,
            DoughnutChartColorEnum.FAILURE,
        ];

        const addressCountByStatus = data.reduce(
            (acc, currentData) => {
                return {
                    nullHexacleCount: acc.nullHexacleCount + currentData[KeyEnum.NULL_HEXACLE_COUNT],
                    sub10charHexacleCount:
                        acc.sub10charHexacleCount +
                        currentData[KeyEnum.VALID_HEXACLE_COUNT] -
                        currentData[KeyEnum.VALID_SUP10CHAR_HEXACLE_COUNT],
                    sup10charHexacleCount:
                        acc.sup10charHexacleCount + currentData[KeyEnum.VALID_SUP10CHAR_HEXACLE_COUNT],
                };
            },
            { nullHexacleCount: 0, sub10charHexacleCount: 0, sup10charHexacleCount: 0 },
        );

        const addressCount =
            addressCountByStatus.nullHexacleCount +
            addressCountByStatus.sub10charHexacleCount +
            addressCountByStatus.sup10charHexacleCount;

        return {
            bigLegend: {
                figure: addressCount,
                name: this._translate('shared.address', { COUNT: addressCount }),
            },
            datasets: [
                {
                    backgroundColor: colors,
                    data: [
                        addressCountByStatus.sub10charHexacleCount,
                        addressCountByStatus.sup10charHexacleCount,
                        addressCountByStatus.nullHexacleCount,
                    ],
                },
            ],
            labels: [
                this._translate('shared.specificCharNumberHexacleCount', { COMPARATOR: 'less', COUNT: 10 }),
                this._translate('shared.specificCharNumberHexacleCount', { COMPARATOR: 'more', COUNT: 10 }),
                this._translate('shared.nullHexacleCount'),
            ],
        };
    }

    getColumns() {
        return [
            {
                key: KeyEnum.ZIP_CODE,
                translation: this._translate('shared.department'),
            },
            {
                key: KeyEnum.ADDRESS_COUNT,
                translation: this._translate('shared.addressCount'),
            },
            {
                key: KeyEnum.VALID_HEXACLE_COUNT,
                translation: this._translate('shared.totalValidHexacleCount'),
            },
            {
                key: KeyEnum.VALID_SUP10CHAR_HEXACLE_COUNT,
                translation: this._translate('shared.validSup10charHexacleCount'),
            },
            {
                key: KeyEnum.NULL_HEXACLE_COUNT,
                translation: this._translate('shared.nullHexacleCount'),
            },
        ];
    }
}

angular.module('dotic').component('codeHexacleIndicator', {
    bindings: {
        data: '<',
        parentRef: '<',
    },
    controller: CodeHexacleComponent,
    templateUrl: template,
});
