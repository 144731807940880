class DeliveryZonesService {
    constructor(deliveryZoneProvider) {
        this._deliveryZoneProvider = deliveryZoneProvider;
    }

    async getDeliveryZoneByName(projectName, deliveryZoneName) {
        const deliveryZone = await this._deliveryZoneProvider.resolve(projectName, deliveryZoneName);

        if (angular.isArray(deliveryZone?.project?.controlConfigurations)) {
            deliveryZone.project.controlConfigurations = deliveryZone.project.controlConfigurations.filter(
                (controlConfiguration) => controlConfiguration.isActive,
            );
        }

        return deliveryZone;
    }
}

angular
    .module('dotic')
    .factory('$deliveryZoneService', (deliveryZoneProvider) => new DeliveryZonesService(deliveryZoneProvider));
