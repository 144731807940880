import { FeatureEnum } from '../../guards/has-feature.guard';

angular.module('dotic').config(($stateProvider) => {
    $stateProvider.state('app.aggregationHome', {
        url: '/aggregation',
        component: 'aggregationHomePage',
        data: {
            authenticationRequired: true,
            roleRequired: ['admin'],
        },
        resolve: {
            canActivate: [
                'hasFeatureGuard',
                (hasFeatureGuard) => {
                    return hasFeatureGuard(FeatureEnum.AGGREGATION);
                },
            ],
        },
    });
});
