import { RemoveModalTypeEnum } from '../../../models/modal.model';
import template from './companyDetail.html';

class CompanyDetailPage {
    constructor(
        $auth,
        $authorizationService,
        $filter,
        $location,
        $log,
        $modalService,
        $scope,
        $state,
        $stateParams,
        $timeout,
        $toasterService,
        companyProvider,
        userMetricsProvider,
    ) {
        this._$location = $location;
        this._$log = $log;
        this._$modalService = $modalService;
        this._$scope = $scope;
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._translate = $filter('translate');
        this._humanizeRole = $filter('humanizeRole');
        this._companyProvider = companyProvider;
        this._userMetricsProvider = userMetricsProvider;

        this.isAllowed = $authorizationService.isAllowed;

        this.company = {};
        this.subCompanies = {};
        this.roles = [];
        this.relations = [
            { key: 'internal', value: 'Interne' },
            { key: 'external', value: 'Externe' },
        ];
        this.employees = [];
        this.filteredEmployeesList = [];
        this.filter = {
            search: $state.params.search || '',
            role: $state.params.role || '',
            relation: $state.params.relation || '',
        };

        this.subcompanySelected = false;
        this.subcompanySelectedLoading = false;

        $scope.$watchGroup(
            ['$ctrl.employees', '$ctrl.filter.search', '$ctrl.filter.relation', '$ctrl.filter.role'],
            () => this.filterEmployees(),
        );

        if ($stateParams.companyId) {
            this.companyId = $stateParams.companyId;
        } else {
            this._userMetricsProvider.openUsersPanel();
            this.companyId = $auth.getPayload().company;
        }
    }

    async $onInit() {
        await this.fetchCompany();
    }

    filterSearch(search) {
        return (employee) =>
            search === '' ||
            employee.fullname.toLowerCase().indexOf(search) > -1 ||
            employee.email.toLowerCase().indexOf(search) > -1;
    }

    filterRole(role) {
        return (employee) => role === '' || employee.role === role;
    }

    filterRelation(relation) {
        return (employee) => {
            return relation === '' || (relation === 'external' ? employee.isExternal : !employee.isExternal);
        };
    }

    filterEmployees() {
        const search = this.filter.search.toLowerCase();
        const role = this.filter.role;
        const relation = this.filter.relation;
        this._$state.go('.', { role, search, relation });

        this.filteredEmployeesList = this.employees
            .filter(this.filterSearch(search))
            .filter(this.filterRelation(relation))
            .filter(this.filterRole(role))
            .sort((a, b) => {
                if (a.fullname < b.fullname) {
                    return -1;
                }

                if (a.fullname > b.fullname) {
                    return 1;
                }

                return 0;
            });
    }

    setRolesFromEmployees() {
        const roles = new Map();

        this.employees.forEach(({ role }) => {
            roles.set(role, this._humanizeRole(role));
        });

        this.roles = Array.from(roles, ([key, value]) => ({ key, value }));
    }

    userCompanyList(user) {
        return user.companies.map((value) => value.name).join(', ');
    }

    async fetchCompany() {
        try {
            this.loading = true;
            this.company = await this._companyProvider.get(this.companyId);
        } catch (error) {
            this._$toasterService.error(error);
        }

        const subCompanies = this.company.subCompanies || [];

        // Only show internal employees from sub companies
        this.subCompanies = subCompanies.map((company) => {
            let employees = [];
            if (company.employees) {
                employees = company.employees.filter((employee) => employee.creditCompany?.id === company.id);
            }

            return { ...company, employees };
        });

        this.employees = this.company.employees
            .map((employee) => ({
                ...employee,
                isExternal: !employee.creditCompany || employee.creditCompany.id !== this.companyId,
            }))
            .reverse();

        this.setRolesFromEmployees();

        if (!this.isAllowed(['superAdmin'])) {
            this._$scope.$emit('updateNavigation', {
                newPage: [
                    {
                        title: this.company.name,
                        href: this._$state.href('app.userList'),
                        key: 'company',
                    },
                ],
            });

            // Timeout necessary as AngularJs doesn't trigger digest cycle from async / await method or function
            this._$timeout(() => (this.loading = false));

            return;
        }

        this._$scope.$emit('keepPreviousNavigation', {
            newPage: [
                {
                    key: 'company',
                    title: this.company.name,
                    href: this._$location.path(),
                },
            ],
            defaultPrevious: {
                title: this._translate('shared.company'),
                href: this._$state.href('app.companyList'),
                key: 'companies',
            },
            allowedPreviousKeys: ['companies'],
        });

        // Timeout necessary as AngularJs doesn't trigger digest cycle from async / await method or function
        this._$timeout(() => (this.loading = false));
    }

    toggleSubCompaniesVisibility(item) {
        // toggle current open subcompany off
        if (this.subcompanySelected && this.subcompanySelected._id === item._id) {
            this.subcompanySelected = null;

            return;
        }

        // toggle on
        this.subcompanySelectedLoading = true;
        this.subcompanySelected = item;
        this._companyProvider
            .getEmployeeDetailsForCompany(this.subcompanySelected._id)
            .then((employees) => {
                this.subcompanySelected.employees = employees;
            })
            .catch((error) => {
                this._$log.error(error);
            })
            .finally(() => {
                this.subcompanySelectedLoading = false;
            });
    }

    async addUserPopup() {
        const isSubmitted = await this._$modalService.triggerUserFormModal(this.company);

        if (!isSubmitted) {
            return;
        }

        await this.fetchCompany();
    }

    async addKnownUserPopup() {
        const isAccepted = await this._$modalService.triggerAddKnownUserModal(this.companyId);

        if (!isAccepted) {
            return;
        }

        await this.fetchCompany();
    }

    async editUserPopup(user) {
        const isSubmitted = await this._$modalService.triggerUserFormModal(this.company, user);

        if (!isSubmitted) {
            return;
        }

        await this.fetchCompany();
    }

    async deleteUser(employee) {
        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.user', { NAME: employee.fullname }).toLowerCase(),
            employee.isExternal ? RemoveModalTypeEnum.WITHDRAW : RemoveModalTypeEnum.DELETE,
        );

        if (!isAccepted) {
            return;
        }

        try {
            await this._companyProvider.removeUser(this.company.id, employee.id);
            this._$toasterService.info({
                body: this._translate('removeModal.successUser', { TYPE: employee.isExternal ? 'withdraw' : '' }),
            });
            await this.fetchCompany();
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    showUserDetail(userId) {
        if (this.detailedUser === userId) {
            this.detailedUser = null;

            return;
        }

        this.detailedUser = userId;
    }

    async removeCompany() {
        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.company', { NAME: this.company.name }).toLowerCase(),
        );

        if (!isAccepted) {
            return;
        }

        try {
            await this._companyProvider.delete(this.company.id);
            this._$toasterService.info({
                body: this._translate('removeModal.successCompany'),
            });
            await this._$state.go('app.companyList');
        } catch (error) {
            this._$toasterService.error(error);
        }
    }
}

angular.module('dotic').component('companyDetailPage', {
    templateUrl: template,
    controller: CompanyDetailPage,
});
