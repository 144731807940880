/**
 * ConnectControl API Documentation (v1)
 * The official ConnectControl Open API documentation (v1)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import FttxHeritageInventory from './FttxHeritageInventory';
import PaginationResponse from './PaginationResponse';

/**
 * The GetFttxHeritageInventories200Response model module.
 * @module model/GetFttxHeritageInventories200Response
 * @version 1.0.0
 */
class GetFttxHeritageInventories200Response {
    /**
     * Constructs a new <code>GetFttxHeritageInventories200Response</code>.
     * @alias module:model/GetFttxHeritageInventories200Response
     * @implements module:model/PaginationResponse
     * @param count {Number} 
     * @param page {Number} 
     * @param totalCount {Number} 
     */
    constructor(count, page, totalCount) { 
        PaginationResponse.initialize(this, count, page, totalCount);
        GetFttxHeritageInventories200Response.initialize(this, count, page, totalCount);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, count, page, totalCount) { 
        obj['count'] = count;
        obj['page'] = page;
        obj['totalCount'] = totalCount;
    }

    /**
     * Constructs a <code>GetFttxHeritageInventories200Response</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/GetFttxHeritageInventories200Response} obj Optional instance to populate.
     * @return {module:model/GetFttxHeritageInventories200Response} The populated <code>GetFttxHeritageInventories200Response</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new GetFttxHeritageInventories200Response();
            PaginationResponse.constructFromObject(data, obj);

            if (data.hasOwnProperty('count')) {
                obj['count'] = ApiClient.convertToType(data['count'], 'Number');
            }
            if (data.hasOwnProperty('data')) {
                obj['data'] = ApiClient.convertToType(data['data'], [FttxHeritageInventory]);
            }
            if (data.hasOwnProperty('page')) {
                obj['page'] = ApiClient.convertToType(data['page'], 'Number');
            }
            if (data.hasOwnProperty('totalCount')) {
                obj['totalCount'] = ApiClient.convertToType(data['totalCount'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>GetFttxHeritageInventories200Response</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>GetFttxHeritageInventories200Response</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of GetFttxHeritageInventories200Response.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        if (data['data']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['data'])) {
                throw new Error("Expected the field `data` to be an array in the JSON data but got " + data['data']);
            }
            // validate the optional field `data` (array)
            for (const item of data['data']) {
                FttxHeritageInventory.validateJSON(item);
            };
        }

        return true;
    }


}

GetFttxHeritageInventories200Response.RequiredProperties = ["count", "page", "totalCount"];

/**
 * @member {Number} count
 */
GetFttxHeritageInventories200Response.prototype['count'] = undefined;

/**
 * @member {Array.<module:model/FttxHeritageInventory>} data
 */
GetFttxHeritageInventories200Response.prototype['data'] = undefined;

/**
 * @member {Number} page
 */
GetFttxHeritageInventories200Response.prototype['page'] = undefined;

/**
 * @member {Number} totalCount
 */
GetFttxHeritageInventories200Response.prototype['totalCount'] = undefined;


// Implement PaginationResponse interface:
/**
 * @member {Number} count
 */
PaginationResponse.prototype['count'] = undefined;
/**
 * @member {Array.<Object>} data
 */
PaginationResponse.prototype['data'] = undefined;
/**
 * @member {Number} page
 */
PaginationResponse.prototype['page'] = undefined;
/**
 * @member {Number} totalCount
 */
PaginationResponse.prototype['totalCount'] = undefined;




export default GetFttxHeritageInventories200Response;

