import template from './userMetrics.html';

class UserMetricsPage {
    constructor(
        $chartService,
        $filter,
        $filterService,
        $location,
        $scope,
        companyProvider,
        userMetricsProvider,
        userProvider,
    ) {
        this._translate = $filter('translate');
        this._$filterService = $filterService;
        this._$scope = $scope;
        this._companyProvider = companyProvider;
        this._userMetricsProvider = userMetricsProvider;
        this._userProvider = userProvider;

        this.filter = {
            company: '',
            search: '',
            timeRange: '',
            employer: '',
            createdAtStart: '',
            createdAtEnd: '',
            user: '',
            activeRange: '',
            creditCompany: '',
        };
        this.employeesByCompany = {};
        this.usersList = [];
        this.actionStats = {};

        this.loading = true;
        this.lastActions = [];
        this.lastActionsFiltered = null;
        this.timeRangeList = [
            { key: 'lastDay', value: 'Hier' },
            { key: 'lastWeek', value: 'Semaine dernière' },
            { key: 'lastMonth', value: 'Mois dernier' },
            { key: 'lastYear', value: 'Année dernière' },
            { key: 'day', value: 'Depuis 24h' },
            { key: 'week', value: 'Depuis 1 semaine' },
            { key: 'month', value: 'Depuis 1 mois' },
            { key: 'year', value: 'Depuis 1 an' },
        ];
        this.activeList = [
            { key: 'inactive', value: 'Inactif' },
            { key: 'active', value: 'Actif' },
            { key: 'lastDay', value: 'Dernière 24h' },
            { key: 'lastWeek', value: 'Semaine dernière' },
            { key: 'lastMonth', value: 'Mois dernier' },
            { key: 'lastSixMonth', value: 'Semestre dernier' },
        ];

        this.horizontalBarOptions = {
            responsive: true,
            scales: $chartService.getDefaultScaleConfiguration(),
        };

        $scope.$watchGroup(
            [
                '$ctrl.filter.search',
                '$ctrl.filter.employer',
                '$ctrl.filter.user',
                '$ctrl.filter.activeRange',
                '$ctrl.filter.creditCompany',
            ],
            () => this.filterLastActions(),
        );
        $scope.$watchGroup(
            [
                '$ctrl.filter.company',
                '$ctrl.filter.timeRange',
                '$ctrl.filter.createdAtStart',
                '$ctrl.filter.createdAtEnd',
            ],
            () => this.filterData(),
        );

        $scope.$emit('updateNavigation', {
            newPage: [
                {
                    key: 'userMetrics',
                    title: this._translate('shared.userMetrics'),
                    href: $location.path(),
                },
            ],
        });
    }

    $onInit() {
        this.loading = true;

        return this.loadStats()
            .then(this.loadUsers())
            .then(this.loadCompanies())
            .then(this.loadActionStats())
            .then(() => {
                this.loading = false;
            });
    }

    loadCompanies() {
        return this._companyProvider.getAll().then((companies) => {
            this.companiesList = companies.map(({ _id, name }) => ({
                key: _id,
                value: name,
            }));
            this.employeesByCompany = companies.reduce((acc, company) => {
                acc[company._id] = company.employees.reduce((acc, { _id }) => {
                    acc[_id] = true;

                    return acc;
                }, {});

                return acc;
            }, {});
        });
    }

    loadUsers() {
        return this._userProvider.getAll().then((users) => {
            this.usersList = users.map(({ _id, fullname, email }) => ({
                key: _id,
                value: `${fullname} - ${email}`,
            }));
        });
    }

    loadStats() {
        return this._userMetricsProvider.getLastActions(this.filter).then((lastActions) => {
            this.lastActions = lastActions.data;
            this.lastActionsFiltered = angular.copy(lastActions.data);
        });
    }

    loadActionStats() {
        return this._userMetricsProvider.getActions(this.filter).then((actions) => {
            const labels = [];
            const data = [];

            actions.data
                .sort((a, b) => a.userMetrics - b.userMetrics)
                .forEach((action) => {
                    data.push(action.userMetrics);
                    labels.push(this._translate(`userMetrics.actions.${action.key}`));
                });

            this.actionStats = {
                labels,
                datasets: [{ data, label: this._translate('shared.action'), backgroundColor: '#a0d468' }],
            };
        });
    }

    filterLastActions() {
        const { search, employer, user, activeRange, creditCompany } = this.filter;

        this.lastActionsFiltered = this.lastActions
            .filter(this.filterByEmployer(employer))
            .filter(this.filterByUser(user))
            .filter(this.filterByCreditCompany(creditCompany))
            .filter(this.filterByActivity(activeRange))
            .filter(this._$filterService.userSearch(search));
    }

    filterByEmployer(employer) {
        return (user) => {
            if (!this.filter.employer) {
                return true;
            }

            return this.employeesByCompany[employer][user._id];
        };
    }

    filterByUser(user) {
        return (userObj) => {
            if (!this.filter.user) {
                return true;
            }

            return userObj._id === user;
        };
    }

    filterByCreditCompany(company) {
        return (user) => {
            if (!this.filter.creditCompany) {
                return true;
            }

            return user.creditCompany === company;
        };
    }

    filterByActivity(range) {
        return (user) => {
            if (!this.filter.activeRange) {
                return true;
            }

            const hasLastConnection = user.lastConnection !== null && angular.isDefined(user.lastConnection);
            const hasDatas = user.lastActivity !== null && angular.isDefined(user.lastActivity.data);
            let lastActivityDate = '';
            if (user.lastActivity) {
                lastActivityDate = user.lastActivity.createdAt;
            } else if (hasLastConnection) {
                lastActivityDate = user.lastConnection;
            }

            const isActive = hasLastConnection || hasDatas;

            switch (range) {
                case 'inactive':
                    return !isActive;
                case 'active':
                    return isActive;
                case 'lastDay':
                    return isActive && moment().diff(lastActivityDate, 'hours') < 24;
                case 'lastWeek':
                    return isActive && moment().diff(lastActivityDate, 'days') < 7;
                case 'lastMonth':
                    return isActive && moment().diff(lastActivityDate, 'months') < 1;
                case 'lastSixMonth':
                    return isActive && moment().diff(lastActivityDate, 'months') < 6;
            }
        };
    }

    filterData() {
        this.loading = true;
        if (this.filter.timeRange) {
            this.filter.createdAtStart = '';
            this.filter.createdAtEnd = '';
        }

        Promise.all([this.loadActionStats(), this.loadStats()])
            .then(() => {
                this.filterLastActions();
            })
            .then(() => {
                this.loading = false;
            });
    }
}

angular.module('dotic').component('userMetricsPage', {
    templateUrl: template,
    controller: UserMetricsPage,
});
