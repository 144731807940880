import { DoughnutChartColorEnum } from '../../color.service';

const KeyEnum = {
    ADDRESS_COUNT: 'addressCount',
    NULL_HEXACLE_COUNT: 'nullHexacleCount',
    VALID_DIFF_10_CHAR_HEXACLE_COUNT: 'validDiff10CharHexacleCount',
    VALID_HEXACLE_COUNT: 'validHexacleCount',
    ZIP_CODE: 'zipCode',
};

// Linked to code-hexacle indicator.
class IndicatorR00142Service {
    static ANCTCorrespondenceNumber = 16;

    constructor($filter) {
        this._translate = $filter('translate');
    }

    generateDataStructure(data) {
        return {
            ANCTCorrespondenceNumber: IndicatorR00142Service.ANCTCorrespondenceNumber,
            data: data,
            doughnutChartData: this.getDoughnutChartData(data),
            tableData: {
                columns: this.getColumns(),
                title: this._translate('shared.detailHexacleCode'),
            },
        };
    }

    getColumns() {
        return [
            {
                key: KeyEnum.ZIP_CODE,
                translation: this._translate('shared.department'),
            },
            {
                key: KeyEnum.ADDRESS_COUNT,
                translation: this._translate('shared.addressCount'),
            },
            {
                key: KeyEnum.VALID_HEXACLE_COUNT,
                translation: this._translate('shared.validHexacleCount'),
            },
            {
                key: KeyEnum.VALID_DIFF_10_CHAR_HEXACLE_COUNT,
                translation: this._translate('shared.validDiff10CharHexacleCount'),
            },
            {
                key: KeyEnum.NULL_HEXACLE_COUNT,
                translation: this._translate('shared.nullHexacleCount'),
            },
        ];
    }

    getDoughnutChartData(data) {
        const addressCountByStatus = data.reduce(
            (acc, currentData) => {
                return {
                    nullHexacleCount: acc.nullHexacleCount + currentData[KeyEnum.NULL_HEXACLE_COUNT],
                    validDiff10CharHexacleCount:
                        acc.validDiff10CharHexacleCount + currentData[KeyEnum.VALID_DIFF_10_CHAR_HEXACLE_COUNT],
                    validHexacleCount: acc.validHexacleCount + currentData[KeyEnum.VALID_HEXACLE_COUNT],
                };
            },
            { nullHexacleCount: 0, validDiff10CharHexacleCount: 0, validHexacleCount: 0 },
        );

        const addressCount =
            addressCountByStatus.nullHexacleCount +
            addressCountByStatus.validDiff10CharHexacleCount +
            addressCountByStatus.validHexacleCount;

        return {
            bigLegend: {
                figure: addressCount,
                name: this._translate('shared.address', { COUNT: addressCount }),
            },
            datasets: [
                {
                    backgroundColor: [
                        DoughnutChartColorEnum.BIG_SUCCESS,
                        DoughnutChartColorEnum.SUCCESS,
                        DoughnutChartColorEnum.FAILURE,
                    ],
                    data: [
                        addressCountByStatus.validHexacleCount,
                        addressCountByStatus.validDiff10CharHexacleCount,
                        addressCountByStatus.nullHexacleCount,
                    ],
                },
            ],
            labels: [
                this._translate('shared.validHexacleCount', { COMPARATOR: 'less', COUNT: 10 }),
                this._translate('shared.validDiff10CharHexacleCount', { COMPARATOR: 'more', COUNT: 10 }),
                this._translate('shared.nullHexacleCount'),
            ],
        };
    }
}

angular.module('dotic').factory('indicatorR00142Service', IndicatorR00142Service);
