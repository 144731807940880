import template from './arch-chart.component.html';

class ArchChartComponent {
    archData = {};
    description = {};
    options = {};
}

angular.module('dotic').component('ccArchChart', {
    bindings: {
        archData: '<',
        description: '<',
        options: '<?',
    },
    controller: ArchChartComponent,
    templateUrl: template,
});
