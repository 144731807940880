class RecordsService {
    constructor($filter) {
        this._translate = $filter('translate');
    }

    fetchResultTables(data = []) {
        data.sort((a, b) => parseInt(a.nb_lignes_par_table, 10) - parseInt(b.nb_lignes_par_table, 10));

        this.resultArray = {
            sumActiveTable: data.filter((active) => active.activated).length,
            activedRecords: data.filter((active) => active.activated && active.nb_lignes_par_table > 0).length,
            activedNoRecords: data.filter((active) => active.activated && active.nb_lignes_par_table === 0).length,
            numberOfLinesPerTableSum: data.reduce((acc, table) => acc + parseInt(table.nb_lignes_par_table, 10), 0),
            abandonedObjectSum: data.reduce((acc, table) => acc + parseInt(table.sumAbandonedObject, 10), 0),
            sumTotalTable: data.length,
            sumEmptyTable: data.filter((active) => active.nb_lignes_par_table === 0 && !active.activated).length,
            sortData: data,
        };

        return this.resultArray;
    }

    getRecordsTable(data1, data2, compared) {
        return data1.sortData
            .filter((elementOrigin) => {
                return !data2.sortData.some((elementToCompare) => {
                    return elementOrigin.nom_table === elementToCompare.nom_table;
                });
            })
            .map((el) => {
                if (compared) {
                    return {
                        activated: false,
                        nb_lignes_par_table: 'NC',
                        nom_table: el.nom_table,
                        sumAbandonedObject: 'NC',
                        toCompare: { ...el },
                        onlyToCompare: true,
                    };
                }

                const toCompare = {
                    activated: false,
                    nb_lignes_par_table: 'NC',
                    nom_table: el.nom_table,
                    sumAbandonedObject: 'NC',
                };

                return {
                    ...el,
                    toCompare: toCompare,
                    onlyOrigin: true,
                };
            });
    }

    getRecordsTableDiff(data1, data2) {
        return data1.sortData
            .filter((elementOrigin) => {
                return data2.sortData.some((elementToCompare) => {
                    return elementOrigin.nom_table === elementToCompare.nom_table;
                });
            })
            .map((el) => {
                let isSameData = false;

                const toCompare = data2.sortData.find((el2) => {
                    return el2.nom_table === el.nom_table;
                });

                data2.sortData.find((el2) => {
                    if (el2.nom_table === el.nom_table) {
                        if (angular.equals(el2, el)) {
                            isSameData = true;

                            return isSameData;
                        } else {
                            isSameData = false;

                            return isSameData;
                        }
                    }
                });

                return {
                    ...el,
                    toCompare,
                    isSameData,
                    notUnique: true,
                };
            });
    }

    getTablesCompared(dataOrigin, dataToCompare, abandonedObjects) {
        if (!dataOrigin.sortData.length || !dataToCompare.sortData.length) {
            return dataOrigin.sortData.length ? dataOrigin.sortData : dataToCompare.sortData;
        }

        return dataOrigin.sortData
            .filter((elementOrigin) => {
                if (dataOrigin.sortData.length <= dataToCompare.sortData.length) {
                    return dataToCompare.sortData.every((elementToCompare) => {
                        if (elementOrigin.nom_table === elementToCompare.nom_table) {
                            return elementOrigin.nom_table === elementToCompare.nom_table;
                        }

                        return elementOrigin.nom_table;
                    });
                }

                return dataToCompare.sortData.some((elementToCompare) => {
                    if (elementOrigin.nom_table === elementToCompare.nom_table) {
                        return elementOrigin.nom_table === elementToCompare.nom_table;
                    }

                    return elementOrigin.nom_table;
                });
            })
            .map((el) => {
                let toCompare = dataToCompare.sortData.find((el2) => {
                    return el2.nom_table === el.nom_table;
                });

                if (angular.isUndefined(toCompare)) {
                    toCompare = abandonedObjects
                        ? {
                              activated: false,
                              nb_lignes_par_table: 0,
                              nom_table: el.nom_table,
                              sumAbandonedObject: 0,
                          }
                        : {
                              activated: false,
                              nb_lignes_par_table: 0,
                              nom_table: el.nom_table,
                          };
                }

                toCompare = abandonedObjects
                    ? {
                          activated: toCompare.activated,
                          nb_lignes_par_table: toCompare.nb_lignes_par_table,
                          nom_table: toCompare.nom_table,
                          sumAbandonedObject: toCompare.sumAbandonedObject,
                      }
                    : {
                          activated: toCompare.activated,
                          nb_lignes_par_table: toCompare.nb_lignes_par_table,
                          nom_table: toCompare.nom_table,
                      };

                return {
                    activated: el.activated,
                    nb_lignes_par_table: el.nb_lignes_par_table,
                    nom_table: el.nom_table,
                    ...(abandonedObjects ? { sumAbandonedObject: el.sumAbandonedObject } : {}),
                    el2: toCompare,
                };
            });
    }

    initAbandonedObjectTree(data1, data2, compare) {
        const origin = {
            parent: {
                icon: 'recycling-man',
                color: '#607099',
                value: data1.abandonedObjectSum,
                text: this._translate('shared.abandonedObject', { COUNT: data1.abandonedObjectSum }),
            },
        };

        const toCompare = {
            parent: {
                icon: 'recycling-man',
                color: '#607099',
                value: data2.abandonedObjectSum,
                text:
                    this._translate('shared.abandonedObject', { COUNT: data2.abandonedObjectSum }) +
                    (data2.abandonedObjectSum === data1.abandonedObjectSum ? '' : ' ⚠️'),
            },
        };

        return compare ? { origin: origin, toCompare: toCompare } : origin;
    }

    initDataSetUnique(label, yAxisID, options, data, isHidden = false) {
        return {
            label: label,
            backgroundColor: options.backgroundColor,
            pointBorderColor: '#fff',
            yAxisID: yAxisID,
            barPercentage: 0.7,
            categoryPercentage: 0.7,
            data: data,
            hidden: isHidden,
            datalabels: {
                align: 'end',
                anchor: 'end',
                rotation: -90,
                padding: options.padding,
                color: options.color,
                clamp: true,
                font: {
                    weight: 'bold',
                },
            },
        };
    }

    initChartData(dataOrigin, dataToCompare, abandonedObjects, compare, isVisibleAbandonedObjects) {
        const tablesCompared = compare
            ? this.getTablesCompared(dataOrigin, dataToCompare, abandonedObjects)
            : dataOrigin.sortData;

        let datasets = [];

        if (!dataToCompare?.sortData?.length) {
            datasets = compare
                ? [
                      this.initDataSetUnique(
                          `${this._translate('shared.nbRowsPerTables')} Stat 1`,
                          'y1',
                          {
                              backgroundColor: '#e971b1',
                              padding: '18',
                              color: '#e971b1',
                          },
                          tablesCompared
                              .map(({ nb_lignes_par_table }) => nb_lignes_par_table)
                              .sort(
                                  (a, b) => parseInt(a.nb_lignes_par_table, 10) - parseInt(b.nb_lignes_par_table, 10),
                              ),
                      ),
                      this.initDataSetUnique(
                          `${this._translate('shared.nbRowsPerTables')} Stat 2`,
                          'y1',
                          {
                              backgroundColor: '#f5bcda',
                              padding: '-2',
                              color: '#f5bcda',
                          },
                          [],
                      ),
                  ]
                : [
                      this.initDataSetUnique(
                          this._translate('shared.nbRowsPerTables'),
                          'y1',
                          {
                              backgroundColor: '#e971b1',
                              padding: '18',
                              color: '#e971b1',
                          },
                          tablesCompared
                              .map(({ nb_lignes_par_table }) => nb_lignes_par_table)
                              .sort(
                                  (a, b) => parseInt(a.nb_lignes_par_table, 10) - parseInt(b.nb_lignes_par_table, 10),
                              ),
                      ),
                  ];
            if (abandonedObjects) {
                datasets = [
                    ...datasets,
                    this.initDataSetUnique(
                        this._translate('shared.abandonedObject'),
                        'y2',
                        {
                            backgroundColor: '#607099',
                            padding: '5',
                            color: '#000000',
                        },
                        tablesCompared
                            .map((data) => data.sumAbandonedObject)
                            .sort((a, b) => parseInt(a.sumAbandonedObject, 10) - parseInt(b.sumAbandonedObject, 10)),
                        isVisibleAbandonedObjects,
                    ),
                ];
            }
        }

        if (!dataOrigin?.sortData?.length) {
            datasets = [
                this.initDataSetUnique(
                    `${this._translate('shared.nbRowsPerTables')} Stat 1`,
                    'y1',
                    {
                        backgroundColor: '#e971b1',
                        padding: '30',
                        color: '#e971b1',
                    },
                    [],
                ),
                this.initDataSetUnique(
                    `${this._translate('shared.nbRowsPerTables')} Stat 2`,
                    'y1',
                    {
                        backgroundColor: '#fcb5da',
                        padding: '14',
                        color: '#fcb5da',
                    },
                    tablesCompared
                        .map(({ nb_lignes_par_table }) => nb_lignes_par_table)
                        .sort((a, b) => parseInt(a.nb_lignes_par_table, 10) - parseInt(b.nb_lignes_par_table, 10)),
                ),
            ];

            if (abandonedObjects) {
                datasets = [
                    ...datasets,
                    this.initDataSetUnique(
                        `${this._translate('shared.abandonedObject')} Stat 2`,
                        'y2',
                        {
                            backgroundColor: '#2bfafa',
                            padding: '-5',
                            color: '#7d7d7d',
                        },
                        tablesCompared
                            .map((data) => data.sumAbandonedObject)
                            .sort((a, b) => parseInt(a.sumAbandonedObject, 10) - parseInt(b.sumAbandonedObject, 10)),
                        isVisibleAbandonedObjects,
                    ),
                ];
            }
        }

        if (dataOrigin?.sortData?.length && dataToCompare?.sortData?.length) {
            datasets = [
                this.initDataSetUnique(
                    `${this._translate('shared.abandonedObject')} Stat 1`,
                    'y1',
                    {
                        backgroundColor: '#e971b1',
                        padding: '18',
                        color: '#e971b1',
                    },
                    tablesCompared
                        .map(({ nb_lignes_par_table }) => nb_lignes_par_table)
                        .sort((a, b) => parseInt(a.nb_lignes_par_table, 10) - parseInt(b.nb_lignes_par_table, 10)),
                    isVisibleAbandonedObjects,
                ),

                this.initDataSetUnique(
                    `${this._translate('shared.nbRowsPerTables')} Stat 2`,
                    'y1',
                    {
                        backgroundColor: '#fcb5da',
                        padding: '14',
                        color: '#fcb5da',
                    },
                    tablesCompared
                        .map(({ el2 }) => el2.nb_lignes_par_table)
                        .sort((a, b) => parseInt(a.nb_lignes_par_table, 10) - parseInt(b.nb_lignes_par_table, 10)),
                ),
            ];

            if (abandonedObjects) {
                datasets = [
                    ...datasets,
                    this.initDataSetUnique(
                        `${this._translate('shared.abandonedObject')} Stat 1`,
                        'y2',
                        {
                            backgroundColor: '#607099',
                            padding: '5',
                            color: '#000000',
                        },
                        tablesCompared
                            .map((data) => data.sumAbandonedObject)
                            .sort((a, b) => parseInt(a.sumAbandonedObject, 10) - parseInt(b.sumAbandonedObject, 10)),
                        isVisibleAbandonedObjects,
                    ),

                    this.initDataSetUnique(
                        `${this._translate('shared.abandonedObject')} Stat 2`,
                        'y2',
                        {
                            backgroundColor: '#2bfafa',
                            padding: '-5',
                            color: '#7d7d7d',
                        },
                        tablesCompared
                            .map(({ el2 }) => el2.sumAbandonedObject || 0)
                            .sort((a, b) => parseInt(a.sumAbandonedObject, 10) - parseInt(b.sumAbandonedObject, 10)),
                        isVisibleAbandonedObjects,
                    ),
                ];
            }
        }

        const labels = this.initLabelTooltipChart(tablesCompared, false, true);

        return {
            labels: labels,
            datasets: datasets,
        };
    }

    initChartOption(abandonedObjects) {
        return {
            responsive: true,
            scales: {
                x: {
                    ticks: {
                        beginAtZero: true,
                        autoSkip: false,
                    },
                },
                y1: {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    id: 'y1',
                    ticks: {
                        fontColor: '#e971b1',
                    },
                },
                ...(abandonedObjects && {
                    y2: {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        id: 'y2',
                        grid: {
                            drawOnChartArea: false,
                        },
                        ticks: {
                            color: '#607099',
                            font: {
                                style: 'italic',
                            },
                            beginAtZero: true,
                            maxTicksLimit: 6,
                        },
                    },
                }),
            },
            tooltips: {
                intersect: false,
            },
            legend: {
                display: true,
                labels: {
                    fontColor: '#1d5276',
                },
            },
            plugins: {
                datalabels: {
                    color: '#1d5276',
                    align: 'start',
                },
            },
        };
    }

    initLabelTooltipChart(checkDifferences, compared, tooltip) {
        return checkDifferences.map((data) => {
            if (compared) {
                if (data.onlyOrigin) {
                    return tooltip ? ' ' : '❌';
                }

                if (data.toCompare.activated && data.toCompare.nb_lignes_par_table > 0) {
                    return tooltip ? `${data.toCompare.nom_table}  🟩` : '🟩';
                }

                if (data.toCompare.activated && data.toCompare.nb_lignes_par_table <= 0) {
                    return tooltip ? `${data.toCompare.nom_table}  🟨` : '🟨';
                }

                if (tooltip && !data.toCompare.activated) {
                    return `${data.toCompare.nom_table}  ⬜`;
                }

                return tooltip ? '' : '⬜';
            }

            if (data.onlyToCompare) {
                return tooltip ? ' ' : '❌';
            }

            if (data.activated && data.nb_lignes_par_table > 0) {
                return tooltip ? `${data.nom_table}  🟢` : '🟢';
            }

            if (data.activated && data.nb_lignes_par_table <= 0) {
                return tooltip ? `${data.nom_table} 🟡 ` : '🟡';
            }

            if (tooltip && !data.activated) {
                return `${data.nom_table}  ⚪`;
            }

            return tooltip ? '' : '⚪';
        });
    }

    initMissingDataChart(differencesFound, abandonedObjects) {
        if (!differencesFound.length) {
            return;
        }

        const labelDiff = this.initLabelTooltipChart(differencesFound, true, true);

        const datasets = [
            this.initDataSetUnique(
                `${this._translate('shared.nbRowsPerTables')} ${this._translate('shared.miss')}`,
                'y1',
                {
                    backgroundColor: '#f5bcda',
                    padding: '',
                    color: '#f5bcda',
                },
                differencesFound.map(({ toCompare }) => toCompare.nb_lignes_par_table),
            ),
        ];

        return {
            labels: labelDiff,
            datasets: abandonedObjects
                ? [
                      ...datasets,
                      this.initDataSetUnique(
                          `${this._translate('shared.abandonedObject')} ${this._translate('shared.miss')}`,
                          'y2',
                          {
                              backgroundColor: '#2bfafa',
                              padding: '-5',
                              color: '#2bfafa',
                          },
                          differencesFound.map(({ toCompare }) => toCompare.sumAbandonedObject),
                      ),
                  ]
                : datasets,
        };
    }

    initNewTable(dataTable, abandonedObjectData) {
        const recordsTableOrigin = dataTable
            .map((data) => {
                return {
                    id: data.id,
                    activated: data.activated,
                    nb_lignes_par_table: data.nb_lignes_par_table,
                    nom_table: data.nom_table,
                };
            })
            .sort((a, b) => a.nom_table.toString().localeCompare(b.nom_table.toString()));

        const abandonedObjectTableOrigin = abandonedObjectData.map((data) => {
            return {
                sumAbandonedObject: data.count,
                nom_table: data.tableName,
            };
        });

        const aggregateTableOrigin = recordsTableOrigin.map((el1) => ({
            ...abandonedObjectTableOrigin.find((el2) => el2.nom_table === el1.nom_table),
            ...el1,
        }));

        return aggregateTableOrigin
            .map((item) => {
                if (!item.sumAbandonedObject) {
                    return { ...item, sumAbandonedObject: 0 };
                }

                return item;
            })
            .sort((a, b) => parseInt(a.nb_lignes_par_table, 10) - parseInt(b.nb_lignes_par_table, 10));
    }

    initStatusLabelTooltipCompared(checkDifferences, tooltip) {
        return checkDifferences.map((el) => {
            if (el.notUnique && el.isSameData) {
                return tooltip ? `${el.nom_table} ✅` : '✅';
            }

            if (el.notUnique && !el.isSameData) {
                return tooltip ? `${el.nom_table} ⚠️` : '⚠️';
            }

            if (!el.notUnique && !el.isSameData) {
                return tooltip ? `${el.nom_table} ❌` : '❌';
            }
        });
    }

    initTotalTableTree(data1, data2, compare) {
        const origin = {
            parent: {
                icon: 'table',
                color: '#e971b1',
                value: data1.sumTotalTable ?? 0,
                text:
                    data1.sumTotalTable > 1
                        ? `${this._translate('shared.tables')} ${this._translate('shared.totales')}`
                        : `${this._translate('shared.table')} ${this._translate('shared.totale')}`,
            },
            children: [
                {
                    icon: 'file-spreadsheet',
                    color: '#0b99e0',
                    value: data1.sumActiveTable ?? 0,
                    text: this._translate('shared.activeTable', { COUNT: data1.sumActiveTable }),
                },
                {
                    icon: 'file-file',
                    color: '#c8c8d1',
                    value: data1.sumEmptyTable ?? 0,
                    text: this._translate('shared.emptyTable', { COUNT: data1.sumEmptyTable }),
                },
            ],
            numberOfLinesPerTableSum: data1.numberOfLinesPerTableSum,
        };

        const toCompare = {
            parent: {
                icon: 'table',
                color: '#e971b1',
                value: data2.sumTotalTable,
                text:
                    (data2.sumTotalTable > 1
                        ? `${this._translate('shared.tables')} ${this._translate('shared.totales')}`
                        : `${this._translate('shared.table')} ${this._translate('shared.totale')}`) +
                    (data2.sumTotalTable === data1.sumTotalTable ? '' : ' ⚠️'),
            },
            children: [
                {
                    icon: 'file-spreadsheet',
                    color: '#0b99e0',
                    value: data2.sumActiveTable,
                    text:
                        this._translate('shared.activeTable', { COUNT: data2.sumActiveTable }) +
                        (data2.sumActiveTable === data1.sumActiveTable ? '' : ' ⚠️'),
                },
                {
                    icon: 'file-file',
                    color: '#c8c8d1',
                    value: data2.sumEmptyTable,
                    text:
                        this._translate('shared.emptyTable', { COUNT: data2.sumEmptyTable }) +
                        (data2.sumEmptyTable === data1.sumEmptyTable ? '' : ' ⚠️'),
                },
            ],
            numberOfLinesPerTableSum: data2.numberOfLinesPerTableSum,
        };

        return compare ? { origin: origin, toCompare: toCompare } : origin;
    }
}

angular.module('dotic').factory('$indicatorRecordsService', ($filter) => new RecordsService($filter));
