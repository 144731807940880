import { pick as radashPick } from 'radash';

import { FMEJobEnum } from '../models/deposit.model';

export class HelpersService {
    static arePhasesEqual(phasesA, phasesB) {
        return angular.equals(HelpersService.getEnabledPhases(phasesA), HelpersService.getEnabledPhases(phasesB));
    }

    static cleanFilter(filters) {
        if (!filters) {
            return;
        }

        Object.keys(filters).forEach((filterName) => {
            if (angular.isArray(filters[filterName])) {
                filters[filterName] = [];
            } else {
                filters[filterName] = '';
            }
        });

        return filters;
    }

    static compareSqueezables(squeezableA, squeezableB, compareKeys) {
        return compareKeys.every((key) => squeezableA[key] === squeezableB[key]);
    }

    static formatDecimalNumber(value, precision = 3) {
        return Number(value.toFixed(precision));
    }

    static getAllNetworkSegmentEnabledList(dataGrid) {
        return HelpersService.isAllControlPointsEnabled(dataGrid)
            .map((val) => val.networkSegments)
            .flat();
    }

    static getAllPhasesEnabledList(dataGrid) {
        return HelpersService.isAllControlPointsEnabled(dataGrid)
            .map((val) => val.phases)
            .flat();
    }

    static getControlJob(deposit) {
        return deposit?.FMEJobs?.find((FMEJob) => FMEJob.job === FMEJobEnum.CONTROL);
    }

    static getEnabledPhases(phases) {
        return phases.filter((phasesFiltered) => phasesFiltered.isEnabled);
    }

    static getLoadJob(deposit) {
        return deposit?.FMEJobs?.find((FMEJob) => FMEJob.job === FMEJobEnum.LOAD);
    }

    static getNetworkSegmentListWithCountEnabled(dataGrid) {
        const networkSegmentsEnabledAmount = HelpersService.getAllNetworkSegmentEnabledList(dataGrid).reduce(
            (acc, val) => ({
                ...acc,
                [val.segmentName]: (acc[val.segmentName] || 0) + (val.isEnabled ? 1 : 0),
            }),
            {},
        );

        const networkSegments = Object.keys(networkSegmentsEnabledAmount).map((segment) => {
            return {
                name: segment,
                count: networkSegmentsEnabledAmount[segment],
            };
        });

        return networkSegments.reduce((acc, item) => {
            const exist = acc.find(({ name }) => item.name === name);

            if (exist) {
                exist.count += item.count;
            } else {
                acc.push(item);
            }

            return acc;
        }, []);
    }

    static getPhasesListWithCountEnabled(dataGrid) {
        const phasesEnabledAmount = HelpersService.getAllPhasesEnabledList(dataGrid).reduce((acc, val) => {
            return {
                ...acc,
                [val.phaseName]: (acc[val.phaseName] || 0) + (val.isEnabled ? 1 : 0),
            };
        }, {});

        const phases = Object.keys(phasesEnabledAmount).map((phase) => {
            return { name: phase, count: phasesEnabledAmount[phase] };
        });

        return HelpersService.orderPhases(phases);
    }

    static isAllControlPointsEnabled(dataGrid) {
        const isControlGrid = dataGrid.some((data) => data.controlPoint);

        if (!isControlGrid) {
            return dataGrid.filter((val) => val.phases.some((phase) => phase.isEnabled));
        }

        return dataGrid.filter(
            (val) =>
                val.networkSegments.some((networkSegment) => networkSegment.isEnabled) &&
                val.phases.some((phase) => phase.isEnabled),
        );
    }

    static isControlPointEnabled(item) {
        return (
            item.networkSegments.some((networkSegment) => networkSegment.isEnabled) &&
            item.phases.some((phase) => phase.isEnabled)
        );
    }

    static isFilterEmpty(filters) {
        if (!filters) {
            return false;
        }

        return Object.values(filters).some((filter) => {
            return filter?.length > 0;
        });
    }

    static orderPhases(phases) {
        const DEFAULT_ORDER_PHASES = {
            DIA: 0,
            PRE: 1,
            AVP: 2,
            PRO: 3,
            EXE: 4,
            REC: 5,
            MCO: 6,
        };

        return phases.sort((a, b) => {
            return DEFAULT_ORDER_PHASES[a.name.toUpperCase()] - DEFAULT_ORDER_PHASES[b.name.toUpperCase()];
        });
    }

    /**
     *
     * @typedef Squeezable
     * @type {Object.<string, any> & Object.<string, number>}
     *
     * Creates a new object with only the referenced key and squeezes the duplicated data
     * @param {Squeezable[]} squeezables
     * @param {string[]} idKeys
     * @param {string} squeezedKey
     */
    static squeezeObjects(squeezables, idKeys, squeezedKey) {
        return squeezables
            .map((squeezable) => radashPick(squeezable, [...idKeys, squeezedKey]))
            .reduce((acc, squeezable) => {
                const squeezed = acc.find((squeezed) =>
                    HelpersService.compareSqueezables(squeezed, squeezable, idKeys),
                );

                if (!squeezed) {
                    return [
                        ...acc,
                        {
                            ...squeezable,
                        },
                    ];
                }

                squeezed[squeezedKey] += squeezable[squeezedKey];

                return [...acc];
            }, []);
    }

    static toKeyValue(array, options = { keyField: 'id', valueField: 'name' }) {
        return array.map((item) => ({
            key: item[options.keyField],
            value: item[options.valueField],
        }));
    }
}

angular.module('dotic').factory('$helpersService', () => new HelpersService());
