import { BtiPositionEnum } from '../../../models/indicator.model';
import { FMEJobEnum } from '../../../models/deposit.model';
import { HelpersService } from '../../../services/helpers.service';
import template from './statisticsCompare.html';

class StatisticsComparePage {
    constructor(
        $auth,
        $companySettings,
        $controlStatisticsService,
        $dataModelService,
        $excelService,
        $filter,
        $indicatorCapacityCableService,
        $indicatorControlPointService,
        $indicatorBoxesService,
        $indicatorDistanceRangeService,
        $indicatorMaxValueService,
        $indicatorNumberControlService,
        $indicatorNewPlugService,
        $indicatorOpticalCableSupportService,
        $indicatorRecordsService,
        $indicatorStatusService,
        $location,
        $modalService,
        $scope,
        $segmentsService,
        $state,
        $stateParams,
        $tableService,
        $timeout,
        $toasterService,
        $uibModal,
        statisticsProvider,
    ) {
        this._$auth = $auth;
        this._$companySettings = $companySettings;
        this._$controlStatisticsService = $controlStatisticsService;
        this._$dataModelService = $dataModelService;
        this._$excelService = $excelService;
        this._humanizeSeverity = $filter('humanizeSeverity');
        this._translate = $filter('translate');
        this._$indicatorCapacityCableService = $indicatorCapacityCableService;
        this._$indicatorControlPointService = $indicatorControlPointService;
        // TODO: find a way to disable lint here as it's not unused
        this._$indicatorBoxesService = $indicatorBoxesService;
        this._$indicatorDistanceRangeService = $indicatorDistanceRangeService;
        this._$indicatorMaxValueService = $indicatorMaxValueService;
        this._$indicatorNumberControlService = $indicatorNumberControlService;
        this._$indicatorNewPlugService = $indicatorNewPlugService;
        this._$indicatorOpticalCableSupportService = $indicatorOpticalCableSupportService;
        this._$indicatorRecordsService = $indicatorRecordsService;
        this._$indicatorStatusService = $indicatorStatusService;
        this._$scope = $scope;
        this._$location = $location;
        this._$modalService = $modalService;
        this._$segmentsService = $segmentsService;
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._$tableService = $tableService;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._$uibModal = $uibModal;
        this._statisticsProvider = statisticsProvider;

        this.companyId = $auth.getPayload().company;

        this.idStatisticsOrigin = this._$stateParams.idStatisticsOrigin;
        this.idStatisticsToCompare = this._$stateParams.idStatisticsToCompare;

        this.statisticsOrigin = {};
        this.statisticsToCompare = {};

        this.metaDataStats = [];
        this.statisticsControl = [];
        this.controlSummaryOrigin = [];
        this.controlSummaryToCompare = [];

        this.graphToCompare = 'all';
        this.isDownloaded = false;

        //Records table compare
        this.newTableOrigin = [];
        this.newTableToCompare = [];

        // housing tree
        this.housingTree = null;
        // plug tree
        this.plugTree = null;

        this.hasSegmentTrDi = false;
        this.hasSegmentDi = false;

        //Stat report
        this.filteredStatisticsCompared = [];
        this.statisticsCompared = [];

        //Boolean
        this.loading = true;
        this.hasIndicatorsOpen = false;
        this.hasDepositsOpen = false;
        this.hasReportsOpen = false;
        this.hasIDClientData = false;
        this.showValues = false;
    }

    async $onInit() {
        this._$scope.$emit('keepPreviousNavigation', {
            newPage: [
                {
                    key: 'comparaison',
                    title: this._translate('statisticsPage.comparison', { TYPE: FMEJobEnum.CONTROL }),
                    href: this._$location.path(),
                },
            ],
            defaultPrevious: {
                title: this._translate('shared.controlStatistic'),
                href: this._$state.href('app.statisticsHome'),
                key: 'statistics',
            },
            allowedPreviousKeys: ['statistics'],
        });

        this.hasLinkBetweenGridsEnabled = await this._$companySettings.getCompanyHasLinkBetweenGridsEnabled(
            this.companyId,
        );

        await this.getStatistics();
        this.preparedMetadataStatistics();
        await this.getDepositList();
        this.initIndicators();
        this.initControlPoint();
        this.prepareControlPointChart();
        this.prepareNbControlChart();
        this.prepareRecordsIndicatorChart();

        this.initValueFilter();
        this.initListFilter();
        this.initWatchFilters();
        // Timeout necessary as AngularJs doesn't trigger digest cycle from async / await method or function
        this._$timeout(() => {
            this.loading = false;
        });

        // Assuming that data models of the statistical deliverables are the same, we get the first deliverable data model slug.
        this.isGraceV3 = this._$dataModelService.isDataGraceTHDV3(
            this.statisticsOrigin?.deposits[0]?.deposit ?? this.statisticsToCompare?.deposits[0]?.deposit,
        );
    }

    async getStatistics() {
        const [statisticsOrigin, statisticsToCompare] = await Promise.all([
            this._statisticsProvider.get(this.idStatisticsOrigin),
            this._statisticsProvider.get(this.idStatisticsToCompare),
        ]);

        this.statisticsOrigin = statisticsOrigin;
        this.statisticsOrigin.networkSegments = this._$segmentsService.setNetworkSegments(
            this.statisticsOrigin.networkSegments,
        );
        this.statisticsToCompare = statisticsToCompare;
        this.statisticsToCompare.networkSegments = this._$segmentsService.setNetworkSegments(
            this.statisticsToCompare.networkSegments,
        );

        const statsOrigin = this.flattenControls(this.statisticsOrigin.aggregated);
        const statsToCompare = this.flattenControls(this.statisticsToCompare.aggregated);

        const filteredStatisticsOrigin = this._$controlStatisticsService
            .groupByControlPoint(statsOrigin)
            .map((control) => this.fetchControlData(control));

        const filteredStatisticsToCompare = this._$controlStatisticsService
            .groupByControlPoint(statsToCompare)
            .map((control) => this.fetchControlData(control));

        this.filteredTotalOrigin = filteredStatisticsOrigin.map((el) => {
            return {
                id: el.id,
                controlPoint: el.controlPoint,
                idClient: el.idClient,
                severity: el.severity,
                description: el.description,
                possibleCause: el.possibleCause,
                errorCode: el.errorCode,
                recipientOperatingMode: el.recipientOperatingMode,
                targetSIOperatingMode: el.targetSIOperatingMode,
                total: el.total,
            };
        });

        this.filteredTotalToCompare = filteredStatisticsToCompare.map((el) => {
            return {
                id: el.id,
                description: el.description,
                total: el.total,
            };
        });

        this.findControlsCompared = this.filteredTotalOrigin
            .filter((elOrigin) => {
                return this.filteredTotalToCompare.every((elToCompare) => {
                    if (elOrigin.id === elToCompare.id) {
                        return elOrigin.id === elToCompare.id;
                    } else {
                        return elOrigin.id;
                    }
                });
            })
            .map((el) => {
                const totalCompare = this.filteredTotalToCompare.find((el2) => {
                    let controlExist = true;
                    let isSame = false;
                    const onlyOrigin = el2.total.errors === 0 && el2.total.total === 0;
                    const onlyToCompare = el.total.errors === 0 && el.total.total === 0;
                    this.totalDiff = [];

                    if (el2.id === el.id) {
                        if (
                            el.total.errors === 0 &&
                            el.total.total === 0 &&
                            el2.total.errors === 0 &&
                            el2.total.total === 0
                        ) {
                            controlExist = false;
                        }

                        if (el.total.errors === el2.total.errors && el.total.total === el2.total.total) {
                            isSame = true;
                        }

                        const errors = el2.total.errors - el.total.errors;
                        const total = el2.total.total - el.total.total;

                        const indicControl = this.getDiffControls(el.total.total, el2.total.total);
                        const indicError = this.getDiffErrors(
                            el.total.errors,
                            el2.total.errors,
                            el.total.total,
                            el2.total.total,
                        );

                        this.totalDiff = {
                            id: el.id,
                            controlPoint: el.controlPoint,
                            idClient: el.idClient,
                            severity: el.severity,
                            description: el.description,
                            errors,
                            total,
                            indicControl,
                            indicError,
                            controlExist,
                            isSame,
                            onlyOrigin,
                            onlyToCompare,
                        };

                        return this.totalDiff;
                    }
                });

                return {
                    ...el,
                    totalCompare,
                    evolution: this.totalDiff,
                };
            });

        this.statisticsCompared = this.findControlsCompared.filter((control) => {
            if (control.evolution) {
                return control.evolution.controlExist === true;
            } else {
                return null;
            }
        });

        // Toggle
        this.tableDetail = this._$tableService.detail();
    }

    flattenControls(controls) {
        return controls.reduce((acc, item) => {
            const { controls, ...control } = item;
            const spread = controls.map((c) => {
                return { ...c, ...control };
            });

            return [...acc, ...spread];
        }, []);
    }

    //filters
    initWatchFilters() {
        this._$scope.$watchGroup(
            [
                '$ctrl.filters.severity',
                '$ctrl.filters.statusControl',
                '$ctrl.filters.statusError',
                '$ctrl.filters.statusDiff',
                '$ctrl.filters.search',
            ],
            () => this.filterStaticsCompare(),
        );
    }

    filterStaticsCompare() {
        this.updateUrlFilters();

        this.filteredStatisticsCompared = this.statisticsCompared
            .filter(this.filterCritical())
            .filter(this.filterSearch())
            .filter(this.filterStatusDiff())
            .filter(this.filterStatusControl())
            .filter(this.filterStatusError());

        this.hasIDClientData = this.filteredStatisticsCompared.some((control) => control.idClient);

        this.showButtonCancelFilter = HelpersService.isFilterEmpty(this.filters);
    }

    updateUrlFilters() {
        const { severity, statusControl, statusError, statusDiff, search } = this.filters;

        this._$state.go('.', {
            severity,
            statusControl,
            statusError,
            statusDiff,
            search,
        });
    }

    initFilterCritical() {
        this.severityList = [
            {
                key: 'blocking',
                value: this._humanizeSeverity('blocking'),
            },
            { key: 'major', value: this._humanizeSeverity('major') },
            { key: 'minor', value: this._humanizeSeverity('minor') },
        ];
    }

    initFilterEvolution() {
        const filterDiffs = {
            gt: this._translate('statisticComparisonEvolution.filterImprovement'),
            eq: this._translate('statisticComparisonEvolution.filterEqual'),
            lt: this._translate('statisticComparisonEvolution.filterRegression'),
        };

        this.filterOptions = {
            diffs: Object.keys(filterDiffs).map((key) => ({
                key,
                value: filterDiffs[key],
            })),
        };
    }

    initFilterSelectListData() {
        const selectListData = {
            isSame: this._translate('shared.same'),
            isDifferent: this._translate('shared.different'),
            onlyOrigin: this._translate('shared.onlyOrigin'),
            onlyToCompare: this._translate('shared.onlyToCompare'),
        };

        this.filterOptionsStatus = {
            status: Object.keys(selectListData).map((key) => ({
                key,
                value: selectListData[key],
            })),
        };
    }

    initValueFilter() {
        this.filters = {
            severity: this._$state.params.severity || '',
            statusControl: this._$state.params.statusControl || '',
            statusError: this._$state.params.statusError || '',
            statusDiff: this._$state.params.statusDiff || '',
            search: this._$state.params.search || '',
        };
    }

    initListFilter() {
        this.initFilterCritical();
        this.initFilterEvolution();
        this.initFilterSelectListData();
        this.showButtonCancelFilter = HelpersService.isFilterEmpty(this.filters);
    }

    filterCritical() {
        const { severity } = this.filters;

        return (control) => severity === '' || control.severity === severity || control.evolution.severity === severity;
    }

    filterSearch() {
        const { search } = this.filters;
        const wordSearch = search.toLowerCase();

        return ({ controlPoint, description }) =>
            wordSearch === '' || controlPoint.includes(wordSearch) || description.toLowerCase().includes(wordSearch);
    }

    filterStatusControl() {
        const { statusControl } = this.filters;

        return ({ evolution }) => {
            switch (statusControl) {
                case 'lt':
                    return evolution.indicControl > 0;
                case 'eq':
                    return evolution.indicControl === 0;
                case 'gt':
                    return evolution.indicControl < 0;
                default:
                    return true;
            }
        };
    }

    filterStatusError() {
        const { statusError } = this.filters;

        return ({ evolution }) => {
            switch (statusError) {
                case 'lt':
                    return evolution.indicError < 0;
                case 'eq':
                    return evolution.indicError === 0;
                case 'gt':
                    return evolution.indicError > 0;
                default:
                    return true;
            }
        };
    }

    filterStatusDiff() {
        const { statusDiff } = this.filters;

        return ({ evolution }) => {
            switch (statusDiff) {
                case 'isSame':
                    return evolution.isSame === true;
                case 'isDifferent':
                    return evolution.isSame === false;
                case 'onlyOrigin':
                    return evolution.onlyOrigin === true;
                case 'onlyToCompare':
                    return evolution.onlyToCompare === true;
                default:
                    return true;
            }
        };
    }

    cleanFilter(filters) {
        HelpersService.cleanFilter(filters);
    }

    preparedMetadataStatistics() {
        this.metaDataStats = [this.statisticsOrigin, this.statisticsToCompare];
    }

    async getDepositList() {
        await Promise.all([
            this._statisticsProvider.getGroupedByDeposits(this.idStatisticsOrigin),
            this._statisticsProvider.getGroupedByDeposits(this.idStatisticsToCompare),
        ])
            .then((data) => {
                this.initDepositsListOrigin = data[0];
                this.initDepositsListToCompare = data[1];

                const addOriginId = this.fetchIdStatToDepositList(this.initDepositsListOrigin, this.idStatisticsOrigin);
                const addToCompareId = this.fetchIdStatToDepositList(
                    this.initDepositsListToCompare,
                    this.idStatisticsToCompare,
                );

                const removeVersionOriginId = this.removeVersion(addOriginId);
                const removeVersionToCompareId = this.removeVersion(addToCompareId);

                const depositListOrigin = removeVersionOriginId?.length
                    ? this.fetchDeposits(removeVersionOriginId)
                    : [];
                const depositListToCompare = removeVersionToCompareId?.length
                    ? this.fetchDeposits(removeVersionToCompareId)
                    : [];

                this.filteredDepositsListOrigin = [...depositListOrigin];
                this.filteredDepositsListToCompare = [...depositListToCompare];

                const sameDeposit = this.getDepositListShared(
                    this.filteredDepositsListOrigin,
                    this.filteredDepositsListToCompare,
                );

                const onlyOrigin = this.getDepositListUnique1(
                    this.filteredDepositsListOrigin,
                    this.filteredDepositsListToCompare,
                );

                const onlyToCompare = this.getDepositListUnique2(
                    this.filteredDepositsListToCompare,
                    this.filteredDepositsListOrigin,
                );

                if (onlyOrigin) {
                    this.filteredDepositOnlyInOrigin = onlyOrigin;
                } else {
                    return;
                }

                if (onlyToCompare) {
                    this.filteredDepositOnlyInToCompare = onlyToCompare;
                } else {
                    return;
                }

                this.filteredSameDeposit = sameDeposit.map((deposit) => {
                    const compared = deposit.depositCompare;

                    let netWorkSegmentCompared = [];

                    this.resultByseverity = {
                        blocking: compared.diff.blocking - deposit.diff.blocking,
                        major: compared.diff.major - deposit.diff.major,
                        minor: compared.diff.minor - deposit.diff.minor,
                    };

                    if (compared.networkSegments) {
                        netWorkSegmentCompared = compared.networkSegments;
                    }

                    return {
                        ...deposit,
                        netWorkSegmentCompared,
                        diff2: this.resultByseverity,
                    };
                });
            })
            .then(() => {
                this.loading = false;
            })
            .catch(() => {
                this.loading = false;
            });
    }

    removeVersion(data) {
        if (!data.length) {
            return [];
        }

        const regexRemoveVersionDepositName = new RegExp('(.*)_cv([0-9]+)');

        return data.reduce((acc, el) => {
            const nameWithoutVersion = regexRemoveVersionDepositName.exec(el.name);
            if (!nameWithoutVersion) {
                return acc;
            }

            return [
                ...acc,
                {
                    ...el,
                    name: nameWithoutVersion[1],
                },
            ];
        }, []);
    }

    fetchDeposits(data) {
        return data.map((deposit) => ({
            ...deposit,
            diff: {
                minor: this.getDiff(deposit, 'minor'),
                major: this.getDiff(deposit, 'major'),
                blocking: this.getDiff(deposit, 'blocking'),
                total: this.getDiff(deposit),
            },
            total: {
                errors: this.sumControls(deposit, 'errors'),
                total: this.sumControls(deposit, 'total'),
            },
        }));
    }

    fetchIdStatToDepositList(data, idStat) {
        return data.map((deposit) => {
            return {
                ...deposit,
                networkSegments: deposit.networkSegments
                    ? this._$segmentsService.setNetworkSegments(deposit.networkSegments)
                    : [],
                idStat: idStat,
            };
        });
    }

    getDepositListShared(data1, data2) {
        return data1
            .filter((elMax) => {
                return data2.some((elMin) => {
                    return elMax.name === elMin.name;
                });
            })
            .map((el) => {
                const depositCompare = data2.find((el2) => {
                    return el2.name === el.name;
                });

                const exist = true;
                const onlyOrigin = false;
                const onlyToCompare = false;

                return {
                    ...el,
                    depositCompare,
                    exist,
                    onlyOrigin,
                    onlyToCompare,
                };
            });
    }

    getDepositListUnique1(data1, data2) {
        return data1
            .filter((elMax) => {
                return !data2.some((elMin) => {
                    return elMax.name === elMin.name;
                });
            })
            .map((el) => {
                const exist = false;
                const onlyOrigin = true;
                const onlyToCompare = false;

                return {
                    ...el,
                    exist,
                    onlyOrigin,
                    onlyToCompare,
                };
            });
    }

    getDepositListUnique2(data2, data1) {
        return data2
            .filter((elMax) => {
                return !data1.some((elMin) => {
                    return elMax.name === elMin.name;
                });
            })
            .map((el) => {
                const exist = false;
                const onlyOrigin = false;
                const onlyToCompare = true;

                return {
                    ...el,
                    exist,
                    onlyOrigin,
                    onlyToCompare,
                };
            });
    }

    getDiff(deposit, severity) {
        // Fetch lastVersion
        return this.sumDepositControls(deposit, { type: 'errors', severity });
    }

    sumControls(deposit, type = 'errors') {
        const { controls } = deposit;

        return controls.reduce((acc, control) => {
            return acc + control.total[type];
        }, 0);
    }

    /**
     * @param deposit
     * @param {'errors'|'total'}type
     * @param severity
     * @return {*}
     */
    sumDepositControls(deposit, { type = 'errors', severity = null }) {
        let { controls } = deposit;
        if (severity) {
            controls = controls.filter((c) => c.severity === severity);
        }

        return controls.reduce((acc, control) => acc + control.total[type], 0);
    }

    initControlPoint() {
        this.statisticsControl = [this.statisticsOrigin.aggregated, this.statisticsToCompare.aggregated];

        // Fetch data for the generic control chart component
        this.controlSummaryOrigin = this.statisticsControl[0].map((stat) => {
            const { total, severity } = this.fetchControlData(stat);

            return {
                severity,
                faults: total.errors,
                success: total.success,
                total: total.total,
                warning: total.warning,
            };
        });

        this.controlSummaryToCompare = this.statisticsControl[1].map((stat) => {
            const { total, severity } = this.fetchControlData(stat);

            return {
                severity,
                faults: total.errors,
                success: total.success,
                total: total.total,
                warning: total.warning,
            };
        });
    }

    getRatio({ total, errors }) {
        if (total === 0) {
            return null;
        }

        const valid = total - errors;

        return (valid / (valid + errors)) * 100;
    }

    getRatioText(total) {
        const value = this.getRatio(total);
        if (value === null) {
            return '-';
        }

        return [`${value.toFixed(2)}%`].join('');
    }

    getDiffControls(total1, total2) {
        if (!total1 || total1 === 0 || !total2 || total2 === 0) {
            return null;
        }

        return ((total1 - total2) / total1) * 100;
    }

    getDiffErrors(error1, error2, total1, total2) {
        if (error1 === 0 && error2 === 0) {
            return 0;
        }

        if (!total1 || !total2 || total2 === 0) {
            return 0;
        }

        if (error1 === 0 && error2 > 0) {
            return (1 - (error2 + 1)) * 100;
        }

        return ((error1 - error2) / error1) * 100;
    }

    fetchControlData(control) {
        const { controls } = control;
        const total = {
            total: controls.reduce((acc, c) => acc + c.total.total, 0) || 0,
            errors: controls.reduce((acc, c) => acc + c.total.errors, 0) || 0,
            success: controls.reduce((acc, c) => acc + c.total.success, 0) || 0,
            warning: controls.reduce((acc, c) => acc + c.total.warning, 0) || 0,
        };
        //Not used
        total.ratio = this.getRatio(total);

        return {
            id: control.name,
            ...control,
            controls,
            total,
        };
    }

    prepareControlPointChart() {
        this.resultControlPointDataOrigin = this._$indicatorControlPointService.computeStatisticChartData(
            this.controlSummaryOrigin,
            this.hasLinkBetweenGridsEnabled,
        );

        this.resultControlPointDataToCompare = this._$indicatorControlPointService.computeStatisticChartData(
            this.controlSummaryToCompare,
            this.hasLinkBetweenGridsEnabled,
        );

        if (
            !Object.keys(this.resultControlPointDataOrigin).length &&
            !Object.keys(this.resultControlPointDataToCompare).length
        ) {
            this.oldControlPoint = true;
        }

        this.oldControlPoint = false;

        this.controlPointData = this._$indicatorControlPointService.loadGlobalControlChart(
            this.resultControlPointDataOrigin,
            this.resultControlPointDataToCompare,
            this.controlSummaryOrigin.length || 0,
            this.controlSummaryToCompare.length || 0,
            true,
            this.hasLinkBetweenGridsEnabled,
        );

        this.controlPointBySeverity = this._$indicatorControlPointService.loadControlPointBySeverityChart(
            this.resultControlPointDataOrigin,
            this.resultControlPointDataToCompare,
            true,
            this.hasLinkBetweenGridsEnabled,
        );
    }

    prepareNbControlChart() {
        const resultNbControlDataOrigin = this._$indicatorNumberControlService.fetchNbControlData(
            this.controlSummaryOrigin,
            true,
        );
        const resultNbControlDataToCompare = this._$indicatorNumberControlService.fetchNbControlData(
            this.controlSummaryToCompare,
            true,
        );

        if (!Object.keys(resultNbControlDataOrigin).length && !Object.keys(resultNbControlDataToCompare).length) {
            this.oldNumberControl = true;
        }

        this.oldNumberControl = false;

        this.anomalyVsSuccessData = this._$indicatorNumberControlService.loadAnomalyVsSuccessChart(
            resultNbControlDataOrigin,
            resultNbControlDataToCompare,
            true,
        );

        this.anomalySuccessNbBySeverity = this._$indicatorNumberControlService.loadAnomalySuccessBySeverityChart(
            resultNbControlDataOrigin,
            resultNbControlDataToCompare,
            true,
        );
    }

    toggleIndicatorsVisibility() {
        this.hasIndicatorsOpen = !this.hasIndicatorsOpen;
    }

    toggleDepositsVisibility() {
        this.hasDepositsOpen = !this.hasDepositsOpen;
    }

    toggleReportsVisibility() {
        this.hasReportsOpen = !this.hasReportsOpen;
    }

    getCableBtiEntranceOrOutPut(cableBti, direction) {
        return cableBti.filter((item) => item.position === direction).length;
    }

    findNetworkSegment(indic, segmentType) {
        let segmentsToFind = indic.includes('TR') && indic.includes('DI');

        if (segmentType === 'DI') {
            segmentsToFind = indic.includes('DI') && !indic.includes('TR');
        }

        return segmentsToFind;
    }

    getNatureComparedDifference(origin, compared, isNatureIndicator) {
        let result = {};
        const comparedKeys = Object.keys(compared);

        Object.entries(origin).forEach(([key, value]) => {
            if (comparedKeys.includes(key)) {
                result = {
                    ...result,
                    [key]: isNatureIndicator
                        ? {
                              color: value.color,
                              icon: value.icon,
                              title: value.title,
                              total: value.count - compared[key].count,
                              value1: value.count,
                              value2: compared[key].count,
                          }
                        : {
                              backgroundColor: value.backgroundColor,
                              title: value.title,
                              total: value.count - compared[key].count,
                              value1: value.count,
                              value2: compared[key].count,
                          },
                };
            }

            if (!comparedKeys.includes(key)) {
                result = {
                    ...result,
                    [key]: isNatureIndicator
                        ? {
                              color: value.color,
                              icon: value.icon,
                              title: value.title,
                              total: value.count,
                              value1: value.count,
                              value2: 0,
                          }
                        : {
                              backgroundColor: value.backgroundColor,
                              title: value.title,
                              total: value.count,
                              value1: value.count,
                              value2: 0,
                          },
                };
            }
        });

        Object.entries(compared).forEach(([key, value]) => {
            if (!Object.keys(result).includes(key)) {
                result = {
                    ...result,
                    [key]: isNatureIndicator
                        ? {
                              color: value.color,
                              icon: value.icon,
                              title: value.title,
                              total: value.count,
                              value1: 0,
                              value2: value.count,
                          }
                        : {
                              backgroundColor: value.backgroundColor,
                              title: value.title,
                              total: value.count,
                              value1: 0,
                              value2: value.count,
                          },
                };
            }
        });

        return result;
    }

    getPathwayComparedDifference(origin, compared) {
        let result = {};
        const comparedKeys = Object.keys(compared);

        Object.entries(origin).forEach(([key, value]) => {
            if (comparedKeys.includes(key)) {
                result = {
                    ...result,
                    [key]: {
                        colorGeom: value.colorGeom,
                        colorReal: value.colorReal,
                        countGeom: value.countGeom - compared[key].countGeom,
                        countReal: value.countReal - compared[key].countReal,
                        icon: value.icon,
                        title: value.title,
                        valueGeom1: value.countGeom,
                        valueGeom2: compared[key].countGeom,
                        valueReal1: value.countReal,
                        valueReal2: compared[key].countReal,
                    },
                };
            }

            if (!comparedKeys.includes(key)) {
                result = {
                    ...result,
                    [key]: {
                        colorGeom: value.colorGeom,
                        colorReal: value.colorReal,
                        countGeom: value.countGeom,
                        countReal: value.countReal,
                        icon: value.icon,
                        title: value.title,
                        valueGeom1: value.countGeom,
                        valueGeom2: 0,
                        valueReal1: value.countReal,
                        valueReal2: 0,
                    },
                };
            }
        });

        Object.entries(compared).forEach(([key, value]) => {
            if (!Object.keys(result).includes(key)) {
                result = {
                    ...result,
                    [key]: {
                        colorGeom: value.colorGeom,
                        colorReal: value.colorReal,
                        countGeom: value.countGeom,
                        countReal: value.countReal,
                        icon: value.icon,
                        title: value.title,
                        valueGeom1: 0,
                        valueGeom2: value.countGeom,
                        valueReal1: 0,
                        valueReal2: value.countReal,
                    },
                };
            }
        });

        return result;
    }

    getOpticalCableSupportComparedDifference(origin, compared) {
        let result = {};
        const comparedKeys = Object.keys(compared);

        Object.entries(origin).forEach(([key, value]) => {
            if (comparedKeys.includes(key)) {
                result = {
                    ...result,
                    [key]: {
                        colorCarto: value.colorCarto,
                        colorSaisie: value.colorSaisie,
                        icon: value.icon,
                        title: value.title,
                        data_geom: value.data_geom - compared[key].data_geom,
                        data_geom1: value.data_geom,
                        data_geom2: compared[key].data_geom,
                        data_saisie: value.data_saisie - compared[key].data_saisie,
                        data_saisie1: value.data_saisie,
                        data_saisie2: compared[key].data_saisie,
                    },
                };
            }

            if (!comparedKeys.includes(key)) {
                result = {
                    ...result,
                    [key]: {
                        colorCarto: value.colorCarto,
                        colorSaisie: value.colorSaisie,
                        icon: value.icon,
                        title: value.title,
                        data_geom: value.data_geom,
                        data_geom1: value.data_geom,
                        data_geom2: 0,
                        data_saisie: value.data_saisie,
                        data_saisie1: value.data_saisie,
                        data_saisie2: 0,
                    },
                };
            }
        });

        Object.entries(compared).forEach(([key, value]) => {
            if (!Object.keys(result).includes(key)) {
                result = {
                    ...result,
                    [key]: {
                        colorCarto: value.colorCarto,
                        colorSaisie: value.colorSaisie,
                        icon: value.icon,
                        title: value.title,
                        data_geom: value.data_geom,
                        data_geom1: 0,
                        data_geom2: value.data_geom,
                        data_saisie: value.data_saisie,
                        data_saisie1: 0,
                        data_saisie2: value.data_saisie,
                    },
                };
            }
        });

        return result;
    }

    initIndicConnectivity(indic) {
        indic.connectivityDiff = {};
        indic.connectivityDiff.nbSuf = 0;
        indic.connectivityDiff.nbSufConnected = 0;
        indic.connectivityDiff.nbSufRatio = 0;
        indic.connectivityDiff.noData = true;

        return {
            nbSuf: indic.connectivityDiff.nbSuf,
            nbSufConnected: indic.connectivityDiff.nbSufConnected,
            nbSufRatio: indic.connectivityDiff.nbSufRatio,
            noData: indic.connectivityDiff.noData,
        };
    }

    getAvgPboIndicator(origin, compared) {
        return {
            avgOrigin: origin,
            avgToCompare: compared,
            diff: Math.abs(origin - compared),
        };
    }

    getCableBtiIndicator(origin, compared) {
        return {
            cableBtiOriginEntrance: origin ? this.getCableBtiEntranceOrOutPut(origin, BtiPositionEnum.IN) : 0,
            cableBtiOriginOutPut: origin ? this.getCableBtiEntranceOrOutPut(origin, BtiPositionEnum.OUT) : 0,
            cableBtiToCompareEntrance: compared ? this.getCableBtiEntranceOrOutPut(compared, BtiPositionEnum.IN) : 0,
            cableBtiToCompareOutPut: compared ? this.getCableBtiEntranceOrOutPut(compared, BtiPositionEnum.OUT) : 0,
        };
    }

    getNbBtiIndicator(origin, compared) {
        return {
            nbBtiOrigin: origin,
            nbBtiToCompare: compared,
            diff: Math.abs(origin.length - compared.length),
        };
    }

    getSroNroIndicator(origin, compared) {
        return {
            origin: origin?.length ? origin.reduce((acc, sro) => acc + sro.count, 0) : 0,
            compared: compared?.length ? compared.reduce((acc, sro) => acc + sro.count, 0) : 0,
        };
    }

    getJunctionBoxSum(array) {
        return array.reduce((acc, value) => acc + parseInt(value.nbBpDerivation, 10), 0);
    }

    initIndicators() {
        if (!this.statisticsOrigin.indicators && !this.statisticsToCompare.indicators) {
            this.isOld = true;

            return;
        }

        this.isOld = false;

        this.findIndicatorsOrigin = this.statisticsOrigin.indicators;
        this.findIndicatorsToCompare = this.statisticsToCompare.indicators;

        this.isOldNewPlug = true;
        this.isOldNroSroTypePhy = true;
        this.isOldCableBti = true;
        this.isOldNbBti = true;
        this.isOldNature = true;
        this.isOldPathway = true;
        this.isOldOpticalCableSupport = true;
        this.isOldLgCable = true;
        this.hasJunctionBox = false;

        // prepare avg-pbo
        if (this.findIndicatorsOrigin.avgpbo || this.findIndicatorsToCompare.avgpbo) {
            this.avgPBODiff = this.getAvgPboIndicator(
                this.findIndicatorsOrigin.avgpbo ?? 0,
                this.findIndicatorsToCompare.avgpbo ?? 0,
            );
        }

        //prepare connectivity
        if (this.findIndicatorsOrigin.connectivity || this.findIndicatorsToCompare.connectivity) {
            this.connectivityDiff = {};

            this.connectivityDiff.connectOrigin =
                this.findIndicatorsOrigin.connectivity ?? this.initIndicConnectivity(this.findIndicatorsOrigin);
            this.connectivityDiff.connectToCompare =
                this.findIndicatorsToCompare.connectivity ?? this.initIndicConnectivity(this.findIndicatorsToCompare);
        }

        // housing tree
        if (this.findIndicatorsOrigin.newPlug || this.findIndicatorsToCompare.newPlug) {
            this.isOldNewPlug = false;

            this.findIndicatorsOrigin.newPlug =
                this.findIndicatorsOrigin.newPlug ??
                this._$indicatorNewPlugService.initIndicNewPlug(this.findIndicatorsOrigin);

            this.findIndicatorsToCompare.newPlug =
                this.findIndicatorsToCompare.newPlug ??
                this._$indicatorNewPlugService.initIndicNewPlug(this.findIndicatorsToCompare);

            this.housingTree = this._$indicatorNewPlugService.initHousingTree(
                this.findIndicatorsOrigin.newPlug,
                this.findIndicatorsToCompare.newPlug,
                true,
            );

            this.plugTree = this._$indicatorNewPlugService.initPlugTree(
                this.findIndicatorsOrigin.newPlug,
                this.findIndicatorsToCompare.newPlug,
                true,
            );
        }

        // housing type
        if (this.findIndicatorsOrigin.housing || this.findIndicatorsToCompare.housing) {
            const housingOrigin =
                this.findIndicatorsOrigin.housing ?? this.initIndicHousingType(this.findIndicatorsOrigin);
            const housingToCompare =
                this.findIndicatorsToCompare.housing ?? this.initIndicHousingType(this.findIndicatorsToCompare);

            this.housingDiff = {
                individualOrigin: housingOrigin.individual,
                individualToCompare: housingToCompare.individual,
                communalOrigin: housingOrigin.communal,
                communalToCompare: housingToCompare.communal,
            };
        }

        // prepare nbBox
        if (
            this.findIndicatorsOrigin.pbo ||
            this.findIndicatorsOrigin.bpe ||
            this.findIndicatorsOrigin.type_ebp_pto ||
            this.findIndicatorsToCompare.pbo ||
            this.findIndicatorsToCompare.bpe ||
            this.findIndicatorsToCompare.type_ebp_pto
        ) {
            this.nbBoxDiff = [
                {
                    value1: this.findIndicatorsOrigin.pbo ?? 0,
                    value2: this.findIndicatorsToCompare.pbo ?? 0,
                    id: 'pbo',
                },
                {
                    value1: this.findIndicatorsOrigin.bpe ?? 0,
                    value2: this.findIndicatorsToCompare.bpe ?? 0,
                    id: 'bpe',
                },
                {
                    value1: this.findIndicatorsOrigin.type_ebp_pto ?? 0,
                    value2: this.findIndicatorsToCompare.type_ebp_pto ?? 0,
                    id: 'pto',
                },
            ];
        }

        // Junction box
        if (this.findIndicatorsOrigin.junctionBox?.length || this.findIndicatorsToCompare.junctionBox?.length) {
            this.hasJunctionBox = true;

            this.cleanDataOrigin = this.findIndicatorsOrigin.junctionBox?.length
                ? this.findIndicatorsOrigin.junctionBox.filter((value) => value.nbBpDerivation > 0)
                : [];
            this.cleanDataToCompare = this.findIndicatorsToCompare.junctionBox?.length
                ? this.findIndicatorsToCompare.junctionBox.filter((value) => value.nbBpDerivation > 0)
                : [];

            this.junctionBoxInfo = {
                name: 'BD',
                color: '#ffb935',
                value1: this.getJunctionBoxSum(this.cleanDataOrigin) ?? 0,
                value2: this.getJunctionBoxSum(this.cleanDataToCompare) ?? 0,
            };
        }

        //Prepare SRO physical type && Prepare NRO physical type
        if (
            (this.findIndicatorsOrigin.sroTypePhy?.length || this.findIndicatorsToCompare.sroTypePhy?.length) &&
            (this.findIndicatorsOrigin.nroTypePhy?.length || this.findIndicatorsToCompare.nroTypePhy?.length)
        ) {
            this.isOldNroSroTypePhy = false;

            this.sumSro = this.getSroNroIndicator(
                this.findIndicatorsOrigin.sroTypePhy,
                this.findIndicatorsToCompare.sroTypePhy,
            );

            this.sumNro = this.getSroNroIndicator(
                this.findIndicatorsOrigin.nroTypePhy,
                this.findIndicatorsToCompare.nroTypePhy,
            );
        }

        // Distance indicator
        this.findIndicatorsOrigin.hasSegmentTrDiOrigin = this.findNetworkSegment(
            this.statisticsOrigin.networkSegments,
            'TR',
        );
        this.findIndicatorsToCompare.hasSegmentTrDiToCompare = this.findNetworkSegment(
            this.statisticsToCompare.networkSegments,
            'TR',
        );

        this.displayTRChart =
            this.findIndicatorsOrigin.hasSegmentTrDiOrigin || this.findIndicatorsToCompare.hasSegmentTrDiToCompare;

        this.findIndicatorsOrigin.hasSegmentDiOrigin = this.findNetworkSegment(
            this.statisticsOrigin.networkSegments,
            'DI',
        );

        this.findIndicatorsToCompare.hasSegmentDiToCompare = this.findNetworkSegment(
            this.statisticsToCompare.networkSegments,
            'DI',
        );

        this.displayDIChart =
            this.findIndicatorsOrigin.hasSegmentDiOrigin || this.findIndicatorsToCompare.hasSegmentDiToCompare;

        if (
            this.findIndicatorsOrigin.distanceBetweenNroPbo?.length ||
            this.findIndicatorsToCompare.distanceBetweenNroPbo?.length
        ) {
            this.oldDistanceNroPbo = false;

            if (!this.findIndicatorsOrigin.hasSegmentTrDiOrigin) {
                this.findIndicatorsOrigin.distanceBetweenNroPbo = [];
            }

            if (!this.findIndicatorsToCompare.hasSegmentTrDiToCompare) {
                this.findIndicatorsToCompare.distanceBetweenNroPbo = [];
            }

            this.distanceRangeNroPbo = this._$indicatorDistanceRangeService.preparedChartDistanceRangeMulti(
                this.findIndicatorsOrigin.distanceBetweenNroPbo ?? [],
                this.findIndicatorsToCompare.distanceBetweenNroPbo ?? [],
            );
        }

        if (
            this.findIndicatorsOrigin.distanceBetweenNroSro?.length ||
            this.findIndicatorsToCompare.distanceBetweenNroSro?.length
        ) {
            this.oldDistanceNroSro = false;

            if (!this.findIndicatorsOrigin.hasSegmentTrDiOrigin) {
                this.findIndicatorsOrigin.distanceBetweenNroSro = [];
            }

            if (!this.findIndicatorsToCompare.hasSegmentTrDiToCompare) {
                this.findIndicatorsToCompare.distanceBetweenNroSro = [];
            }

            this.distanceRangeNroSro = this._$indicatorDistanceRangeService.preparedChartDistanceRangeMulti(
                this.findIndicatorsOrigin.distanceBetweenNroSro ?? [],
                this.findIndicatorsToCompare.distanceBetweenNroSro ?? [],
            );
        }

        if (
            this.findIndicatorsOrigin.distanceBetweenSroPbo?.length ||
            this.findIndicatorsToCompare.distanceBetweenSroPbo?.length
        ) {
            this.oldDistanceSroPbo = false;

            if (!this.findIndicatorsOrigin.hasSegmentDiOrigin) {
                this.findIndicatorsOrigin.distanceBetweenSroPbo = [];
            }

            if (!this.findIndicatorsToCompare.hasSegmentDiToCompare) {
                this.findIndicatorsToCompare.distanceBetweenSroPbo = [];
            }

            this.distanceRangeSroPbo = this._$indicatorDistanceRangeService.preparedChartDistanceRangeMulti(
                this.findIndicatorsOrigin.distanceBetweenSroPbo ?? [],
                this.findIndicatorsToCompare.distanceBetweenSroPbo ?? [],
            );
        }

        // prepare nbBti
        if (this.findIndicatorsOrigin.nbBti?.length || this.findIndicatorsToCompare.nbBti?.length) {
            this.isOldNbBti = false;

            this.nbBtiDiff = this.getNbBtiIndicator(
                this.findIndicatorsOrigin.nbBti ?? [],
                this.findIndicatorsToCompare.nbBti ?? [],
            );
        }

        // prepare cableBti
        if (this.findIndicatorsOrigin.cableBti?.length || this.findIndicatorsToCompare.cableBti?.length) {
            this.isOldCableBti = false;
            this.cableBtiDiff = this.getCableBtiIndicator(
                this.findIndicatorsOrigin.cableBti,
                this.findIndicatorsToCompare.cableBti,
            );
        }

        // Prepare technical point
        if (
            (angular.isDefined(this.findIndicatorsOrigin.nature_type_phy) &&
                angular.isDefined(this.findIndicatorsOrigin.nature_type_phy?.nature_support) &&
                this.findIndicatorsOrigin.nature_type_phy.status?.length &&
                this.findIndicatorsOrigin.nature_type_phy.table?.length) ||
            (angular.isDefined(this.findIndicatorsToCompare.nature_type_phy) &&
                angular.isDefined(this.findIndicatorsToCompare.nature_type_phy?.nature_support) &&
                this.findIndicatorsToCompare.nature_type_phy.status?.length &&
                this.findIndicatorsToCompare.nature_type_phy.table?.length)
        ) {
            this.isOldNature = false;

            const natureOrigin = angular.isDefined(this.findIndicatorsOrigin.nature_type_phy)
                ? this._$indicatorStatusService.sumByTypePhy(this.findIndicatorsOrigin.nature_type_phy)
                : {};
            const natureToCompare = angular.isDefined(this.findIndicatorsToCompare.nature_type_phy)
                ? this._$indicatorStatusService.sumByTypePhy(this.findIndicatorsToCompare.nature_type_phy)
                : {};

            this.natureComparedDifference = this.getNatureComparedDifference(natureOrigin, natureToCompare, true);
        }

        // Prepare pathway
        if (
            (angular.isDefined(this.findIndicatorsOrigin.pathway) &&
                angular.isDefined(this.findIndicatorsOrigin.pathway?.pathway_support) &&
                this.findIndicatorsOrigin.pathway.status?.length &&
                this.findIndicatorsOrigin.pathway.table?.length) ||
            (angular.isDefined(this.findIndicatorsToCompare.pathway) &&
                angular.isDefined(this.findIndicatorsToCompare.pathway?.pathway_support) &&
                this.findIndicatorsToCompare.pathway.status?.length &&
                this.findIndicatorsToCompare.pathway.table?.length)
        ) {
            this.isOldPathway = false;

            const pathwaysOrigin = angular.isDefined(this.findIndicatorsOrigin.pathway)
                ? this.sumByPathwayTypePhy(this.findIndicatorsOrigin.pathway)
                : {};
            const pathwaysCompare = angular.isDefined(this.findIndicatorsToCompare.pathway)
                ? this.sumByPathwayTypePhy(this.findIndicatorsToCompare.pathway)
                : {};

            this.pathwayComparedDifference = this.getPathwayComparedDifference(pathwaysOrigin, pathwaysCompare);
            this.sumNbChemCompared = this.getSumNbChems(
                this.findIndicatorsOrigin.pathway?.table ?? [],
                this.findIndicatorsToCompare.pathway?.table ?? [],
            );
        }

        //  Prepare Optical cable support
        if (
            (angular.isDefined(this.findIndicatorsOrigin.opticalCableSupport) &&
                angular.isDefined(this.findIndicatorsOrigin.opticalCableSupport?.opticalSupports) &&
                this.findIndicatorsOrigin.opticalCableSupport.status?.length &&
                this.findIndicatorsOrigin.opticalCableSupport.table?.length) ||
            (angular.isDefined(this.findIndicatorsToCompare.opticalCableSupport) &&
                angular.isDefined(this.findIndicatorsToCompare.opticalCableSupport?.opticalSupports) &&
                this.findIndicatorsToCompare.opticalCableSupport.status?.length &&
                this.findIndicatorsToCompare.opticalCableSupport.table?.length)
        ) {
            this.isOldOpticalCableSupport = false;

            const opticalSupportsOrigin = angular.isDefined(this.findIndicatorsOrigin.opticalCableSupport)
                ? this._$indicatorOpticalCableSupportService.sumOpticalByType(
                      this.findIndicatorsOrigin.opticalCableSupport,
                  )
                : {};

            const opticalSupportsCompare = angular.isDefined(this.findIndicatorsToCompare.opticalCableSupport)
                ? this._$indicatorOpticalCableSupportService.sumOpticalByType(
                      this.findIndicatorsToCompare.opticalCableSupport,
                  )
                : {};

            this.opticalSupportComparedDiff = this.getOpticalCableSupportComparedDifference(
                opticalSupportsOrigin,
                opticalSupportsCompare,
            );
        }

        // prepare optical cable
        if (this.findIndicatorsOrigin.lgCable?.length || this.findIndicatorsToCompare.lgCable?.length) {
            this.isOldLgCable = false;

            const typesOrigin = this._$indicatorCapacityCableService.sumCapacityCable(
                this.findIndicatorsOrigin.lgCable,
            );
            const typesToCompare = this._$indicatorCapacityCableService.sumCapacityCable(
                this.findIndicatorsToCompare.lgCable,
            );

            this.typesComparedDifference = this.getNatureComparedDifference(typesOrigin, typesToCompare, false);

            this.sumNbCableCompared = this._$indicatorCapacityCableService.getSumNbCable(
                this.findIndicatorsOrigin.lgCable ?? [],
                this.findIndicatorsToCompare.lgCable ?? [],
                true,
            );

            this.newNbCables = angular.isNumber(this.sumNbCableCompared.origin || this.sumNbCableCompared.toCompare);
        }

        this.initRecordsIndicators();
    }

    initIndicHousingType(indic) {
        indic.housing = {};
        indic.housing.individual = 0;
        indic.housing.communal = 0;

        return {
            individual: indic.housing.individual,
            communal: indic.housing.communal,
        };
    }

    sumByPathwayTypePhy({ table }) {
        const pathways = {};

        table.forEach((entry) => {
            const { avancement, countReal, countGeom, nbChems } = entry;
            if (!pathways[avancement]) {
                const status = this._$indicatorOpticalCableSupportService.getData(avancement);
                this.newNbChems = angular.isNumber(nbChems);

                const title = (status && status.title) || '';
                const colorReal = (status && status.colorSaisie) || 'GREY';
                const colorGeom = (status && status.colorCarto) || '';
                const icon = (status && status.icon) || '';

                pathways[avancement] = {
                    countReal: 0,
                    countGeom: 0,
                    nbChems,
                    title,
                    colorReal,
                    colorGeom,
                    icon,
                };
            }

            pathways[avancement].countReal += countReal;
            pathways[avancement].countGeom += countGeom;
        });

        return pathways;
    }

    getSumNbChems(data1, data2) {
        const result1 = Object.values(data1).reduce((avancement, nbChems) => {
            for (const [key, value] of Object.entries(nbChems)) {
                if (!avancement[key]) {
                    avancement[key] = 0;
                }

                avancement[key] += value;
            }

            return avancement;
        }, {});

        const result2 = Object.values(data2).reduce((avancement, nbChems) => {
            for (const [key, value] of Object.entries(nbChems)) {
                if (!avancement[key]) {
                    avancement[key] = 0;
                }

                avancement[key] += value;
            }

            return avancement;
        }, {});

        return { origin: result1.nbChems, toCompare: result2.nbChems };
    }

    initRecordsIndicators() {
        // Fetch records data
        const tablesIndicatorOrigin = this.findIndicatorsOrigin.tables ?? [];
        const tablesIndicatorToCompare = this.findIndicatorsToCompare.tables ?? [];
        const abandonedObjectIndicatorOrigin = this.findIndicatorsOrigin.abandonedObject ?? [];
        const abandonedObjectIndicatorToCompare = this.findIndicatorsToCompare.abandonedObject ?? [];

        this.newTableOrigin = this._$indicatorRecordsService.initNewTable(
            tablesIndicatorOrigin,
            abandonedObjectIndicatorOrigin,
        );

        this.newTableToCompare = this._$indicatorRecordsService.initNewTable(
            tablesIndicatorToCompare,
            abandonedObjectIndicatorToCompare,
        );
    }

    getLegend(legendEmoji = [], legendLabel = []) {
        const res = [];
        for (let i = 0; i < legendEmoji.length; i++) {
            const emoji = legendEmoji[i];
            const label = legendLabel[i];
            res.push({ emoji, label });
        }

        return res;
    }

    prepareRecordsIndicatorChart() {
        if (!this.newTableOrigin.length && !this.newTableToCompare.length) {
            this.isOldRecords = true;
            this.loading = false;

            return;
        }

        this.isOldRecords = false;

        // Tables records data
        this.resultTablesDataOrigin = this._$indicatorRecordsService.fetchResultTables(this.newTableOrigin);
        this.resultTablesDataToCompare = this._$indicatorRecordsService.fetchResultTables(this.newTableToCompare);

        // Tree records indicator
        this.totalTableTreeCompared = this._$indicatorRecordsService.initTotalTableTree(
            this.resultTablesDataOrigin,
            this.resultTablesDataToCompare,
            !!this.newTableToCompare.length,
        );

        const recordsTableDiff = this._$indicatorRecordsService.getRecordsTableDiff(
            this.resultTablesDataOrigin,
            this.resultTablesDataToCompare,
        );

        const recordsTableOnlyOrigin = this._$indicatorRecordsService.getRecordsTable(
            this.resultTablesDataOrigin,
            this.resultTablesDataToCompare,
            false,
        );

        const recordsTableOnlyToCompare = this._$indicatorRecordsService.getRecordsTable(
            this.resultTablesDataToCompare,
            this.resultTablesDataOrigin,
            true,
        );

        const checkDifferences = [...recordsTableDiff, ...recordsTableOnlyOrigin, ...recordsTableOnlyToCompare];

        const abandonedObjects = !!(
            this.resultTablesDataOrigin.abandonedObjectSum || this.resultTablesDataToCompare.abandonedObjectSum
        );

        if (abandonedObjects) {
            this.abandonedObjectTreeCompared = this._$indicatorRecordsService.initAbandonedObjectTree(
                this.resultTablesDataOrigin,
                this.resultTablesDataToCompare,
                true,
            );
        }

        this.chartOptions = this._$indicatorRecordsService.initChartOption(abandonedObjects);

        this.chartData = this._$indicatorRecordsService.initChartData(
            this.resultTablesDataOrigin,
            this.resultTablesDataToCompare,
            abandonedObjects,
            true,
            false,
        );

        this.recordsMaxValue = this._$indicatorMaxValueService.findMaxValueFlatten(this.chartData);

        this.legendeOrigin = this.getLegend(
            this._$indicatorRecordsService.initLabelTooltipChart(checkDifferences, false, false),
            this._$indicatorRecordsService.initLabelTooltipChart(checkDifferences, false, true),
        );
        this.legendeToCompare = this.getLegend(
            this._$indicatorRecordsService.initLabelTooltipChart(checkDifferences, true, false),
            this._$indicatorRecordsService.initLabelTooltipChart(checkDifferences, true, true),
        );

        // Compare labels
        this.legendeCompared = this.getLegend(
            this._$indicatorRecordsService.initStatusLabelTooltipCompared(checkDifferences, false),
            this._$indicatorRecordsService.initStatusLabelTooltipCompared(checkDifferences, true),
        );

        // List difference
        const differencesFound = checkDifferences.filter((table) => table.onlyToCompare);

        this.filteredCheckDiff = differencesFound
            .map(({ toCompare }) => {
                return {
                    ...toCompare,
                    isNbLineFilled: toCompare.nb_lignes_par_table > 0,
                };
            })
            .filter((el) => el.isNbLineFilled);

        this.missingChartData = this._$indicatorRecordsService.initMissingDataChart(differencesFound, abandonedObjects);

        this.loading = false;
    }

    download() {
        try {
            const synthesisControl = this.filteredStatisticsCompared;
            const nameOrigin = this._$controlStatisticsService.makeNameStatistics(this.statisticsOrigin.createdAt);
            const nameToCompare = this._$controlStatisticsService.makeNameStatistics(
                this.statisticsToCompare.createdAt,
            );

            // anomaly comparison table
            const tableAnomalies = this._$controlStatisticsService.preparationDataForExcel(
                synthesisControl,
                { nameOrigin, nameToCompare },
                '',
            );
            // synthesis table
            const tableSynthesis = this._$controlStatisticsService.preparationSynthesisForExcel(
                this.statisticsOrigin,
                this.statisticsToCompare,
            );

            // Add statisticName, controlPoints & nbControls to indicators
            this.findIndicatorsOrigin.statisticName = this.statisticsOrigin.statisticName;
            this.findIndicatorsToCompare.statisticName = this.statisticsToCompare.statisticName;
            this.findIndicatorsOrigin.controlPoints = this.resultControlPointDataOrigin;
            this.findIndicatorsToCompare.controlPoints = this.resultControlPointDataToCompare;

            this.findIndicatorsOrigin.nbControls = this.anomalySuccessNbBySeverity.datasets.filter(
                (item) => item.stack === 'Stack 1',
            );
            this.findIndicatorsToCompare.nbControls = this.anomalySuccessNbBySeverity.datasets.filter(
                (item) => item.stack === 'Stack 2',
            );
            // indicators tables
            const tablesIndicators = this._$controlStatisticsService.preparationIndicatorsForExcel([
                this.findIndicatorsOrigin,
                this.findIndicatorsToCompare,
            ]);

            const fileName = [
                this._translate('statisticsPage.comparison', { TYPE: FMEJobEnum.CONTROL }),
                this._$controlStatisticsService.makeDateFileName(this.statisticsOrigin.createdAt),
                this._$controlStatisticsService.makeDateFileName(this.statisticsToCompare.createdAt),
            ].join('_');

            return this._$excelService.exportReportXLSX(
                [tableSynthesis, tableAnomalies],
                tablesIndicators,
                this.statisticsOrigin.company.settings,
                fileName,
                'toaster.downloadSuccess',
                { hasNbRecordsPerTable: false },
            );
        } catch {
            this._$toasterService.error(this._translate('toaster.downloadError'));
        }
    }

    getRemoveDeposit(deposits) {
        return deposits.some((deposit) => deposit.deposit === null);
    }
}

angular.module('dotic').component('statisticsComparePage', {
    controller: StatisticsComparePage,
    templateUrl: template,
});
