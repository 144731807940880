/**
 * ConnectControl API Documentation (v1)
 * The official ConnectControl Open API documentation (v1)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class PhaseNameEnum.
* @enum {}
* @readonly
*/
export default class PhaseNameEnum {
    
        /**
         * value: "AVP"
         * @const
         */
        "AVP" = "AVP";

    
        /**
         * value: "DIA"
         * @const
         */
        "DIA" = "DIA";

    
        /**
         * value: "EXE"
         * @const
         */
        "EXE" = "EXE";

    
        /**
         * value: "MCO"
         * @const
         */
        "MCO" = "MCO";

    
        /**
         * value: "PRE"
         * @const
         */
        "PRE" = "PRE";

    
        /**
         * value: "PRO"
         * @const
         */
        "PRO" = "PRO";

    
        /**
         * value: "REC"
         * @const
         */
        "REC" = "REC";

    

    /**
    * Returns a <code>PhaseNameEnum</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/PhaseNameEnum} The enum <code>PhaseNameEnum</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

