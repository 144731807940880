import { defaultPagination } from '../../models/common.model';
import fttxDataEvolutionTemplate from './fttx-data-evolution.html';
import { FttxHeritageInventoriesApi } from '../../../sdk/connect-control-api-v1/src';

class FttxDataEvolutionPage {
    isGenerating = false;
    loading = true;
    paginatedInventories = {
        ...defaultPagination,
        totalCount: 0,
    };
    paginationParameters = {
        page: 0,
        pageSize: 10,
        sort: { column: 'createdAt', order: -1 },
    };
    tableSelect = {};

    constructor(
        $apiClientService,
        $deleteService,
        $modalService,
        $filter,
        $location,
        $scope,
        $tableService,
        $timeout,
        $toasterService,
    ) {
        this._fttxHeritageInventoriesApi = new FttxHeritageInventoriesApi($apiClientService.client);
        this._translate = $filter('translate');
        this._$deleteService = $deleteService;
        this._$modalService = $modalService;
        this._$scope = $scope;
        this._$tableService = $tableService;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;

        $scope.$emit('updateNavigation', {
            newPage: [
                {
                    key: 'fttxDataEvolution',
                    title: this._translate('shared.fttxDataEvolution'),
                    href: $location.path(),
                },
            ],
        });
        $scope.$on('selectRows', (event, data) => {
            this.tableSelect.select(data.rows, true);
        });
    }

    async $onInit() {
        this.tableSelect = this._$tableService.select();

        this.paginatedInventories = await this.getInventories();
    }

    async createHeritageInventory() {
        const inventoryData = await this._$modalService.triggerFttxHeritageInventoryModal();
        if (!inventoryData) {
            return;
        }

        this.isGenerating = true;
        try {
            await this._fttxHeritageInventoriesApi.createFttxHeritageInventoryWithHttpInfo(inventoryData);
            this._$toasterService.success({ body: this._translate('shared.generationSucceeded') });
        } catch (error) {
            this._$toasterService.error(error);
        }

        this.isGenerating = false;
        this.paginatedInventories = await this.getInventories();
    }

    async getInventories(page = 0) {
        this.loading = true;
        try {
            return (
                await this._fttxHeritageInventoriesApi.getFttxHeritageInventoriesWithHttpInfo({
                    paginationParameters: {
                        limit: this.paginationParameters.pageSize,
                        order: this.paginationParameters.sort.order,
                        orderBy: this.paginationParameters.sort.column,
                        page: page,
                    },
                })
            ).response.body;
        } catch {
            return {
                ...defaultPagination,
                totalCount: 0,
            };
        } finally {
            this._$timeout(() => (this.loading = false));
        }
    }

    async onSortOrPaginationChange(page, perPage, sort) {
        this.paginationParameters = {
            page: page,
            pageSize: perPage,
            sort: sort,
        };

        this.paginatedInventories = await this.getInventories(page - 1);
    }

    async removeSelection() {
        const selectedInventories = this.paginatedInventories.data.filter(this.tableSelect.isActive);
        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.heritageInventory', {
                COUNT: selectedInventories.length,
                NAME: selectedInventories[0].name,
            }).toLowerCase(),
        );
        if (!isAccepted) {
            return;
        }

        await this._$deleteService.deleteArrayOfIds(
            selectedInventories.map((inventory) => inventory._id),
            (inventoryId) => this._fttxHeritageInventoriesApi.removeFttxHeritageInventoryWithHttpInfo(inventoryId),
        );
        this.tableSelect.empty();
        this.paginatedInventories = await this.getInventories();
    }

    selectAll() {
        this._$scope.$broadcast('getVisibleRows');
    }
}

angular
    .module('dotic')
    .component('fttxDataEvolution', { controller: FttxDataEvolutionPage, templateUrl: fttxDataEvolutionTemplate });
