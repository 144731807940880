import fttxGenerationTemplate from './fttx-data-evolution-help-generation.html';
import fttxInformationsTemplate from './fttx-data-evolution-help-informations.html';
import fttxMenuTemplate from './fttx-data-evolution-help-menu.html';
import fttxViewTemplate from './fttx-data-evolution-help-view.html';

angular.module('dotic').config(($stateProvider) => {
    $stateProvider
        .state('app.help.fttxEvolutionManagement', {
            url: '/fttx-data-evolution',
            templateUrl: fttxMenuTemplate,
            data: {
                title: "Gestion de l'évolution des données FTTX",
                navigation: [],
                next: 'app.help.fttxEvolutionInformation',
                previous: 'app.help.tableauUser',
            },
        })
        .state('app.help.fttxEvolutionInformation', {
            url: '/fttx-data-evolution/informations',
            templateUrl: fttxInformationsTemplate,
            data: {
                title: 'Informations sur les inventaires patrimoniaux',
                navigation: [
                    {
                        title: "Gestion de l'évolution des données FTTX",
                        sref: 'app.help.fttxEvolutionManagement',
                    },
                ],
                next: 'app.help.fttxEvolutionGeneration',
                previous: 'app.help.fttxEvolutionManagement',
            },
        })
        .state('app.help.fttxEvolutionGeneration', {
            url: '/fttx-data-evolution/generations',
            templateUrl: fttxGenerationTemplate,
            data: {
                title: 'Générer un inventaire patrimonial',
                navigation: [
                    {
                        title: "Gestion de l'évolution des données FTTX",
                        sref: 'app.help.fttxEvolutionManagement',
                    },
                ],
                next: 'app.help.fttxDataEvolutionView',
                previous: 'app.help.fttxEvolutionInformation',
            },
        })
        .state('app.help.fttxDataEvolutionView', {
            url: '/fttx-data-evolution/view',
            templateUrl: fttxViewTemplate,
            data: {
                title: "Vue détaillée d'un inventaire patrimonial",
                navigation: [
                    {
                        title: "Gestion de l'évolution des données FTTX",
                        sref: 'app.help.fttxEvolutionManagement',
                    },
                ],
                previous: 'app.help.fttxEvolutionGeneration',
            },
        });
});
