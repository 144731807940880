export class ChartService {
    getDefaultScaleConfiguration(isStacked = true, { minRotation, fontSize } = { minRotation: 0, fontSize: 10 }) {
        return {
            x: {
                stacked: isStacked,
                ticks: {
                    beginAtZero: true,
                },
            },
            y: {
                stacked: isStacked,
                display: true,
                ticks: {
                    beginAtZero: true,
                    font: {
                        size: fontSize,
                    },
                    minRotation: minRotation,
                },
            },
        };
    }

    static getTableChartDataset(tableChartData, coloredLabel, color) {
        return {
            backgroundColor: color,
            barPercentage: 0.75,
            borderColor: '#fff',
            borderWidth: 1,
            categoryPercentage: 0.75,
            data: tableChartData,
            datalabels: {
                align: 'end',
                anchor: 'end',
                clamp: true,
                color: color,
                font: {
                    weight: 'bold',
                },
                padding: '0',
                rotation: 0,
            },
            label: coloredLabel,
            maxBarThickness: 15,
        };
    }
}

angular.module('dotic').factory('$chartService', () => new ChartService());
