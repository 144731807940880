import { sum as radashSum } from 'radash';

import { DistanceRangeEnum } from '../../../models/grace.model';
import { DoughnutChartColorEnum } from '../../color.service';

const KeyEnum = {
    ABOVE_25_COUNT: 'above25Count',
    ABOVE_25_SRO_COUNT: 'above25SROCount',
    BELOW_10_COUNT: 'below10Count',
    BELOW_10_SRO_COUNT: 'below10SROCount',
    BETWEEN_10_AND_16_COUNT: 'between10And16Count',
    BETWEEN_10_AND_16_SRO_COUNT: 'between10And16SROCount',
    BETWEEN_16_AND_20_COUNT: 'between16And20Count',
    BETWEEN_16_AND_20_SRO_COUNT: 'between16And20SROCount',
    BETWEEN_20_AND_25_COUNT: 'between20And25Count',
    BETWEEN_20_AND_25_SRO_COUNT: 'between20And25SROCount',
};

// Linked to distance-between-nro-pto indicator
class IndicatorR00825Service {
    static ANCTCorrespondenceNumber = 25;

    constructor($filter) {
        this._translate = $filter('translate');
    }

    generateDataStructure(data) {
        const totalCount = radashSum(Object.values(data));

        return {
            ANCTCorrespondenceNumber: IndicatorR00825Service.ANCTCorrespondenceNumber,
            data: [data],
            doughnutChartData: {
                bigLegend: {
                    figure: totalCount,
                    name: this._translate('shared.distance', { COUNT: totalCount }),
                },
                datasets: [
                    {
                        backgroundColor: [
                            DoughnutChartColorEnum.BIG_SUCCESS,
                            DoughnutChartColorEnum.SMALL_SUCCESS,
                            DoughnutChartColorEnum.NEUTRAL,
                            DoughnutChartColorEnum.SMALL_FAILURE,
                            DoughnutChartColorEnum.BIG_FAILURE,
                        ],
                        data: [
                            data[KeyEnum.BELOW_10_COUNT],
                            data[KeyEnum.BETWEEN_10_AND_16_COUNT],
                            data[KeyEnum.BETWEEN_16_AND_20_COUNT],
                            data[KeyEnum.BETWEEN_20_AND_25_COUNT],
                            data[KeyEnum.ABOVE_25_COUNT],
                        ],
                    },
                ],
                labels: [
                    `${DistanceRangeEnum.FROM_0_TO_10} km`,
                    `${DistanceRangeEnum.FROM_10_TO_16} km`,
                    `${DistanceRangeEnum.FROM_16_TO_20} km`,
                    `${DistanceRangeEnum.FROM_20_TO_25} km`,
                    `${DistanceRangeEnum.MORE_THAN_25} km`,
                ],
            },
            tableData: {
                columns: this.getColumns(),
                title: this._translate('shared.detailDistanceBetweenNroPto'),
            },
        };
    }

    getColumns() {
        return [
            {
                key: KeyEnum.BELOW_10_COUNT,
                translation: `${DistanceRangeEnum.FROM_0_TO_10} km`,
            },
            {
                key: KeyEnum.BETWEEN_10_AND_16_COUNT,
                translation: `${DistanceRangeEnum.FROM_10_TO_16} km`,
            },
            {
                key: KeyEnum.BETWEEN_16_AND_20_COUNT,
                translation: `${DistanceRangeEnum.FROM_16_TO_20} km`,
            },
            {
                key: KeyEnum.BETWEEN_20_AND_25_COUNT,
                translation: `${DistanceRangeEnum.FROM_20_TO_25} km`,
            },
            {
                key: KeyEnum.ABOVE_25_COUNT,
                translation: `${DistanceRangeEnum.MORE_THAN_25} km`,
            },
            {
                key: KeyEnum.BELOW_10_SRO_COUNT,
                translation: `SRO ${DistanceRangeEnum.FROM_0_TO_10} km`,
            },
            {
                key: KeyEnum.BETWEEN_10_AND_16_SRO_COUNT,
                translation: `SRO ${DistanceRangeEnum.FROM_10_TO_16} km`,
            },
            {
                key: KeyEnum.BETWEEN_16_AND_20_SRO_COUNT,
                translation: `SRO ${DistanceRangeEnum.FROM_16_TO_20} km`,
            },
            {
                key: KeyEnum.BETWEEN_20_AND_25_SRO_COUNT,
                translation: `SRO ${DistanceRangeEnum.FROM_20_TO_25} km`,
            },
            {
                key: KeyEnum.ABOVE_25_SRO_COUNT,
                translation: `SRO ${DistanceRangeEnum.MORE_THAN_25} km`,
            },
        ];
    }
}

angular.module('dotic').factory('indicatorR00825Service', IndicatorR00825Service);
