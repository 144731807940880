import userListTemplate from './userList.html';
import userProfileTemplate from '../user/profile/userProfile.html';

angular.module('dotic').config(($stateProvider) => {
    $stateProvider
        .state('app.userList', {
            url: '/users/list?role&user&p',
            templateUrl: userListTemplate,
            controller: 'UserListController as $ctrl',
            params: {
                role: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                user: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
                p: {
                    value: '',
                    squash: true,
                    dynamic: true,
                },
            },
            data: {
                authenticationRequired: true,
                roleRequired: ['superAdmin'],
            },
        })
        .state('app.userProfile', {
            url: '/users/:userId/profile',
            templateUrl: userProfileTemplate,
            controller: 'UserProfileController as $ctrl',
            data: {
                authenticationRequired: true,
                roleRequired: [],
            },
        });
});
