/**
 * ConnectControl API Documentation (v1)
 * The official ConnectControl Open API documentation (v1)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The PaginationParameters model module.
 * @module model/PaginationParameters
 * @version 1.0.0
 */
class PaginationParameters {
    /**
     * Constructs a new <code>PaginationParameters</code>.
     * @alias module:model/PaginationParameters
     * @param limit {Number} 
     */
    constructor(limit) { 
        
        PaginationParameters.initialize(this, limit);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, limit) { 
        obj['limit'] = limit;
    }

    /**
     * Constructs a <code>PaginationParameters</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PaginationParameters} obj Optional instance to populate.
     * @return {module:model/PaginationParameters} The populated <code>PaginationParameters</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PaginationParameters();

            if (data.hasOwnProperty('limit')) {
                obj['limit'] = ApiClient.convertToType(data['limit'], 'Number');
            }
            if (data.hasOwnProperty('order')) {
                obj['order'] = ApiClient.convertToType(data['order'], 'String');
            }
            if (data.hasOwnProperty('orderBy')) {
                obj['orderBy'] = ApiClient.convertToType(data['orderBy'], 'String');
            }
            if (data.hasOwnProperty('page')) {
                obj['page'] = ApiClient.convertToType(data['page'], 'Number');
            }
            if (data.hasOwnProperty('search')) {
                obj['search'] = ApiClient.convertToType(data['search'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>PaginationParameters</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>PaginationParameters</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of PaginationParameters.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['order'] && !(typeof data['order'] === 'string' || data['order'] instanceof String)) {
            throw new Error("Expected the field `order` to be a primitive type in the JSON string but got " + data['order']);
        }
        // ensure the json data is a string
        if (data['orderBy'] && !(typeof data['orderBy'] === 'string' || data['orderBy'] instanceof String)) {
            throw new Error("Expected the field `orderBy` to be a primitive type in the JSON string but got " + data['orderBy']);
        }
        // ensure the json data is a string
        if (data['search'] && !(typeof data['search'] === 'string' || data['search'] instanceof String)) {
            throw new Error("Expected the field `search` to be a primitive type in the JSON string but got " + data['search']);
        }

        return true;
    }


}

PaginationParameters.RequiredProperties = ["limit"];

/**
 * @member {Number} limit
 */
PaginationParameters.prototype['limit'] = undefined;

/**
 * @member {String} order
 */
PaginationParameters.prototype['order'] = undefined;

/**
 * @member {String} orderBy
 */
PaginationParameters.prototype['orderBy'] = undefined;

/**
 * @member {Number} page
 */
PaginationParameters.prototype['page'] = undefined;

/**
 * @member {String} search
 */
PaginationParameters.prototype['search'] = undefined;






export default PaginationParameters;

