angular.module('dotic').config(($stateProvider) => {
    $stateProvider.state('app.postProcessingList', {
        url: '/superAdmin/post-processes',
        params: {
            p: {
                value: '',
                squash: true,
                dynamic: true,
            },
        },
        component: 'postProcessingListPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['superAdmin'],
        },
    });

    $stateProvider.state('app.postProcessingEdit', {
        url: '/superAdmin/post-processes/:postProcessingId/edit/',
        component: 'postProcessingFormPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['superAdmin'],
        },
    });

    $stateProvider.state('app.postProcessingCreate', {
        url: '/superAdmin/post-processes/create',
        component: 'postProcessingFormPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['superAdmin'],
        },
    });

    $stateProvider.state('app.postProcessingAdminList', {
        url: '/post-processes',
        data: {
            authenticationRequired: true,
            roleRequired: ['admin'],
        },
    });

    $stateProvider.state('app.postProcessingAdminDetail', {
        url: '/post-processes/:postProcessingId/',
        component: 'postProcessingAdminDetailPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['admin'],
        },
    });
});
