import { group as radashGroup, sum as radashSum, unique as radashUnique } from 'radash';

import { ChartService } from '../../../services/chart.service';
import { ColorService } from '../../../services/color.service';
import template from './nb-gc-indicator.html';

class NbGcIndicatorComponent {
    chartsData = [];
    doughnutChartData = { bigLegend: {}, datasets: [], labels: [] };
    chartSize = 180;
    countByOwners = [];
    data = [];
    isParentLoading = false;
    parentRef = null;

    constructor($chartService, $filter) {
        this.stackedBarChartOptions = {
            responsive: true,
            scales: $chartService.getDefaultScaleConfiguration(false),
        };
        this._translate = $filter('translate');
    }

    $onInit() {
        this.countByOwners = this.getCountByOwners(this.data.nbByNature);

        this.doughnutChartData = this.getDoughnutChartData(this.data.nbByNature);
        this.chartsData = this.getChartsData(this.data);
    }

    getChartsData(data) {
        return data.nbByTypephy.map((typePhy) => {
            const natures = data.nbByNature.filter((nature) => nature.ptTypephy === typePhy.ptTypephy);
            const labels = this.getLabels(natures);
            const datasets = this.getDatasets(natures, labels);

            return { name: typePhy.libelle, chartData: { datasets: datasets, labels: labels } };
        });
    }

    getCountByOwners(natures) {
        const valuesByOwners = radashGroup(natures, (nature) => nature.owner);
        const colors = ColorService.getDistinctRandomColors(
            Object.keys(valuesByOwners).length,
            ColorService.colorsPalette,
        );

        return Object.entries(valuesByOwners).map(([key, values], index) => {
            return {
                name: key,
                count: radashSum(values, (value) => value.nbGc),
                color: colors[index],
            };
        });
    }

    getDatasets(natures, labels) {
        const valuesByOwners = radashGroup(natures, (value) => value.owner);

        return Object.entries(valuesByOwners).map(([key, values]) => {
            const chartValues = labels.map((label) => {
                const filteredValues = values.filter((value) => value.libelle === label);

                return radashSum(filteredValues, (value) => value.nbGc || 0);
            });

            const color =
                this.countByOwners.find((owner) => owner.name === key)?.color ?? ColorService.getRandomColor();

            return ChartService.getTableChartDataset(chartValues, key, color);
        });
    }

    getDoughnutChartData() {
        return {
            bigLegend: {
                figure: radashSum(this.data.nbByTypephy, (item) => item.nbGc),
                name: 'GC',
            },
            datasets: [
                {
                    data: this.countByOwners.map((item) => item.count),
                    backgroundColor: ColorService.getDistinctRandomColors(
                        this.countByOwners.length,
                        ColorService.colorsPalette,
                    ),
                },
            ],
            labels: this.countByOwners.map((item) => item.name),
        };
    }

    getLabels(values) {
        return radashUnique(values.map((value) => value.libelle));
    }

    getPhysicalTypeBackgroundColor(physicalType) {
        return ColorService.getTechnicalPointsTypeColor(physicalType);
    }
}

angular.module('dotic').component('nbGcIndicator', {
    controller: NbGcIndicatorComponent,
    templateUrl: template,
    bindings: {
        data: '<',
        isParentLoading: '<',
        parentRef: '<',
    },
});
