import * as Sentry from '@sentry/browser';
import { map as radashMap, parallel as radashParallel } from 'radash';

import EnvironmentSettingsService from '../../services/environment-settings.service';
import { FMEJobEnum } from '../../models/deposit.model';
import { HelpersService } from '../../services/helpers.service';

class DepositListController {
    postProcessingListData = [];

    constructor(
        $auth,
        $authorizationService,
        $companySettings,
        $deleteService,
        $filter,
        $filterService,
        $log,
        $mViewerService,
        $modalService,
        $postProcessingService,
        $scope,
        $segmentsService,
        $state,
        $stateParams,
        $synopticService,
        $tableService,
        $timeout,
        $toasterService,
        $uibModal,
        $userService,
        $window,
        LoaderService,
        RestProvider,
        companyProvider,
        depositProvider,
        postProcessingProvider,
        userMetricsProvider,
    ) {
        this._$companySettings = $companySettings;
        this._$deleteService = $deleteService;
        this._translate = $filter('translate');
        this._$filterService = $filterService;
        this._$log = $log;
        this._$mViewerService = $mViewerService;
        this._$postProcessingService = $postProcessingService;
        this._$scope = $scope;
        this._$segmentsService = $segmentsService;
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._$synopticService = $synopticService;
        this._$tableService = $tableService;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._$uibModal = $uibModal;
        this._$window = $window;
        this._LoaderService = LoaderService;
        this._RestProvider = RestProvider;
        this._companyProvider = companyProvider;
        this._depositProvider = depositProvider;
        this._postProcessingProvider = postProcessingProvider;
        this._userMetricsProvider = userMetricsProvider;
        this._$modalService = $modalService;

        this._isInitiated = false;

        this.page = $stateParams.p || null;
        this.pageSize = 20;
        this.sort = { column: 'createdAt', order: -1 };
        this.totalCount = 0;

        this.isOrange = EnvironmentSettingsService.isOrange();
        this.isAllowed = $authorizationService.isAllowed;
        this.companyId = $auth.getPayload().company;
        this.connectedCompany = $userService.getConnectedCompany();
        this.connectedUser = $userService.getConnectedUser();

        this.DEPOSIT_STATUS_DONE = 'DONE';

        this.loading = true;
        this.segments = this._$segmentsService.getAll();

        this.createdAtStart = this._$stateParams.createdAtStart;
        this.createdAtEnd = this._$stateParams.createdAtEnd;

        this.currentJob = this._$state.current.data.currentJob;

        this.specificFilterOption = {
            allowed: true,
            name: 'postProcessingReferences',
            value: 'postProcessingReference',
            placeholder: 'filter.all.postProcessingReferences',
            translateKeyLabel: 'filter.postProcessingReferences',
        };

        this.defaultFilterOptions = [
            {
                allowed: true,
                name: 'projectIds',
                value: 'projectId',
                hasSearch: true,
                placeholder: 'filter.all.projectIds',
                translateParamsLabel: { COUNT: 1 },
                translateKeyLabel: 'shared.businessCode',
            },
            {
                allowed: true,
                name: 'controlConfigurations',
                value: 'controlConfiguration',
                hasSearch: true,
                placeholder: 'filter.all.controlConfigurations',
                translateKeyLabel: 'shared.controlConfiguration',
            },
            {
                allowed: true,
                name: 'date',
                value: 'date',
                placeholder: 'filter.all.date',
                type: 'date',
                saveValue: true,
                translateKeyLabel: 'shared.date',
            },
            {
                allowed: true,
                name: 'idLivrables',
                value: 'idLivrable',
                placeholder: 'filter.all.deliverableIds',
                hasSearch: true,
                translateKeyLabel: 'shared.deliverableID',
            },
            {
                allowed: true,
                name: 'phases',
                value: 'phase',
                placeholder: 'filter.all.phases',
                translateKeyLabel: 'shared.steps',
                saveValue: true,
            },
            {
                allowed: true,
                name: 'dataModels',
                value: 'dataModel',
                hasSearch: true,
                placeholder: 'filter.all.dataModels',
                translateKeyLabel: 'shared.dataModel',
                translateParamsLabel: { COUNT: 1 },
            },
            {
                allowed: true,
                name: 'projects',
                value: 'project',
                hasSearch: true,
                placeholder: 'filter.all.projects',
                translateKeyLabel: 'shared.project',
            },
            {
                allowed: true,
                name: 'subCompanies',
                value: 'subCompany',
                hasSearch: true,
                placeholder: 'filter.all.subCompanies',
                translateKeyLabel: 'shared.subcontractor',
            },
            {
                allowed: true,
                name: 'segmentsList',
                selected: 'segment',
                placeholder: 'filter.all.segments',
                saveValue: true,
                translateKeyLabel: 'shared.networkSegment',
                type: 'multi-select',
            },
            {
                allowed: true,
                name: 'status',
                value: 'status',
                placeholder: 'filter.all.status',
                saveValue: true,
                translateKeyLabel: 'shared.status',
            },
            {
                allowed: true,
                name: 'createdBy',
                value: 'createdBy',
                hasSearch: true,
                placeholder: 'filter.all.createdBy',
                translateKeyLabel: 'shared.createdBy',
            },
            {
                allowed: true,
                name: 'versions',
                value: 'version',
                placeholder: 'filter.all.versions',
                saveValue: true,
                translateKeyLabel: 'shared.versions',
            },
            {
                allowed: true,
                name: 'deliveryZones',
                value: 'deliveryZone',
                hasSearch: true,
                placeholder: 'filter.all.deliveryZones',
                translateKeyLabel: 'shared.deliveryZone',
                translateParamsLabel: { COUNT: 1 },
            },
        ];

        this.filterOptions = [...this.defaultFilterOptions];

        this.filterValues = {
            controlConfigurations: [],
            createdBy: [],
            dataModels: [],
            deliveryZones: [],
            idLivrable: [],
            phases: [],
            projectIds: [],
            projects: [],
            postProcessingReference: [],
            segmentsList: this.segments.map((segment) => ({
                key: segment,
                value: segment,
            })),
            servers: [],
            status: [],
            subCompanies: [],
        };

        this.depositList = [];
        this.depositDetail = [];
        this.subCompanies = [];

        this.setDefaultFilter($stateParams);

        // Watch filter change
        $scope.$watchGroup(
            [
                '$ctrl.createdAtEnd',
                '$ctrl.createdAtStart',
                '$ctrl.filter.controlConfiguration',
                '$ctrl.filter.createdBy',
                '$ctrl.filter.dataModel',
                '$ctrl.filter.date',
                '$ctrl.filter.deliveryZone',
                '$ctrl.filter.idLivrable',
                '$ctrl.filter.phase',
                '$ctrl.filter.project',
                '$ctrl.filter.projectId',
                '$ctrl.filter.postProcessingReference',
                '$ctrl.filter.search',
                '$ctrl.filter.segment',
                '$ctrl.filter.server',
                '$ctrl.filter.status',
                '$ctrl.filter.subCompany',
                '$ctrl.filter.version',
            ],
            async (newValue, oldValue) => {
                if (newValue !== oldValue) {
                    await this.updateFilters();
                }
            },
        );

        // Watch query params change
        $scope.$on('$locationChangeSuccess', () => {
            $scope.$emit('updateNavigationUrl');
        });

        $scope.$on('selectRows', (e, data) => {
            if (this.connectedUser.role === 'user') {
                this.tableSelect.select(
                    data.rows.filter((row) => row.createdBy === this.connectedUser.id),
                    true,
                );

                return;
            }

            this.tableSelect.select(data.rows);
        });
    }

    selectVisibleRows() {
        this._$scope.$broadcast('getVisibleRows');
    }

    // includeSearch allows to clear all filters except search -- cf. cancelFilter function
    setDefaultFilter(params = {}, includeSearch = true) {
        if (!includeSearch && this.filter) {
            params.search = this.filter.search;
        }

        this.filter = {
            controlConfiguration: params.controlConfiguration || '',
            createdBy: params.createdBy || '',
            dataModel: params.dataModel || '',
            date: params.date || '',
            deliveryZone: params.deliveryZone || '',
            idLivrable: params.idLivrable || '',
            phase: params.phase || '',
            project: params.project || '',
            projectId: params.projectId || '',
            postProcessingReference: params.postProcessingReference || '',
            search: params.search || '',
            segment: this._$filterService.getFilterValue(params.segment),
            server: params.server || '',
            status: params.status || '',
            subCompany: params.subCompany || '',
            version: params.version || '',
        };

        if (params.createdAtStart && params.createdAtEnd) {
            this.setDateParams();

            return;
        }

        this.updateStateParams();
    }

    async $onInit() {
        this.tableSelect = this._$tableService.select();
        this.tableDetail = this._$tableService.detail();

        let title;

        if (this.currentJob === FMEJobEnum.LOAD) {
            title = this._translate('shared.loadDeposit');
            this._userMetricsProvider.listStoredDeposits();
        } else if (this.currentJob === FMEJobEnum.CONTROL) {
            title = this._translate('shared.controlDeposit');
            this._userMetricsProvider.listControlledDeposits();
        } else {
            title = this._translate('shared.deliverable');
            this._userMetricsProvider.listDeposits();
        }

        this._$scope.$emit('updateNavigation', {
            newPage: [
                {
                    key: 'deposits',
                    title,
                    href: this._$state.href(this._$state.current.name, {
                        ...this._$state.params,
                    }),
                },
            ],
        });

        if (this.currentJob === FMEJobEnum.LOAD || this.currentJob === FMEJobEnum.CONTROL) {
            // Check if post process abandoned object exist to load specific column
            this.enabledAbandonedObject = await this.companyHasAbandonedObjectPostProcess();

            if (this.enabledAbandonedObject) {
                this.filterOptions = [...this.defaultFilterOptions, this.specificFilterOption];
            }
        }

        try {
            this.getAvailableFilters();
            await this.search(this.page, this.pageSize, this.sort);
            this.hasLinkBetweenGridsEnabled = await this._$companySettings.getCompanyHasLinkBetweenGridsEnabled(
                this.companyId,
            );
        } catch (error) {
            Sentry.captureException(error);
        }

        this._$timeout(() => {
            this.loading = false;
        });
    }

    async updateFilters() {
        this.loading = true;
        this.updateStateParams();
        this.getAvailableFilters();
        this.tableSelect.empty();
        await this.search(1, this.pageSize, this.sort);
    }

    showDetails(deposit) {
        this.depositDetail = [];
        this.tableDetail.toggle(deposit);
    }

    setDateParams() {
        if (!this.createdAtStart || !this.createdAtEnd) {
            return;
        }

        const dateFilter = this.filterOptions.find((item) => item.name === 'date');

        this.filter.date = {
            startDate: moment(this.createdAtStart),
            endDate: moment(this.createdAtEnd),
        };
        this._$scope.$broadcast('forceFilterRender', {
            filter: dateFilter,
            saveChanges: false,
            openDropdown: false,
        });
    }

    getQuery() {
        switch (this.currentJob) {
            case FMEJobEnum.LOAD:
                return this._depositProvider.listLoad;
            case FMEJobEnum.CONTROL:
                return this._depositProvider.listControl;
            default:
                return this._depositProvider.listAll;
        }
    }

    async search(page, perPage, sort) {
        this.loading = true;

        const query = this.getQuery();
        this.pageSize = perPage;
        this.page = page;
        this.sort = sort;

        try {
            const queryResult = await query({ filters: this.filter, page: page, perPage: perPage, sort: sort });
            const depositData = this.preparedResultDeposit(queryResult.results);

            this.depositList = depositData.map((deposit) => {
                return {
                    ...deposit,
                    networkSegments: this._$segmentsService.sortSegments(deposit.networkSegments || []),
                };
            });

            this.totalCount = queryResult.count;
        } catch (error) {
            this._$toasterService.error(error);
        }

        this.loading = false;
    }

    preparedResultDeposit(dirtyDeposits) {
        return dirtyDeposits.map((deposit) => {
            if (!deposit.report?.newPlug || !deposit.dataModel) {
                return {
                    ...deposit,
                    networkSegments: this._$segmentsService.setNetworkSegments(deposit.networkSegments) || [],
                    jobActionName: this.getJobActionName(deposit),
                };
            }

            return {
                ...deposit,
                networkSegments: this._$segmentsService.setNetworkSegments(deposit.networkSegments) || [],
                jobActionName: this.getJobActionName(deposit),
                report: {
                    ...deposit.report,
                    newPlug: {
                        ...deposit.report.newPlug,
                        messageHousing:
                            deposit.dataModel.regexSlug === 'V300'
                                ? 'ad_nblres, ad_nblpro, ad_nblent, ad_nblpub, ad_nblobj, ad_nblope'
                                : 'ad_nblhab, ad_nblpro',
                    },

                    errorsBySeverity: {
                        ...deposit.report.errorsBySeverity,
                        totalControlPoint:
                            deposit.report.errorsBySeverity.blocking +
                            deposit.report.errorsBySeverity.error +
                            deposit.report.errorsBySeverity.major +
                            deposit.report.errorsBySeverity.minor +
                            deposit.report.errorsBySeverity.success,
                    },
                },
            };
        });
    }

    getAvailableFilters() {
        const filters = { ...this.filter };

        if (this.currentJob) {
            filters.job = this.currentJob;
        }

        return this._depositProvider
            .listFilters({ filters })
            .then((filters) => {
                this.filterValues = filters;
                this.ensureCompatibility();
            })
            .catch(this._$toasterService.error);
    }

    /**
     * Tara compatibility fix
     * Change filter value from name to id
     */
    ensureCompatibility() {
        // Allows the redirection page to exit the function
        if (this._isInitiated === true) {
            return;
        }

        this._isInitiated = true;
        const { filter, filterValues } = this;
        // Changing the project name into the id
        if (filter.project && filterValues.projects && filterValues.projects.length > 0) {
            const findByName = filterValues.projects.find(({ value }) => value === filter.project);
            if (findByName) {
                filter.project = findByName.key;
            }
        }

        // Changing the deliveryZone name into the id but taking care to the prefix
        if (filter.deliveryZone && filterValues.deliveryZones && filterValues.deliveryZones.length > 0) {
            filter.deliveryZone = filterValues.deliveryZones[0].key;
        }
    }

    updateStateParams() {
        const {
            company,
            controlConfiguration,
            createdAtEnd,
            createdAtStart,
            createdBy,
            dataModel,
            deliveryZone,
            idLivrable,
            phase,
            project,
            projectId,
            postProcessingReference,
            search,
            segment,
            server,
            status,
            version,
        } = this.filter;

        this._$state.go('.', {
            company,
            controlConfiguration,
            createdAtEnd,
            createdAtStart,
            createdBy,
            dataModel,
            deliveryZone,
            idLivrable,
            phase,
            project,
            projectId,
            postProcessingReference,
            search,
            segment: segment.join(','),
            server,
            status,
            version,
        });
    }

    canSeeSynopticButton(deposit) {
        return this.isLoadSucceeded(deposit) && this.connectedCompany?.settings?.feature.hasSynopticEnabled;
    }

    canOpenSynoptic(deposit) {
        if (!deposit.idLivrable || !deposit.hasSynoptic) {
            return false;
        }

        return deposit.dataModel?.settings?.hasSynopticEnabled;
    }

    getSynopticButtonTooltip(deposit) {
        if (!deposit.dataModel?.settings?.hasSynopticEnabled) {
            return this._translate('deposit.synopticDisabledByDataModel');
        }

        if (!deposit.idLivrable || !deposit.hasSynoptic) {
            return this._translate('deposit.noOpticalSynoptic');
        }

        return this._translate('deposit.opticalSynoptic');
    }

    openSynoptic(deposit, control = null) {
        this._$log.debug('opening synoptic with', deposit, control);

        this._$synopticService
            .getServerUrl({ deposit, control })
            .then((url) => {
                if (url) {
                    if (deposit) {
                        this._userMetricsProvider.openSynoptic(deposit._id);
                    }

                    if (control) {
                        this._userMetricsProvider.openSynoptic(control._id);
                    }

                    return this._$window.open(url, '_blank');
                }

                throw new Error("Le synoptique optique n'est pas activé pour votre entreprise");
            })
            .catch(this._$toasterService.error)
            .finally(() => {
                this._LoaderService.dismiss();
            });
    }

    async openSynopticModal(deposit) {
        const results = await this._$modalService.triggerLinkedDownloadModal(
            deposit,
            this._translate('shared.controlDeposit', { COUNT: 1 }).toLowerCase(),
            FMEJobEnum.CONTROL,
            this._translate('shared.visualize'),
        );
        if (!results) {
            return;
        }

        if (!results.deposit2) {
            return this.openSynoptic(deposit);
        }

        let deposit2;
        try {
            deposit2 = await this._depositProvider.get(results.deposit2);
        } catch (error) {
            this._$toasterService.error(error);

            return;
        }

        if (HelpersService.getLoadJob(deposit)) {
            return this.openSynoptic(deposit, deposit2);
        }

        return this.openSynoptic(deposit, deposit2);
    }

    canDownloadExport(deposit) {
        return this.isLoadSucceeded(deposit) && deposit.idLivrable && angular.isString(deposit.exportURL);
    }

    canDownloadQGis(deposit) {
        return deposit.dataModel?.settings?.hasQGisEnabled;
    }

    async downloadExport(deposit) {
        try {
            await this._RestProvider.downloadFromPath(`deposits/${deposit.id}/export/zip`);
            this._$toasterService.success({
                body: this._translate('deposit.downloadedExport', { NAME: deposit.name }),
            });
        } catch {
            this._$toasterService.error({ body: this._translate('toaster.downloadError') });
        }
    }

    async downloadSelectedExports() {
        const deposits = this.depositList
            .filter(this.tableSelect.isActive)
            .filter((deposit) => this.canDownloadExport(deposit));

        if (!deposits.length) {
            this._$toasterService.error({
                body: this._translate('deposit.noDeliverablesAvailableToExport'),
            });

            return;
        }

        this._LoaderService.open(`
            <p>${this._translate('deposit.exportingDeliverables')}</p>
            <p>${this._translate('shared.timeConsuming')}</p>
        `);

        await radashParallel(10, deposits, (deposit) => {
            return this.downloadExport(deposit);
        });
        this._LoaderService.dismiss();
    }

    async fetchPostProcessingList() {
        try {
            this.postProcessingListData = await this.getPostProcessingData();
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    async companyHasAbandonedObjectPostProcess() {
        await this.fetchPostProcessingList();

        return this.postProcessingListData.some((postProcessingData) =>
            this._$postProcessingService.isPostProcessingAbandonedObject(postProcessingData.slug),
        );
    }

    companyHasPostProcessSpecific() {
        return this.connectedCompany.settings?.feature?.hasPostProcessEnabled;
    }

    canRunPostProcessSpecific(deposit) {
        const enabled = this.companyHasPostProcessSpecific();
        const job = HelpersService.getLoadJob(deposit);
        const isJobValid = job && !job.action;

        return this.isLoadSucceeded(deposit) && deposit.idLivrable && enabled && isJobValid;
    }

    async setReferencePostProcessToDeposit(deposit) {
        const isLoadSucceeded = this.isLoadSucceeded(deposit);
        const enabled = this.companyHasPostProcessSpecific();

        if (!enabled && !isLoadSucceeded) {
            return;
        }

        if (!deposit.isPostProcessReference) {
            this.loading = true;
            await this.updateDeposit(deposit);

            return;
        }

        const isAccepted = await this._$modalService.triggerConfirmationModal(
            'deposit.removeReferenceFromAbandonedObjects',
            'shared.continue',
        );
        if (!isAccepted) {
            return;
        }

        this.loading = true;
        await this.updateDeposit(deposit);
    }

    async updateDeposit(deposit) {
        try {
            await this._depositProvider.update(deposit._id, deposit);

            this._$toasterService.info({
                body: this._translate('deposit.updateDeposit'),
            });
        } catch (error) {
            this._$toasterService.error(error);
        } finally {
            await this.$onInit();
        }
    }

    async getPostProcessingData() {
        try {
            const postProcessings = await this._postProcessingProvider.getAll();

            return postProcessings.map((postProcessing) => {
                return {
                    key: postProcessing.id,
                    value: postProcessing.name,
                    slug: postProcessing.slug,
                };
            });
        } catch (error) {
            this._$toasterService.error(error);

            return [];
        }
    }

    runPostProcessSpecific() {
        const deposits = this.depositList
            .filter(this.tableSelect.isActive)
            .filter((deposit) => this.canRunPostProcessSpecific(deposit));

        if (deposits.length === 0) {
            this._$toasterService.error(this._translate('deposit.noDataForThePostProcess'));

            return;
        }

        const modal = this._$uibModal.open({
            component: 'postProcessingListModal',
            size: 'sm',
            resolve: {
                action: () => this._translate('shared.run'),
                postProcessingList: () => this.getPostProcessingData(),
                title: () => this._translate('shared.postProcessing'),
            },
        });

        modal.result
            .then(
                (_confirm) => _confirm,
                () => false,
            )
            .then((results) => {
                if (!results) {
                    return;
                }

                this.launchPostProcessing(results.selectedPostProcessings, deposits);
            });
    }

    launchPostProcessing(selectedPostProcessings, deposits) {
        if (!deposits.length) {
            return;
        }

        return Promise.all(deposits.map((deposit) => this.postProcess(deposit, selectedPostProcessings)))
            .then((responseModal) => {
                this.successPostProcessing(responseModal, deposits);
                this.$onInit();
            })
            .catch((error) => {
                this.failPostProcessing(error);
            });
    }

    successPostProcessing(responseModal, deposits) {
        if (!responseModal) {
            return;
        }

        this._$toasterService.success({
            body: this._translate(
                deposits.length > 1
                    ? 'deposit.runMultiPostProcessSpecificSuccess'
                    : 'deposit.runPostProcessSpecificSuccess',
            ),
        });
    }

    failPostProcessing(error) {
        this._$log.error(error);
        let body = this._translate('deposit.runPostProcessSpecificError');
        if (error.data?.message) {
            body = `${body}: ${error.data.message}`;
        }

        this._$toasterService.error({ body });
    }

    hasMViewer(deposit) {
        if (!deposit?.idLivrable) {
            return false;
        }

        return !!deposit.project?.geoserver?.slug;
    }

    canSeeMViewerButton(deposit) {
        return this.isLoadSucceeded(deposit) || this.isControlSucceeded(deposit);
    }

    postProcess(deposit, selectedPostProcessings) {
        return this._depositProvider.runAction(deposit.id, 'post_process_specific', {
            selectedPostProcessings,
        });
    }

    openMviewer(deposit, control = null) {
        this._$log.debug('opening mviewer with', deposit, control);

        if (!this.hasMViewer(deposit) || (control && !this.hasMViewer(control))) {
            return;
        }

        this._$mViewerService
            .getServerUrl({ deposit, control })
            .then((url) => {
                if (url) {
                    if (deposit) {
                        this._userMetricsProvider.openMviewer(deposit._id);
                    }

                    if (control) {
                        this._userMetricsProvider.openMviewer(control._id);
                    }

                    return this._$window.open(url, '_blank');
                }

                throw new Error(this._translate('shared.disabledMapDistribution'));
            })
            .catch(this._$toasterService.error);
    }

    async openMviewerModal(deposit) {
        const results = await this._$modalService.triggerLinkedDownloadModal(
            deposit,
            this._translate('shared.controlDeposit', { COUNT: 1 }).toLowerCase(),
            FMEJobEnum.CONTROL,
            this._translate('shared.visualize'),
        );
        if (!results) {
            return;
        }

        if (!results.deposit2) {
            return this.openMviewer(deposit);
        }

        let deposit2;
        try {
            deposit2 = await this._depositProvider.get(results.deposit2);
        } catch (error) {
            this._$toasterService.error(error);

            return;
        }

        return this.openMviewer(deposit2, deposit);
    }

    cancelFilters() {
        this.setDefaultFilter({}, false);
    }

    removeFilter(filterName, initialValue) {
        this.filter[filterName] = initialValue;
        this._$state.go('.', this.filter);
    }

    removeSearch() {
        this.filter.search = '';
        this._$state.go('.', this.filter);
    }

    async removeDeposits() {
        try {
            const ids = await this.tableSelect.getSelection();

            const isANCT = (
                await Promise.all(
                    ids.map((id) => {
                        return this._depositProvider.get(id);
                    }),
                )
            ).some((deposit) => deposit?.isANCT && deposit.FMEJobs[0].job === FMEJobEnum.CONTROL);

            const isAccepted = await this._$modalService.triggerConfirmDepositDeletionModal(isANCT);

            if (!isAccepted) {
                return;
            }

            this._LoaderService.open(
                `<p>${this._translate('deposit.deletingFiles', {
                    COUNT: ids.length,
                })}</p>`,
            );

            await radashParallel(5, ids, (depositId) => this._depositProvider.delete(depositId));

            this._$toasterService.success({
                body: this._translate('deposit.successDeleteDeposit', { COUNT: ids.length }),
            });
            this.page = 1;
        } catch (error) {
            this._LoaderService.dismiss();
            this._$toasterService.error(error);
        }

        this._LoaderService.dismiss();
        await this.$onInit();
    }

    canViewControl(deposit) {
        return this.isControlSucceeded(deposit) && this.hasReport(deposit);
    }

    downloadControlReport(deposit) {
        const deposits = deposit ? [deposit] : this.depositList.filter(this.tableSelect.isActive);

        return this.downloadMultipleFilesFromDeposits(
            deposits,
            async (deposit) => await this.downloadErrorPdf(deposit),
        );
    }

    downloadControlErrors(deposit) {
        const deposits = deposit ? [deposit] : this.depositList.filter(this.tableSelect.isActive);

        return this.downloadMultipleFilesFromDeposits(
            deposits,
            async (deposit) => await this.downloadErrorZip(deposit),
        );
    }

    async downloadErrorPdf(deposit) {
        if (!this.hasReport(deposit)) {
            this._$toasterService.error(`${this._translate('deposit.unableToDownloadTheDeliverable')} ${deposit.name}`);

            return;
        }

        try {
            this._userMetricsProvider.downloadReport(deposit.report.id);
            await this._RestProvider.downloadFromPath(`reports/${deposit.report.id}/pdf`);
        } catch {
            this._$toasterService.error({ body: this._translate('toaster.downloadError') });
        }
    }

    async downloadErrorZip(deposit) {
        if (!this.hasReport(deposit)) {
            this._$toasterService.error(`${this._translate('deposit.unableToDownloadTheDeliverable')} ${deposit.name}`);

            return;
        }

        try {
            await this._RestProvider.downloadFromPath(`reports/${deposit.report.id}/errors/zip`);
        } catch {
            this._$toasterService.error({ body: this._translate('toaster.downloadError') });
        }
    }
    async downloadMultipleFilesFromDeposits(deposits, downloadProcedure) {
        if (deposits.length > 1) {
            this.loaderMultiFilesNotice();
        } else {
            this.loaderSingleFileNotice();
        }

        await radashMap(deposits, (deposit) => downloadProcedure(deposit));
        this._LoaderService.dismiss();
    }

    async openDownloadQgisModal(deposit) {
        const results = await this._$modalService.triggerLinkedDownloadModal(
            deposit,
            this._translate('shared.controlDeposit', { COUNT: 1 }).toLowerCase(),
            FMEJobEnum.CONTROL,
        );
        if (!results) {
            return;
        }

        let params;
        if (results.deposit2) {
            params = { deposit2: results.deposit2 };
        }

        try {
            await this._RestProvider.downloadFromPath(`deposits/${deposit.id}/qgis/zip`, params);
        } catch {
            this._$toasterService.error({ body: this._translate('toaster.downloadError') });
        }
    }

    async downloadQgis(deposit) {
        const hasLoad = this.isLoadSucceeded(deposit);
        const hasControl = this.isControlSucceeded(deposit);

        if (!hasLoad && !hasControl) {
            this._$toasterService.error(this._translate('deposit.deliverablesConditionsQGIS'));

            return;
        }

        try {
            await this._RestProvider.downloadFromPath(`deposits/${deposit.id}/qgis/zip`);
        } catch {
            this._$toasterService.error({ body: this._translate('toaster.downloadError') });
        }
    }

    isControlSucceeded = (deposit) => {
        const isControlJob = !!HelpersService.getControlJob(deposit);

        return isControlJob && deposit.status === this.DEPOSIT_STATUS_DONE;
    };

    isLoadSucceeded(deposit) {
        const isLoadJob = !!HelpersService.getLoadJob(deposit);

        return isLoadJob && deposit.status === this.DEPOSIT_STATUS_DONE;
    }

    hasReport(deposit) {
        return !!deposit?.report?.id;
    }

    compareReport(deposit) {
        this._$state.go('app.comparisonDepositPage', {
            deposit: deposit._id,
        });
    }

    getDepositType(deposit) {
        const load = HelpersService.getLoadJob(deposit);
        const control = HelpersService.getControlJob(deposit);
        if (load) {
            return this.getJobName(load.job);
        } else if (control) {
            return this.getJobName(control.job);
        }

        return '-';
    }

    getJobs(deposit) {
        const jobs = deposit.FMEJobs.map((job) => job.job);
        if (jobs.length === 0) {
            jobs.push('UNKNOWN');
        }

        return jobs;
    }

    getJobName(job) {
        if (job === FMEJobEnum.LOAD) {
            return `${this._translate('shared.storage')}`;
        } else {
            return this._translate('shared.control', { COUNT: 1 });
        }
    }

    getJobActionName(deposit) {
        const job = HelpersService.getLoadJob(deposit);
        if (!job) {
            return '';
        } else if (job.action) {
            // In anticipation of being able to post-process a post-processing we would have a P 2 version!
            return this._translate('depositJobActionType', {
                LENGTH_POST_PROCESSING: 1,
            });
        } else {
            return 'N';
        }
    }

    loaderSingleFileNotice() {
        this._LoaderService.open(`
            <p>${this._translate('shared.downloadingFile')}</p>
            <p>${this._translate('shared.timeConsuming')}</p>
        `);
    }

    loaderMultiFilesNotice() {
        this._LoaderService.open(`
            <p>${this._translate('shared.downloadingFile')}</p>
            <p>${this._translate('shared.timeConsuming')}</p>
        `);
    }

    canCompare(deposit) {
        return deposit?.project && deposit.deliveryZone && deposit.phase;
    }
}

angular.module('dotic').controller('DepositListController', DepositListController);
