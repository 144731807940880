import { ColorService, DoughnutChartColorEnum } from '../../../../services/color.service';
import {
    FieldOccurrenceAdditionalFieldEnum,
    FieldOccurrenceKeyEnum,
    IndicatorFieldOccurrenceService,
} from '../../../../services/indicator-field-occurrence.service';
import template from './field-occurrence.component.html';

class FieldOccurrenceComponent {
    chartSize = 180;
    data = [];
    doughnutChartData = { bigLegend: {}, datasets: [], labels: [] };
    parentRef = {};

    constructor($indicatorFieldOccurrenceService, $filter) {
        this._$indicatorFieldOccurrenceService = $indicatorFieldOccurrenceService;
        this._translate = $filter('translate');
    }

    $onInit() {
        const formattedData = IndicatorFieldOccurrenceService.getFormattedFieldOccurrenceData(this.data);
        this.doughnutChartData = this.getDoughnutChartData(formattedData);
    }

    getDoughnutChartData(data) {
        const dataToDisplay = data.filter(
            (currentData) =>
                currentData[FieldOccurrenceKeyEnum.FIELD] !== FieldOccurrenceAdditionalFieldEnum.ADDRESS_COUNT,
        );
        const occurrenceCount =
            data.find(
                (currentData) =>
                    currentData[FieldOccurrenceKeyEnum.FIELD] === FieldOccurrenceAdditionalFieldEnum.ADDRESS_COUNT,
            )?.[FieldOccurrenceKeyEnum.OCCURRENCE_COUNT] ?? 0;

        const colors = dataToDisplay.map((currentData) => {
            if (currentData[FieldOccurrenceKeyEnum.FIELD] === FieldOccurrenceAdditionalFieldEnum.NON_SPECIFIC) {
                return DoughnutChartColorEnum.BIG_FAILURE;
            }

            return ColorService.getGraceFieldColor(currentData[FieldOccurrenceKeyEnum.FIELD]);
        });

        return {
            bigLegend: {
                figure: occurrenceCount,
                name: this._translate('shared.address', { COUNT: occurrenceCount }),
            },
            datasets: [
                {
                    backgroundColor: colors,
                    data: dataToDisplay.map((currentData) => currentData[FieldOccurrenceKeyEnum.OCCURRENCE_COUNT]),
                },
            ],
            labels: dataToDisplay.map((currentData) =>
                this._$indicatorFieldOccurrenceService.getDisplayedLabel(
                    currentData[FieldOccurrenceKeyEnum.FIELD],
                    currentData[FieldOccurrenceKeyEnum.OCCURRENCE_COUNT],
                ),
            ),
        };
    }
}

angular.module('dotic').component('fieldOccurrenceIndicator', {
    bindings: {
        data: '<',
        parentRef: '<',
    },
    controller: FieldOccurrenceComponent,
    templateUrl: template,
});
