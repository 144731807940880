/**
 * ConnectControl API Documentation (v1)
 * The official ConnectControl Open API documentation (v1)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";

/**
* Deposits service.
* @module api/DepositsApi
* @version 1.0.0
*/
export default class DepositsApi {

    /**
    * Constructs a new DepositsApi. 
    * @alias module:api/DepositsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Download a deposit file export
     * @param {String} depositId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    downloadExportWithHttpInfo(depositId) {
      let postBody = null;
      // verify the required parameter 'depositId' is set
      if (depositId === undefined || depositId === null) {
        throw new Error("Missing the required parameter 'depositId' when calling downloadExport");
      }

      let pathParams = {
        'depositId': depositId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/deposits/{depositId}/export/zip', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Download a deposit file export
     * @param {String} depositId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    downloadExport(depositId) {
      return this.downloadExportWithHttpInfo(depositId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Download a deposit qgis zip file
     * @param {String} depositId 
     * @param {String} deposit2 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    downloadQgisZipWithHttpInfo(depositId, deposit2) {
      let postBody = null;
      // verify the required parameter 'depositId' is set
      if (depositId === undefined || depositId === null) {
        throw new Error("Missing the required parameter 'depositId' when calling downloadQgisZip");
      }
      // verify the required parameter 'deposit2' is set
      if (deposit2 === undefined || deposit2 === null) {
        throw new Error("Missing the required parameter 'deposit2' when calling downloadQgisZip");
      }

      let pathParams = {
        'depositId': depositId
      };
      let queryParams = {
        'deposit2': deposit2
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/deposits/{depositId}/qgis/zip', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Download a deposit qgis zip file
     * @param {String} depositId 
     * @param {String} deposit2 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    downloadQgisZip(depositId, deposit2) {
      return this.downloadQgisZipWithHttpInfo(depositId, deposit2)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get a deposit by id
     * @param {Object.<String, Object>} depositId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    getByIdWithHttpInfo(depositId) {
      let postBody = null;
      // verify the required parameter 'depositId' is set
      if (depositId === undefined || depositId === null) {
        throw new Error("Missing the required parameter 'depositId' when calling getById");
      }

      let pathParams = {
        'depositId': depositId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/deposits/{depositId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get a deposit by id
     * @param {Object.<String, Object>} depositId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    getById(depositId) {
      return this.getByIdWithHttpInfo(depositId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get comparable deposits
     * @param {String} depositId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    getComparableDepositsWithHttpInfo(depositId) {
      let postBody = null;
      // verify the required parameter 'depositId' is set
      if (depositId === undefined || depositId === null) {
        throw new Error("Missing the required parameter 'depositId' when calling getComparableDeposits");
      }

      let pathParams = {
        'depositId': depositId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/deposits/{depositId}/comparable', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get comparable deposits
     * @param {String} depositId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    getComparableDeposits(depositId) {
      return this.getComparableDepositsWithHttpInfo(depositId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get deposit data for dashboard
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    getDashboardWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/deposits/dashboard', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get deposit data for dashboard
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    getDashboard() {
      return this.getDashboardWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get deposit job status
     * @param {String} depositId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    getJobStatusWithHttpInfo(depositId) {
      let postBody = null;
      // verify the required parameter 'depositId' is set
      if (depositId === undefined || depositId === null) {
        throw new Error("Missing the required parameter 'depositId' when calling getJobStatus");
      }

      let pathParams = {
        'depositId': depositId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/deposits/{depositId}/job-status', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get deposit job status
     * @param {String} depositId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    getJobStatus(depositId) {
      return this.getJobStatusWithHttpInfo(depositId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get a deposit list detail
     * @param {String} depositId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    getListDetailWithHttpInfo(depositId) {
      let postBody = null;
      // verify the required parameter 'depositId' is set
      if (depositId === undefined || depositId === null) {
        throw new Error("Missing the required parameter 'depositId' when calling getListDetail");
      }

      let pathParams = {
        'depositId': depositId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/deposits/{depositId}/list-details', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get a deposit list detail
     * @param {String} depositId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    getListDetail(depositId) {
      return this.getListDetailWithHttpInfo(depositId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get a deposit report
     * @param {String} depositId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    getReportWithHttpInfo(depositId) {
      let postBody = null;
      // verify the required parameter 'depositId' is set
      if (depositId === undefined || depositId === null) {
        throw new Error("Missing the required parameter 'depositId' when calling getReport");
      }

      let pathParams = {
        'depositId': depositId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/deposits/{depositId}/reports', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get a deposit report
     * @param {String} depositId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    getReport(depositId) {
      return this.getReportWithHttpInfo(depositId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List all deposits
     * @param {Object.<String, Object>} params 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    listWithHttpInfo(params) {
      let postBody = null;
      // verify the required parameter 'params' is set
      if (params === undefined || params === null) {
        throw new Error("Missing the required parameter 'params' when calling list");
      }

      let pathParams = {
      };
      let queryParams = {
        'params': params
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/deposits', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List all deposits
     * @param {Object.<String, Object>} params 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    list(params) {
      return this.listWithHttpInfo(params)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List control deposits with data models
     * @param {Object.<String, Object>} params 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    listControlDepositWithHttpInfo(params) {
      let postBody = null;
      // verify the required parameter 'params' is set
      if (params === undefined || params === null) {
        throw new Error("Missing the required parameter 'params' when calling listControlDeposit");
      }

      let pathParams = {
      };
      let queryParams = {
        'params': params
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/deposits/control', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List control deposits with data models
     * @param {Object.<String, Object>} params 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    listControlDeposit(params) {
      return this.listControlDepositWithHttpInfo(params)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List deposit with filters
     * @param {Object.<String, Object>} params 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    listFiltersWithHttpInfo(params) {
      let postBody = null;
      // verify the required parameter 'params' is set
      if (params === undefined || params === null) {
        throw new Error("Missing the required parameter 'params' when calling listFilters");
      }

      let pathParams = {
      };
      let queryParams = {
        'params': params
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/deposits/list-filters', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List deposit with filters
     * @param {Object.<String, Object>} params 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    listFilters(params) {
      return this.listFiltersWithHttpInfo(params)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List load deposits with data models
     * @param {Object.<String, Object>} params 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    listLoadDepositWithHttpInfo(params) {
      let postBody = null;
      // verify the required parameter 'params' is set
      if (params === undefined || params === null) {
        throw new Error("Missing the required parameter 'params' when calling listLoadDeposit");
      }

      let pathParams = {
      };
      let queryParams = {
        'params': params
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/deposits/load', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List load deposits with data models
     * @param {Object.<String, Object>} params 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    listLoadDeposit(params) {
      return this.listLoadDepositWithHttpInfo(params)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List deposit versions
     * @param {Object.<String, Object>} params 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    listVersionsWithHttpInfo(params) {
      let postBody = null;
      // verify the required parameter 'params' is set
      if (params === undefined || params === null) {
        throw new Error("Missing the required parameter 'params' when calling listVersions");
      }

      let pathParams = {
      };
      let queryParams = {
        'params': params
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/deposits/list-versions', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List deposit versions
     * @param {Object.<String, Object>} params 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    listVersions(params) {
      return this.listVersionsWithHttpInfo(params)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Remove a deposit
     * @param {Object.<String, Object>} depositId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    removeWithHttpInfo(depositId) {
      let postBody = null;
      // verify the required parameter 'depositId' is set
      if (depositId === undefined || depositId === null) {
        throw new Error("Missing the required parameter 'depositId' when calling remove");
      }

      let pathParams = {
        'depositId': depositId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/deposits/{depositId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Remove a deposit
     * @param {Object.<String, Object>} depositId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    remove(depositId) {
      return this.removeWithHttpInfo(depositId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Run deposit action
     * @param {String} depositId 
     * @param {Object.<String, Object>} body Action and post-processings
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    runActionWithHttpInfo(depositId, body) {
      let postBody = body;
      // verify the required parameter 'depositId' is set
      if (depositId === undefined || depositId === null) {
        throw new Error("Missing the required parameter 'depositId' when calling runAction");
      }
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling runAction");
      }

      let pathParams = {
        'depositId': depositId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/deposits/{depositId}/run-action', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Run deposit action
     * @param {String} depositId 
     * @param {Object.<String, Object>} body Action and post-processings
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    runAction(depositId, body) {
      return this.runActionWithHttpInfo(depositId, body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Launch a FME deposit
     * @param {String} depositId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    runsWithHttpInfo(depositId) {
      let postBody = null;
      // verify the required parameter 'depositId' is set
      if (depositId === undefined || depositId === null) {
        throw new Error("Missing the required parameter 'depositId' when calling runs");
      }

      let pathParams = {
        'depositId': depositId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/deposits/{depositId}/runs', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Launch a FME deposit
     * @param {String} depositId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    runs(depositId) {
      return this.runsWithHttpInfo(depositId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a deposit
     * @param {Object.<String, Object>} depositId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    updateWithHttpInfo(depositId) {
      let postBody = null;
      // verify the required parameter 'depositId' is set
      if (depositId === undefined || depositId === null) {
        throw new Error("Missing the required parameter 'depositId' when calling update");
      }

      let pathParams = {
        'depositId': depositId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/deposits/{depositId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Update a deposit
     * @param {Object.<String, Object>} depositId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    update(depositId) {
      return this.updateWithHttpInfo(depositId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
