angular.module('dotic').factory('deliveryZoneProvider', deliveryZoneProvider);

function deliveryZoneProvider(ApiProvider, URI_KEYS) {
    return {
        get,
        list,
        resolve,
    };

    /**
     * GET /deliveryZones/
     * @param {object} queryParams
     * @param {string} queryParams.serverId
     *
     * @returns {*} Promise
     */
    function list(queryParams = {}) {
        return ApiProvider.one(URI_KEYS.DELIVERY_ZONE).customGET(null, queryParams);
    }

    /**
     * GET /deliveryZones/:deliveryZoneId/
     * @param {string} deliveryZoneId
     * @returns {*} Promise
     */
    function get(deliveryZoneId) {
        return ApiProvider.one(URI_KEYS.DELIVERY_ZONE, deliveryZoneId).get();
    }

    /**
     * GET /deliveryZones/resolve-deposit/:project/:name
     * @param {string} projectName
     * @param {string} name
     * @returns {*} Promise
     */
    function resolve(projectName, name) {
        return ApiProvider.one(URI_KEYS.DELIVERY_ZONE, 'resolve-deposit').one(projectName).one(name).get();
    }
}
