import { ColorService } from '../../../../services/color.service';
import template from './local-number-by-site-logical-type.component.html';

const KeyEnum = {
    ADDRESS_COUNT: 'addressCount',
    LOGICAL_TYPE: 'logicalType',
};

class LocalNumberBySiteLogicalTypeComponent {
    columns = [];
    data = [];
    iconOptions = [];
    parentRef = {};
    tableTitle = '';

    constructor($filter) {
        this._translate = $filter('translate');
    }

    $onInit() {
        this.tableTitle = this._translate('shared.detailLocalCount');

        this.iconOptions = this.data.map((currentData) => {
            return {
                color: ColorService.getLogicalTypeColor(currentData[KeyEnum.LOGICAL_TYPE]),
                icon: 'home',
                label: currentData[KeyEnum.LOGICAL_TYPE],
                value: currentData[KeyEnum.ADDRESS_COUNT],
            };
        });

        this.columns = this.getColumns();
    }

    getColumns() {
        return [
            {
                key: KeyEnum.LOGICAL_TYPE,
                translation: this._translate('shared.logicalType'),
            },
            {
                key: KeyEnum.ADDRESS_COUNT,
                translation: this._translate('shared.addressCount'),
            },
        ];
    }
}

angular.module('dotic').component('localNumberBySiteLogicalTypeIndicator', {
    bindings: {
        data: '<',
        parentRef: '<',
    },
    controller: LocalNumberBySiteLogicalTypeComponent,
    templateUrl: template,
});
