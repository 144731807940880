import template from './supervision.html';

class SupervisionPage {
    constructor(
        $authorizationService,
        $deleteService,
        $filter,
        $location,
        $modalService,
        $scope,
        $tableService,
        $timeout,
        $toasterService,
        LoaderService,
        supervisionProvider,
    ) {
        this._$deleteService = $deleteService;
        this._translate = $filter('translate');
        this._$scope = $scope;
        this._supervisionProvider = supervisionProvider;
        this._$tableService = $tableService;
        this._LoaderService = LoaderService;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this.isAllowed = $authorizationService.isAllowed;
        this._$modalService = $modalService;

        this.loading = true;
        this.indicatorAlertList = [];

        $scope.$emit('updateNavigation', {
            newPage: [
                {
                    key: 'supervisionList',
                    title: this._translate('supervision.supervisionList'),
                    href: $location.path(),
                },
            ],
        });

        // Watch selection
        $scope.$on('selectRows', (e, data) => {
            this.tableSelect.select(data.rows, true);
        });
    }

    async $onInit() {
        await this.initData();
    }

    async initData() {
        this.loading = true;

        try {
            const indicatorsAlert = await this._supervisionProvider.getList();
            this.indicatorAlertList = indicatorsAlert.map((indicator) => {
                const companies = indicator.selectedUsers
                    .map((user) => user.companyName)
                    .filter((companyName, index, companies) => companies.indexOf(companyName) === index);

                return {
                    ...indicator,
                    companiesList: companies,
                };
            });
            this.tableSelect = this._$tableService.select();
        } catch (error) {
            this._$toasterService.error(error);
        }

        // Timeout necessary as AngularJs doesn't trigger digest cycle from async / await method or function
        this._$timeout(() => (this.loading = false));
    }

    selectVisibleRows() {
        this._$scope.$broadcast('getVisibleRows');
    }

    isAllowedToRemove(item) {
        return item && this.isAllowed(['superAdmin']);
    }

    getSelectionType() {
        const size = this.tableSelect.selectionSize();
        const removable = this.indicatorAlertList.filter((item) => this.isAllowedToRemove(item._id));

        if (size === removable.length) {
            return 'all';
        }

        if (size === 0) {
            return 'none';
        }

        if (size === 10) {
            return 'visible';
        }

        return 'few';
    }

    async removeAlert() {
        const selectedSupervisions = this.indicatorAlertList
            .filter(this.tableSelect.isActive)
            .filter((item) => this.isAllowedToRemove(item._id));

        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.supervision', {
                COUNT: selectedSupervisions.length,
                NAME: selectedSupervisions[0].name,
            }).toLowerCase(),
        );
        if (!isAccepted) {
            return;
        }

        const selectedIndicatorIds = this.tableSelect.getSelection();
        await this._$deleteService.deleteArrayOfIds(
            selectedIndicatorIds,
            (indicatorId) => this._supervisionProvider.deleteAlert(indicatorId),
            'removeModal.successSupervision',
        );

        await this.initData();
        this._LoaderService.dismiss();
    }

    async openCompanyModal(companies) {
        await this._$modalService.triggerListModal(
            companies.map((company) => ({
                name: company,
            })),
            this._translate('shared.company'),
        );
    }
}

angular.module('dotic').component('supervisionPage', {
    templateUrl: template,
    controller: SupervisionPage,
});
