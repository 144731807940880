angular.module('dotic').factory('projectProvider', projectProvider);

function projectProvider(ApiProvider, URI_KEYS) {
    return {
        delete: deleteProject,
        getAll: getAllProject,
        getAllDeliveryZone: getAllDeliveryZone,
        getAssignments: getProjectAssignments,
        refreshTables: refreshTables,
    };

    /**
     * DELETE /projects/:projectId
     *
     * @param {string} projectId
     *
     * @returns {*} Promise
     */
    function deleteProject(projectId) {
        return ApiProvider.one(URI_KEYS.PROJECT, projectId).remove();
    }

    /**
     * GET /projects/:projectId/assignments
     *
     * @param {string} projectId
     *
     * @returns {*} Promise
     */
    function getProjectAssignments(projectId) {
        return ApiProvider.one(URI_KEYS.PROJECT, projectId).one('assignments').get();
    }

    /**
     * GET /projects
     * @param {object} queryParams
     * @returns {*} Promise
     */
    function getAllProject(queryParams = {}) {
        return ApiProvider.one(URI_KEYS.PROJECT).get(queryParams);
    }

    /**
     * GET /projects/:projectId/deliveryZones
     *
     * @param {string} projectId
     *
     * @returns {*} Promise
     */
    function getAllDeliveryZone(projectId) {
        return ApiProvider.one(URI_KEYS.PROJECT, projectId).one(URI_KEYS.DELIVERY_ZONE).getList();
    }

    function refreshTables(projectId) {
        return ApiProvider.one(URI_KEYS.PROJECT, projectId).one('refresh').get();
    }
}
