angular.module('dotic').config(($stateProvider) => {
    $stateProvider.state('app.controlConfigurationList', {
        url: '/controlConfiguration?search',
        data: {
            authenticationRequired: true,
            roleRequired: ['admin'],
        },
    });
    $stateProvider.state('app.controlConfigurationGridsLink', {
        url: '/configurations/:controlConfigurationId/link',
        component: 'controlConfigurationGridsLinkPage',
        data: {
            authenticationRequired: true,
            roleRequired: ['admin'],
        },
    });
});
