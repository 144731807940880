export const DepositStatusEnum = {
    DONE: 'DONE',
    DONE_WITH_ERROR: 'DONE_WITH_ERROR',
    QUEUED: 'QUEUED',
    RUNNING: 'RUNNING',
    UNKNOWN: 'UNKNOWN',
};

export const FMEJobEnum = {
    CONTROL: 'control',
    GEOPACKAGE: 'geopackage',
    LOAD: 'load',
};
