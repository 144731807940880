import { FMEJobEnum } from '../../models/deposit.model';
import { HelpersService } from '../../services/helpers.service';

class DashboardController {
    chartMinErrors = null;
    chartAverageErrors = null;
    chartMaxErrors = null;
    controlConfigurations = [];
    controlGrids = [];
    dataLoading = true;
    depositsChartData = null;
    depositsLoading = true;
    errorChartOptions = {
        circumference: 180,
        cutout: '75%',
        legend: { display: false },
        rotation: -90,
        scales: {},
        tooltips: { enabled: false },
    };
    fillGrids = [];
    projects = [];
    statistics = {
        errorMin: Infinity,
        errorMax: 0,
        errorMoy: 0,
        totalErrors: 0,
        total: 0,
    };

    constructor(
        $auth,
        $authorizationService,
        $chartService,
        $filter,
        $location,
        $phaseService,
        $scope,
        $state,
        $toasterService,
        $userService,
        controlConfigurationProvider,
        depositProvider,
        fillGridProvider,
        gridControlProvider,
        projectProvider,
    ) {
        this._$phaseService = $phaseService;
        this._$toasterService = $toasterService;
        this._$scope = $scope;
        this._$state = $state;
        this._controlConfigurationProvider = controlConfigurationProvider;
        this._controlGridProvider = gridControlProvider;
        this._depositProvider = depositProvider;
        this._fillGridProvider = fillGridProvider;
        this._projectProvider = projectProvider;
        this._translate = $filter('translate');

        this.isAllowed = $authorizationService.isAllowed;

        this.horizontalBarChartOptions = {
            responsive: true,
            scales: $chartService.getDefaultScaleConfiguration(),
        };

        if (!$auth.getPayload()) {
            return;
        }

        this.userId = $auth.getPayload().userId;
        this.connectedCompany = $userService.getConnectedCompany();

        $scope.$emit('updateNavigation', {
            newPage: [
                {
                    key: 'dashboard',
                    title: this._translate('shared.dashboard'),
                    href: $location.path(),
                },
            ],
        });
    }

    async $onInit() {
        await this.setDepositData();
        if (this.isAllowed(['admin'])) {
            await this.setData();
        }
    }

    getDepositChartValues(depositsByDates) {
        return Object.entries(depositsByDates).reduce(
            (acc, [month, monthDeposits]) => {
                const successCount = monthDeposits.filter((deposit) =>
                    deposit.FMEJobs.some((job) => job.status === 'SUCCESS'),
                ).length;
                const failedCount = monthDeposits.filter((monthDeposit) =>
                    monthDeposit.FMEJobs.some((job) => job.status === 'FME_FAILURE' || job.status === 'JOB_FAILURE'),
                ).length;

                const chartDate = month.split('-');
                const date = moment(new Date(chartDate[1], chartDate[0] - 1, 1)).format('MMMM');

                return {
                    controlDeposits: [
                        ...acc.controlDeposits,
                        monthDeposits.filter((deposit) => HelpersService.getControlJob(deposit)).length,
                    ],
                    failedDeposits: [...acc.failedDeposits, failedCount],
                    loadedDeposits: [
                        ...acc.loadedDeposits,
                        monthDeposits.filter((deposit) => HelpersService.getLoadJob(deposit)).length,
                    ],
                    months: [...acc.months, `${date.capitalize()} ${chartDate[1]}`],
                    passedDeposits: [...acc.passedDeposits, successCount],
                };
            },
            {
                controlDeposits: [],
                failedDeposits: [],
                loadedDeposits: [],
                months: [],
                passedDeposits: [],
            },
        );
    }

    getStatistics(reports) {
        let counter = 0;
        const statistics = reports.reduce(
            (acc, report) => {
                if (!report.totalCount) {
                    return acc;
                }

                counter += 1;
                const rate = Math.round((100 * 100 * report.invalidCount) / report.totalCount) / 100;

                return {
                    totalErrors: acc.totalErrors + report.invalidCount,
                    total: acc.totalErrors + report.totalCount,
                    errorMax: Math.max(acc.errorMax, rate),
                    errorMin: Math.min(acc.errorMin, rate),
                    errorMoy: acc.errorMoy + rate,
                };
            },
            {
                errorMin: Infinity,
                errorMax: 0,
                errorMoy: 0,
                totalErrors: 0,
                total: 0,
            },
        );

        if (statistics.total && counter) {
            statistics.errorMoy = statistics.errorMoy / counter;
        }

        if (statistics.errorMin === Infinity) {
            statistics.errorMin = 0;
        }

        return statistics;
    }

    groupDepositsBySteps(deposits) {
        return deposits.reduce(
            (acc, deposit) => ({
                ...acc,
                [deposit.phase]: [...(acc[deposit.phase] || []), deposit],
            }),
            {},
        );
    }

    loadDepositChart(deposits) {
        const depositsByDates = deposits.reduce((acc, deposit) => {
            const date = moment(deposit.createdAt).format('MM-YYYY');
            if (!acc[date]) {
                acc[date] = [];
            }

            acc[date].push(deposit);

            return acc;
        }, {});

        const chartValues = this.getDepositChartValues(depositsByDates);

        this.depositsChartData = {
            labels: chartValues.months,
            datasets: [
                {
                    stack: 'status',
                    label: this._translate('shared.deliverableValidated'),
                    backgroundColor: '#00997D',
                    hoverBackgroundColor: '#008A70',
                    borderColor: '#fff',
                    borderWidth: 1,
                    data: chartValues.passedDeposits,
                },
                {
                    stack: 'status',
                    label: this._translate('shared.deliverableError'),
                    backgroundColor: '#DD3073',
                    hoverBackgroundColor: '#F5005E',
                    borderColor: '#fff',
                    borderWidth: 1,
                    data: chartValues.failedDeposits,
                },
                {
                    stack: 'job',
                    label: this._translate('shared.loadDeposit'),
                    pointBorderColor: '#fff',
                    backgroundColor: '#EDB349',
                    hoverBackgroundColor: '#FFAF1A',
                    borderColor: '#fff',
                    borderWidth: 1,
                    data: chartValues.loadedDeposits,
                },
                {
                    stack: 'job',
                    label: this._translate('shared.controlDeposit'),
                    pointBorderColor: '#fff',
                    backgroundColor: '#0B99E0',
                    hoverBackgroundColor: '#008BD1',
                    borderColor: '#fff',
                    borderWidth: 1,
                    data: chartValues.controlDeposits,
                },
            ],
        };
    }

    loadErrorCharts(errorMin, errorMoy, errorMax) {
        const labels = [this._translate('shared.anomaly'), this._translate('shared.successfulControl')];
        const baseDatasets = {
            label: this._translate('shared.anomaly'),
            borderWidth: 1,
        };

        this.chartMinErrors = {
            labels: labels,
            datasets: [
                {
                    ...baseDatasets,
                    data: [errorMin.toFixed(2), 100 - errorMin.toFixed(2)],
                    backgroundColor: ['rgba(0, 168, 137, 1)', 'rgba(230, 230, 230, 1)'],
                    borderColor: ['rgba(160, 212, 104, 1)', 'rgba(230, 230, 230, 1)'],
                },
            ],
        };

        this.chartAverageErrors = {
            labels: labels,
            datasets: [
                {
                    ...baseDatasets,
                    data: [errorMoy.toFixed(2), 100 - errorMoy.toFixed(2)],
                    backgroundColor: ['rgba(0, 153, 250, 1)', 'rgba(230, 230, 230, 1)'],
                    borderColor: ['rgba(0, 153, 250, 1)', 'rgba(230, 230, 230, 1)'],
                },
            ],
        };

        this.chartMaxErrors = {
            labels: labels,
            datasets: [
                {
                    ...baseDatasets,
                    data: [errorMax.toFixed(2), 100 - errorMax.toFixed(2)],
                    backgroundColor: ['rgba(221, 48, 115, 1)', 'rgba(230, 230, 230, 1)'],
                    borderColor: ['rgba(255, 119, 97, 1)', 'rgba(230, 230, 230, 1)'],
                },
            ],
        };
    }

    loadDoughnutCharts(deposits) {
        const controlDeposits = this.setDepositColors(
            deposits.filter((deposit) => HelpersService.getControlJob(deposit)),
        );
        const loadDeposits = this.setDepositColors(deposits.filter((deposit) => HelpersService.getLoadJob(deposit)));

        const loadDepositsBySteps = this.groupDepositsBySteps(loadDeposits);
        const controlDepositsBySteps = this.groupDepositsBySteps(controlDeposits);

        const sortedLoadDeposit = this.sortDeposit(loadDepositsBySteps);
        const sortedControlDeposit = this.sortDeposit(controlDepositsBySteps);

        const listLoadColors = Object.values(sortedLoadDeposit).map((value) => value[0]);
        const listControlColors = Object.values(sortedControlDeposit).map((value) => value[0]);

        this.depositLoadChartData = {
            bigLegend: {
                figure: loadDeposits.length,
                name: this._translate('shared.deliverable', { COUNT: loadDeposits.length }),
            },
            labels: Object.keys(sortedLoadDeposit),
            datasets: [
                {
                    data: Object.values(sortedLoadDeposit).map((value) => value.length),
                    backgroundColor: listLoadColors.map((value) => value.backgroundColor),
                    spec: FMEJobEnum.LOAD,
                },
            ],
        };

        this.depositControlChartData = {
            bigLegend: {
                figure: controlDeposits.length,
                name: this._translate('shared.report', { COUNT: controlDeposits.length }),
            },
            labels: Object.keys(sortedControlDeposit),
            datasets: [
                {
                    data: Object.values(sortedControlDeposit).map((value) => value.length),
                    backgroundColor: listControlColors.map((value) => value.backgroundColor),
                    spec: FMEJobEnum.CONTROL,
                },
            ],
        };
    }

    async setData() {
        this.dataLoading = true;

        try {
            this.controlConfigurations = await this._controlConfigurationProvider.getAll();
            this.controlGrids = await this._controlGridProvider.listAllControlGrids({
                limit: 1,
                page: 0,
                template: false,
            });
            this.fillGrids = await this._fillGridProvider.listAllFillGrids({ limit: 1, page: 0, template: false });
            this.projects = await this._projectProvider.getAll();
        } catch (error) {
            this._$toasterService.error(error);
        }

        this.dataLoading = false;
    }

    setDepositColors(deposits) {
        const colors = this._$phaseService.getColors();

        return deposits.map((deposit) => {
            deposit.backgroundColor = colors[deposit.phase].backgroundColor;

            return deposit;
        });
    }

    scrollElement(customClickEvent) {
        if (customClickEvent.isDoughnut) {
            return this._$state.go(`app.depositJob-${customClickEvent.typeDeposit ?? FMEJobEnum.CONTROL}`, {
                phase: customClickEvent.label,
            });
        }

        if (!customClickEvent.isBar) {
            return;
        }

        let statusDeposit = '';
        let jobDeposit = 'app.depositList';

        switch (customClickEvent.datasetIndexResult) {
            case 0:
                statusDeposit = 'SUCCESS';
                break;
            case 1:
                statusDeposit = 'QUEUED';
                break;
            case 2:
                statusDeposit = 'FME_FAILURE';
                break;
            case 3:
                jobDeposit = 'app.depositJob-load';
                break;
            case 4:
                jobDeposit = 'app.depositJob-control';
                break;
        }

        return this._$state.go(jobDeposit, {
            status: statusDeposit,
            createdAtStart: customClickEvent.label
                ? moment().month(customClickEvent.label.split(' ')[0]).startOf('month')
                : '',
            createdAtEnd: customClickEvent.label
                ? moment().month(customClickEvent.label.split(' ')[0]).endOf('month')
                : '',
        });
    }

    async setDepositData() {
        this.depositsLoading = true;

        try {
            const dashboard = await this._depositProvider.getDashboard();
            this.statistics = this.getStatistics(dashboard.reports);

            this.loadErrorCharts(
                dashboard.reports.length ? this.statistics.errorMin : 0,
                dashboard.reports.length ? this.statistics.errorMoy : 0,
                dashboard.reports.length ? this.statistics.errorMax : 0,
            );
            this.loadDoughnutCharts(dashboard.deposits);
            this.loadDepositChart(dashboard.deposits);
        } catch (error) {
            this._$toasterService.error(error);
        }

        this.depositsLoading = false;
    }

    sortDeposit(depositPhase) {
        // Sort max deposit phase
        return Object.entries(depositPhase)
            .sort(([, value1], [, value2]) => value2.length - value1.length)
            .reduce(
                (depositPhase, [key, deposit]) => ({
                    ...depositPhase,
                    [key]: deposit,
                }),
                {},
            );
    }
}

angular.module('dotic').controller('DashboardController', DashboardController);
