import template from './controlGridFromDeposit.html';

class ControlGridFromDepositController {
    constructor($scope, $state, $location, $stateParams, $filter, userMetricsProvider, depositProvider) {
        this._depositProvider = depositProvider;
        this._$state = $state;
        this._$scope = $scope;
        this._$location = $location;
        this._translate = $filter('translate');
        this._userMetricsProvider = userMetricsProvider;

        this.depositId = $stateParams.depositId;
        this.deposit = null;
        this.controlGridsEnableSum = 0;

        this.loading = true;
    }

    $onInit() {
        this.loading = true;

        if (!this.depositId) {
            throw new Error('invalid deposit id');
        }

        // Get deposit with grid control
        this._depositProvider.get(this.depositId).then((data) => {
            this._userMetricsProvider.openControlGridDeposit(this.depositId);

            this.loading = false;
            this.deposit = data;

            this.controlGridsEnableSum = this.deposit.gridControl.filter((item) => item.enable).length;

            this.controlGridName = `${this.deposit.gridControlName} ${moment(this.deposit.createdAt).format(
                'DD-MM-YYYY',
            )}`;

            this._$scope.$emit('keepPreviousNavigation', {
                newPage: [
                    {
                        key: 'gridControlFromDeposit',
                        title: `${this._translate('shared.controlGrid', { COUNT: 1 })} ${this.deposit.gridControlName}`,
                        href: this._$location.path(),
                    },
                ],
                defaultPrevious: {
                    key: 'report',
                    title: `${this._translate('shared.report', { COUNT: 1 })} ${data.name}`,
                    href: this._$state.href('app.reportDetail', {
                        reportId: data.report._id,
                    }),
                },
                allowedPreviousKeys: ['anctVerification', 'comparaison', 'deposits', 'report'],
            });
        });
    }
}

angular.module('dotic').component('controlGridFromDepositPage', {
    controller: ControlGridFromDepositController,
    templateUrl: template,
});
