angular.module('dotic').factory('userMetricsProvider', userMetricsProvider);

const Entities = {
    AGGREGATION: 'AGGREGATION',
    COMPANY: 'COMPANY',
    CONTROL_CONFIGURATION: 'CONTROL_CONFIGURATION',
    CONTROL_GRID: 'CONTROL_GRID',
    CONTROL_STATS: 'CONTROL_STATS',
    DELIVERY_ZONE: 'DELIVERY_ZONE',
    DEPOSIT: 'DEPOSIT',
    EXPORT_GEOPACKAGE: 'EXPORT_GEOPACKAGE',
    FILLING_STATS: 'FILLING_STATS',
    FILL_GRID: 'FILL_GRID',
    PROJECT: 'PROJECT',
    REPORT: 'REPORT',
    SERVER: 'SERVER',
    USER: 'USER',
};

const Actions = {
    CHANGE_COMPANY: 'CHANGE_COMPANY',
    COMPARE_CONTROL_GRIDS: 'COMPARE_CONTROL_GRIDS',
    COMPARE_FILLING_GRIDS: 'COMPARE_FILLING_GRIDS',
    COMPARE_REPORTS: 'COMPARE_REPORTS',
    DOWNLOAD_COMPARED_REPORTS: 'DOWNLOAD_COMPARED_REPORTS',
    DOWNLOAD_CONTROL_STATS: 'DOWNLOAD_CONTROL_STATS',
    DOWNLOAD_EXPORT_GEOPACKAGE: 'DOWNLOAD_EXPORT_GEOPACKAGE',
    DOWNLOAD_FILLING_STATS: 'DOWNLOAD_FILLING_STATS',
    DOWNLOAD_REPORT: 'DOWNLOAD_REPORT',
    DOWNLOAD_REPORT_ERRORS: 'DOWNLOAD_REPORT_ERRORS',
    DOWNLOAD_REPORT_FME_LOGS: 'DOWNLOAD_REPORT_FME_LOGS',
    DOWNLOAD_REPORT_FME_TARA: 'DOWNLOAD_REPORT_FME_TARA',
    DOWNLOAD_REPORT_QGIS: 'DOWNLOAD_REPORT_QGIS',
    GENERATE_AGGREGATION: 'GENERATE_AGGREGATION',
    GENERATE_CONTROL_STATS: 'GENERATE_CONTROL_STATS',
    GENERATE_FILLING_STATS: 'GENERATE_FILLING_STATS',
    LIST_AGGREGATIONS: 'LIST_AGGREGATIONS',
    LIST_CONTROLLED_DEPOSITS: 'LIST_CONTROLLED_DEPOSITS',
    LIST_CONTROL_CONFIGURATIONS: 'LIST_CONTROL_CONFIGURATIONS',
    LIST_CONTROL_GRIDS: 'LIST_CONTROL_GRIDS',
    LIST_CONTROL_STATS: 'LIST_CONTROL_STATS',
    LIST_DEPOSITS: 'LIST_DEPOSITS',
    LIST_FILLING_GRIDS: 'LIST_FILLING_GRIDS',
    LIST_FILLING_STATS: 'LIST_FILLING_STATS',
    LIST_PROJECTS: 'LIST_PROJECTS',
    LIST_SERVERS: 'LIST_SERVERS',
    LIST_STORED_DEPOSITS: 'LIST_STORED_DEPOSITS',
    LOGOUT: 'LOGOUT',
    OPEN_CONTROL_CONFIGURATION: 'OPEN_CONTROL_CONFIGURATION',
    OPEN_CONTROL_GRID: 'OPEN_CONTROL_GRID',
    OPEN_CONTROL_GRID_DEPOSIT: 'OPEN_CONTROL_GRID_DEPOSIT',
    OPEN_CONTROL_STATS: 'OPEN_CONTROL_STATS',
    OPEN_DELIVERY_ZONE: 'OPEN_DELIVERY_ZONE',
    OPEN_DEPOSIT_BULK: 'OPEN_DEPOSIT_BULK',
    OPEN_DEPOSIT_MODAL: 'OPEN_DEPOSIT_MODAL',
    OPEN_DEPOSIT_MVIEWER: 'OPEN_DEPOSIT_MVIEWER',
    OPEN_DEPOSIT_SYNOPTIC: 'OPEN_DEPOSIT_SYNOPTIC',
    OPEN_FILLING_GRID_DEPOSIT: 'OPEN_FILLING_GRID_DEPOSIT',
    OPEN_FILLING_STATS: 'OPEN_FILLING_STATS',
    OPEN_FILL_GRID: 'OPEN_FILL_GRID',
    OPEN_NOTIFICATIONS: 'OPEN_NOTIFICATIONS',
    OPEN_PROFILE: 'OPEN_PROFILE',
    OPEN_PROJECT: 'OPEN_PROJECT',
    OPEN_PROJECT_MVIEWER: 'OPEN_PROJECT_MVIEWER',
    OPEN_REPORT: 'OPEN_REPORT',
    OPEN_SERVER: 'OPEN_SERVER',
    OPEN_USERS_PANEL: 'OPEN_USERS_PANEL',
};

function userMetricsProvider(RestProvider, URI_KEYS) {
    return {
        changeCompany,
        compareControlGrids,
        compareFillingGrids,
        compareReports,
        downloadComparedReports,
        downloadControlStatistics,
        downloadExportGeopackage,
        downloadFillingStatistics,
        downloadReport,
        generateAggregation,
        generateControlStatistics,
        generateFillingStatistics,
        getActions,
        getLastActions,
        getMetricsByUser,
        listAggregations,
        listControlConfigurations,
        listControlGrids,
        listControlStatistics,
        listControlledDeposits,
        listDeposits,
        listFillingGrids,
        listFillingStatistics,
        listProjects,
        listServers,
        listStoredDeposits,
        logout,
        openControlConfiguration,
        openControlGrid,
        openControlGridDeposit,
        openControlStatistics,
        openDeliveryZone,
        openDepositBulk,
        openFillGrid,
        openFillingGridDeposit,
        openFillingStatistics,
        openMviewer,
        openNotifications,
        openProfile,
        openProject,
        openProjectMviewer,
        openServer,
        openSynoptic,
        openUsersPanel,
    };

    function downloadExportGeopackage(exportGeopackageId) {
        RestProvider.post([
            URI_KEYS.USER_METRIC,
            Entities.EXPORT_GEOPACKAGE,
            exportGeopackageId,
            Actions.DOWNLOAD_EXPORT_GEOPACKAGE,
        ]);
    }

    function getMetricsByUser(userId) {
        return RestProvider.get([URI_KEYS.USER_METRIC, 'user', userId]);
    }

    function openMviewer(depositId) {
        return RestProvider.post([URI_KEYS.USER_METRIC, Entities.DEPOSIT, depositId, Actions.OPEN_DEPOSIT_MVIEWER]);
    }

    function openSynoptic(depositId) {
        return RestProvider.post([URI_KEYS.USER_METRIC, Entities.DEPOSIT, depositId, Actions.OPEN_DEPOSIT_SYNOPTIC]);
    }

    function downloadReport(reportId) {
        return RestProvider.post([URI_KEYS.USER_METRIC, Entities.REPORT, reportId, Actions.DOWNLOAD_REPORT]);
    }

    function compareReports(reportId, reportId2) {
        return RestProvider.post([URI_KEYS.USER_METRIC, Entities.REPORT, reportId, Actions.COMPARE_REPORTS], {
            report2: reportId2,
        });
    }

    function openProject(projectId) {
        return RestProvider.post([URI_KEYS.USER_METRIC, Entities.PROJECT, projectId, Actions.OPEN_PROJECT]);
    }

    function listDeposits() {
        return RestProvider.post([URI_KEYS.USER_METRIC, Actions.LIST_DEPOSITS]);
    }

    function listStoredDeposits() {
        return RestProvider.post([URI_KEYS.USER_METRIC, Actions.LIST_STORED_DEPOSITS]);
    }

    function listControlledDeposits() {
        return RestProvider.post([URI_KEYS.USER_METRIC, Actions.LIST_CONTROLLED_DEPOSITS]);
    }

    function changeCompany(companyId) {
        return RestProvider.post([URI_KEYS.USER_METRIC, Entities.COMPANY, companyId, Actions.CHANGE_COMPANY]);
    }

    function downloadComparedReports(reportId, reportId2) {
        return RestProvider.post([URI_KEYS.USER_METRIC, Entities.REPORT, reportId, Actions.DOWNLOAD_COMPARED_REPORTS], {
            report2: reportId2,
        });
    }

    function openControlStatistics(statsId) {
        return RestProvider.post([URI_KEYS.USER_METRIC, Entities.CONTROL_STATS, statsId, Actions.OPEN_CONTROL_STATS]);
    }

    function downloadControlStatistics(statsId) {
        return RestProvider.post([
            URI_KEYS.USER_METRIC,
            Entities.CONTROL_STATS,
            statsId,
            Actions.DOWNLOAD_CONTROL_STATS,
        ]);
    }

    function listControlStatistics() {
        return RestProvider.post([URI_KEYS.USER_METRIC, Actions.LIST_CONTROL_STATS]);
    }

    function generateControlStatistics() {
        // Save the action even if it failed
        return RestProvider.post([URI_KEYS.USER_METRIC, Actions.GENERATE_CONTROL_STATS]);
    }

    function openFillingStatistics(statsId) {
        return RestProvider.post([URI_KEYS.USER_METRIC, Entities.FILLING_STATS, statsId, Actions.OPEN_FILLING_STATS]);
    }

    function listFillingStatistics() {
        return RestProvider.post([URI_KEYS.USER_METRIC, Actions.LIST_FILLING_STATS]);
    }

    function generateFillingStatistics() {
        // Save the action even if it failed
        return RestProvider.post([URI_KEYS.USER_METRIC, Actions.GENERATE_FILLING_STATS]);
    }

    function downloadFillingStatistics(statsId) {
        return RestProvider.post([
            URI_KEYS.USER_METRIC,
            Entities.FILLING_STATS,
            statsId,
            Actions.DOWNLOAD_FILLING_STATS,
        ]);
    }

    function listServers() {
        return RestProvider.post([URI_KEYS.USER_METRIC, Actions.LIST_SERVERS]);
    }

    function openServer(serverId) {
        return RestProvider.post([URI_KEYS.USER_METRIC, Entities.SERVER, serverId, Actions.OPEN_SERVER]);
    }

    function openUsersPanel() {
        return RestProvider.post([URI_KEYS.USER_METRIC, Actions.OPEN_USERS_PANEL]);
    }

    function openProjectMviewer(projectId) {
        return RestProvider.post([URI_KEYS.USER_METRIC, Entities.PROJECT, projectId, Actions.OPEN_PROJECT_MVIEWER]);
    }

    function listControlConfigurations() {
        return RestProvider.post([URI_KEYS.USER_METRIC, Actions.LIST_CONTROL_CONFIGURATIONS]);
    }

    function listControlGrids() {
        return RestProvider.post([URI_KEYS.USER_METRIC, Actions.LIST_CONTROL_GRIDS]);
    }

    function listFillingGrids() {
        return RestProvider.post([URI_KEYS.USER_METRIC, Actions.LIST_FILLING_GRIDS]);
    }

    function openDepositBulk() {
        return RestProvider.post([URI_KEYS.USER_METRIC, Actions.OPEN_DEPOSIT_BULK]);
    }

    function compareFillingGrids(gridId1, gridId2) {
        return RestProvider.post([URI_KEYS.USER_METRIC, Entities.FILL_GRID, gridId1, Actions.COMPARE_FILLING_GRIDS], {
            fillGrid2: gridId2,
        });
    }

    function compareControlGrids(gridId1, gridId2) {
        return RestProvider.post(
            [URI_KEYS.USER_METRIC, Entities.CONTROL_GRID, gridId1, Actions.COMPARE_CONTROL_GRIDS],
            {
                controlGrid2: gridId2,
            },
        );
    }

    function openControlGridDeposit(depositId) {
        return RestProvider.post([
            URI_KEYS.USER_METRIC,
            Entities.DEPOSIT,
            depositId,
            Actions.OPEN_CONTROL_GRID_DEPOSIT,
        ]);
    }

    function openFillingGridDeposit(depositId) {
        return RestProvider.post([
            URI_KEYS.USER_METRIC,
            Entities.DEPOSIT,
            depositId,
            Actions.OPEN_FILLING_GRID_DEPOSIT,
        ]);
    }

    function logout() {
        return RestProvider.post([URI_KEYS.USER_METRIC, Actions.LOGOUT]);
    }

    function openProfile(userId) {
        return RestProvider.post([URI_KEYS.USER_METRIC, Entities.USER, userId, Actions.OPEN_PROFILE]);
    }

    function openNotifications() {
        return RestProvider.post([URI_KEYS.USER_METRIC, Actions.OPEN_NOTIFICATIONS]);
    }

    function openDeliveryZone(deliveryZoneId) {
        return RestProvider.post([
            URI_KEYS.USER_METRIC,
            Entities.DELIVERY_ZONE,
            deliveryZoneId,
            Actions.OPEN_DELIVERY_ZONE,
        ]);
    }

    function openControlConfiguration(configId) {
        return RestProvider.post([
            URI_KEYS.USER_METRIC,
            Entities.CONTROL_CONFIGURATION,
            configId,
            Actions.OPEN_CONTROL_CONFIGURATION,
        ]);
    }

    function openFillGrid(fillGridId) {
        return RestProvider.post([URI_KEYS.USER_METRIC, Entities.FILL_GRID, fillGridId, Actions.OPEN_FILL_GRID]);
    }

    function openControlGrid(controlGridId) {
        return RestProvider.post([
            URI_KEYS.USER_METRIC,
            Entities.CONTROL_GRID,
            controlGridId,
            Actions.OPEN_CONTROL_GRID,
        ]);
    }

    function listProjects() {
        return RestProvider.post([URI_KEYS.USER_METRIC, Actions.LIST_PROJECTS]);
    }

    function getLastActions({ range = '', company = '', createdAtStart = '', createdAtEnd = '' }) {
        return RestProvider.get([URI_KEYS.USER_METRIC, 'lastActions'], {
            range,
            company,
            createdAtStart: encodeURIComponent(createdAtStart),
            createdAtEnd: encodeURIComponent(createdAtEnd),
        });
    }

    function getActions({ timeRange = '', company = '', createdAtStart = '', createdAtEnd = '' }) {
        return RestProvider.get([URI_KEYS.USER_METRIC, 'actions'], {
            range: timeRange,
            company,
            createdAtStart: encodeURIComponent(createdAtStart),
            createdAtEnd: encodeURIComponent(createdAtEnd),
        });
    }

    function generateAggregation() {
        return RestProvider.post([URI_KEYS.USER_METRIC, Actions.GENERATE_AGGREGATION]);
    }

    function listAggregations() {
        return RestProvider.post([URI_KEYS.USER_METRIC, Actions.LIST_AGGREGATIONS]);
    }
}
