import template from './housing-types-indicator.component.html';

class HousingTypesIndicatorComponent {
    data;
    isGraceV3;
    isRequestOptionActivated;
    parentRef = null;

    constructor() {
        this.collectifRequest = '';
        this.individualRequest = '';
        this.isDisplayingRequest = false;
    }

    $onInit() {
        if (this.isGraceV3) {
            this.collectifRequest =
                "SELECT COUNT(*) as attr1 FROM t_adresse WHERE (Case when ad_nblres::text ='' THEN 0 else ad_nblres::integer end Case when ad_nblpro::text ='' THEN 0 else ad_nblpro::integer end Case when ad_nblent::text ='' THEN 0 else ad_nblent::integer end Case when ad_nblpub::text ='' THEN 0 else ad_nblpub::integer end Case when ad_nblobj::text ='' THEN 0 else ad_nblobj::integer end Case when ad_nblope::text ='' THEN 0 else ad_nblope::integer end) > 3 ;";
            this.individualRequest =
                "SELECT COUNT(*) as attr1 FROM t_adresse WHERE (Case when ad_nblres::text ='' THEN 0 else ad_nblres::integer end Case when ad_nblpro::text ='' THEN 0 else ad_nblpro::integer end Case when ad_nblent::text ='' THEN 0 else ad_nblent::integer end Case when ad_nblpub::text ='' THEN 0 else ad_nblpub::integer end Case when ad_nblobj::text ='' THEN 0 else ad_nblobj::integer end Case when ad_nblope::text ='' THEN 0 else ad_nblope::integer end) < 3 ;";

            return;
        }

        this.collectifRequest = "SELECT COUNT(*) as lgt FROM t_adresse WHERE ad_itypeim = 'I'";
        this.individualRequest = "SELECT COUNT(*) as lgt FROM t_adresse WHERE ad_itypeim = 'P'";
    }

    toggleDisplayRequestButton() {
        this.parentRef.toggle();
        this.isDisplayingRequest = !this.isDisplayingRequest;
    }
}

angular.module('dotic').component('housingTypesIndicator', {
    bindings: {
        data: '<',
        isGraceV3: '<',
        isRequestOptionActivated: '<',
        parentRef: '<',
    },
    controller: HousingTypesIndicatorComponent,
    templateUrl: template,
});
