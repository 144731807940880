import { unique as radashUnique } from 'radash';

import { ColorService } from '../../../../services/color.service';
import { IndicatorService } from '../../../../services/indicator.service';
import template from './cable-length-by-owner-and-manager.component.html';

const KeyEnum = {
    CABLE_LENGTH: 'cableLength',
    MANAGER: 'manager',
    OWNER: 'owner',
};

class CableLengthByOwnerAndManagerComponent {
    chartData = { datasets: [], labels: [] };
    columns = [];
    data = [];
    formattedData = [];
    iconOptions = [];
    parentRef = {};
    tableTitle = '';

    constructor($filter) {
        this._translate = $filter('translate');
    }

    $onInit() {
        this.formattedData = IndicatorService.getFormattedKilometerData(this.data, [KeyEnum.CABLE_LENGTH]);
        this.iconOptions = this.getIconOptions(this.formattedData);

        const managers = radashUnique(this.data.map((currentData) => currentData[KeyEnum.MANAGER]));
        const colors = ColorService.getDistinctRandomColors(managers.length, ColorService.colorsPalette);

        const managerFormattedData = IndicatorService.getTableChartFormattedData(
            this.formattedData,
            KeyEnum.MANAGER,
            KeyEnum.OWNER,
            KeyEnum.CABLE_LENGTH,
        );
        this.chartData = IndicatorService.getTableChartData(
            managerFormattedData,
            (manager) => colors[managers.indexOf(manager)],
        );

        this.columns = this.getColumns();
        this.tableTitle = this._translate('reportCompare.detailLengthCable');
    }

    getColumns() {
        return [
            {
                key: KeyEnum.MANAGER,
                translation: this._translate('shared.manager'),
            },
            {
                key: KeyEnum.OWNER,
                translation: this._translate('shared.owner'),
            },
            {
                key: KeyEnum.CABLE_LENGTH,
                translation: `${this._translate('shared.length', { COUNT: 1 })} (km)`,
            },
        ];
    }

    getIconOptions(data) {
        const colors = ColorService.getDistinctRandomColors(data.length, ColorService.colorsPalette);

        return data.map((currentData, index) => {
            return {
                color: colors[index],
                icon: 'architecture-alt',
                label: `${currentData[KeyEnum.OWNER]} / ${currentData[KeyEnum.MANAGER]}`,
                unit: 'Km',
                value: currentData[KeyEnum.CABLE_LENGTH],
            };
        });
    }
}

angular.module('dotic').component('cableLengthByOwnerAndManagerIndicator', {
    bindings: {
        data: '<',
        parentRef: '<',
    },
    controller: CableLengthByOwnerAndManagerComponent,
    templateUrl: template,
});
