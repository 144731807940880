import { group as radashGroup, sum as radashSum, unique as radashUnique } from 'radash';

import { ChartService } from '../../../../services/chart.service';
import { ColorService } from '../../../../services/color.service';
import { IndicatorService } from '../../../../services/indicator.service';
import template from './cable-length-by-type.component.html';

const KeyEnum = {
    CABLE_LENGTH: 'cableLength',
    CABLE_LOGICAL_TYPE: 'cableLogicalType',
    CABLE_PHYSICAL_TYPE: 'cablePhysicalType',
    CABLE_TYPE: 'cableType',
};

class CableLengthByTypeComponent {
    chartData = { datasets: [], labels: [] };
    columns = [];
    data = [];
    formattedData = [];
    iconOptions = [];
    parentRef = {};
    tableTitle = '';

    constructor($filter) {
        this._translate = $filter('translate');
    }

    $onInit() {
        this.formattedData = IndicatorService.getFormattedKilometerData(this.data, [KeyEnum.CABLE_LENGTH]);
        this.iconOptions = this.getIconOptions(this.formattedData);
        this.chartData = this.getChartData(this.formattedData);
        this.columns = this.getColumns();
        this.tableTitle = this._translate('reportCompare.detailLengthCable');
    }

    getChartData(data) {
        const dataGroupByLogicalType = radashGroup(data, (currentData) => currentData[KeyEnum.CABLE_LOGICAL_TYPE]);
        const cableTypes = radashUnique(data.map((currentData) => currentData[KeyEnum.CABLE_TYPE]));

        const datasets = Object.entries(dataGroupByLogicalType).map(([key, values]) => {
            const cableLengths = cableTypes.map((cableType) => {
                const findValue = values.find((currentValue) => currentValue[KeyEnum.CABLE_TYPE] === cableType);

                return findValue?.[KeyEnum.CABLE_LENGTH] || 0;
            });

            return ChartService.getTableChartDataset(cableLengths, key, ColorService.getCableLogicalTypeColor(key));
        });

        return {
            datasets: datasets,
            labels: cableTypes,
        };
    }

    getColumns() {
        return [
            {
                key: KeyEnum.CABLE_TYPE,
                translation: this._translate('shared.type'),
            },
            {
                key: KeyEnum.CABLE_LOGICAL_TYPE,
                translation: this._translate('shared.logicalType'),
            },
            {
                key: KeyEnum.CABLE_PHYSICAL_TYPE,
                translation: this._translate('shared.physicalType'),
            },
            {
                key: KeyEnum.CABLE_LENGTH,
                translation: `${this._translate('shared.length', { COUNT: 1 })} (km)`,
            },
        ];
    }

    getIconOptions(data) {
        const groupDataByLogicalType = radashGroup(data, (currentData) => currentData[KeyEnum.CABLE_LOGICAL_TYPE]);

        return radashUnique(
            Object.entries(groupDataByLogicalType).map(([key, currentData]) => {
                return {
                    color: ColorService.getCableLogicalTypeColor(key),
                    textIcon: key,
                    unit: 'Km',
                    value: radashSum(currentData, (value) => value[KeyEnum.CABLE_LENGTH]),
                };
            }),
            (currentData) => currentData.textIcon,
        );
    }
}

angular.module('dotic').component('cableLengthByTypeIndicator', {
    bindings: {
        data: '<',
        parentRef: '<',
    },
    controller: CableLengthByTypeComponent,
    templateUrl: template,
});
