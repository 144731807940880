import controlConfigurationFormModalTemplate from '../../controlConfiguration/formModal/controlConfigurationFormModal.html';
import template from './controlConfigurationDetail.html';

class ControlConfigurationDetailPage {
    isModalOpen = false;
    loading = true;

    constructor(
        $auth,
        $filter,
        $location,
        $modalService,
        $scope,
        $state,
        $stateParams,
        $toasterService,
        $uibModal,
        controlConfigurationProvider,
        projectProvider,
        userMetricsProvider,
    ) {
        this._$state = $state;
        this._$modalService = $modalService;
        this._$toasterService = $toasterService;
        this._$uibModal = $uibModal;

        this._controlConfigurationProvider = controlConfigurationProvider;
        this._projectProvider = projectProvider;
        this._translate = $filter('translate');

        this._companyId = $auth.getPayload().company;
        this.controlConfigurationId = $stateParams.controlConfigurationId;

        $scope.$emit('keepPreviousNavigation', {
            newPage: [
                {
                    title: this._translate('shared.configurationDetail'),
                    key: 'controlConfigurationDetail',
                    href: $location.path(),
                },
            ],
            defaultPrevious: {
                title: this._translate('shared.controlConfiguration'),
                href: $state.href('app.controlConfigurationList'),
                key: 'controlConfigurations',
            },
            allowedPreviousKeys: [
                'anctVerification',
                'comparaison',
                'controlConfigurations',
                'controlGrid',
                'deposits',
                'fillGrid',
                'projectDetail',
                'report',
            ],
        });

        userMetricsProvider.openControlConfiguration(this.controlConfigurationId);
    }

    $onInit() {
        this.loading = true;
        this.getControlConfigurations(this.controlConfigurationId).then(() => {
            this.loading = false;
        });
    }

    static addCCData(cc) {
        if (cc.startAt && cc.endAt) {
            cc.date = `${new Date(cc.startAt).toLocaleDateString('fr-FR')} - ${new Date(cc.endAt).toLocaleDateString(
                'fr-FR',
            )}`;
        }

        cc.countProjects = cc.projects.length;

        return cc;
    }

    getControlConfigurations(controlConfigurationId) {
        return this._controlConfigurationProvider.get(controlConfigurationId).then((controlConfiguration) => {
            this.controlConfiguration = {
                ...controlConfiguration,
                countProjects: controlConfiguration.projects.length,
            };

            this.loading = false;
        });
    }

    createControlConfiguration() {
        this.isModalOpen = true;
        this.openModal(false, {
            company: this._companyId,
            project: [],
        });
    }

    async removeControlConfiguration() {
        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.controlConfiguration', {
                COUNT: 1,
                NAME: this.controlConfiguration.name,
            }).toLowerCase(),
        );
        if (!isAccepted) {
            return;
        }

        try {
            await this._controlConfigurationProvider.delete(this.controlConfiguration.id);
            this._$toasterService.info(this._translate('removeModal.successControlConfiguration'));
            await this._$state.go('app.controlConfigurationList');
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    openModal(isEditMode, controlConfiguration) {
        const modal = this._$uibModal.open({
            templateUrl: controlConfigurationFormModalTemplate,
            controller: 'ControlConfigurationFormModalController as $ctrl',
            size: 'sm',
            backdrop: 'static',
            resolve: {
                isEditMode,
                controlConfiguration,
            },
        });

        modal.result
            .then(
                (controlConfiguration) => {
                    if (isEditMode) {
                        this.$onInit();
                    } else {
                        this._$state.go('app.controlConfigurationDetail', {
                            controlConfigurationId: controlConfiguration.id,
                        });
                    }
                },
                () => {},
            )
            .finally(() => {
                this.isModalOpen = false;
            });
    }

    async duplicateOrUpdateControlConfiguration(isEditMode) {
        this.isModalOpen = true;

        try {
            const projects = await this._projectProvider.getAll({
                controlConfigurations: [this.controlConfiguration.id],
            });

            this.openModal(isEditMode, {
                ...(isEditMode && { ...this.controlConfiguration }),
                company: this._companyId,
                dataModel: this.controlConfiguration.dataModel ?? null,
                fillGrid: this.controlConfiguration.fillGrid?.id ?? null,
                controlGrid: this.controlConfiguration.controlGrid?.id ?? null,
                project: projects.map((project) => project.id),
                startAt: this.controlConfiguration.startAt ? new Date(this.controlConfiguration.startAt) : null,
                endAt: this.controlConfiguration.endAt ? new Date(this.controlConfiguration.endAt) : null,
            });
        } catch {
            this._$toasterService.error(this._translate('toaster.error'));
            this.isModalOpen = false;
        }
    }

    configurationHasDependencies() {
        if (!this.controlConfiguration) {
            return false;
        }

        return this.controlConfiguration.countDeposits > 0;
    }
}

angular.module('dotic').component('controlConfigurationDetailPage', {
    controller: ControlConfigurationDetailPage,
    templateUrl: template,
});
