class CcTableController {
    constructor($compile, $element, $scope, $timeout, $state, $stateParams, $filter) {
        this._$scope = $scope;
        this._$state = $state;
        this._$stateParams = $stateParams;
        this._$timeout = $timeout;
        this._translate = $filter('translate');

        this.rows = $scope.rows || [];
        this.paginationWidth = $scope.paginationWidth || 11;
        this.pagination = {
            page: this.getPageNumber(),
            pageSize: Number.parseInt($scope.pageSize) || 10,
            nbPages: 1,
        };

        this.sortedItems = [];
        this.sort = {
            column: '',
            asc: true,
        };
        this.infinite = Boolean($scope.infinite);

        const footerTemplate = `<div class="table__footer">
<span class="table__length-selector">${this._translate(
            'shared.show',
        )}<select class="table__length-selector-select" ng-model="pageSize" ng-options="size for size in pageSizes"></select>${this._translate('shared.result')}</span><span class="table__nbrows"></span>
<span class="table__paging"></span></div>`;

        const pageSizes = [10, 20, 50, 100];
        if (!pageSizes.includes(this.pagination.pageSize)) {
            pageSizes.push(this.pagination.pageSize);
            pageSizes.sort((a, b) => a - b);
        }

        $scope.$watch('rows', () => {
            this.rows = $scope.rows || [];
            this.sortItems();
            this.createNbRowsSpan();
        });

        // Compare params and reset pagination on change
        $scope.$watchCollection(
            () => $stateParams,
            (newParams, oldParams) => {
                const a = { ...newParams };
                delete a.p;

                const b = { ...oldParams };
                delete b.p;

                if (!angular.equals(a, b)) {
                    this.sortItems(1);
                }
            },
        );

        $scope.$on('getVisibleRows', () => {
            const rows = (this.rows || [])
                .slice(0)
                .sort(getCompareFunction(this.sort.column.split(/\./), this.sort.asc));
            const { page, pageSize } = this.pagination;
            const minIndex = (page - 1) * pageSize;
            const maxIndex = minIndex + pageSize;

            $scope.$emit('selectRows', {
                rows: rows.slice(minIndex, maxIndex),
            });
        });

        $scope.$on('getAllRows', () => {
            const rows = this.rows || [];
            $scope.$emit('selectRows', {
                rows,
            });
        });

        $timeout(() => {
            let tableLines = angular.element('body').find('.table__content-table [cc-table-repeat]');
            if (tableLines.length === 0) {
                tableLines = angular.element('body').find('.table__content-table tbody tr');
            }

            tableLines.each((i, e) => {
                const hasLink = angular.element(e).find('td[ui-sref], td[data-ui-sref], td[ng-click]').length > 0;
                if (hasLink) {
                    angular.element(e).addClass('table-row--with-link');
                }
            });
        });

        const paginationScope = $scope.$new(true);
        paginationScope.pageSizes = pageSizes;
        paginationScope.pageSize = this.pagination.pageSize;

        paginationScope.$watch('pageSize', () => {
            this.pagination.pageSize = paginationScope.pageSize;
            this.sortItems();
        });

        if (!this.infinite) {
            const footer = $compile(footerTemplate)(paginationScope);
            $element.after(footer);

            this.paging = footer.find('span.table__paging');
            this.numberOfRows = footer.find('span.table__nbrows');
        }
    }

    getPageNumber() {
        const { p } = this._$stateParams;

        // Get page number from query string params
        let startPage = p ? parseInt(p) : 1;
        if (!angular.isNumber(startPage) || isNaN(startPage)) {
            startPage = 1;
        }

        // Safety check
        if (this.pagination && startPage > this.pagination.nbPages) {
            startPage = 1;
        }

        return startPage;
    }

    /**
     * @param page force the current page number
     */
    sortItems(page = null) {
        this.computeTotalPages();

        let pageNumber = page;
        if (!pageNumber) {
            pageNumber = this.getPageNumber();
        }

        const attributes = this.sort.column.split(/\./);
        this.sortedItems = (this.rows || []).slice(0).sort(getCompareFunction(attributes, this.sort.asc));

        this.setPage(pageNumber);
        this.updateSortIcons();
    }

    setPage(page) {
        this.pagination.page = page;

        // Update state
        if (page !== parseInt(this._$stateParams.p)) {
            this._$timeout(() => {
                const p = page > 1 ? page : null;
                this._$state.go('.', { p });
            });
        }

        if (!this.infinite) {
            this.createPageLinks();
        }

        this.updatePage();
    }

    createNbRowsSpan() {
        if (this.numberOfRows) {
            this.numberOfRows.contents().detach();
            if (this.rows.length !== 0) {
                const line = angular.element(`<span> | ${this.rows.length}</span>`);
                this.numberOfRows.append(line);
            }
        }
    }

    createPageLinks() {
        this.paging.contents().detach();
        if (this.pagination.nbPages > 1) {
            const pageLinks = [this.createPreviousLink()];
            if (this.pagination.nbPages <= this.paginationWidth) {
                for (let i = 1; i <= this.pagination.nbPages; i++) {
                    pageLinks.push(this.createPageLink(i));
                }
            } else {
                this.addPageLinksWithEllipses(pageLinks);
            }

            pageLinks.push(this.createNextLink());
            this.paging.append(pageLinks);
        }
    }

    addPageLinksWithEllipses(pageLinks) {
        let before = Math.floor((this.paginationWidth - 1) / 2);
        let after = Math.floor(this.paginationWidth / 2);
        let ellipsisBefore = true;
        let ellipsisAfter = true;
        let start, end;
        if (before > this.pagination.page - 1) {
            after += before - this.pagination.page + 1;
            before = this.pagination.page - 1;
            ellipsisBefore = false;
            start = 1;
        } else if (after > this.pagination.nbPages - this.pagination.page) {
            before += after - this.pagination.nbPages + this.pagination.page;
            after = this.pagination.nbPages - this.pagination.page;
            ellipsisAfter = false;
            end = this.pagination.nbPages;
        }

        start = start || this.pagination.page - before + 2;
        end = end || this.pagination.page + after - 2;
        if (ellipsisBefore) {
            pageLinks.push(this.createPageLink(1));
            pageLinks.push(this.createPageLink());
        }

        for (let i = start; i <= end; i++) {
            pageLinks.push(this.createPageLink(i));
        }
        if (ellipsisAfter) {
            pageLinks.push(this.createPageLink());
            pageLinks.push(this.createPageLink(this.pagination.nbPages));
        }
    }

    createPageLink(page) {
        const link = angular.element(`<span class="table__paging-item">${page || '...'}</span>`);
        if (page) {
            link.click(() => {
                this._$scope.$apply(() => {
                    this.setPage(page);
                });
            });
        }

        if (page === this.pagination.page) {
            link.addClass('table__paging-item--active');
        }

        return link;
    }

    createPreviousLink() {
        const link = angular.element(
            `<span class="table__paging-item table__paging-item--prev" title="${this._translate(
                'shared.toPrevious',
            )}">${this._translate('shared.previous')}</span>`,
        );
        if (this.pagination.page === 1) {
            link.attr('disabled');
        } else {
            link.click(() => {
                this._$scope.$apply(() => {
                    this.setPage(this.pagination.page - 1);
                });
            });
        }

        return link;
    }

    createNextLink() {
        const link = angular.element(
            `<span class="table__paging-item table__paging-item--next" title="${this._translate(
                'shared.toNext',
            )}">${this._translate('shared.next')}</span>`,
        );
        if (this.pagination.page === this.pagination.nbPages) {
            link.attr('disabled');
        } else {
            link.click(() => {
                this._$scope.$apply(() => {
                    this.setPage(this.pagination.page + 1);
                });
            });
        }

        return link;
    }

    updatePage() {
        if (this.infinite) {
            this._$scope.$parent[this.currentPage] = this.sortedItems;
        } else {
            const firstItemIndex = (this.pagination.page - 1) * this.pagination.pageSize;
            this._$scope.$parent[this.currentPage] = this.sortedItems.slice(
                firstItemIndex,
                firstItemIndex + this.pagination.pageSize,
            );
        }
    }

    computeTotalPages() {
        this.pagination.nbPages = Math.max(1, Math.ceil(this.rows.length / this.pagination.pageSize));
    }

    toggleSort(sortColumn) {
        if (this.sort.column === sortColumn) {
            this.sort.asc = !this.sort.asc;
        } else {
            this.sort.asc = true;
            this.sort.column = sortColumn;
        }

        this.sortItems();
    }

    updateSortIcons() {
        this._$timeout(() => {
            this.sortColumns.forEach((sortColumn) => {
                sortColumn.find('span[class^="table__content-header-cell"]').detach();
                if (this.sort.column === sortColumn.attr('cc-table-sort')) {
                    sortColumn.append(
                        angular.element(
                            this.sort.asc
                                ? '<span class="table__content-header-cell--asc"></span>'
                                : '<span class="table__content-header-cell--desc"></span>',
                        ),
                    );
                }
            });
        });
    }
}

function getCompareFunction(attributes, asc) {
    return function (i1, i2) {
        let i = 0;
        while (i < attributes.length && i1 != null && i2 != null) {
            // eslint-disable-next-line no-param-reassign
            i1 = i1[attributes[i]];
            // eslint-disable-next-line no-param-reassign
            i2 = i2[attributes[i]];
            i++;
        }

        if (i1 != null && i2 != null) {
            const result = angular.isString(i1) ? i1.localeCompare(i2) : i1 - i2;

            return asc ? result : -result;
        } else {
            return i1 != null ? -1 : 1;
        }
    };
}

angular.module('dotic').controller('ccTableController', CcTableController);
