import { DataModelSlugNameEnum } from '../models/data-model.model';

class DataModelService {
    constructor($toasterService) {
        this._$toasterService = $toasterService;
    }

    excelToJson(dataModels) {
        return dataModels.map((dataModel) => {
            return {
                name: dataModel.NOM,
                code: dataModel.CODE,
                label: dataModel.LIBELLE,
                description: dataModel.DESCRIPTION,
            };
        });
    }

    // Case of dataModelName is to be kept for compatibilities.
    isDataGraceTHDV3(data) {
        return (
            [DataModelSlugNameEnum.GRACETHDV300, DataModelSlugNameEnum.GRACETHDV301].includes(data?.dataModel?.slug) ||
            data?.dataModelName === 'grace thd 3.0.0'
        );
    }
}

angular.module('dotic').factory('$dataModelService', () => new DataModelService());
