// Imports
var ___HTML_LOADER_IMPORT_0___ = require("../../../../../assets/images/help/fttxDataEvolution/fttx-view-info.png");
var ___HTML_LOADER_IMPORT_1___ = require("../../../../../assets/images/help/fttxDataEvolution/fttx-view-comparison.png");
var ___HTML_LOADER_IMPORT_2___ = require("../../../../../assets/images/help/fttxDataEvolution/fttx-view-download.png");
var ___HTML_LOADER_IMPORT_3___ = require("../../../../../assets/images/help/fttxDataEvolution/fttx-view-indicators-thematic.png");
var ___HTML_LOADER_IMPORT_4___ = require("../../../../../assets/images/help/fttxDataEvolution/fttx-view-indicators-differences-details.png");
// Module
var code = "<div class=\"wrapper wrapper-content help-Doc-body\"> <h3 class=\"help-Doc__subTitre3 help_Doc__paddingBottom-paddingTop\"> 11.3 / {{ 'shared.fttxDataEvolutionView' | translate }} : </h3> <ul class=\"help-Doc__ul\"> <li class=\"help-Doc__subTitre4\"> {{ 'shared.informations' | translate }} : <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_0___ + "\" alt=\"{{ 'shared.informations' | translate }}\"/> </li> <li class=\"help-Doc__subTitre4\"> {{ 'shared.comparison' | translate }} : <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_1___ + "\" alt=\"{{ 'shared.comparison' | translate }}\"/> </li> <li class=\"help-Doc__subTitre4\"> {{ 'shared.downloading' | translate }} : <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_2___ + "\" alt=\"{{ 'shared.downloading' | translate }}\"/> </li> <li class=\"help-Doc__subTitre4\"> {{ 'shared.indicators' | translate }} : <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_3___ + "\" alt=\"{{ 'shared.indicators' | translate }}\"/> {{ 'shared.differencesDetails' | translate }} : <img class=\"help-Doc__img help-Doc__W95\" src=\"" + ___HTML_LOADER_IMPORT_4___ + "\" alt=\"{{ 'shared.differencesDetails' | translate }}\"/> </li> </ul> </div> ";
// Exports
var _module_exports = code;;
var path = '/app/pages/help/userManual/fttx-data-evolution/fttx-data-evolution-help-view.html';
window.angular.module('dotic').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;