import template from './nbControlChart.html';

class NbControlChartController {
    constructor($chartService, $filter, $indicatorNumberControlService) {
        this._$filter = $filter;
        this._$indicatorNumberControlService = $indicatorNumberControlService;
        this.loading = true;

        this.nbControl = false;
        this.isStatisticData = false;

        this.controlPointData = {};
        this.reportControls = [];
        this.chartSize = 200;

        this.anomalyVsSuccessData = null;
        this.anomalySuccessNbBySeverity = null;

        this.getHorizontalBarChartOptions = {
            responsive: true,
            tooltips: {
                enabled: false,
            },
            scales: $chartService.getDefaultScaleConfiguration(true, { fontSize: 12, minRotation: 8 }),
        };
    }

    $onChanges() {
        if (angular.isUndefined(this.reportControls)) {
            return null;
        }

        if (angular.isDefined(this.size)) {
            this.chartSize = parseInt(this.size, 10);
        }

        this.loading = true;

        const nbControlData = {
            minorAnomalyNb: 0,
            majorAnomalyNb: 0,
            blockingAnomalyNb: 0,
            minorSuccessNb: 0,
            majorSuccessNb: 0,
            blockingSuccessNb: 0,
            numberTotalControl: 0,
            anomalyNumberTotalCount: 0,
            successNumberTotalCount: 0,
        };

        this.reportControls.forEach((control) => {
            control.total_count = this.parseValue(control.total_count);
            control.invalid_count = this.parseValue(control.invalid_count);
            control.valid_count = this.parseValue(control.valid_count);

            nbControlData.numberTotalControl += this.isStatisticData ? control.total : control.total_count;
            nbControlData.anomalyNumberTotalCount += this.isStatisticData ? control.faults : control.invalid_count;
            nbControlData.successNumberTotalCount += this.isStatisticData ? control.success : control.valid_count;

            if (control.faults || control.invalid_count) {
                this._$indicatorNumberControlService.increaseCountingNbControls(
                    nbControlData,
                    control,
                    this.isStatisticData,
                    'minorAnomalyNb',
                    'minorSuccessNb',
                    'majorAnomalyNb',
                    'majorSuccessNb',
                    'blockingAnomalyNb',
                    'blockingSuccessNb',
                );

                return;
            }

            if (!control.faults || !control.invalid_count) {
                this._$indicatorNumberControlService.increaseCountingNbControls(
                    nbControlData,
                    control,
                    this.isStatisticData,
                    'minorAnomalyNb',
                    'minorSuccessNb',
                    'majorAnomalyNb',
                    'majorSuccessNb',
                    'blockingAnomalyNb',
                    'blockingSuccessNb',
                );
            }
        });

        this.anomalyVsSuccessData = this._$indicatorNumberControlService.loadAnomalyVsSuccessChart(
            nbControlData,
            [],
            false,
        );

        this.anomalySuccessNbBySeverity = this._$indicatorNumberControlService.loadAnomalySuccessBySeverityChart(
            nbControlData,
            [],
            false,
        );

        this.loading = false;
    }

    parseValue(rawValue) {
        return angular.isNumber(rawValue) && !isNaN(rawValue) ? rawValue : 0;
    }

    onClickAction(customClickEvent) {
        if (!this.getEvent) {
            return;
        }

        this.onClick({ customClickEvent: customClickEvent });
    }
}

angular.module('dotic').component('nbControlChart', {
    controller: NbControlChartController,
    bindings: {
        getEvent: '<',
        isStatisticData: '<',
        notResponsive: '<',
        onClick: '&',
        reportControls: '<',
        size: '<',
    },
    templateUrl: template,
});
