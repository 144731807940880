angular.module('dotic').config(($stateProvider) => {
    $stateProvider.state('app.sendToExternalPlatform', {
        url: '/superAdmin/sendToExternalPlatform',
        data: {
            authenticationRequired: true,
            roleRequired: ['superAdmin'],
        },
    });

    $stateProvider.state('app.sendToExternalPlatformAdmin', {
        url: '/sendToExternalPlatform',
        data: {
            authenticationRequired: true,
            roleRequired: ['admin'],
        },
    });
});
