class ControlPointService {
    constructor($filter) {
        this._translate = $filter('translate');
    }

    computeChartData(controlPointData) {
        const controlPointDataSummary = {
            blockingSuccessControls: 0,
            majorSuccessControls: 0,
            minorSuccessControls: 0,

            blockingAnomalyControls: 0,
            majorAnomalyControls: 0,
            minorAnomalyControls: 0,

            blockingErrorControls: 0,
            majorErrorControls: 0,
            minorErrorControls: 0,

            blockingWarningControls: 0,
            majorWarningControls: 0,
            minorWarningControls: 0,

            successControls: 0,
            anomalyControl: 0,
            errorControls: 0,
            warningControls: 0,
        };

        controlPointData.forEach((control) => {
            if (control.faults > 0) {
                controlPointDataSummary.anomalyControl += 1;

                this.increaseCountingControls(
                    controlPointDataSummary,
                    control.severity,
                    'minorAnomalyControls',
                    'majorAnomalyControls',
                    'blockingAnomalyControls',
                );

                return;
            }

            if (control.faults < 0) {
                if (!control.warningControlPoint) {
                    controlPointDataSummary.errorControls += 1;
                    this.increaseCountingControls(
                        controlPointDataSummary,
                        control.severity,
                        'minorErrorControls',
                        'majorErrorControls',
                        'blockingErrorControls',
                    );

                    return;
                }

                controlPointDataSummary.warningControls += 1;
                this.increaseCountingControls(
                    controlPointDataSummary,
                    control.severity,
                    'minorWarningControls',
                    'majorWarningControls',
                    'blockingWarningControls',
                );

                return;
            }

            if (control.faults === 0 && control.total === 0) {
                if (!control.warning) {
                    controlPointDataSummary.errorControls += 1;
                    this.increaseCountingControls(
                        controlPointDataSummary,
                        control.severity,
                        'minorErrorControls',
                        'majorErrorControls',
                        'blockingErrorControls',
                    );

                    return;
                }

                controlPointDataSummary.warningControls += 1;
                this.increaseCountingControls(
                    controlPointDataSummary,
                    control.severity,
                    'minorWarningControls',
                    'majorWarningControls',
                    'blockingWarningControls',
                );

                return;
            }

            controlPointDataSummary.successControls += 1;
            this.increaseCountingControls(
                controlPointDataSummary,
                control.severity,
                'minorSuccessControls',
                'majorSuccessControls',
                'blockingSuccessControls',
            );
        });

        return controlPointDataSummary;
    }

    computeStatisticChartData(controlPointData, hasLinkBetweenGridsEnabled) {
        const controlPointDataSummary = {
            blockingSuccessControls: 0,
            majorSuccessControls: 0,
            minorSuccessControls: 0,

            blockingAnomalyControls: 0,
            majorAnomalyControls: 0,
            minorAnomalyControls: 0,

            blockingErrorControls: 0,
            majorErrorControls: 0,
            minorErrorControls: 0,

            blockingWarningControls: 0,
            majorWarningControls: 0,
            minorWarningControls: 0,

            successControls: 0,
            anomalyControl: 0,
            errorControls: 0,
            warningControls: 0,
        };

        controlPointData.forEach((control) => {
            if (control.faults > 0) {
                controlPointDataSummary.anomalyControl += 1;

                this.increaseCountingControls(
                    controlPointDataSummary,
                    control.severity,
                    'minorAnomalyControls',
                    'majorAnomalyControls',
                    'blockingAnomalyControls',
                );

                return;
            }

            if (control.faults === 0 && control.total === 0 && !control.warning) {
                controlPointDataSummary.errorControls += 1;

                this.increaseCountingControls(
                    controlPointDataSummary,
                    control.severity,
                    'minorErrorControls',
                    'majorErrorControls',
                    'blockingErrorControls',
                );

                return;
            }

            if (control.faults === 0 && control.total === 0 && control.warning && hasLinkBetweenGridsEnabled) {
                controlPointDataSummary.warningControls += 1;

                this.increaseCountingControls(
                    controlPointDataSummary,
                    control.severity,
                    'minorWarningControls',
                    'majorWarningControls',
                    'blockingWarningControls',
                );

                return;
            }

            controlPointDataSummary.successControls += 1;

            this.increaseCountingControls(
                controlPointDataSummary,
                control.severity,
                'minorSuccessControls',
                'majorSuccessControls',
                'blockingSuccessControls',
            );
        });

        return controlPointDataSummary;
    }

    increaseCountingControls(controlPointData, severity, minorKey, majorKey, blockingKey) {
        if (severity === 'minor') {
            controlPointData[minorKey] += 1;

            return;
        }

        if (severity === 'major') {
            controlPointData[majorKey] += 1;

            return;
        }

        controlPointData[blockingKey] += 1;
    }

    initControlPointBySeverity(resultControlPointDataOrigin, resultControlPointDataToCompare, isCompare) {
        const initDataOrigin = {
            successValuesOrigin: [
                resultControlPointDataOrigin.blockingSuccessControls,
                resultControlPointDataOrigin.majorSuccessControls,
                resultControlPointDataOrigin.minorSuccessControls,
            ],
            anomalyValuesOrigin: [
                resultControlPointDataOrigin.blockingAnomalyControls,
                resultControlPointDataOrigin.majorAnomalyControls,
                resultControlPointDataOrigin.minorAnomalyControls,
            ],
            errorValuesOrigin: [
                resultControlPointDataOrigin.blockingErrorControls,
                resultControlPointDataOrigin.majorErrorControls,
                resultControlPointDataOrigin.minorErrorControls,
            ],
            warningValuesOrigin: [
                resultControlPointDataOrigin.blockingWarningControls,
                resultControlPointDataOrigin.majorWarningControls,
                resultControlPointDataOrigin.minorWarningControls,
            ],
        };

        if (!isCompare) {
            return initDataOrigin;
        }

        return {
            ...initDataOrigin,
            successValuesToCompare: [
                resultControlPointDataToCompare.blockingSuccessControls,
                resultControlPointDataToCompare.majorSuccessControls,
                resultControlPointDataToCompare.minorSuccessControls,
            ],
            anomalyValuesToCompare: [
                resultControlPointDataToCompare.blockingAnomalyControls,
                resultControlPointDataToCompare.majorAnomalyControls,
                resultControlPointDataToCompare.minorAnomalyControls,
            ],
            errorValuesToCompare: [
                resultControlPointDataToCompare.blockingErrorControls,
                resultControlPointDataToCompare.majorErrorControls,
                resultControlPointDataToCompare.minorErrorControls,
            ],
            warningValuesToCompare: [
                resultControlPointDataToCompare.blockingWarningControls,
                resultControlPointDataToCompare.majorWarningControls,
                resultControlPointDataToCompare.minorWarningControls,
            ],
        };
    }

    getBasicDatasetDoughnut(resultData) {
        return [resultData.successControls, resultData.anomalyControl, resultData.errorControls];
    }

    initDatasetDoughnut(resultDataOrigin, resultDataToCompare, isCompare, hasLinkBetweenGridsEnabled) {
        const initBasicDatasetOrigin = this.getBasicDatasetDoughnut(resultDataOrigin);
        const initBasicBackgroundOrigin = ['#00a889', '#dd3073', '#b2b2ba'];

        const datasetBasicOrigin = hasLinkBetweenGridsEnabled
            ? [...initBasicDatasetOrigin, resultDataOrigin.warningControls]
            : initBasicDatasetOrigin;

        const datasetBasicBackgroundColor = hasLinkBetweenGridsEnabled
            ? [...initBasicBackgroundOrigin, '#5069a9']
            : initBasicBackgroundOrigin;

        if (!isCompare) {
            return [
                {
                    data: datasetBasicOrigin,
                    backgroundColor: datasetBasicBackgroundColor,
                },
            ];
        }

        const initBasicDatasetCompared = this.getBasicDatasetDoughnut(resultDataToCompare);
        const initBasicBackgroundCompared = ['#7fffd4', '#f5bcda', '#e2e2e5'];

        const datasetBasicDataCompared = hasLinkBetweenGridsEnabled
            ? [...initBasicDatasetCompared, resultDataToCompare.warningControls]
            : initBasicDatasetCompared;

        const datasetBasicBackgroundCompared = hasLinkBetweenGridsEnabled
            ? [...initBasicBackgroundCompared, '#adb9d8']
            : initBasicBackgroundCompared;

        return [
            {
                label: 'Dataset 1',
                data: datasetBasicOrigin,
                backgroundColor: datasetBasicBackgroundColor,
            },
            {
                label: 'Dataset 2',
                data: datasetBasicDataCompared,
                backgroundColor: datasetBasicBackgroundCompared,
            },
        ];
    }

    initLabelsDoughnut(resultDataOrigin, resultDataToCompare, isCompare, hasLinkBetweenGridsEnabled) {
        const labelsBasic = [
            this._translate('shared.noAnomalyControl', {
                COUNT: isCompare
                    ? resultDataOrigin.successControls || resultDataToCompare.successControls
                    : resultDataOrigin.successControls,
            }),
            this._translate('shared.anomalyControlPoint', {
                COUNT: isCompare
                    ? resultDataOrigin.anomalyControl || resultDataToCompare.anomalyControl
                    : resultDataOrigin.anomalyControl,
            }),
            this._translate('shared.inoperativeControl', {
                COUNT: isCompare
                    ? resultDataOrigin.errorControls || resultDataToCompare.errorControls
                    : resultDataOrigin.errorControls,
            }),
        ];

        if (!hasLinkBetweenGridsEnabled) {
            return labelsBasic;
        }

        return [
            ...labelsBasic,
            this._translate('shared.notMetControlPoint', {
                COUNT: isCompare
                    ? resultDataOrigin.warningControls || resultDataToCompare.warningControls
                    : resultDataOrigin.warningControls,
            }),
        ];
    }

    loadGlobalControlChart(
        objectOrigin,
        objectCompared,
        dataOriginLength,
        dataToCompareLength,
        isCompare,
        hasLinkBetweenGridsEnabled,
    ) {
        return {
            bigLegend: {
                figure: isCompare ? `${dataOriginLength ?? 0} vs ${dataToCompareLength ?? 0}` : (dataOriginLength ?? 0),
                name: this._translate('shared.controlPoint', {
                    COUNT: isCompare ? dataOriginLength > 1 || dataToCompareLength > 1 : dataOriginLength > 1,
                }),
            },
            labels: this.initLabelsDoughnut(objectOrigin, objectCompared, isCompare, hasLinkBetweenGridsEnabled),
            datasets: this.initDatasetDoughnut(objectOrigin, objectCompared, isCompare, hasLinkBetweenGridsEnabled),
        };
    }

    initDataSetUnique(translateKey, options, data, isOrigin, isComparison) {
        const statNumber = isOrigin ? 'stat 1' : 'stat 2';
        const suffix = isComparison ? statNumber : '';

        return {
            label: `${this._translate(translateKey)} ${suffix}`,
            backgroundColor: options.backgroundColor,
            hoverBackgroundColor: options.hoverBackgroundColor,
            borderColor: '#fff',
            borderWidth: 1,
            categoryPercentage: 0.7,
            barPercentage: 0.7,
            maxBarThickness: 15,
            data: data,
            stack: isOrigin ? 'Stack 1' : 'Stack 2',
            datalabels: {
                align: isOrigin ? 'top' : 'bottom',
                padding: options.padding,
                anchor: 'center',
                color: options.dataLabelColor,
                clamp: true,
                font: {
                    weight: 'bold',
                },
            },
        };
    }

    initLabelsSeverity() {
        return [
            this._translate('severity.blocking', { COUNT: 1 }),
            this._translate('severity.major', { COUNT: 1 }),
            this._translate('severity.minor', { COUNT: 1 }),
        ];
    }

    initBasicDataSetsSeverity(data, isCompare) {
        const dataSetUnique = [
            this.initDataSetUnique(
                'shared.noAnomalyControl',
                {
                    backgroundColor: '#00a889',
                    hoverBackgroundColor: '#006b58',
                    padding: '5',
                    dataLabelColor: '#006b58',
                },
                data.successValuesOrigin,
                true,
                isCompare,
            ),
            this.initDataSetUnique(
                'shared.anomalyControlPoint',
                {
                    backgroundColor: '#dd3073',
                    hoverBackgroundColor: '#941848',
                    padding: '14',
                    dataLabelColor: '#941848',
                },
                data.anomalyValuesOrigin,
                true,
                isCompare,
            ),
            this.initDataSetUnique(
                'shared.inoperativeControl',
                {
                    backgroundColor: '#b2b2ba',
                    hoverBackgroundColor: '#8c8c98',
                    padding: '5',
                    dataLabelColor: '#b2b2ba',
                },
                data.errorValuesOrigin,
                true,
                isCompare,
            ),
        ];

        if (!isCompare) {
            return dataSetUnique;
        }

        return [
            ...dataSetUnique,
            this.initDataSetUnique(
                'shared.noAnomalyControl',
                {
                    backgroundColor: '#8cf2d0',
                    hoverBackgroundColor: '#00d2ab',
                    padding: '5',
                    dataLabelColor: '#006b58',
                },
                data.successValuesToCompare,
                false,
                true,
            ),
            this.initDataSetUnique(
                'shared.anomalyControlPoint',
                {
                    backgroundColor: '#f5bcda',
                    hoverBackgroundColor: '#e66a9a',
                    padding: '14',
                    dataLabelColor: '#e66a9a',
                },
                data.anomalyValuesToCompare,
                false,
                true,
            ),
            this.initDataSetUnique(
                'shared.inoperativeControl',
                {
                    backgroundColor: '#e2e2e5',
                    hoverBackgroundColor: '#b6b6b6',
                    padding: '5',
                    dataLabelColor: '#b6b6b6',
                },
                data.errorValuesToCompare,
                false,
                true,
            ),
        ];
    }

    initWarningDatasetSeverity(data, isCompare) {
        const warningDatasetUnique = [
            this.initDataSetUnique(
                'shared.notMetControlPoint',
                {
                    backgroundColor: '#5069a9',
                    hoverBackgroundColor: '#405487',
                    padding: '14',
                    dataLabelColor: '#405487',
                },
                data.warningValuesOrigin,
                true,
                isCompare,
            ),
        ];

        if (!isCompare) {
            return warningDatasetUnique;
        }

        return [
            ...warningDatasetUnique,
            this.initDataSetUnique(
                'shared.notMetControlPoint',
                {
                    backgroundColor: '#adb9d8',
                    hoverBackgroundColor: '#788cbf',
                    padding: '14',
                    dataLabelColor: '#788cbf',
                },
                data.warningValuesToCompare,
                false,
                true,
            ),
        ];
    }

    loadControlPointBySeverityChart(
        resultControlPointDataOrigin,
        resultControlPointDataToCompare,
        isCompare,
        hasLinkBetweenGridsEnabled,
    ) {
        const initData = this.initControlPointBySeverity(
            resultControlPointDataOrigin,
            resultControlPointDataToCompare,
            isCompare,
        );

        const basicDataSets = this.initBasicDataSetsSeverity(initData, isCompare);
        const warningDatasets = this.initWarningDatasetSeverity(initData, isCompare);

        return {
            labels: this.initLabelsSeverity(),
            datasets: hasLinkBetweenGridsEnabled ? [...basicDataSets, ...warningDatasets] : basicDataSets,
        };
    }
}

angular.module('dotic').factory('$indicatorControlPointService', ($filter) => new ControlPointService($filter));
