/**
 * ConnectControl API Documentation (v1)
 * The official ConnectControl Open API documentation (v1)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";

/**
* DataModels service.
* @module api/DataModelsApi
* @version 1.0.0
*/
export default class DataModelsApi {

    /**
    * Constructs a new DataModelsApi. 
    * @alias module:api/DataModelsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * List all data models
     * @param {Object} opts Optional parameters
     * @param {Object.<String, Object>} [params] 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    createWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'params': opts['params']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/dataModel', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List all data models
     * @param {Object} opts Optional parameters
     * @param {Object.<String, Object>} opts.params 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    create(opts) {
      return this.createWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get a data model by id
     * @param {String} dataModelId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    getByIdWithHttpInfo(dataModelId) {
      let postBody = null;
      // verify the required parameter 'dataModelId' is set
      if (dataModelId === undefined || dataModelId === null) {
        throw new Error("Missing the required parameter 'dataModelId' when calling getById");
      }

      let pathParams = {
        'dataModelId': dataModelId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/dataModel/{dataModelId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get a data model by id
     * @param {String} dataModelId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    getById(dataModelId) {
      return this.getByIdWithHttpInfo(dataModelId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get data model value
     * @param {String} dataModelId 
     * @param {String} dataModelValueId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    getDataModelValueWithHttpInfo(dataModelId, dataModelValueId) {
      let postBody = null;
      // verify the required parameter 'dataModelId' is set
      if (dataModelId === undefined || dataModelId === null) {
        throw new Error("Missing the required parameter 'dataModelId' when calling getDataModelValue");
      }
      // verify the required parameter 'dataModelValueId' is set
      if (dataModelValueId === undefined || dataModelValueId === null) {
        throw new Error("Missing the required parameter 'dataModelValueId' when calling getDataModelValue");
      }

      let pathParams = {
        'dataModelId': dataModelId,
        'dataModelValueId': dataModelValueId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/dataModel/{dataModelId}/value/{dataModelValueId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get data model value
     * @param {String} dataModelId 
     * @param {String} dataModelValueId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    getDataModelValue(dataModelId, dataModelValueId) {
      return this.getDataModelValueWithHttpInfo(dataModelId, dataModelValueId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get all data models in a simplified list
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    getSimplifiedListWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/dataModel/list', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get all data models in a simplified list
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    getSimplifiedList() {
      return this.getSimplifiedListWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a data model
     * @param {Object.<String, Object>} body Data Model to create
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    listWithHttpInfo(body) {
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling list");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/dataModel', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Create a data model
     * @param {Object.<String, Object>} body Data Model to create
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    list(body) {
      return this.listWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List all data models names
     * @param {Object.<String, Object>} params 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    listNamesWithHttpInfo(params) {
      let postBody = null;
      // verify the required parameter 'params' is set
      if (params === undefined || params === null) {
        throw new Error("Missing the required parameter 'params' when calling listNames");
      }

      let pathParams = {
      };
      let queryParams = {
        'params': params
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/dataModel/name', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List all data models names
     * @param {Object.<String, Object>} params 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    listNames(params) {
      return this.listNamesWithHttpInfo(params)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Remove a data model
     * @param {String} dataModelId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    removeWithHttpInfo(dataModelId) {
      let postBody = null;
      // verify the required parameter 'dataModelId' is set
      if (dataModelId === undefined || dataModelId === null) {
        throw new Error("Missing the required parameter 'dataModelId' when calling remove");
      }

      let pathParams = {
        'dataModelId': dataModelId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/dataModel/{dataModelId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Remove a data model
     * @param {String} dataModelId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    remove(dataModelId) {
      return this.removeWithHttpInfo(dataModelId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a data model
     * @param {String} dataModelId 
     * @param {Object.<String, Object>} body Data Model to update
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    updateWithHttpInfo(dataModelId, body) {
      let postBody = body;
      // verify the required parameter 'dataModelId' is set
      if (dataModelId === undefined || dataModelId === null) {
        throw new Error("Missing the required parameter 'dataModelId' when calling update");
      }
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling update");
      }

      let pathParams = {
        'dataModelId': dataModelId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearerAuth'];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/v1/dataModel/{dataModelId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Update a data model
     * @param {String} dataModelId 
     * @param {Object.<String, Object>} body Data Model to update
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    update(dataModelId, body) {
      return this.updateWithHttpInfo(dataModelId, body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
