import template from './fillGridAdminList.html';

class FillGridAdminListController {
    constructor(
        $auth,
        $authorizationService,
        $deleteService,
        $fillGridService,
        $filter,
        $gridService,
        $location,
        $modalService,
        $scope,
        $state,
        $tableService,
        $timeout,
        $toasterService,
        $uibModal,
        fillGridProvider,
        userMetricsProvider,
    ) {
        this._$deleteService = $deleteService;
        this._$fillGridService = $fillGridService;
        this._$gridService = $gridService;
        this._$modalService = $modalService;
        this._$scope = $scope;
        this._$state = $state;
        this._$tableService = $tableService;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._translate = $filter('translate');
        this._fillGridProvider = fillGridProvider;

        this.page = $state.params.p || null;
        this.pageSize = 10;
        this.sort = { column: 'createdAt', order: -1 };
        this.totalCount = 0;

        this.isAllowed = $authorizationService.isAllowed;
        this.companyId = $auth.getPayload().company;

        this.gridList = [];
        this.gridTemplateList = [];
        this.isTemplateVisible = false;
        this.isWorkingGridsVisible = true;

        this.tableDetail = this._$tableService.detail();
        this.tableSelect = this._$tableService.select();
        this.filter = {
            search: $state.params.search || '',
        };

        $scope.$watchGroup(['$ctrl.filter.search'], (next, prev) => {
            if (next !== prev) {
                this.onFiltersChange();
            }
        });

        $scope.$emit('updateNavigation', {
            newPage: [
                {
                    key: 'controlGrids',
                    title: this._translate('shared.fillGrid'),
                    href: $location.path(),
                },
            ],
        });

        $scope.$on('$locationChangeSuccess', () => {
            this.filter.search = $state.params.search || '';
            $scope.$emit('updateNavigationUrl');
        });

        // Watch selection
        $scope.$on('selectRows', (e, data) => {
            this.tableSelect.select(data.rows, true);
        });

        userMetricsProvider.listFillingGrids();
    }

    async $onInit() {
        await this.getLists();
    }

    async getLists() {
        this.loading = true;

        try {
            await Promise.all([this.search(this.page, this.pageSize, this.sort), this.getAdminTemplates()]);
            this._$scope.$apply();
        } catch (error) {
            this._$toasterService.error(error);
        }

        // Timeout necessary as AngularJs doesn't trigger digest cycle from async / await method or function
        this._$timeout(() => (this.loading = false));
    }

    async getAdminTemplates() {
        this.gridTemplateList = await this._$gridService.getTemplates(this.filter, this.sort, true);
    }

    async search(page, perPage, sort) {
        const getPagination = await this._$gridService.search(
            page,
            perPage,
            sort,
            { ...this.filter, template: false },
            true,
        );

        if (!getPagination) {
            return;
        }

        this.totalCount = getPagination.totalCount;
        this.gridList = getPagination.data;
    }

    async onFiltersChange() {
        if (this.tableSelect) {
            this.tableSelect.empty();
        }

        const search = this.filter.search.toLocaleLowerCase();
        this._$state.go('.', { search });
        await this.getLists();
    }

    isAllowedToRemove(item) {
        return item && (item.countDeposits === 0 || this.isAllowed(['superAdmin']));
    }

    selectVisibleRows() {
        this._$scope.$broadcast('getVisibleRows');
    }

    selectedGridHasDependencies() {
        const selected = this.gridList.filter(this.tableSelect.isActive);

        return selected.some((grid) => grid.countDeposits > 0);
    }

    removeTooltip() {
        if (!this.tableSelect.hasSelected()) {
            return '';
        } else if (this.selectedGridHasDependencies()) {
            return this._translate('shared.attachedGrid');
        } else {
            return this._translate('shared.deleteSelection');
        }
    }

    async removeFillGrids() {
        // Get selected rows
        const selectedGrids = this.gridList.filter(this.tableSelect.isActive);

        if (selectedGrids.length === 0 || this.selectedGridHasDependencies()) {
            return;
        }

        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.fillGrid', {
                COUNT: selectedGrids.length,
                NAME: selectedGrids[0].fullname,
            }).toLowerCase(),
        );
        if (!isAccepted) {
            return;
        }

        this.deleting = true;

        const selectedGridIds = selectedGrids.map((selectedGrid) => selectedGrid.id);
        await this._$deleteService.deleteArrayOfIds(
            selectedGridIds,
            (gridId) => this._fillGridProvider.delete(gridId),
            'removeModal.successGrid',
            'removeModal.failureGrid',
        );

        await this.getLists();
        this.tableSelect.empty();
        this.deleting = false;
    }

    toggleTemplates = () => {
        this.isTemplateVisible = !this.isTemplateVisible;
    };

    toggleGrids() {
        this.isWorkingGridsVisible = !this.isWorkingGridsVisible;
    }

    compareFillGrid(fillGrid) {
        this._$state.go('app.comparisonGridPage', {
            fillGrid: fillGrid.id,
        });
    }
}

angular.module('dotic').component('fillGridAdminListPage', {
    controller: FillGridAdminListController,
    templateUrl: template,
});
