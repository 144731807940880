angular
    .module('dotic')
    .factory(
        '$toasterService',
        ($log, toaster, $filter, $timeout) => new ToasterService($log, toaster, $filter, $timeout),
    );

class ToasterService {
    constructor($log, toaster, $filter, $timeout) {
        this._$log = $log;
        this._toaster = toaster;
        this._translate = $filter('translate');
        this._$timeout = $timeout;

        this.error = this.error.bind(this);
    }

    error(error) {
        // Si l'erreur apparait suite à une annulation de requête on n'affiche aucune erreur
        if (error.xhrStatus === 'abort') {
            return;
        }

        this._$log.error(error);

        // When the toaster.error method is triggered, it needs the digest cycle to happen once.
        // Depending on the architecture or refreshing of the app, this cycle can be skipped.
        // This result with an inconsistent toaster, that appear sometime when the cycle is triggered the right way.
        // When using $timeout force the following code to happen after the next digest cycle, meaning that the toaster will appear in any situations.
        this._$timeout(() => {
            if (angular.isString(error)) {
                this._toaster.error({ body: error });
            } else if (error.body && angular.isString(error.body)) {
                this._toaster.error(error);
            } else if (error.body?.message) {
                this._toaster.error({ body: error.body.message });
            } else if (error.data && angular.isString(error.data)) {
                this._toaster.error({ body: error.data });
            } else if (error.data?.message) {
                this._toaster.error({ body: error.data.message });
            } else if (error.data?.body) {
                this._toaster.error(error.data);
            } else if (error.message) {
                this._toaster.error({ body: error.message });
            } else if (
                error.data &&
                angular.isArray(error.data.errors) &&
                error.data.errors[0] &&
                error.data.errors[0].message
            ) {
                this._toaster.error(error.data.errors[0].message);
            } else if (error.data instanceof ArrayBuffer) {
                // Handle error for Array buffer file requests
                const decoder = new TextDecoder('utf-8');
                const decodedString = angular.fromJson(decoder.decode(error.data));
                this._toaster.error(decodedString);
            } else {
                this._toaster.error({ body: this._translate('toaster.error') });
            }
        }, 100);
    }

    info(info) {
        this._$timeout(() => {
            this._toaster.info(info);
        }, 100);
    }

    success(success) {
        this._$timeout(() => {
            this._toaster.success(success);
        }, 100);
    }

    warning(warning) {
        this._$timeout(() => {
            this._toaster.warning(warning);
        }, 100);
    }
}
