import { Observable } from 'rxjs';

import { CompaniesApi } from '../../sdk/connect-control-api-v1';

export const FeatureEnum = {
    AGGREGATION: 'aggregation',
    ANCT: 'anct',
    FTTX_DATA_EVOLUTION: 'fttxDataEvolution',
    GEO_PACKAGE_EXPORT: 'geoPackageExport',
    STATISTICS: 'statistics',
};

function buildFeatureGuard($apiClientService, $auth, $filter, $state, $toasterService) {
    return (feature) => {
        // The guard should return an Observable, as it won't work properly using Promise and tag <a>
        return new Observable(async (subscriber) => {
            const companiesApi = new CompaniesApi($apiClientService.client);
            const currentCompany = (await companiesApi.getDetailsWithHttpInfo($auth.getPayload().company)).response
                .body;

            switch (feature) {
                case FeatureEnum.AGGREGATION:
                    if (currentCompany?.settings?.feature?.hasAggregationsEnabled) {
                        subscriber.next(true);

                        return;
                    }
                    break;
                case FeatureEnum.ANCT:
                    if (currentCompany?.settings?.feature?.hasANCTVerification) {
                        subscriber.next(true);

                        return;
                    }
                    break;
                case FeatureEnum.FTTX_DATA_EVOLUTION:
                    if (currentCompany?.settings?.feature?.hasFttxDataEvolutionEnabled) {
                        subscriber.next(true);

                        return;
                    }
                    break;
                case FeatureEnum.GEO_PACKAGE_EXPORT:
                    if (currentCompany?.settings?.feature?.hasGeoPackageExportEnabled) {
                        subscriber.next(true);

                        return;
                    }
                    break;
                case FeatureEnum.STATISTICS:
                    if (currentCompany?.settings?.feature?.hasStatisticsEnabled) {
                        subscriber.next(true);

                        return;
                    }
                    break;
                default:
                    subscriber.next(true);

                    return;
            }

            $toasterService.error($filter('translate')('shared.unavailableFeature'));
            $state.go('app.dashboard');

            subscriber.next(false);
        });
    };
}

angular
    .module('dotic')
    .factory('hasFeatureGuard', ($apiClientService, $auth, $filter, $state, $toasterService) =>
        buildFeatureGuard($apiClientService, $auth, $filter, $state, $toasterService),
    );
