import template from './confirm-deposit-deletion-modal.component.html';

class ConfirmDepositDeletionModalComponent {
    close = null;
    resolve = {
        isANCT: false,
    };

    cancel() {
        if (!this.close) {
            return;
        }

        this.close({ $value: false });
    }

    confirm() {
        if (!this.close) {
            return;
        }

        this.close({ $value: true });
    }
}

angular.module('dotic').component('confirmDepositDeletionModal', {
    bindings: {
        close: '&',
        resolve: '<',
    },
    controller: ConfirmDepositDeletionModalComponent,
    templateUrl: template,
});
