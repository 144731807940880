import { unique as radashUnique } from 'radash';

import { ColorService } from '../../color.service';
import { IndicatorService } from '../indicator.service';

const KeyEnum = {
    CABLE_LENGTH: 'cableLength',
    MANAGER: 'manager',
    OWNER: 'owner',
};

// Linked to cable-length-by-owner-and-manager indicator.
class IndicatorR00145Service {
    static ANCTCorrespondenceNumber = 26;

    constructor($filter) {
        this._translate = $filter('translate');
    }

    generateDataStructure(data) {
        const formattedData = IndicatorService.getFormattedKilometerData(data, [KeyEnum.CABLE_LENGTH]);

        const managers = radashUnique(data.map((currentData) => currentData[KeyEnum.MANAGER]));
        const colors = ColorService.getDistinctRandomColors(managers.length, ColorService.colorsPalette);

        const managerFormattedData = IndicatorService.getTableChartFormattedData(
            formattedData,
            KeyEnum.MANAGER,
            KeyEnum.OWNER,
            KeyEnum.CABLE_LENGTH,
        );

        return {
            ANCTCorrespondenceNumber: IndicatorR00145Service.ANCTCorrespondenceNumber,
            data: formattedData,
            iconOptions: this.getIconOptions(formattedData),
            tableChartData: IndicatorService.getTableChartData(
                managerFormattedData,
                (manager) => colors[managers.indexOf(manager)],
            ),
            tableData: {
                columns: this.getColumns(),
                title: this._translate('reportCompare.detailLengthCable'),
            },
        };
    }

    getColumns() {
        return [
            {
                key: KeyEnum.MANAGER,
                translation: this._translate('shared.manager'),
            },
            {
                key: KeyEnum.OWNER,
                translation: this._translate('shared.owner'),
            },
            {
                key: KeyEnum.CABLE_LENGTH,
                translation: `${this._translate('shared.length', { COUNT: 1 })} (km)`,
            },
        ];
    }

    getIconOptions(data) {
        const colors = ColorService.getDistinctRandomColors(data.length, ColorService.colorsPalette);

        return data.map((currentData, index) => {
            return {
                color: colors[index],
                icon: 'architecture-alt',
                label: `${currentData[KeyEnum.OWNER]} / ${currentData[KeyEnum.MANAGER]}`,
                unit: 'Km',
                value: currentData[KeyEnum.CABLE_LENGTH],
            };
        });
    }
}

angular.module('dotic').factory('indicatorR00145Service', IndicatorR00145Service);
