class LogoutPage {
    constructor($auth, $http, $log, $state) {
        this._$auth = $auth;
        this._$http = $http;
        this._$log = $log;
        this._$state = $state;
    }

    $onInit() {
        if (this._$auth?.isAuthenticated()) {
            this.logout();
        } else {
            this._$state.go('login', { reload: true });
        }
    }

    async logout() {
        await this._$auth.logout();

        if (APP_SETTINGS.OPENID_LABEL) {
            // in background, notify webserver of logout to handle auth0 logout
            this._$http({ method: 'GET', url: '/auth0/logout' }).catch(this._$log.error);
        }

        return this._$state.go('login', { reload: true });
    }
}

angular.module('dotic').component('logoutPage', {
    controller: LogoutPage,
    template: '',
});
