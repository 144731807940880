import { defaultPagination } from '../../models/common.model';
import { HelpersService } from '../../services/helpers.service';

class ComparisonController {
    listAllSegments = [
        { key: 'DI', value: 'DI' },
        { key: 'RA', value: 'RA' },
        { key: 'TR', value: 'TR' },
    ];
    paginationParameters = {
        page: 0,
        pageSize: 5,
        sort: {},
    };
    selection = {
        company: null,
        phases: [],
        segments: [],
        date: '',
    };
    statistics = { ...defaultPagination };

    constructor(
        $auth,
        $comparisonService,
        $filter,
        $location,
        $modalService,
        $phaseService,
        $scope,
        $segmentsService,
        $state,
        $timeout,
        $toasterService,
        $userService,
        fillingStatisticsProvider,
        statisticsProvider,
    ) {
        this._$state = $state;
        this._translate = $filter('translate');
        this._$timeout = $timeout;
        this._$scope = $scope;
        this._$location = $location;
        this._$modalService = $modalService;
        this._$segmentsService = $segmentsService;
        this._$comparisonService = $comparisonService;
        this._$toasterService = $toasterService;

        this._fillingStatisticsProvider = fillingStatisticsProvider;
        this._statisticsProvider = statisticsProvider;

        this.selection.company = $auth.getPayload().company;

        const userCompanies = $userService
            .getConnectedUser()
            .companies.filter(
                (company) =>
                    company._id === $auth.getPayload().company ||
                    company.settings.feature.hasGrantedAccessRightsToGrids,
            );
        this.companies = HelpersService.toKeyValue(userCompanies, { keyField: '_id', valueField: 'name' });

        //filters options
        this.phaseList = $phaseService.getAll().map((phase) => ({ key: phase, value: phase }));

        $scope.$watchGroup(
            ['$ctrl.selection.company', '$ctrl.selection.phases', '$ctrl.selection.segments', '$ctrl.selection.date'],
            async () => {
                this.paginationParameters.page = 0;
                this.loading = true;
                this.statistics = await this.getStatistics();
                this.statistics.data = await this.formatNetworkSegments(this.statistics.data);
                this._$timeout(() => (this.loading = false));
            },
        );
    }

    async $onInit() {
        this.loading = true;

        this.statistic = await this.getSelectedStatistic();

        this.statistics = await this.getStatistics();
        this.statistics.data = await this.formatNetworkSegments(this.statistics.data);

        // Timeout necessary as AngularJs doesn't trigger digest cycle from async / await method or function
        this._$timeout(() => (this.loading = false));

        this.setScopeNavigation();
    }

    async compareWith(statistic) {
        const type = Object.entries(this._$state.params).find((param) => param[1])[0];

        const statisticsToCompare = this._$state.params.fillStat
            ? await this._fillingStatisticsProvider.getOne(statistic._id)
            : await this._statisticsProvider.get(statistic._id);

        await this._$comparisonService.goToComparePage(this.statistic, statisticsToCompare, type);
    }

    formatNetworkSegments(statistics) {
        return statistics.map((statistic) => {
            return {
                ...statistic,
                networkSegments: this._$segmentsService.setNetworkSegments(statistic.networkSegments || []),
            };
        });
    }

    formatQueryParams() {
        const params = {
            company: this.selection.company,
            limit: this.paginationParameters.pageSize,
            page: this.paginationParameters.page - 1,
            phase: this.selection.phases,
            segment: this.selection.segments,
            ...this.paginationParameters.sort,
        };

        if (!this.selection.date) {
            return params;
        }

        return {
            ...params,
            createdAtStart: moment(this.selection.date.startDate).utc().format(),
            createdAtEnd: moment(this.selection.date.endDate).utc().format(),
        };
    }

    async getSelectedStatistic() {
        try {
            if (this._$state.params.fillStat) {
                return await this._fillingStatisticsProvider.getOne(this._$state.params.fillStat);
            }

            const controlStatistic = await this._statisticsProvider.get(this._$state.params.controlStat);
            controlStatistic.networkSegments = this._$segmentsService.setNetworkSegments(
                controlStatistic.networkSegments,
            );

            return controlStatistic;
        } catch (error) {
            this._$toasterService.error(error);
        }
    }

    async getStatistics() {
        try {
            return this._$state.params.fillStat
                ? await this._fillingStatisticsProvider.list(this.formatQueryParams())
                : await this._statisticsProvider.list(this.formatQueryParams());
        } catch {
            return defaultPagination;
        }
    }

    async onPaginationChange(page, pageSize, sort) {
        this.paginationParameters = {
            ...this.paginationParameters,
            pageSize: pageSize,
            page: page,
            sort: sort,
        };

        this.loading = true;
        this.statistics = await this.getStatistics();
        this.statistics.data = await this.formatNetworkSegments(this.statistics.data);

        this._$timeout(() => (this.loading = false));
    }

    setScopeNavigation() {
        this._$scope.$emit('keepPreviousNavigation', {
            newPage: [
                {
                    key: 'compare',
                    title: this._translate('shared.comparisonInterface'),
                    href: this._$location.path(),
                },
            ],
            defaultPrevious: {
                title: this._translate(
                    this._$state.params.controlStat ? 'shared.controlStatistic' : 'shared.fillingStatistic',
                ),
                href: this._$state.href(
                    this._$state.params.controlStat ? 'app.statisticsHome' : 'app.statisticsFillHome',
                ),
                key: this._$state.params.controlStat ? 'statistics' : 'fillingStatistics',
            },
            allowedPreviousKeys: ['fillingStatistics', 'statistics'],
        });
    }
}

angular.module('dotic').controller('ComparisonController', ComparisonController);
