import fttxDataEvolutionViewTemplate from './fttx-data-evolution-view-indicators.html';
import { IndicatorDetailDifferencesTypeEnum } from '../../../../services/indicator-fttx-data-evolution.service';
import { IndicatorService } from '../../../../services/indicator.service';
import { IndicatorsTypeEnum } from '../../../../models/indicator.model';

class FttxDataEvolutionViewIndicatorsComponent {
    comparisonDeposit = null;
    comparisonIndicators = null;
    detailDifferencesArrays = {
        advancementLevelByPTOAndCapacityMax: { columns: [], values: [] },
        advancementLevelByStateAndCapacityMax: { columns: [], values: [] },
        averageBoxSaturationRate: { columns: [], values: [] },
        averageCableSaturationRate: { columns: [], values: [] },
        averageBoxTheoreticalReserveRatio: { columns: [], values: [] },
        averageCableTheoreticalReserveRatio: { columns: [], values: [] },
        beingDeployedAddresses: { columns: [], values: [] },
        cableLengthByFoCapacityAndTypelog: { columns: [], values: [] },
        cableLengthByTypeAndProvider: { columns: [], values: [] },
        connectivity: { columns: [], values: [] },
        deployedAddresses: { columns: [], values: [] },
        distanceNroPbo: { columns: [], values: [] },
        linearMeterByClass: { columns: [], values: [] },
        linearMeterByLogicalType: { columns: [], values: [] },
        linearMeterByMethod: { columns: [], values: [] },
        nbBoxes: { columns: [], values: [] },
        nbConnectionsByImplantationType: { columns: [], values: [] },
        nbGc: { columns: [], values: [] },
        nbHouses: { columns: [], values: [] },
        nbOpticalChamber: { columns: [], values: [] },
        nbPa: { columns: [], values: [] },
        nbPlugs: { columns: [], values: [] },
        nroHostingInOrangeNra: { columns: [], values: [] },
        nroSro: { columns: [], values: [] },
        opticalCableSupport: { columns: [], values: [] },
        pathway: { columns: [], values: [] },
        plugsStatus: { columns: [], values: [] },
        ptoStatus: { columns: [], values: [] },
        raFtte: { columns: [], values: [] },
        siteNumberByLogicalType: { columns: [], values: [] },
        technicalPoints: { columns: [], values: [] },
        trancheeLinearMeterByType: { columns: [], values: [] },
    };
    indicatorsTypeEnum = IndicatorsTypeEnum;
    indicatorTypeSelected = IndicatorsTypeEnum.NETWORK_SITES;
    isGraceV3 = false;
    loading = false;
    referenceDeposit = null;
    referenceIndicators = null;

    constructor(
        $dataModelService,
        $filter,
        $indicatorFieldOccurrenceService,
        $indicatorFttxDataEvolutionService,
        $timeout,
        $toasterService,
        dataModelProvider,
    ) {
        this._$dataModelService = $dataModelService;
        this._$indicatorFieldOccurrenceService = $indicatorFieldOccurrenceService;
        this._$indicatorFttxDataEvolutionService = $indicatorFttxDataEvolutionService;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;
        this._dataModelProvider = dataModelProvider;
        this._translate = $filter('translate');
    }

    async $onChanges(changes) {
        if (changes.comparisonIndicators) {
            this.comparisonIndicators = changes.comparisonIndicators.currentValue;
        }

        if (changes.referenceIndicators) {
            this.referenceIndicators = changes.referenceIndicators.currentValue;
        }

        if (this.referenceIndicators.fttxIndicators && this.comparisonIndicators.fttxIndicators && !this.loading) {
            this.detailDifferencesArrays = this.getDetailDifferencesArrays();
        }

        if (changes.referenceDeposit?.currentValue) {
            try {
                const dataModel = await this._dataModelProvider.get(changes.referenceDeposit.currentValue.dataModel);
                this.isGraceV3 = this._$dataModelService.isDataGraceTHDV3({ dataModel: dataModel });
            } catch (error) {
                this._$toasterService.error(error);
            }
        }
    }

    getDetailDifferencesArrays() {
        return {
            advancementLevelByPTOAndCapacityMax:
                this._$indicatorFttxDataEvolutionService.getSimpleNumberDetailDifferences(
                    this.referenceIndicators.fttxIndicators.deploymentProgress.advancementLevelByPTOAndCapacityMax,
                    this.comparisonIndicators.fttxIndicators.deploymentProgress.advancementLevelByPTOAndCapacityMax,
                ),
            advancementLevelByStateAndCapacityMax: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.fttxIndicators.deploymentProgress.advancementLevelByStateAndCapacityMax,
                this.comparisonIndicators.fttxIndicators.deploymentProgress.advancementLevelByStateAndCapacityMax,
                'advancement',
                IndicatorDetailDifferencesTypeEnum.ADVANCEMENT_LEVEL_BY_STATE,
            ),
            averageBoxSaturationRate: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                IndicatorService.sortByCableCapacity(
                    this.referenceIndicators.fttxIndicators.networkSaturationLevel.averageBoxSaturationRate,
                ),
                IndicatorService.sortByCableCapacity(
                    this.comparisonIndicators.fttxIndicators.networkSaturationLevel.averageBoxSaturationRate,
                ),
                'averageSaturation',
                IndicatorDetailDifferencesTypeEnum.AVERAGE_SATURATION,
            ),
            averageBoxTheoreticalReserveRatio: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                IndicatorService.sortByCableCapacity(
                    this.referenceIndicators.fttxIndicators.networkSaturationLevel.averageBoxTheoreticalReserveRatio,
                ),
                IndicatorService.sortByCableCapacity(
                    this.comparisonIndicators.fttxIndicators.networkSaturationLevel.averageBoxTheoreticalReserveRatio,
                ),
                'averageReserve',
                IndicatorDetailDifferencesTypeEnum.AVERAGE_SATURATION,
            ),
            averageCableSaturationRate: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                IndicatorService.sortByCableCapacity(
                    this.referenceIndicators.fttxIndicators.networkSaturationLevel.averageCableSaturationRate,
                ),
                IndicatorService.sortByCableCapacity(
                    this.comparisonIndicators.fttxIndicators.networkSaturationLevel.averageCableSaturationRate,
                ),
                'averageSaturation',
                IndicatorDetailDifferencesTypeEnum.AVERAGE_SATURATION,
            ),
            averageCableTheoreticalReserveRatio: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                IndicatorService.sortByCableCapacity(
                    this.referenceIndicators.fttxIndicators.networkSaturationLevel.averageCableTheoreticalReserveRatio,
                ),
                IndicatorService.sortByCableCapacity(
                    this.comparisonIndicators.fttxIndicators.networkSaturationLevel.averageCableTheoreticalReserveRatio,
                ),
                'averageReserve',
                IndicatorDetailDifferencesTypeEnum.AVERAGE_SATURATION,
            ),
            beingDeployedAddresses: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.fttxIndicators.deploymentProgress.beingDeployedAddresses,
                this.comparisonIndicators.fttxIndicators.deploymentProgress.beingDeployedAddresses,
                'beingDeployedAddresses',
                IndicatorDetailDifferencesTypeEnum.ADDRESS_DEPLOYMENT,
                ['addressCount', 'globalPercentage'],
            ),
            cableLengthByImplantation: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.anct.cableLengthByImplantation,
                this.comparisonIndicators.anct.cableLengthByImplantation,
                'cableLength',
                IndicatorDetailDifferencesTypeEnum.CABLE_LENGTH_BY_IMPLANTATION,
            ),
            cableLengthByOrganizationAndDesign: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.anct.cableLengthByOrganizationAndDesign,
                this.comparisonIndicators.anct.cableLengthByOrganizationAndDesign,
                'cableLength',
                IndicatorDetailDifferencesTypeEnum.CABLE_LENGTH_BY_ORGANIZATION,
            ),
            cableLengthByOwnerAndManager: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.anct.cableLengthByOwnerAndManager,
                this.comparisonIndicators.anct.cableLengthByOwnerAndManager,
                'cableLength',
                IndicatorDetailDifferencesTypeEnum.CABLE_LENGTH_BY_OWNER_MANAGER,
            ),
            cableLengthBySliceType: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.anct.cableLengthBySliceType,
                this.comparisonIndicators.anct.cableLengthBySliceType,
                'cableLength',
                IndicatorDetailDifferencesTypeEnum.CABLE_LENGTH_BY_SLICE_TYPE,
            ),
            cableLengthByType: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.anct.cableLengthByType,
                this.comparisonIndicators.anct.cableLengthByType,
                'cableLength',
                IndicatorDetailDifferencesTypeEnum.CABLE_LENGTH_BY_TYPE,
            ),
            cableLengthByTypeAndProvider: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.fttxIndicators.fttxCables.cableLengthByTypeAndProvider,
                this.comparisonIndicators.fttxIndicators.fttxCables.cableLengthByTypeAndProvider,
                'longueurCable',
                IndicatorDetailDifferencesTypeEnum.CABLE_LENGTH_BY_PROVIDER,
            ),
            cableLengthByFoCapacityAndTypelog: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.fttxIndicators.fttxCables.cableLengthByFoCapacityAndTypelog,
                this.comparisonIndicators.fttxIndicators.fttxCables.cableLengthByFoCapacityAndTypelog,
                'count',
                IndicatorDetailDifferencesTypeEnum.OPTICAL_CABLES,
            ),
            codeHexacle: this._$indicatorFttxDataEvolutionService.getDetailDifferencesSeveralCountKeys(
                this.referenceIndicators.anct.codeHexacle,
                this.comparisonIndicators.anct.codeHexacle,
                ['addressCount', 'validHexacleCount', 'validSup10charHexacleCount', 'nullHexacleCount'],
                IndicatorDetailDifferencesTypeEnum.CODE_HEXACLE,
            ),
            connectivity: this._$indicatorFttxDataEvolutionService.getObjectDetailDifferences(
                this.referenceIndicators.connectivity,
                this.comparisonIndicators.connectivity,
            ),
            deployedAddresses: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.fttxIndicators.deploymentProgress.deployedAddresses,
                this.comparisonIndicators.fttxIndicators.deploymentProgress.deployedAddresses,
                'deployedAddresses',
                IndicatorDetailDifferencesTypeEnum.ADDRESS_DEPLOYMENT,
                ['addressCount', 'globalPercentage'],
            ),
            distanceNroPbo: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.distanceBetweenNroPbo,
                this.comparisonIndicators.distanceBetweenNroPbo,
                'distanceKm',
                IndicatorDetailDifferencesTypeEnum.DISTANCE_NRO_PBO,
            ),
            fieldOccurrence: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this._$indicatorFieldOccurrenceService.prepareFieldOccurrenceIndicator(
                    this.referenceIndicators.anct.fieldOccurrence,
                ),
                this._$indicatorFieldOccurrenceService.prepareFieldOccurrenceIndicator(
                    this.comparisonIndicators.anct.fieldOccurrence,
                ),
                'occurrenceCount',
                IndicatorDetailDifferencesTypeEnum.FIELD_OCCURRENCE,
            ),
            linearMeterByClass: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.fttxIndicators.gc.linearMeterByClass,
                this.comparisonIndicators.fttxIndicators.gc.linearMeterByClass,
                'linearMeter',
                IndicatorDetailDifferencesTypeEnum.LINEAR_METER_CLASS,
            ),
            linearMeterByLogicalType: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.fttxIndicators.linearMeterByLogicalType,
                this.comparisonIndicators.fttxIndicators.linearMeterByLogicalType,
                'linearMeter',
                IndicatorDetailDifferencesTypeEnum.LINEAR_METER_LOGICAL_TYPE,
            ),
            linearMeterByMethod: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.fttxIndicators.gc.linearMeterByMethod,
                this.comparisonIndicators.fttxIndicators.gc.linearMeterByMethod,
                'linearMeter',
                IndicatorDetailDifferencesTypeEnum.LINEAR_METER_DEFAULT,
            ),
            localNumberByImplantation: this._$indicatorFttxDataEvolutionService.getDetailDifferencesSeveralCountKeys(
                this.referenceIndicators.anct.localNumberByImplantation,
                this.comparisonIndicators.anct.localNumberByImplantation,
                ['housingCount', 'percent'],
                IndicatorDetailDifferencesTypeEnum.LOCAL_NUMBER_BY_IMPLANTATION,
            ),
            localNumberByDistanceRange: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.anct.localNumberByDistanceRange,
                this.comparisonIndicators.anct.localNumberByDistanceRange,
                'localCount',
                IndicatorDetailDifferencesTypeEnum.LOCAL_NUMBER_BY_DISTANCE_RANGE,
            ),
            localNumberByLogicalType: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.anct.localNumberByLogicalType,
                this.comparisonIndicators.anct.localNumberByLogicalType,
                'addressCount',
                IndicatorDetailDifferencesTypeEnum.SITE_NUMBER,
            ),
            localNumberBySiteLogicalType: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.anct.localNumberBySiteLogicalType,
                this.comparisonIndicators.anct.localNumberBySiteLogicalType,
                'addressCount',
                IndicatorDetailDifferencesTypeEnum.SITE_NUMBER,
            ),
            nbBoxes: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.nbBoxIndicator.nbBPE,
                this.comparisonIndicators.nbBoxIndicator.nbBPE,
                'count',
                IndicatorDetailDifferencesTypeEnum.NB_BOXES,
            ),
            nbConnectionsByImplantationType: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.fttxIndicators.nbConnectionsByImplantationType,
                this.comparisonIndicators.fttxIndicators.nbConnectionsByImplantationType,
                'nbConnections',
                IndicatorDetailDifferencesTypeEnum.NB_CONNECTIONS_BY_IMPLANTATION_TYPE,
            ),
            nbGc: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.fttxIndicators.gc.nbGroupBy.nbByNature,
                this.comparisonIndicators.fttxIndicators.gc.nbGroupBy.nbByNature,
                'nbGc',
                IndicatorDetailDifferencesTypeEnum.NB_GC,
            ),
            nbHouses: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                [
                    this.referenceIndicators.housingIndicator.parent,
                    ...this.referenceIndicators.housingIndicator.children,
                ],
                [
                    this.comparisonIndicators.housingIndicator.parent,
                    ...this.comparisonIndicators.housingIndicator.children,
                ],
                'value',
                IndicatorDetailDifferencesTypeEnum.NB_PLUGS,
                ['icon', 'color'],
            ),
            nbOpticalChamber: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.fttxIndicators.nbOpticalChamber,
                this.comparisonIndicators.fttxIndicators.nbOpticalChamber,
                'nbChambres',
                IndicatorDetailDifferencesTypeEnum.NB_OPTICAL_CHAMBER,
            ),
            nbPa: this._$indicatorFttxDataEvolutionService.getSimpleNumberDetailDifferences(
                this.referenceIndicators.fttxIndicators.nbPa,
                this.comparisonIndicators.fttxIndicators.nbPa,
            ),
            nbPlugs: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                [this.referenceIndicators.plugIndicator.parent, ...this.referenceIndicators.plugIndicator.children],
                [this.comparisonIndicators.plugIndicator.parent, ...this.comparisonIndicators.plugIndicator.children],
                'value',
                IndicatorDetailDifferencesTypeEnum.NB_PLUGS,
                ['icon', 'color'],
            ),
            nroData: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.fttxIndicators.nroData,
                this.comparisonIndicators.fttxIndicators.nroData,
                'distanceKm',
                IndicatorDetailDifferencesTypeEnum.NRO_DATA,
                ['distanceClass'],
            ),
            nroHostingInOrangeNra: this._$indicatorFttxDataEvolutionService.getSimpleNumberDetailDifferences(
                this.referenceIndicators.fttxIndicators.nroHostingInOrangeNra,
                this.comparisonIndicators.fttxIndicators.nroHostingInOrangeNra,
            ),
            nroSro: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                Object.values(this.referenceIndicators.nroSroIndicator).flat(),
                Object.values(this.comparisonIndicators.nroSroIndicator).flat(),
                'count',
                IndicatorDetailDifferencesTypeEnum.NRO_SRO,
            ),
            opticalCableSupport: this._$indicatorFttxDataEvolutionService.getDetailDifferencesSeveralCountKeys(
                this.referenceIndicators.opticalCableSupport.table,
                this.comparisonIndicators.opticalCableSupport.table,
                ['data_geom', 'data_saisie'],
                IndicatorDetailDifferencesTypeEnum.OPTICAL_CABLE_SUPPORT,
            ),
            pathway: this._$indicatorFttxDataEvolutionService.getDetailDifferencesSeveralCountKeys(
                this.referenceIndicators.pathway.table,
                this.comparisonIndicators.pathway.table,
                ['nbChems', 'countGeom'],
                IndicatorDetailDifferencesTypeEnum.TECHNICAL_POINTS,
                ['countReal', 'idManager', 'idOwner'],
            ),
            pboData: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.anct.pboData,
                this.comparisonIndicators.anct.pboData,
                'nbPositions',
                IndicatorDetailDifferencesTypeEnum.PBO_DATA,
            ),
            plugsStatus: this._$indicatorFttxDataEvolutionService.getObjectDetailDifferences(
                this.referenceIndicators.fttxIndicators.plugsStatus,
                this.comparisonIndicators.fttxIndicators.plugsStatus,
            ),
            ptoStatus: this._$indicatorFttxDataEvolutionService.getObjectDetailDifferences(
                this.referenceIndicators.fttxIndicators.ptoStatus,
                this.comparisonIndicators.fttxIndicators.ptoStatus,
            ),
            raFtte: this._$indicatorFttxDataEvolutionService.getSimpleNumberDetailDifferences(
                this.referenceIndicators.fttxIndicators.fttxCables.raFtte,
                this.comparisonIndicators.fttxIndicators.fttxCables.raFtte,
            ),
            siteNumberByLogicalType: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.anct.siteNumberByLogicalType,
                this.comparisonIndicators.anct.siteNumberByLogicalType,
                'siteCount',
                IndicatorDetailDifferencesTypeEnum.SITE_NUMBER,
            ),
            technicalPoints: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.technicalPoints.table,
                this.comparisonIndicators.technicalPoints.table,
                'count',
                IndicatorDetailDifferencesTypeEnum.TECHNICAL_POINTS,
                ['idManager', 'idOwner'],
            ),
            trancheeLinearMeterByType: this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.fttxIndicators.trancheeLinearMeterByType,
                this.comparisonIndicators.fttxIndicators.trancheeLinearMeterByType,
                'linearMeter',
                IndicatorDetailDifferencesTypeEnum.LINEAR_METER_DEFAULT,
            ),
        };
    }

    updateIndicatorTypeSelected(value) {
        if (!Object.values(IndicatorsTypeEnum).includes(value)) {
            throw new Error(`Unknown value : the received value (${value}) is not part of IndicatorTypeEnum`);
        }

        this.loading = true;
        this.indicatorTypeSelected = value;

        const $element = angular.element('#indicatorsBox');
        this._$timeout(() => {
            angular.element('html').animate({ scrollTop: $element.offset().top }, 'slow');
            this.loading = false;
        });
    }
}

angular.module('dotic').component('fttxDataEvolutionViewIndicators', {
    controller: FttxDataEvolutionViewIndicatorsComponent,
    templateUrl: fttxDataEvolutionViewTemplate,
    bindings: {
        comparisonDeposit: '<',
        comparisonIndicators: '<',
        referenceDeposit: '<',
        referenceIndicators: '<',
        loading: '<',
    },
});
