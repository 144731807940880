import { sum as radashSum } from 'radash';

import { ChartService } from '../../../services/chart.service';
import { GeneralBarChartColorEnum } from '../../../services/color.service';
import { GraceKeyEnum } from '../../../models/grace.model';
import template from './linear-meter-indicator.html';

class LinearMeterIndicatorComponent {
    globalLengthColor = GeneralBarChartColorEnum.LINEAR_METER;
    chartData = { datasets: [], labels: [] };
    data = [];
    designColumnName = GraceKeyEnum.DESIGN;
    globalCount = 0;
    hasDesign = false;
    hasNbGc = false;
    libelleColumnText = '';
    isParentLoading = false;
    parentRef = null;
    type = '';

    constructor($chartService, $filter) {
        this.stackedBarChartOptions = {
            responsive: true,
            scales: $chartService.getDefaultScaleConfiguration(false),
        };
        this._translate = $filter('translate');
    }

    $onInit() {
        this.globalCount = Math.round(radashSum(this.data, (item) => item.linearMeter || 0));

        this.chartData = this.getChartData(this.data);
    }

    getChartData(data) {
        return { datasets: this.getDatasets(data), labels: data.map((item) => item.libelle) };
    }

    getDatasets(values) {
        return [
            ChartService.getTableChartDataset(
                values.map((value) => (value.linearMeter ?? 0).toFixed(2)),
                this._translate('shared.linearMeter', { TYPE: this.type }),
                GeneralBarChartColorEnum.LINEAR_METER,
            ),
        ];
    }
}

angular.module('dotic').component('linearMeterIndicator', {
    controller: LinearMeterIndicatorComponent,
    templateUrl: template,
    bindings: {
        data: '<',
        hasDesign: '<?',
        hasNbGc: '<?',
        libelleColumnText: '@',
        isParentLoading: '<',
        parentRef: '<',
        type: '@',
    },
});
