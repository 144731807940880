import template from './control-counts-card.component.html';

class ControlCountsCardComponent {
    count = 0;
    countColor = '';
    displayText = '';
    loading = false;
    onClick = () => {};
    title = '';

    click() {
        this.onClick();
    }
}

angular.module('dotic').component('controlCountsCard', {
    controller: ControlCountsCardComponent,
    templateUrl: template,
    bindings: {
        count: '<',
        countColor: '@',
        displayText: '@',
        loading: '<',
        onClick: '&',
        title: '@',
    },
});
