import { omit as radashOmit } from 'radash';

import { GraceKeyEnum } from '../models/grace.model';
import { HelpersService } from './helpers.service';

export const IndicatorDetailDifferencesTypeEnum = {
    ADDRESS_DEPLOYMENT: 'addressDeployment',
    ADVANCEMENT_LEVEL_BY_STATE: 'advancementLevelByState',
    AVERAGE_SATURATION: 'averageSaturation',
    CABLE_LENGTH_BY_IMPLANTATION: 'cableLengthByImplantation',
    CABLE_LENGTH_BY_ORGANIZATION: 'cableLengthByOrganization',
    CABLE_LENGTH_BY_OWNER_MANAGER: 'cableLengthByOwnerAndManager',
    CABLE_LENGTH_BY_PROVIDER: 'cableLengthByProvider',
    CABLE_LENGTH_BY_SLICE_TYPE: 'cableLengthBySliceType',
    CABLE_LENGTH_BY_TYPE: 'cableLengthByType',
    CODE_HEXACLE: 'codeHexacle',
    DISTANCE_NRO_PBO: 'distanceNroPbo',
    FIELD_OCCURRENCE: 'fieldOccurrence',
    LINEAR_METER_CLASS: 'linearMeterClass',
    LINEAR_METER_DEFAULT: 'linearMeter',
    LINEAR_METER_LOGICAL_TYPE: 'linearMeterLogicalType',
    LOCAL_NUMBER_BY_IMPLANTATION: 'localNumberByImplantationType',
    LOCAL_NUMBER_BY_DISTANCE_RANGE: 'localNumberByDistanceRange',
    NB_BOXES: 'nbBoxes',
    NB_CONNECTIONS_BY_IMPLANTATION_TYPE: 'nbConnectionsByImplantationType',
    NB_GC: 'nbGc',
    NB_PLUGS: 'nbPlugs',
    NB_OPTICAL_CHAMBER: 'nbOpticalChamber',
    NRO_NRA_DISTANCE: 'distanceBetweenNroNra',
    NRO_SRO: 'nroSro',
    OPTICAL_CABLES: 'opticalCables',
    OPTICAL_CABLE_SUPPORT: 'opticalCableSupport',
    PBO_DATA: 'pboData',
    RECORDS: 'records',
    SITE_NUMBER: 'siteNumber',
    TECHNICAL_POINTS: 'technicalPoints',
};

const TranslationKeyEnum = {
    AD_RACC: 'adRacc',
    ADDRESS_STATUS_ID: 'addressStatusId',
    ADDRESS_STATUS_NAME: 'addressStatusName',
    AVANCEMENT: 'avancement',
    BP_CODE: 'bpCode',
    CABLE_CAPACITY: 'cableCapacity',
    CABLE_LOGICAL_TYPE: 'cableLogicalType',
    CABLE_PHYSICAL_TYPE: 'cablePhysicalType',
    CABLE_TYPE: 'cableType',
    CAPACITY: 'capacity',
    CB_FO_TYPE: 'cbFoType',
    DEFINITION: 'definition',
    DETAIL: 'detail',
    DISTANCE_RANGE: 'distanceRange',
    FIELD: 'field',
    ID_MANAGER: 'idManager',
    ID_OWNER: 'idOwner',
    IMPLANTATION_CODE: 'implantationCode',
    IMPLANTATION: 'implantation',
    LIBELLE: 'libelle',
    LINEAR_METER: 'linearMeter',
    LOGICAL_TYPE: 'logicalType',
    MANAGER: 'manager',
    NB_CABLES: 'nbCables',
    NB_PLUGS_IN_PROGRESS: 'nbPlugsInProgress',
    NB_PLUGS_OPERATED: 'nbPlugsOperated',
    NB_PLUGS_PLANNED: 'nbPlugsPlanned',
    NB_POSITIONS: 'nbPositions',
    NB_SUF: 'nbSuf',
    NB_SUF_CONNECTED: 'nbSufConnected',
    NB_SUF_RATIO: 'nbSufRatio',
    NOM_TABLE: 'nom_table',
    ORGANIZATION: 'organization',
    OWNER: 'owner',
    PA_DTCLASS: 'paDtclass',
    PBO: 'pbo',
    POSITION_FUNCTION: 'positionFunction',
    PT_NATURE: 'ptNature',
    PHYSICAL_TYPE: 'physicalType',
    PTO_KO: 'ptoKo',
    PTO_OK: 'ptoOk',
    REFERENCE_NRO: 'referenceNRO',
    RESULT_TYPE: 'resultType',
    RF_DESIGN: 'design',
    SLICE_COMPOSITION: 'sliceComposition',
    ST_CODE: 'stCode',
    TEXT: 'text',
    TYPE: 'type',
    TYPOLOGIE: 'typologie',
    VALUE: 'value',
    ZIP_CODE: 'zipCode',
    ZN_CODE: 'znCode',
    ZS_CODE: 'zsCode',
};
export class IndicatorFttxDataEvolutionService {
    static nbDifferencesColumnName = 'nbDifferences';

    constructor($filter) {
        this._translate = $filter('translate');
    }

    getComparisonValue(comparisonValues, value, indicatorType) {
        return comparisonValues.find((cpValue) => {
            switch (indicatorType) {
                case IndicatorDetailDifferencesTypeEnum.ADDRESS_DEPLOYMENT:
                    return cpValue.zsCode === value.zsCode;
                case IndicatorDetailDifferencesTypeEnum.ADVANCEMENT_LEVEL_BY_STATE:
                    return cpValue.addressStatusId === value.addressStatusId;
                case IndicatorDetailDifferencesTypeEnum.AVERAGE_SATURATION:
                    return cpValue.cableCapacity === value.cableCapacity;
                case IndicatorDetailDifferencesTypeEnum.CABLE_LENGTH_BY_IMPLANTATION:
                    return (
                        cpValue.cableCapacity === value.cableCapacity &&
                        cpValue.cableLogicalType === value.cableLogicalType &&
                        cpValue.implantation === value.implantation
                    );
                case IndicatorDetailDifferencesTypeEnum.CABLE_LENGTH_BY_ORGANIZATION:
                    return (
                        cpValue.organization === value.organization &&
                        cpValue.cableCapacity === value.cableCapacity &&
                        cpValue.design === value.design
                    );
                case IndicatorDetailDifferencesTypeEnum.CABLE_LENGTH_BY_OWNER_MANAGER:
                    return cpValue.manager === value.manager && cpValue.owner === value.owner;
                case IndicatorDetailDifferencesTypeEnum.CABLE_LENGTH_BY_PROVIDER:
                    return cpValue.type === value.type && cpValue.design === value.design;
                case IndicatorDetailDifferencesTypeEnum.CABLE_LENGTH_BY_SLICE_TYPE:
                    return cpValue.sliceComposition === value.sliceComposition;
                case IndicatorDetailDifferencesTypeEnum.CABLE_LENGTH_BY_TYPE:
                    return (
                        cpValue.cableType === value.cableType &&
                        cpValue.cableLogicalType === value.cableLogicalType &&
                        cpValue.cablePhysicalType === value.cablePhysicalType
                    );
                case IndicatorDetailDifferencesTypeEnum.CODE_HEXACLE:
                    return cpValue.zipCode === value.zipCode;
                case IndicatorDetailDifferencesTypeEnum.DISTANCE_NRO_PBO:
                    return (
                        cpValue.bpCode === value.bpCode &&
                        cpValue.stCode === value.stCode &&
                        cpValue.znCode === value.znCode
                    );
                case IndicatorDetailDifferencesTypeEnum.FIELD_OCCURRENCE:
                    return cpValue.field === value.field;
                case IndicatorDetailDifferencesTypeEnum.LINEAR_METER_CLASS:
                case IndicatorDetailDifferencesTypeEnum.LINEAR_METER_DEFAULT:
                case IndicatorDetailDifferencesTypeEnum.LINEAR_METER_LOGICAL_TYPE:
                case IndicatorDetailDifferencesTypeEnum.NB_CONNECTIONS_BY_IMPLANTATION_TYPE:
                    return cpValue.libelle === value.libelle;
                case IndicatorDetailDifferencesTypeEnum.LOCAL_NUMBER_BY_IMPLANTATION:
                    return cpValue.implantation === value.implantation && cpValue.adRacc === value.adRacc;
                case IndicatorDetailDifferencesTypeEnum.LOCAL_NUMBER_BY_DISTANCE_RANGE:
                    return cpValue.implantation === value.implantation && cpValue.distanceRange === value.distanceRange;
                case IndicatorDetailDifferencesTypeEnum.NB_GC:
                    return (
                        cpValue.ptNature === value.ptNature &&
                        cpValue.physicalType === value.physicalType &&
                        cpValue.owner === value.owner
                    );
                case IndicatorDetailDifferencesTypeEnum.NB_OPTICAL_CHAMBER:
                    return cpValue.paDtclass === value.paDtclass;
                case IndicatorDetailDifferencesTypeEnum.NB_PLUGS:
                    return cpValue.icon === value.icon && cpValue.color === value.color;
                case IndicatorDetailDifferencesTypeEnum.NB_BOXES:
                case IndicatorDetailDifferencesTypeEnum.NRO_SRO:
                case IndicatorDetailDifferencesTypeEnum.OPTICAL_CABLES:
                    return cpValue.capacity === value.capacity && cpValue.type === value.type;
                case IndicatorDetailDifferencesTypeEnum.NRO_NRA_DISTANCE:
                    return (
                        cpValue.nroCode === value.nroCode &&
                        cpValue.nodeNroCode === value.nodeNroCode &&
                        cpValue.nraOperator === value.nraOperator &&
                        cpValue.referenceNRO === value.referenceNRO
                    );
                case IndicatorDetailDifferencesTypeEnum.OPTICAL_CABLE_SUPPORT:
                    return cpValue.avancement === value.avancement && cpValue.typologie === value.typologie;
                case IndicatorDetailDifferencesTypeEnum.PBO_DATA:
                    return (
                        cpValue.logicalType === value.logicalType && cpValue.positionFunction === value.positionFunction
                    );
                case IndicatorDetailDifferencesTypeEnum.RECORDS:
                    return cpValue.nom_table === value.nom_table;
                case IndicatorDetailDifferencesTypeEnum.SITE_NUMBER:
                    return cpValue.logicalType === value.logicalType;
                case IndicatorDetailDifferencesTypeEnum.TECHNICAL_POINTS:
                    return (
                        cpValue.idManager === value.idManager &&
                        cpValue.idOwner === value.idOwner &&
                        cpValue.detail === value.detail &&
                        cpValue.type === value.type &&
                        cpValue.avancement === value.avancement
                    );
                default:
                    return cpValue;
            }
        });
    }

    getDetailDifferences(
        referenceValues,
        comparisonValues,
        countKey,
        indicatorType,
        additionalIgnoreKeys = [],
        reformatTable = null,
    ) {
        const hasReferenceValues = !!referenceValues?.length;

        const keys = this.getDetailDifferencesKeys(
            referenceValues[0] ?? comparisonValues[0] ?? {},
            [countKey],
            additionalIgnoreKeys,
        );
        const baseValues = hasReferenceValues ? referenceValues : comparisonValues || [];

        const formatedValues = baseValues.map((value) => {
            const comparisonValue = hasReferenceValues
                ? this.getComparisonValue(comparisonValues, value, indicatorType)
                : {};
            const nbDifferences = HelpersService.formatDecimalNumber(
                (value[countKey] || 0) - (comparisonValue?.[countKey] || 0),
            );

            return {
                ...radashOmit(value, [countKey]),
                [IndicatorFttxDataEvolutionService.nbDifferencesColumnName]: hasReferenceValues
                    ? -nbDifferences
                    : nbDifferences,
            };
        });

        // Add all values from comparisonValues that not match any value in referencesValues
        if (hasReferenceValues) {
            const missingValues = this.getMissingValues(referenceValues, comparisonValues, [countKey], indicatorType);
            formatedValues.push(
                ...missingValues.map((value) => ({ ...value, nbDifferences: Object.values(value.nbDifferences)[0] })),
            );
        }

        return {
            columns: keys.map((key) => {
                return { name: key, text: this.getTranslation(key, indicatorType) };
            }),
            values: reformatTable ? reformatTable(formatedValues) : formatedValues,
        };
    }

    getDetailDifferencesKeys(baseKeys, countKeys, additionalIgnoreKeys = []) {
        return [
            ...Object.keys(baseKeys).filter(
                (key) => ![...countKeys, '$$hashKey', ...additionalIgnoreKeys].includes(key),
            ),
            IndicatorFttxDataEvolutionService.nbDifferencesColumnName,
        ];
    }

    getDetailDifferencesSeveralCountKeys(
        referenceValues,
        comparisonValues,
        countKeys,
        indicatorType,
        additionalIgnoreKeys = [],
    ) {
        const hasReferenceValues = !!referenceValues.length;
        const keys = this.getDetailDifferencesKeys(
            referenceValues[0] ?? comparisonValues[0] ?? {},
            countKeys,
            additionalIgnoreKeys,
        );
        const baseValues = hasReferenceValues ? referenceValues : comparisonValues;

        const formatedValues = baseValues.map((value) => {
            const comparisonValue = hasReferenceValues
                ? this.getComparisonValue(comparisonValues, value, indicatorType)
                : {};
            const differencesCountByKeys = countKeys.reduce((acc, countKey) => {
                return {
                    ...acc,
                    [countKey]: HelpersService.formatDecimalNumber(
                        (value[countKey] || 0) - (comparisonValue?.[countKey] || 0),
                    ),
                };
            }, {});

            return {
                ...radashOmit(value, countKeys),
                [IndicatorFttxDataEvolutionService.nbDifferencesColumnName]: countKeys.reduce((acc, key) => {
                    return {
                        ...acc,
                        [key]: hasReferenceValues ? -differencesCountByKeys[key] : differencesCountByKeys[key],
                    };
                }, {}),
            };
        });

        // Add all values from comparisonValues that not match any value in referencesValues
        if (hasReferenceValues) {
            formatedValues.push(...this.getMissingValues(referenceValues, comparisonValues, countKeys, indicatorType));
        }

        return {
            columns: keys.map((key) => {
                return { name: key, text: this.getTranslation(key, indicatorType) };
            }),
            values: formatedValues,
        };
    }

    getMissingValues(referenceValues, comparisonValues, countKeys, indicatorType) {
        return comparisonValues
            .filter((value) => !this.getComparisonValue(referenceValues, value, indicatorType))
            .map((value) => {
                return {
                    ...radashOmit(value, countKeys),
                    nbDifferences: countKeys.reduce(
                        (acc, countKey) => ({
                            ...acc,
                            [countKey]: HelpersService.formatDecimalNumber(value[countKey]),
                        }),
                        {},
                    ),
                };
            });
    }

    getObjectDetailDifferences(referenceValue, comparisonValue) {
        return {
            columns: [
                { name: 'type', text: this.getTranslation('resultType') },
                {
                    name: IndicatorFttxDataEvolutionService.nbDifferencesColumnName,
                    text: this.getTranslation(IndicatorFttxDataEvolutionService.nbDifferencesColumnName),
                },
            ],
            values: Object.entries(comparisonValue).map(([key, value]) => ({
                [IndicatorFttxDataEvolutionService.nbDifferencesColumnName]: HelpersService.formatDecimalNumber(
                    value - (referenceValue[key] || 0),
                ),
                type: this.getTranslation(key),
            })),
        };
    }

    getSimpleNumberDetailDifferences(referenceValue, comparisonValue) {
        return {
            columns: [
                {
                    name: IndicatorFttxDataEvolutionService.nbDifferencesColumnName,
                    text: this.getTranslation(IndicatorFttxDataEvolutionService.nbDifferencesColumnName),
                },
            ],
            values: [
                {
                    [IndicatorFttxDataEvolutionService.nbDifferencesColumnName]: HelpersService.formatDecimalNumber(
                        (comparisonValue || 0) - (referenceValue || 0),
                    ),
                },
            ],
        };
    }

    getTranslation(key, indicatorType = null) {
        switch (key) {
            case TranslationKeyEnum.AD_RACC:
                return `${this._translate('shared.connectionTypeId')} (ad_racc)`;
            case TranslationKeyEnum.ADDRESS_STATUS_ID:
                return 'ID';
            case TranslationKeyEnum.ADDRESS_STATUS_NAME:
                return this._translate('shared.addressStatus');
            case TranslationKeyEnum.AVANCEMENT:
                return this._translate('shared.progress');
            case TranslationKeyEnum.BP_CODE:
                return 'PBO';
            case TranslationKeyEnum.CABLE_LOGICAL_TYPE:
                return this.getTranslation(TranslationKeyEnum.LOGICAL_TYPE);
            case TranslationKeyEnum.CB_FO_TYPE:
                return this._translate('shared.type');
            case TranslationKeyEnum.DEFINITION:
                return this._translate('shared.description');
            case TranslationKeyEnum.DETAIL:
                if (indicatorType === IndicatorDetailDifferencesTypeEnum.TECHNICAL_POINTS) {
                    return this._translate('shared.natureDetail');
                }

                return this._translate('shared.detail');
            case TranslationKeyEnum.ID_MANAGER:
                return this._translate('shared.managerId');
            case TranslationKeyEnum.ID_OWNER:
                return this._translate('shared.ownerId');
            case TranslationKeyEnum.IMPLANTATION_CODE:
                return this._translate('shared.code');
            case TranslationKeyEnum.IMPLANTATION:
                return this._translate('shared.implantationType');
            case TranslationKeyEnum.LIBELLE:
            case TranslationKeyEnum.TEXT:
                if (indicatorType === IndicatorDetailDifferencesTypeEnum.LINEAR_METER_CLASS) {
                    return this.getTranslation(TranslationKeyEnum.PA_DTCLASS);
                }
                if (indicatorType === IndicatorDetailDifferencesTypeEnum.LINEAR_METER_LOGICAL_TYPE) {
                    return this.getTranslation(TranslationKeyEnum.LOGICAL_TYPE);
                }

                return this._translate('shared.type');
            case TranslationKeyEnum.NB_CABLES:
                return this._translate('sites.nbCables');
            case IndicatorFttxDataEvolutionService.nbDifferencesColumnName:
                return this._translate('shared.difference');
            case TranslationKeyEnum.NB_SUF:
            case TranslationKeyEnum.NB_SUF_CONNECTED:
            case TranslationKeyEnum.NB_SUF_RATIO:
                return this._translate(`statisticsPage.${key}`, { TYPE: 'local' });
            case TranslationKeyEnum.NB_PLUGS_IN_PROGRESS:
                return this._translate('shared.plugStatus', { TYPE: 'ongoing' });
            case TranslationKeyEnum.NB_PLUGS_OPERATED:
                return this._translate('shared.plugStatus', { TYPE: 'operated' });
            case TranslationKeyEnum.NB_PLUGS_PLANNED:
                return this._translate('shared.plugStatus', { TYPE: 'planned' });
            case TranslationKeyEnum.NOM_TABLE:
                return this._translate('shared.table');
            case TranslationKeyEnum.PA_DTCLASS:
                return this._translate('shared.class');
            case TranslationKeyEnum.PT_NATURE:
                return this._translate('shared.nature');
            case TranslationKeyEnum.PTO_KO:
                return this._translate('indicator.ptoAddresses');
            case TranslationKeyEnum.PTO_OK:
                return this._translate('indicator.ptoAddresses', { WITH: 1 });
            case TranslationKeyEnum.CABLE_PHYSICAL_TYPE:
                return this._translate('shared.physicalType');
            case TranslationKeyEnum.PBO:
                return `${this._translate('shared.code')} (nb_code)`;
            case TranslationKeyEnum.REFERENCE_NRO:
                return this._translate('shared.referenceNro');
            case TranslationKeyEnum.RF_DESIGN:
                return GraceKeyEnum.DESIGN;
            case TranslationKeyEnum.SLICE_COMPOSITION:
                return this._translate('shared.sliceType');
            case TranslationKeyEnum.ST_CODE:
                return indicatorType === IndicatorDetailDifferencesTypeEnum.DISTANCE_NRO_PBO ? 'NRO' : 'SRO';
            case TranslationKeyEnum.CABLE_TYPE:
            case TranslationKeyEnum.TYPE:
            case TranslationKeyEnum.TYPOLOGIE:
                if (indicatorType === IndicatorDetailDifferencesTypeEnum.TECHNICAL_POINTS) {
                    return this.getTranslation(TranslationKeyEnum.PT_NATURE);
                }

                return this._translate('shared.type');
            case TranslationKeyEnum.ZIP_CODE:
                return this._translate('shared.department');
            case TranslationKeyEnum.ZN_CODE:
                return 'ZANRO';
            case TranslationKeyEnum.ZS_CODE:
                return 'ZSRO';
            default:
                return this._translate(`shared.${key}`, { COUNT: 1 });
        }
    }
}

angular.module('dotic').factory('$indicatorFttxDataEvolutionService', IndicatorFttxDataEvolutionService);
