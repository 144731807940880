import { omit as radashOmit, pick as radashPick, unique as radashUnique } from 'radash';

import { FMEJobEnum } from '../../../models/deposit.model';
import fttxDataEvolutionViewTemplate from './fttx-data-evolution-view.html';
import { FttxHeritageInventoriesApi } from '../../../../sdk/connect-control-api-v1/src';
import { HelpersService } from '../../../services/helpers.service';
import { IndicatorDetailDifferencesTypeEnum } from '../../../services/indicator-fttx-data-evolution.service';

const initialIndicators = {
    anct: {
        cableLengthByImplantation: [],
        cableLengthByOrganizationAndDesign: [],
        cableLengthByOwnerAndManager: [],
        cableLengthBySliceType: [],
        cableLengthByType: [],
        codeHexacle: [],
        distanceBetweenNroNra: [],
        fieldOccurrence: [],
        localNumberByDistanceRange: [],
        localNumberByImplantation: [],
        localNumberByLogicalType: [],
        localNumberBySiteLogicalType: [],
        pboData: [],
        siteNumberByLogicalType: [],
    },
    connectivity: {},
    distanceBetweenNroPbo: [],
    fttxIndicators: {
        deploymentProgress: {
            advancementLevelByStateAndCapacityMax: [],
            advancementLevelByPTOAndCapacityMax: 0,
            beingDeployedAddresses: [],
            deployedAddresses: [],
        },
        fttxCables: {
            cableLengthByFoCapacityAndTypelog: [],
            cableLengthByTypeAndProvider: [],
            raFtte: 0,
        },
        gc: {
            linearMeterByClass: [],
            linearMeterByMethod: [],
            nbEnedis: 0,
            nbGroupBy: { nbByNature: [], nbByPhysicalType: [] },
        },
        linearMeterByLogicalType: [],
        nbOpticalChamber: [],
        nbPa: 0,
        nbConnectionsByImplantationType: [],
        networkSaturationLevel: {
            averageBoxSaturationRate: [],
            averageCableSaturationRate: [],
            averageBoxTheoreticalReserveRatio: [],
            averageCableTheoreticalReserveRatio: [],
        },
        nroHostingInOrangeNra: 0,
        plugsStatus: { nbPlugsInProgress: 0, nbPlugsOperated: 0, nbPlugsPlanned: 0 },
        ptoStatus: { ptoKo: 0, ptoOk: 0 },
        trancheeLinearMeterByType: [],
    },
    housingIndicator: { children: [], parent: {} },
    nbBoxIndicator: { data: [], junctionBox: 0, nbBPE: [] },
    nroSroIndicator: { nro: [], sro: [] },
    opticalCableSupport: { opticalSupports: {}, status: [], table: [] },
    pathway: { pathway_support: {}, status: [], table: [] },
    plugIndicator: { children: [], parent: {} },
    recordsIndicator: { abandonedObjectTable: [], recordsTable: [] },
    technicalPoints: { nature_support: {}, status: [], table: [] },
};

class FttxDataEvolutionViewPage {
    comparisonDeposit = null;
    comparisonIndicators = { ...initialIndicators };
    depositFileNamesListValues = [];
    depositVersions = [];
    depositVersionsListValues = [];
    inventory = null;
    inventoryId = null;
    isDepositInformationToggleOpen = false;
    isDepositOverviewToggleOpen = false;
    isAnalyticsIndicatorsToggleOpen = true;
    loading = true;
    loadingComparisonDepositData = false;
    recordDetailDifferencesArray = { columns: [], values: [] };
    referenceIndicators = { ...initialIndicators };
    selectComparisonDepositFileName = null;
    selectComparisonDepositId = null;

    constructor(
        $apiClientService,
        $filter,
        $indicatorFttxDataEvolutionService,
        $indicatorNewPlugService,
        $location,
        $modalService,
        $scope,
        $state,
        $stateParams,
        $timeout,
        $toasterService,
        depositProvider,
        reportProvider,
    ) {
        this._depositProvider = depositProvider;
        this._fttxHeritageInventoriesApi = new FttxHeritageInventoriesApi($apiClientService.client);
        this._reportProvider = reportProvider;
        this._translate = $filter('translate');
        this._$indicatorFttxDataEvolutionService = $indicatorFttxDataEvolutionService;
        this._$indicatorNewPlugService = $indicatorNewPlugService;
        this._$modalService = $modalService;
        this._$state = $state;
        this._$timeout = $timeout;
        this._$toasterService = $toasterService;

        this.inventoryId = $stateParams.inventoryId;

        $scope.$emit('keepPreviousNavigation', {
            allowedPreviousKeys: ['fttxDataEvolution'],
            defaultPrevious: {
                key: 'fttxDataEvolution',
                title: this._translate('shared.fttxDataEvolution'),
                href: $location.path(),
            },
            newPage: [
                {
                    key: 'fttxDataEvolutionView',
                    title: this._translate('shared.fttxDataEvolutionView'),
                    href: $location.path(),
                },
            ],
        });

        $scope.$watch('$ctrl.selectComparisonDepositId', () => {
            this.fetchComparisonDeposit(this.selectComparisonDepositId);
        });

        $scope.$watch('$ctrl.selectComparisonDepositFileName', () => {
            this.filterVersionsByFileName(this.selectComparisonDepositFileName);
        });
    }

    async $onInit() {
        try {
            this.inventory = (
                await this._fttxHeritageInventoriesApi.getFttxHeritageInventoryWithHttpInfo(this.inventoryId)
            ).response.body;

            this.depositVersions = await this._depositProvider.listVersions({
                projectId: this.inventory.project._id,
                phase: this.inventory.deposit.phase,
                job: FMEJobEnum.CONTROL,
                deliveryZoneId: this.inventory.deposit.deliveryZone,
            });
            this.referenceIndicators = this.formatIndicators(this.inventory.deposit.indicators);
        } catch {
            this._$toasterService.error(this._translate('toaster.error'));
        }
        this.setFormValues();

        this._$timeout(() => (this.loading = false));
    }

    async fetchComparisonDeposit(value) {
        if (!value) {
            this.comparisonDeposit = null;
            this.selectComparisonDepositId = null;

            return;
        }

        this.loadingComparisonDepositData = true;
        try {
            this.comparisonDeposit = await this._depositProvider.get(value);
            const comparisonReport = await this._reportProvider.get(this.comparisonDeposit.report.id);
            this.comparisonIndicators = this.formatIndicators(comparisonReport.indicators);
            this.recordDetailDifferencesArray = this._$indicatorFttxDataEvolutionService.getDetailDifferences(
                this.referenceIndicators.recordsIndicator.recordsTable,
                this.comparisonIndicators.recordsIndicator.recordsTable,
                'nb_lignes_par_table',
                IndicatorDetailDifferencesTypeEnum.RECORDS,
                ['activated'],
            );
        } catch {
            this._$toasterService.error(this._translate('toaster.error'));
        }

        this._$timeout(() => (this.loadingComparisonDepositData = false));
    }

    filterVersionsByFileName(fileName) {
        const filteredVersions = this.depositVersions.filter(
            (depositVersion) => !fileName || depositVersion.zipFile.originalname === fileName,
        );

        this.depositVersionsListValues = HelpersService.toKeyValue(filteredVersions, {
            keyField: 'id',
            valueField: 'version',
        }).sort((a, b) => Number(b.value) - Number(a.value));

        if (!filteredVersions.find((depositVersion) => depositVersion.id === this.selectComparisonDepositId)) {
            this.comparisonDeposit = null;
            this.selectComparisonDepositId = null;
        }
    }

    formatIndicators(indicators) {
        return {
            anct: indicators.ANCT,
            fttxIndicators: {
                ...radashOmit(indicators.fttxEvolution, ['gc']),
                gc: {
                    ...radashPick(indicators.fttxEvolution.gc, [
                        'linearMeterByClass',
                        'linearMeterByMethod',
                        'nbEnedis',
                    ]),
                    nbGroupBy: {
                        nbByNature: indicators.fttxEvolution.gc.nbByNature,
                        nbByPhysicalType: indicators.fttxEvolution.gc.nbByPhysicalType,
                    },
                },
            },
            ...radashPick(indicators, ['connectivity', 'distanceBetweenNroPbo', 'opticalCableSupport', 'pathway']),
            housingIndicator: this._$indicatorNewPlugService.initHousingTree(indicators.newPlug, null, false),
            nbBoxIndicator: {
                data: [
                    { value: indicators.pbo, id: 'pbo' },
                    { value: indicators.bpe, id: 'bpe' },
                    { value: indicators.type_ebp_pto, id: 'pto' },
                ],
                junctionBox: indicators.junctionBox,
                nbBPE: indicators.nbBPE,
            },
            nroSroIndicator: { nro: indicators.nroTypePhy, sro: indicators.sroTypePhy },
            plugIndicator: this._$indicatorNewPlugService.initPlugTree(indicators.newPlug, null, false),
            recordsIndicator: {
                recordsTable: indicators.tables ?? [],
                abandonedObjectTable: indicators.abandonedObject || [],
            },
            technicalPoints: indicators.nature_type_phy,
        };
    }

    async removeInventory() {
        const isAccepted = await this._$modalService.triggerRemoveModal(
            this._translate('removeModal.heritageInventory', {
                COUNT: 1,
                NAME: this.inventory.name,
            }).toLowerCase(),
        );
        if (!isAccepted) {
            return;
        }

        try {
            await this._fttxHeritageInventoriesApi.removeFttxHeritageInventoryWithHttpInfo(this.inventoryId);

            this._$toasterService.info(this._translate('removeModal.success', { COUNT: 1 }));
            await this._$state.go('app.fttxDataEvolution');
        } catch {
            this._$toasterService.error(this._translate('removeModal.failure', { COUNT: 1 }));
        }
    }

    setFormValues() {
        this.depositVersionsListValues = HelpersService.toKeyValue(this.depositVersions, {
            keyField: 'id',
            valueField: 'version',
        }).sort((a, b) => Number(b.value) - Number(a.value));

        this.depositFileNamesListValues = radashUnique(
            this.depositVersions.map((item) => ({
                key: item.zipFile.originalname,
                value: item.zipFile.originalname,
            })),
            (item) => item.value,
        );
    }
}

angular.module('dotic').component('fttxDataEvolutionView', {
    controller: FttxDataEvolutionViewPage,
    templateUrl: fttxDataEvolutionViewTemplate,
});
