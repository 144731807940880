class PdfReportService {
    constructor($document, $filter, $indicatorControlPointService, $location, $reportService, $state) {
        this._$document = $document;
        this._$indicatorControlPointService = $indicatorControlPointService;
        this._$location = $location;
        this._$reportService = $reportService;
        this._$state = $state;
        this._prefixNumber = $filter('prefixNumber');
        this._translate = $filter('translate');
    }

    definePageNumbers() {
        const pages = this._$document.find('.page');
        for (let i = 0; i < pages.length; i++) {
            const pageNumbers = pages[i].getElementsByClassName('page__footer-number');
            if (pageNumbers[0]) {
                pageNumbers[0].innerHTML = `${this._prefixNumber(i + 1)} / ${pages.length}`;
            }
        }
    }

    getControlPointChartData(report, hasLinkBetweenGridsEnabled) {
        const controls = this._$reportService.getControls(report.controls, report.deposit.gridControl).controls;
        const formattedControls = controls.map((controlPoint) => {
            return { ...controlPoint, uniqId: `${controlPoint.severity}-${controlPoint.controlPoint}` };
        });

        return this._$indicatorControlPointService.loadControlPointBySeverityChart(
            this._$indicatorControlPointService.computeChartData(formattedControls),
            null,
            false,
            hasLinkBetweenGridsEnabled,
        );
    }

    getThematicsLength(controls) {
        return Object.keys(controls.thematics).length;
    }

    initHousePlugTree(indicator, type) {
        let icon = '';
        let parentKey = '';
        let privateCount = 0;
        let proCount = 0;
        let totalCount = 0;

        switch (type) {
            case 'house':
                icon = 'ui-home';
                parentKey = 'shared.house';
                proCount = indicator.sum_nbl_pro || 0;
                privateCount = indicator.sum_nbl_hab || 0;
                totalCount = indicator.sum_nbl || proCount + privateCount;
                break;
            case 'plug':
                icon = 'plugin';
                parentKey = 'shared.potentialCatch';
                proCount = indicator.sum_nbpr_pro || 0;
                privateCount = indicator.sum_nbpr_hab || 0;
                totalCount = indicator.sum_nbpr || proCount + privateCount;
                break;
        }

        return {
            children: [
                {
                    icon: 'home',
                    color: '#e971b1',
                    text: this._translate('shared.privateIndividual', { COUNT: privateCount }),
                    value: privateCount,
                },
                {
                    icon: 'industries-4',
                    color: '#0b99e0',
                    text: this._translate('shared.professional', { COUNT: proCount }),
                    value: proCount,
                },
            ],
            parent: {
                icon: icon,
                color: '#5069a9',
                text: this._translate(parentKey, { COUNT: totalCount }),
                value: totalCount,
            },
        };
    }

    initThematics(thematics) {
        const limits = {
            remplissage: 10,
            structure: 10,
            'coherence metier': 10,
            'donnees et modele': 10,
            'geometrie et topologie': 10,
            geometrie: 10,
            topologie: 10,
            'format et coherence grace': 10,
            'compatibilite si orange': 10,
            'chronologie des donnees': 10,
        };

        const newThematics = { ...thematics };

        Object.keys(newThematics).forEach((key) => {
            newThematics[key].groups = this._$reportService.computeChunk(
                newThematics[key].controls.all ?? newThematics[key].controls,
                limits[key] || 6,
            );
        });

        return newThematics;
    }

    setScopeNavigation(report, scope) {
        scope.$emit('keepPreviousNavigation', {
            allowedPreviousKeys: ['report', 'deposits'],
            defaultPrevious: [
                {
                    title: this._translate('shared.controlDeposit'),
                    href: this._$state.href('app.depositJob-control'),
                    key: 'deposits',
                },
                {
                    title: `${this._translate('shared.report', { COUNT: 1 })} ${report.deposit.name}`,
                    href: this._$state.href('app.reportDetail', {
                        reportId: report.id,
                    }),
                    key: 'report',
                },
            ],
            newPage: [
                {
                    key: 'reportPDF',
                    title: this._translate('pdf.overview'),
                    href: this._$location.path(),
                },
            ],
        });
    }
}

angular
    .module('dotic')
    .factory(
        '$pdfReportService',
        ($document, $filter, $indicatorControlPointService, $location, $reportService, $state) =>
            new PdfReportService($document, $filter, $indicatorControlPointService, $location, $reportService, $state),
    );
