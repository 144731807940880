import template from './controlChartComponent.html';

class CcControlChartController {
    constructor($filter) {
        this._translate = $filter('translate');

        this.loading = true;
        this.controlPointData = {};
        this.reportControls = [];
        this.chartSize = 244;
        this.notResponsive = false;
    }

    $onChanges() {
        if (angular.isUndefined(this.reportControls)) {
            return null;
        }

        if (angular.isDefined(this.size)) {
            this.chartSize = parseInt(this.size, 10);
        }

        if (angular.isUndefined(this.showErrors)) {
            this.showErrors = true;
        }

        this.loading = true;

        const controlActivatedLength = this.reportControls.length;
        const controlData = {
            successControls: 0,
            blockingControls: 0,
            majorControls: 0,
            minorControls: 0,
            errorControls: 0,
        };

        this.reportControls.forEach((control) => {
            if (!control.faults) {
                controlData.successControls += 1;

                return;
            }

            if (control.faults < 0) {
                controlData.errorControls += 1;

                return;
            }

            if (control.severity === 'minor') {
                controlData.minorControls += 1;

                return;
            }

            if (control.severity === 'major') {
                controlData.majorControls += 1;

                return;
            }

            controlData.blockingControls += 1;
        });

        const labels = [
            this._translate('shared.noAnomalyControl', {
                COUNT: controlData.successControls,
            }),
            this._translate('shared.anomalyControlPoint', { COUNT: controlData.minorControls, SEVERITY: 'minor' }),
            this._translate('shared.anomalyControlPoint', { COUNT: controlData.majorControls, SEVERITY: 'major' }),
            this._translate('shared.anomalyControlPoint', {
                COUNT: controlData.blockingControls,
                SEVERITY: 'blocking',
            }),
        ];

        const datasets = [
            {
                data: [
                    controlData.successControls,
                    controlData.minorControls,
                    controlData.majorControls,
                    controlData.blockingControls,
                ],
                backgroundColor: ['#009688', '#FECA0B', '#FF7761', '#c72c48'],
            },
        ];

        if (this.showErrors) {
            labels.push(
                this._translate('shared.inoperativeControl', {
                    COUNT: controlData.errorControls,
                }),
            );
            datasets[0].data.push(controlData.errorControls);
            datasets[0].backgroundColor.push('#b2b2ba');
        }

        this.controlPointData = {
            bigLegend: {
                figure: `${controlData.successControls}/${controlActivatedLength}`,
                name: this._translate('shared.controlPoint', { COUNT: controlData.successControls }),
            },
            labels,
            datasets,
        };

        this.loading = false;
    }
}

angular.module('dotic').component('ccControlChart', {
    controller: CcControlChartController,
    bindings: {
        reportControls: '<',
        size: '<',
        notResponsive: '<?',
        showErrors: '<',
    },
    templateUrl: template,
});
