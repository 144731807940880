/**
 * ConnectControl API Documentation (v1)
 * The official ConnectControl Open API documentation (v1)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import FttxHeritageInventoryDeposit from './FttxHeritageInventoryDeposit';

/**
 * The FttxHeritageInventory model module.
 * @module model/FttxHeritageInventory
 * @version 1.0.0
 */
class FttxHeritageInventory {
    /**
     * Constructs a new <code>FttxHeritageInventory</code>.
     * @alias module:model/FttxHeritageInventory
     * @param createdBy {String} 
     * @param createdAt {String} 
     * @param company {String} 
     * @param deposit {module:model/FttxHeritageInventoryDeposit} 
     * @param id {String} 
     * @param name {String} 
     * @param updatedAt {String} 
     */
    constructor(createdBy, createdAt, company, deposit, id, name, updatedAt) { 
        
        FttxHeritageInventory.initialize(this, createdBy, createdAt, company, deposit, id, name, updatedAt);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, createdBy, createdAt, company, deposit, id, name, updatedAt) { 
        obj['createdBy'] = createdBy;
        obj['createdAt'] = createdAt;
        obj['company'] = company;
        obj['deposit'] = deposit;
        obj['id'] = id;
        obj['name'] = name;
        obj['updatedAt'] = updatedAt;
    }

    /**
     * Constructs a <code>FttxHeritageInventory</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/FttxHeritageInventory} obj Optional instance to populate.
     * @return {module:model/FttxHeritageInventory} The populated <code>FttxHeritageInventory</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new FttxHeritageInventory();

            if (data.hasOwnProperty('createdBy')) {
                obj['createdBy'] = ApiClient.convertToType(data['createdBy'], 'String');
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'String');
            }
            if (data.hasOwnProperty('company')) {
                obj['company'] = ApiClient.convertToType(data['company'], 'String');
            }
            if (data.hasOwnProperty('deposit')) {
                obj['deposit'] = FttxHeritageInventoryDeposit.constructFromObject(data['deposit']);
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('project')) {
                obj['project'] = ApiClient.convertToType(data['project'], 'String');
            }
            if (data.hasOwnProperty('updatedAt')) {
                obj['updatedAt'] = ApiClient.convertToType(data['updatedAt'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>FttxHeritageInventory</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>FttxHeritageInventory</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of FttxHeritageInventory.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['createdBy'] && !(typeof data['createdBy'] === 'string' || data['createdBy'] instanceof String)) {
            throw new Error("Expected the field `createdBy` to be a primitive type in the JSON string but got " + data['createdBy']);
        }
        // ensure the json data is a string
        if (data['createdAt'] && !(typeof data['createdAt'] === 'string' || data['createdAt'] instanceof String)) {
            throw new Error("Expected the field `createdAt` to be a primitive type in the JSON string but got " + data['createdAt']);
        }
        // ensure the json data is a string
        if (data['company'] && !(typeof data['company'] === 'string' || data['company'] instanceof String)) {
            throw new Error("Expected the field `company` to be a primitive type in the JSON string but got " + data['company']);
        }
        // validate the optional field `deposit`
        if (data['deposit']) { // data not null
          FttxHeritageInventoryDeposit.validateJSON(data['deposit']);
        }
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['project'] && !(typeof data['project'] === 'string' || data['project'] instanceof String)) {
            throw new Error("Expected the field `project` to be a primitive type in the JSON string but got " + data['project']);
        }
        // ensure the json data is a string
        if (data['updatedAt'] && !(typeof data['updatedAt'] === 'string' || data['updatedAt'] instanceof String)) {
            throw new Error("Expected the field `updatedAt` to be a primitive type in the JSON string but got " + data['updatedAt']);
        }

        return true;
    }


}

FttxHeritageInventory.RequiredProperties = ["createdBy", "createdAt", "company", "deposit", "id", "name", "updatedAt"];

/**
 * @member {String} createdBy
 */
FttxHeritageInventory.prototype['createdBy'] = undefined;

/**
 * @member {String} createdAt
 */
FttxHeritageInventory.prototype['createdAt'] = undefined;

/**
 * @member {String} company
 */
FttxHeritageInventory.prototype['company'] = undefined;

/**
 * @member {module:model/FttxHeritageInventoryDeposit} deposit
 */
FttxHeritageInventory.prototype['deposit'] = undefined;

/**
 * @member {String} id
 */
FttxHeritageInventory.prototype['id'] = undefined;

/**
 * @member {String} name
 */
FttxHeritageInventory.prototype['name'] = undefined;

/**
 * @member {String} project
 */
FttxHeritageInventory.prototype['project'] = undefined;

/**
 * @member {String} updatedAt
 */
FttxHeritageInventory.prototype['updatedAt'] = undefined;






export default FttxHeritageInventory;

