import template from './icon-list.component.html';

class IconListComponent {
    iconOptions = [];
    iconOptionsToDisplay = [];
    maxDisplay = -1;
    numberHidden = 0;

    $onInit() {
        if (this.maxDisplay < 0 || this.maxDisplay >= this.iconOptions.length) {
            this.iconOptionsToDisplay = this.iconOptions;

            return;
        }

        this.iconOptionsToDisplay = this.iconOptions.slice(0, Math.max(0, this.maxDisplay));
        this.numberHidden = this.iconOptions.length - this.iconOptionsToDisplay.length;
    }
}

angular.module('dotic').component('iconList', {
    bindings: {
        iconOptions: '<',
        maxDisplay: '<?',
    },
    controller: IconListComponent,
    templateUrl: template,
});
