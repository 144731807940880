import { iterate as radashIterate } from 'radash';

import {
    AddressStateTypeEnum,
    BoxTypeEnum,
    CableLogicalTypeEnum,
    DistanceRangeEnum,
    FieldEnum,
    ImplantationTypeEnum,
    LogicalTypeEnum,
    PositionFunctionTypeEnum,
    PrecisionClassEnum,
    SiteLogicalTypeEnum,
    SliceTypeEnum,
    TechnicalPointNatureTypeEnum,
} from '../models/grace.model';

export const DoughnutChartColorEnum = {
    BIG_FAILURE: '#dd3073',
    BIG_SUCCESS: '#00A889',
    FAILURE: '#b5285e',
    IN_PROGRESS: '#0647ea',
    NEUTRAL: '#b2b2ba',
    SMALL_FAILURE: '#5e1430',
    SMALL_SUCCESS: '#004538',
    SUCCESS: '#008c73',
};

export const GeneralBarChartColorEnum = {
    BOX: '#ffb935',
    IMPLANTATION_TYPE: '#36831e',
    LENGTH: '#919dd4',
    LINEAR_METER: '#f88e55',
    SATURATION_RATE: '#9c0f89',
};

export const GeneralIconColorEnum = {
    BUILDING: '#e971b1',
    CLIENT: '#6d74f6',
    DESIGN: '#9e08a7',
    FIBER: '#45a19a',
    IMPLANTATION_TYPE: '#36831e',
    LOCAL: '#384c57',
    LOGICAL_TYPE: '#0c3794',
    NETWORK: '#c78935',
    ORGANIZATION: '#86a4a1',
    ROAD: '#c5606a',
};

export class ColorService {
    static colorsPalette = ['#91be3e', '#dd3073', '#5a52d1', '#733f28', '#00a889'];
    static genericDistanceColorOrdered = ['#50d0c3', '#00a2fb', '#5069a9', '#edb349', '#dd3073'];
    static hexadecimalCharacters = '0123456789ABCDEF';

    static getAddressStateColor(state) {
        switch (state) {
            case AddressStateTypeEnum.CI:
                return '#120457';
            case AddressStateTypeEnum.DE:
                return '#33ccda';
            case AddressStateTypeEnum.EC:
                return '#a96b50';
            case AddressStateTypeEnum.RC:
                return '#319416';
            case AddressStateTypeEnum.RD:
                return '#531029';
            case AddressStateTypeEnum.SI:
                return '#00a889';
            default:
                return ColorService.getRandomColor();
        }
    }

    static getBoxTypeColor(boxType) {
        switch (boxType) {
            case BoxTypeEnum.BD:
                return '#ffb935';
            case BoxTypeEnum.BPE:
                return '#e971b1';
            case BoxTypeEnum.PBO:
                return '#5069a9';
            case BoxTypeEnum.PTO:
                return '#00a889';
            default:
                return ColorService.getRandomColor();
        }
    }

    static getCableLogicalTypeColor(cableLogicalType) {
        switch (cableLogicalType) {
            case CableLogicalTypeEnum.BM:
                return '#f88e55';
            case CableLogicalTypeEnum.CD:
                return '#006768';
            case CableLogicalTypeEnum.CO:
                return '#00a889';
            case CableLogicalTypeEnum.CT:
                return '#bbd2e1';
            case CableLogicalTypeEnum.CX:
                return '#5069a9';
            case CableLogicalTypeEnum.DI:
                return '#0b99e0';
            case CableLogicalTypeEnum.NC:
                return '#bababa';
            case CableLogicalTypeEnum.RA:
                return '#edb349';
            case CableLogicalTypeEnum.TD:
                return '#e971b1';
            case CableLogicalTypeEnum.TR:
                return '#dd3073';
            default:
                return ColorService.getRandomColor();
        }
    }

    static getDistanceRangeColor(distanceRange) {
        switch (distanceRange) {
            case DistanceRangeEnum.FROM_0_TO_50:
                return DoughnutChartColorEnum.BIG_SUCCESS;
            case DistanceRangeEnum.FROM_50_TO_100:
                return DoughnutChartColorEnum.SUCCESS;
            case DistanceRangeEnum.FROM_100_TO_150:
                return DoughnutChartColorEnum.SMALL_SUCCESS;
            case DistanceRangeEnum.FROM_150_TO_200:
                return DoughnutChartColorEnum.NEUTRAL;
            case DistanceRangeEnum.FROM_200_TO_300:
                return DoughnutChartColorEnum.SMALL_FAILURE;
            case DistanceRangeEnum.FROM_300_TO_500:
                return DoughnutChartColorEnum.FAILURE;
            case DistanceRangeEnum.MORE_THAN_500:
                return DoughnutChartColorEnum.BIG_FAILURE;
            default:
                return ColorService.getRandomColor();
        }
    }

    static getDistinctRandomColors(number, defaultColors = []) {
        return radashIterate(
            number - defaultColors.length,
            (acc) => {
                const newRandomColor = ColorService.getDistinctRandomColor(acc);

                return [...acc, newRandomColor];
            },
            [...defaultColors],
        );
    }

    static getDistinctRandomColor(colors = []) {
        const randomColor = ColorService.getRandomColor();
        if (colors.every((color) => color.localeCompare(randomColor))) {
            return randomColor;
        }

        return ColorService.getDistinctRandomColor(colors);
    }

    static getGraceFieldColor(graceField) {
        switch (graceField) {
            case FieldEnum.AD_IMNEUF:
                return '#254535';
            case FieldEnum.AD_ISOLE:
                return '#5a52d1';
            case FieldEnum.AD_PRIO:
                return '#c1640a';
            default:
                return ColorService.getDistinctRandomColor();
        }
    }

    static getImplantationTypeColor(implantationType) {
        switch (implantationType) {
            case ImplantationTypeEnum.AIR:
                return '#91be3e';
            case ImplantationTypeEnum.AIR_TELECOM:
                return '#08c6b0';
            case ImplantationTypeEnum.FRONT:
                return '#8e8985';
            case ImplantationTypeEnum.PIPE:
                return '#6e8293';
            case ImplantationTypeEnum.SPECIFIC:
                return '#df877a';
            case ImplantationTypeEnum.TELECOM:
                return '#0859c6';
            case ImplantationTypeEnum.NULL:
                return '#000000';
            default:
                return ColorService.getRandomColor();
        }
    }

    static getLogicalTypeColor(logicalType) {
        switch (logicalType) {
            case LogicalTypeEnum.CLIENT:
                return '#e3bd2e';
            case LogicalTypeEnum.NETWORK:
                return '#b25633';
            default:
                return ColorService.getRandomColor();
        }
    }

    static getPositionFunctionTypeColor(positionFunctionType) {
        switch (positionFunctionType) {
            case PositionFunctionTypeEnum.AT:
                return '#e3bd2e';
            case PositionFunctionTypeEnum.CO:
                return '#08c6b0';
            case PositionFunctionTypeEnum.EP:
                return '#e38247';
            case PositionFunctionTypeEnum.MA:
                return '#2b6874';
            case PositionFunctionTypeEnum.PA:
                return '#1997c7';
            case PositionFunctionTypeEnum.PI:
                return '#218116';
            default:
                ColorService.getRandomColor();
        }
    }

    static getPrecisionClassColor(precisionType) {
        switch (precisionType) {
            case PrecisionClassEnum.A:
                return '#218116';
            case PrecisionClassEnum.AP:
                return '#e38247';
            case PrecisionClassEnum.B:
                return '#e13892';
            case PrecisionClassEnum.C:
                return '#71e9b7';
            case PrecisionClassEnum.EMPTY:
                return '#1997c7';
            default:
                return ColorService.getRandomColor();
        }
    }

    static getRandomColor() {
        return radashIterate(
            6,
            (acc) => {
                return [...acc, ColorService.hexadecimalCharacters[Math.floor(Math.random() * 16)]];
            },
            '#',
        ).join('');
    }

    static getSiteLogicalTypeColor(sliceLogicalType) {
        switch (sliceLogicalType) {
            case SiteLogicalTypeEnum.FTTH:
                return '#857ab3';
            case SiteLogicalTypeEnum.NRO:
                return '#2b6874';
            case SiteLogicalTypeEnum.SRO:
                return '#1997c7';
            default:
                return ColorService.getRandomColor();
        }
    }

    static getSliceTypeColor(sliceType) {
        switch (sliceType) {
            case SliceTypeEnum.MIXED:
                return '#d4b895';
            case SliceTypeEnum.OTHER:
                return '#4b2c34';
            case SliceTypeEnum.PEHD:
                return '#a9c9c4';
            case SliceTypeEnum.PVC:
                return '#cc87e0';
            default:
                return ColorService.getRandomColor();
        }
    }

    static getTechnicalPointsTypeColor(technicalPointType) {
        switch (technicalPointType) {
            case TechnicalPointNatureTypeEnum.BUILDING:
                return '#f88e55';
            case TechnicalPointNatureTypeEnum.CHAMBER:
                return '#dd3073';
            case TechnicalPointNatureTypeEnum.FRONT_ANCHOR:
                return '#5069a9';
            case TechnicalPointNatureTypeEnum.OTHER:
                return '#ffb935';
            case TechnicalPointNatureTypeEnum.SUPPORT:
                return '#50d061';
            default:
                return ColorService.getRandomColor();
        }
    }
}

angular.module('dotic').factory('$colorService', () => new ColorService());
